import { Grid, IconButton, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { CustomizedTableCell, CustomizedTableRow } from '../styles/TablebButtonStyles';
import { IoEyeOutline } from "react-icons/io5";
import PropTypes from 'prop-types';
import { Paginations } from '../sharable/pagination/Pagination';
export const UserListTable = ({isDarkMode,pageCount,currentPage,handlePageChange,TableData, handleView }) => {

  return (
    <Grid Container sx={{ mt: 5, ml: 3 }}>
      <Grid item lg={12} md={12}>
        {TableData.length && TableData != undefined ?
          <>
            <div className='table_Scroll '>
              <Table className="table_mob" sx={{ mt: 2, mb: 2, width: "100%" }} >
                <TableHead>
                  <TableRow>
                    <CustomizedTableCell>Name Of User</CustomizedTableCell>
                    <CustomizedTableCell>Site</CustomizedTableCell>
                    <CustomizedTableCell>Role </CustomizedTableCell>
                    <CustomizedTableCell>Mail ID</CustomizedTableCell>
                    <CustomizedTableCell >Actions</CustomizedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TableData.map((item, index) => (
                    <CustomizedTableRow key={index}>
                      <CustomizedTableCell>{item.username}</CustomizedTableCell>
                      <CustomizedTableCell>{item.site_name}</CustomizedTableCell>
                      <CustomizedTableCell>{item.role}</CustomizedTableCell>
                      <CustomizedTableCell>{item.email}</CustomizedTableCell>
                      <CustomizedTableCell>
                        <IconButton
                          onClick={() => handleView(item.id)}
                        >
                          <IoEyeOutline color="#161616" />
                        </IconButton>
                      </CustomizedTableCell>
                    </CustomizedTableRow>
                  ))}
                </TableBody>

              </Table>
            </div>
            <div>
            <Paginations
                  isDarkMode={isDarkMode}
                  pageCount={pageCount}
                  page={currentPage}
                  onChange={handlePageChange}
                />
            </div>
          </>

          : <Grid item lg={12} md={12} sm={6} xs={6} className="mt2" sx={{ ml: 3, mt: 6 }}>
            <Typography >No Reporting Users found to Show </Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}
UserListTable.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  TableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      site_name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleView: PropTypes.func.isRequired,
};