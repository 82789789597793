import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { DatePick } from "../sharable/datePicker/DatePicker";
import { subDays } from "date-fns";
import { Tables } from "../sharable/tables/Table";
import { DashboardTableData, DashboardTableHead } from "../../assets/data/data";
import IconButton from '@mui/material/IconButton';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { AlertTable } from "../sharable/tables/AlertTable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchAlertList } from "../../redux/actions/AlertActions";
import { alertTimeOnly, dateOnly, dateSearchConverter, extractDatefromTimeStamp, GmtToLocalClockTime, sensorDatefieldConvertorDateOnly, timeOnly, timeStampToTime } from "../convertors/TimeConvertor";
import { MenuProps } from "../Styles";
import dayjs from "dayjs";

export const DashboardTop10Alerts = ({ isDarkMode, removeWidget, id }) => {
    const dispatch = useDispatch();
    const alerListData = useSelector(state => state.alert.alertList);
    const [alertList, setAlertList] = useState([]);
    const [isSentry, setIsSentry] = useState(false);
    const [isPatrol, setIsPatrol] = useState(false);

    const [isSensor, setIsSensor] = useState(false);
    const [isVa, setIsVa] = useState(false);

    const [isDevice, setIsDevice] = useState(false)
    const [selectedStartDate, setSelectedStartDate] = useState(subDays(new Date(), 20));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    //  const [isDarkMode, setDarkMode] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedType, setSelectedType] = useState('');
    const [selectedStage, setSelectedStage] = useState('');
    const [perFeature, setPerFeature] = useState([]);
    const [orgId, setOrgId] = useState("")

    const itemsPerPage = 10;
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            to_date: dateSearchConverter(selectedEndDate),
            from_date: dateSearchConverter(selectedStartDate),
            type: '',
            limit: limit,
            start: start
        }
        let orgId = sessionStorage.getItem('org_Id');
        setOrgId(orgId);
        const feature = sessionStorage.getItem('per_feature');
        if (feature) {
            setPerFeature(JSON.parse(feature)); // Assuming per_feature is stored as JSON in sessionStorage
        }
    }, []);
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            to_date: dateSearchConverter(selectedEndDate),
            from_date: dateSearchConverter(selectedStartDate),
            type: selectedType,
            stage: selectedStage,
            // site_id: selectedSite,
            // searchword: searchWord,
            start: start,
            limit: limit
        }
        if(selectedType != ""){
        dispatch(fetchAlertList(data));
    }
        if (selectedType == 'patrol') {
            setIsPatrol(true);
        }
        else {
            setIsPatrol(false);
        }
        if (selectedType == 'sentry') {
            setIsSentry(true);
        }
        else {
            setIsSentry(false);
        }
        if (selectedType == 'device') {
            setIsDevice(true);
        }
        else {
            setIsDevice(false);
        }
        if(selectedType == 'sensor'){
            setIsSensor(true);
        }
        else{
            setIsSensor(false);
        }
        if(selectedType == 'camera'){
            setIsVa(true)
        }
        else{
            setIsVa(false)
        
        }
    }, [selectedType, selectedEndDate, selectedStartDate, selectedStage]);
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };
    const handleSingleAlertView = (id, type) => {
        sessionStorage.setItem("alertViewId", id);
        sessionStorage.setItem("alert_type", type);
        // sessionStorage.setItem("alert_type", type);
        sessionStorage.setItem('alert_stage',selectedType);
        window.location.href = `/alert/view/${type}/${id}`;
    }
    const handleSelectedType = (data) => {
        setSelectedType(data);
    }
    const handleSelectedStage = (data) => {
        setSelectedStage(data);
    }
    const dateFormat = (date) => {
        const tempdate = dateOnly(date)
        let formattedDate = dayjs(tempdate);

        return formattedDate.format("MM/DD/YYYY")
    }
   
    useEffect(() => {
        if (!alerListData.isFetching && alerListData.payload && alerListData.payload.success === true) {
            const temp = alerListData.payload.data;
            const tempPagination = alerListData.payload.pagination;
            const templist = temp.map((item) => {
                return {
                    id: item.id,
                    //   date:item.duty_type === 0 ? : item.date,
                    tag_name: item.duty_type === 0 ? item.infantName : item.infant_name,
                    violationtype: item.alert_type,
                    site: item.site_name,
                    violation: item.message,
                    datedata: item.duty_type == 0 ? dateOnly(item.timestamp) : dateOnly(item.date),
                    isVaDate :  item.duty_type == 4 ? extractDatefromTimeStamp(item.timestamp) : 0,
                    trackDate:item.duty_type == 1 || item.duty_type == 2  ? sensorDatefieldConvertorDateOnly(item.created_at) :"",

                    duty_type: item.duty_type,
                    start_time: item.sentry_starttime == 0 || item.sentryreportid == 0 ? 0 : alertTimeOnly(item.sentry_starttime),
                    end_time: item.sentry_endtime == 0 || item.sentryreportid == 0 ? 0 : alertTimeOnly(item.sentry_endtime),
                    time: item.duty_type === 0 ? timeOnly(item.timestamp) : 0,
                    contractorname: item.contractorname,
                    assetName: item.infantName,
                    listenerName: item.listenerName,
                    sensor_name: item.duty_type == 5 ? item.sensor.sensor_name :"",
                    camera_name: item.duty_type == 4 ? item.camera_details.title :"",
                    isVatime: item.duty_type == 4 ? alertTimeOnly(item.timestamp) : 0,
                    tag:item.duty_type == 1 || item.duty_type == 2  ?item.infant_name :"",
                    patrol_end_time :item.duty_type == 2 && item.RouteData && item.RouteData.routeendtime ? GmtToLocalClockTime(item.RouteData?.routeendtime) : 0,
                    patrol_start_time :item.duty_type == 2  && item.RouteData && item.RouteData.routestarttime ? GmtToLocalClockTime(item.RouteData?.routestarttime) : 0,


                }
            });
            const tempData = templist.map((item) => {
                return {
                    ...item,
                    date:dateFormat(item.datedata),
                   //trackDate:dateFormat(item.trackDate)

                }
            })
            setAlertList(tempData);
            setPageCount(tempPagination.total_pages)
        }
    }, [alerListData])
    return (
        <>
            <Grid container spacing={1} sx={{ mt: 1 }}>
                < Grid container sx={{ mt: 2 }}>
                    <Grid item lg={2.7} md={2.7} sm={12} xs={12} className="mtdash2" >
                        <Typography className='headersubhead light_text htl'> Top 10 Alerts</Typography>
                    </Grid>

                    <Grid item lg={4.2} sm={12} xs={12} md={4.2} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <Grid item lg={12} sm={12} xs={12} md={12} className="mt2"
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        //className="mt2"
                        >
                            <Grid item lg={3.5} sm={3.5} xs={3.5} md={3.5}>
                                <Typography sx={{ textAlign: 'center', mt: 2 }}
                                    className={isDarkMode ? "headersubhead aligncenter chartheading_color" : "headersubhead aligncenter light_text"}>
                                    Type :
                                </Typography>

                            </Grid>
                            <Grid item lg={7} sm={7} xs={7} md={7}>
                                <Select
                                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                    sx={{
                                        color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                        textAlign: 'left',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: isDarkMode ? "white !important" : "#161616",
                                        }
                                    }}
                                    defaultValue={'patrol'}
                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                    value={selectedType}
                                    onChange={(e) => handleSelectedType(e.target.value)}
                                >

                                    {perFeature.includes(5) || orgId == 0 ? (<MenuItem value={'patrol'}>Patrol Violations</MenuItem>) : null}
                                    {perFeature.includes(5) || orgId == 0 ? (<MenuItem value={'sentry'}>Sentry Violations</MenuItem>) : null}
                                    {perFeature.includes(5) || orgId == 0 ? (<MenuItem value={'device'}>Device Violations</MenuItem>) : null}
                                    {perFeature.includes(7) || orgId == 0 ? (<MenuItem value={'camera'}>VA Violations</MenuItem>) : null}
                                    {perFeature.includes(7) || orgId == 0 ? (<MenuItem value={'sensor'}>Sensor Violations</MenuItem>) : null}
                                    {!perFeature.includes(5) || !orgId == 0 && !perFeature.includes(7) && <MenuItem value={''}>No Violation allowed</MenuItem>}


                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item lg={4.2} sm={12} xs={12} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Grid item lg={12} sm={12} xs={12} md={12} className="mt2"
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Grid item lg={4.2} sm={4.2} xs={4.2} md={4.2}>
                                <Typography sx={{ textAlign: 'center', mt: 2 }}
                                    className={isDarkMode ? "headersubhead aligncenter chartheading_color" : "headersubhead aligncenter light_text"}>
                                    Stage :
                                </Typography>

                            </Grid>
                            <Grid item lg={6.5} sm={6.5} xs={6.5} md={6.5}>
                                <Select
                                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                    sx={{
                                        color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                        textAlign: 'left',

                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: isDarkMode ? "white !important" : "#161616",
                                        }
                                    }}

                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                    value={selectedStage}
                                    onChange={(e) => handleSelectedStage(e.target.value)}
                                >
                                    <MenuItem value={''}>All</MenuItem>
                                    <MenuItem value={1}>Critical</MenuItem>
                                    <MenuItem value={2}>Medium</MenuItem>
                                    <MenuItem value={3}>Low</MenuItem>

                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>




                    <Grid item xs={2} sm={2} lg={.9} md={.9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
                            <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
                        </IconButton>
                    </Grid>
                </Grid>



                {/* <Grid lg={6} sm={12} xs={12}>
                    <Grid container className="alignR">
                        <Grid lg={2.5} sm={5} xs={5} sx={{ pl: 1 }}>
                            <DatePick
                                tableDatePick={"true"}
                                handleDateChange={handleStartDateChange}
                                isDarkMode={isDarkMode}
                                selectedDate={selectedStartDate} />
                        </Grid>
                        <Grid lg={1} sm={1} xs={1} className={isDarkMode ? "daterange_to" : "daterange_to_light"}>
                            <Typography >To</Typography>
                        </Grid>
                        <Grid lg={1} sm={5} xs={5}>
                            <DatePick
                                tableDatePick={"true"}
                                handleDateChange={handleEndDateChange}
                                isDarkMode={isDarkMode}
                                selectedDate={selectedEndDate} />
                        </Grid>
                    </Grid>
                </Grid> */}


            </Grid>
            <Grid lg={12} sm={12} xs={12}>
                {/* <Tables
                    isDarkMode={isDarkMode}
                    column={DashboardTableHead}
                    row={DashboardTableData}
                    view={true}
                    buttonName={"View More"}
                    actionName={"View"}
                /> */}
                <AlertTable
                    isDarkMode={isDarkMode}
                    tableData={alertList}
                    handleView={handleSingleAlertView}
                    isPatrol={isPatrol}
                    isSentry={isSentry}
                    isDevice={isDevice}
                    isSensor={isSensor}
                    isVa={isVa}
                    pageCount={pageCount}
                    page={currentPage}
                    // onChange={handlePageChange}
                    isDashboard={true}

                />
            </Grid>
        </>
    )
}