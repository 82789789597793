import React, { useState, useEffect, useRef } from "react";
import { Alert, Grid, IconButton, InputLabel, Snackbar, TextField, Typography } from "@mui/material";
import { StyledGreyButton } from "../Styles";
import './Athentication.css';
import { useDispatch, useSelector } from "react-redux";
import { clearOtpVerification, otpVerification } from "../../redux/actions/AuthenticationActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const OtpVerification = ({ handlePasswordResetScreen }) => {
    const [otp, setOtp] = useState({

        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        otp5: '',
        otp6: ''
    });
    const [validationErrors, setValidationErrors] = useState('');
    const otpRefs = useRef([]);
    const [showAlert, setAlert] = useState(false);
    const [alerText, setAlertText] = useState("");
    const dispatch = useDispatch();
    const otpStatus = useSelector(state => state.login.otpData);
    useEffect(() => {
        if (!otpStatus.isFetching && otpStatus.payload && otpStatus.payload.success === true) {
            window.location.href = '/password-confirmation'

        }
        if (!otpStatus.isFetching && otpStatus.error === true) {
            const message = otpStatus.errorMessage.message;
            setAlertText(message)

            setAlert(true);
            const timeoutId = setTimeout(() => {
                setAlert(false);
                dispatch(clearOtpVerification());
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [otpStatus])
    const handleChange = (value, index) => {
        if (value.length === 1 && index < otpRefs.current.length - 1) {
            otpRefs.current[index + 1].focus(); // Move focus to the next OTP field
        }

        let temp = { ...otp };
        temp[`otp${index + 1}`] = value;
        setOtp(temp);

        let strln = temp['otp1'] + temp['otp2'] + temp['otp3'] + temp['otp4'] + temp['otp5'] + temp['otp6'];
        // Your validation logic here
        if (strln.length < 6) {
            setValidationErrors('Fields cannot be empty');
        }
        else {
            setValidationErrors('');
        };
    }
    const handleBackspace = (event, index) => {
        if (event.key === 'Backspace' && index > 0 && otp[`otp${index}`] === '') {
            // Move focus to the previous OTP field if backspace is pressed in an empty field
            event.preventDefault();
            otpRefs.current[index - 1].focus();
        }
    };
    const handleOtp = () => {

        const email = sessionStorage.getItem("forgotEmail")
        let body = {
            email: email,
            code: otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + otp.otp6,
        }
        if (body.code.length < 6) {
            setValidationErrors('Fields cannot be empty');
        }
        else {
            setValidationErrors('');
            dispatch(otpVerification(body));
        }


    }
    const handleBack = () => {
        window.location.href = '/forgot-password';
    }
    const handlealertClose = () => {
        setAlert(false)
    }
    return (
        <div style={{ height: '100%' }}>
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    {alerText}
                </Alert>
            </Snackbar>
            <Grid container style={{ height: '100%', padding: "5% !important" }} className="light_grey_bg pd5">
                <Grid item container lg={12} md={12} sm={12} style={{ justifyContent: 'left' }}>
                    <IconButton
                        sx={{ backgroundColor: '#E7E7E7', opacity: '70%', borderRadius: 0, mt: 1 }}
                        onClick={handleBack}
                    >
                        <ArrowBackIcon sx={{ color: "#161616", opacity: '100%' }} fontSize="mediuam" />
                    </IconButton>
                </Grid>
                <Grid item container lg={12} md={12} sm={12} style={{ justifyContent: 'center' }}>
                    <Grid item container lg={6} md={6} sm={12} className="light_grey_bg login_div_container">
                        <div className="login_div1 loginpt1">

                            <Grid item container style={{ justifyContent: 'center' }}>
                                <img className="login_logo1" src={require("../../assets/img/logo.png")} alt="logo" />

                            </Grid>
                            {/* <div> */}
                            <Typography className="otphead" sx={{mb: 3}}>
                            Please enter the verification code  sent to  your email id
                            </Typography>
                                {/* <InputLabel
                                    className="otphead"
                                    sx={{
                                        color: "black", fontSize: '28px !important', mb: 3,
                                        fontFamily: 'Nunito, sans-serif', fontWeight: 600, textAlign: 'center'
                                    }}>
                                    Please enter the verification code  sent to  your email id</InputLabel>
                            </div> */}

                            <Grid container spacing={3} sx={{ mb: 3 }} style={{ justifyContent: 'center' }}>
                                {[...Array(6)].map((_, index) => (
                                    <Grid key={index} item lg={2} md={2} sm={2} xs={2}>
                                        <TextField
                                            value={otp[`otp${index + 1}`]}
                                            className={"text"}
                                            inputProps={{ maxLength: 1, autoComplete: 'off' }}
                                            id={`otp${index + 1}`}
                                            name={`otp${index + 1}_${Math.random()}`}
                                            onChange={(e) => handleChange(e.target.value, index)}
                                            onKeyDown={(e) => handleBackspace(e, index + 1)}
                                            error={!!validationErrors}
                                            inputRef={(ref) => (otpRefs.current[index] = ref)} // Assign ref
                                        />
                                    </Grid>
                                ))}
                                {/* <Grid item lg={2} md={2} sm={2}>
                                    <TextField
                                        value={otp.fn}
                                        className={"text"}
                                        onChange={(e) => { handleChange(e.target.value, 'fn') }}
                                        error={!!validationErrors}
                                        inputProps={{ maxLength: 1,autoComplete: 'off' }}

                                        id="otp1" 
                                        name={`otp1_${Math.random()}`}
                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <TextField
                                        value={otp.sn}
                                        className={"text"}
                                        inputProps={{ maxLength: 1,autoComplete: 'off' }}

                                        id="otp2" 
                                        name={`otp2_${Math.random()}`}
                                        onChange={(e) => { handleChange(e.target.value, 'sn') }}
                                        error={!!validationErrors}

                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <TextField
                                        value={otp.tn}
                                        className={"text"}
                                        inputProps={{ maxLength: 1,autoComplete: 'off' }}

                                        id="otp3" 
                                        name={`otp3_${Math.random()}`}                                        onChange={(e) => { handleChange(e.target.value, 'tn') }}
                                        error={!!validationErrors}

                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <TextField
                                        value={otp.ftn}
                                        className={"text"}
                                        inputProps={{ maxLength: 1,autoComplete: 'off' }}

                                        id="otp4" 
                                        name={`otp4_${Math.random()}`}
                                        onChange={(e) => { handleChange(e.target.value, 'ftn') }}
                                        error={!!validationErrors}

                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <TextField
                                        value={otp.fifn}
                                        className={"text"}
                                        inputProps={{ maxLength: 1,autoComplete: 'off' }}

                                        id="otp5" 
                                        name={`otp5_${Math.random()}`}
                                        onChange={(e) => { handleChange(e.target.value, 'fifn') }}
                                        error={!!validationErrors}

                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <TextField
                                        value={otp.sin}
                                        className={"text"}
                                        inputProps={{ maxLength: 1,autoComplete: 'off' }}

                                        id="otp6" 
                                        name={`otp6_${Math.random()}`}
                                        onChange={(e) => { handleChange(e.target.value, 'sin') }}
                                        error={!!validationErrors}

                                    /> */}
                                {/* </Grid> */}
                                {validationErrors && (
                                    <Typography
                                        sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                        className={' validation_error errorStyle'}
                                    >
                                        {validationErrors}
                                    </Typography>
                                )}
                            </Grid>
                            <StyledGreyButton
                                sx={{ mt: 3 }}
                                className="full_width"
                                variant="contained"
                                disableRipple
                                onClick={handleOtp}

                            >
                                SUBMIT
                            </StyledGreyButton>
                        </div>
                    </Grid>
                </Grid>
            </Grid >
        </div >
    )
}