import { routeActionType } from "../actions/RouteActions"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    routeList:dataTemplate,
    newRoute:dataTemplate,
   editedRoute:dataTemplate,
   singleRoute:dataTemplate,
   deletedRoute:dataTemplate,
  
}

const RouteReducer = (state = initialData, action) => {
    switch (action.type) {
         //fetch route list
         case routeActionType.FETCH_ROUTE:
            return {
                ...state,
                routeList: {
                    ...state.routeList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case routeActionType.FETCH_ROUTE_SUCCESS:
            return {

                ...state,
                routeList: {
                    ...state.routeList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case routeActionType.FETCH_ROUTE_ERROR:
            return {
                ...state,
                routeList: {
                    ...state.routeList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case routeActionType.FETCH_SINGLE_ROUTE:
                return {
                    ...state,
                    singleRoute: {
                        ...state.singleRoute,
                        isFetching: true,
                        payload: [],
                        error: false
                    }
                }
            case routeActionType.FETCH_SINGLE_ROUTE_SUCCESS:
                return {
    
                    ...state,
                    singleRoute: {
                        ...state.singleRoute,
                        isFetching: false,
                        error: false,
                        payload: action.payload
                    }
                }
            case routeActionType.FETCH_SINGLE_ROUTE_ERROR:
                return {
                    ...state,
                    singleRoute: {
                        ...state.singleRoute,
                        isFetching: false,
                        payload: [],
                        error: true,
                        errorMessage: action.error
                    }
                }
            case routeActionType.ADD_ROUTE:
                return {
                    ...state,
                    newRoute: {
                        ...state.newRoute,
                        isFetching: true,
                        payload: [],
                        error: false
                    }
                }
            case routeActionType.ADD_ROUTE_SUCCESS:
                return {
    
                    ...state,
                    newRoute: {
                        ...state.newRoute,
                        isFetching: false,
                        error: false,
                        payload: action.payload
                    }
                }
            case routeActionType.ADD_ROUTE_ERROR:
                return {
                    ...state,
                    newRoute: {
                        ...state.newRoute,
                        isFetching: false,
                        payload: [],
                        error: true,
                        errorMessage: action.error
                    }
                }   
                case routeActionType.CLEAR_ADD_ROUTE_STATUS:
                    return {
                        ...state,
                        newRoute: {
                            ...state.newRoute,
                            isFetching: false,
                            payload: [],
                            error: false,
                            errorMessage: ''
                        }
                    }
                case routeActionType.UPDATE_ROUTE:
                    return {
                        ...state,
                        editedRoute: {
                            ...state.editedRoute,
                            isFetching: true,
                            payload: [],
                            error: false
                        }
                    }
                case routeActionType.UPDATE_ROUTE_SUCCESS:
                    return {
        
                        ...state,
                        editedRoute: {
                            ...state.editedRoute,
                            isFetching: false,
                            error: false,
                            payload: action.payload
                        }
                    }
                case routeActionType.UPDATE_ROUTE_ERROR:
                    return {
                        ...state,
                        editedRoute: {
                            ...state.editedRoute,
                            isFetching: false,
                            payload: [],
                            error: true,
                            errorMessage: action.error
                        }
                    }  
                    case routeActionType.CLEAR_UPDATE_ROUTE_STATUS:
                    return {
                        ...state,
                        editedRoute: {
                            ...state.editedRoute,
                            isFetching: false,
                            payload: [],
                            error: false,
                            errorMessage: ''
                        }
                    } 
                    case routeActionType.DELETE_ROUTE:
                        return {
                            ...state,
                            deletedRoute: {
                                ...state.deletedRoute,
                                isFetching: true,
                                payload: [],
                                error: false
                            }
                        }
                    case routeActionType.DELETE_ROUTE_SUCCESS:
                        return {
                            ...state,
                            deletedRoute: {
                                ...state.deletedRoute,
                                isFetching: false,
                                error: false,
                                payload: action.payload
                            }
                        }
                    case routeActionType.DELETE_ROUTE_ERROR:
                        return {
                            ...state,
                            deletedRoute: {
                                ...state.deletedRoute,
                                isFetching: false,
                                payload: [],
                                error: true,
                                errorMessage: action.error
                            }
                        } 
                        case routeActionType.CLEAR_DELETE_ROUTE_STATUS:
                            return {
                                ...state,
                                deletedRoute: {
                                    ...state.deletedRoute,
                                    isFetching: false,
                                    payload: [],
                                    error: false,
                                    errorMessage: ''
                                }
                            } 
        default:
            return state
    }

}
export default RouteReducer;