import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Alert, CircularProgress, Grid, Snackbar, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DeleteModal } from "../../Modals/DeleteModal";
import { UserDetails } from "../../userManagement/view/UserDetails";
import { SubUserList } from "../../userManagement/view/SubUserList";
import { clearDeleteUserStatus, deleteUser, fetchUser, fetchUserList } from "../../../redux/actions/UserActions";
import { Paginations } from "../../sharable/pagination/Pagination";

export const ViewUser = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user.userData);
    const userListData = useSelector(state => state.user.userList)
    const userDeleteStatus = useSelector(state => state.user.deleteUser);
    const [userDetails, setUserDetails] = useState({});
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("organisation");
    const [subUserList, setSubUserList] = useState([]);
    const [deleteId, setDeleteId] = useState(0)
    const [deleteUserName, setDeleteUserName] = useState('');
    const [deleteUserOrgId, setDeleteUserOrgId] = useState('')

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [previewUrl, setPreviewUrl] = useState('');

    useEffect(() => {

        const id = sessionStorage.getItem("orgsanisation_org_userid");
        dispatch(fetchUser(id));
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            parent_id: id,
            org_id: sessionStorage.getItem("orgsanisation_org_id"),
            start: start,
            limit: limit
        }
        dispatch(fetchUserList(data))

    }, []);
    useEffect(() => {
        if (currentPage) {
            const id = sessionStorage.getItem("orgsanisation_org_userid");

            const start = (currentPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let data = {

                parent_id: id,
                org_id: sessionStorage.getItem("orgsanisation_org_id"),
                start: start,
                limit: limit

            }
            dispatch(fetchUserList(data));
        }
    }, [currentPage]);
    useEffect(() => {
        if (!userData.isFetching && userData.payload && userData.payload.success === true) {
            const tempUser = userData.payload.data;
            if (tempUser.profilepic !== "") {
                const tempUrl = process.env.REACT_APP_API_BASE_URL + '/media' + tempUser.profilepic;
                setPreviewUrl(tempUrl);
            }
            sessionStorage.setItem("user_role_id", tempUser.role_id);

            setUserDetails(tempUser);
        }
        if (!userListData.isFetching && userListData.payload && userListData.payload.success === true) {
            const tempUser = userListData.payload.user_list;
            const tempPagination = userListData.payload.pagination;
            setSubUserList(tempUser);
            setPageCount(tempPagination.total_pages)
        }
        if (!userDeleteStatus.isFetching && userDeleteStatus.payload && userDeleteStatus.payload.success === true) {
            window.location.href = '/organisation'
        }
        if (!userDeleteStatus.isFetching && userDeleteStatus.error) {
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearDeleteUserStatus());
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [userData, userListData, userDeleteStatus]);
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }

    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);

    }
    const handleCreate = () => {
        const id = sessionStorage.getItem("orgsanisation_org_userid");
        sessionStorage.setItem("org_parent_id", id)
        window.location.href = '/organisation/subuser/create';

    }
    const handleView = (id) => {

        sessionStorage.setItem("viewOrgSubUserId", id);
        window.location.href = '/organisation/user/subuser/view'
    }
    const handleDelete = (id, name, orgId) => {
        setDeleteId(id);
        setDeleteUserName(name);
        setOpenDeleteModal(true);
        setDeleteUserOrgId(orgId);

    }
    const handleDeleteModal = () => {
        setOpenDeleteModal(false);
    }
    const onDeleteConfirm = () => {
        let data = {
            created_user: parseInt(sessionStorage.getItem('id')),
            org_id: deleteUserOrgId,
            id: deleteId,
        }
        //  const id = deleteId;
        dispatch(deleteUser(data));
    }
    const handleEdit = (id) => {


        window.location.href = '/organisation/user/edit';
    }
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <Layout
            isDarkMode={isDarkMode}
            handleDarkmode={handleDarkmode}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"organisation"}
            activeMenuKey={"Organisation"}
            isCreate={true}
        //placeHolder={"Search Organization name.."}
        >
            <DeleteModal
                handleDeleteModal={handleDeleteModal}
                openDeleteModal={openDeleteModal}
                ModalHead={'Delete User'}
                ModalBody={deleteUserName}
                onDeleteConfirm={onDeleteConfirm}
            />
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    User Deletion Failed
                </Alert>
            </Snackbar>
            <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
                {userData.isFetching ?
                    <Grid item lg={12} md={12} sm={12} xs={12} container
                        sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Grid>
                    :
                    <Grid container sx={{ mt: 5, ml: 9, '@media (max-width: 600px)': { ml: 0 } }}>
                        <Grid item lg={2} sm={12} xs={12} md={2}>
                            <Typography sx={{ textAlign: 'left', mt: 2 }}
                                className={isDarkMode ? "headersubhead  chartheading_color" : "headersubhead  light_text"}>
                                User Details
                                {/* Coming  Soon */}
                            </Typography>
                        </Grid>
                        <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                            <UserDetails
                                userInfo={userDetails}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                preview={previewUrl}
                            />
                        </Grid>
                        <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                            <SubUserList
                                isDarkMode={isDarkMode}
                                handleCreate={handleCreate}
                                TableData={subUserList}
                                handleView={handleView}
                                subUser={true}

                                pageCount={pageCount}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                            />
                            {/* {subUserList.length && subUserList != undefined ?
                            <div>
                                <Paginations
                                    isDarkMode={isDarkMode}
                                    pageCount={pageCount}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                />
                            </div> : <></>
                        } */}
                        </Grid>
                    </Grid>
                }
            </div>
        </Layout>
    )
}