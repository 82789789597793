import React, { useEffect, useState } from "react";
import "../dashboard/Dashboard.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StyledGreyButton } from "../Styles";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchSites, fetchUserRole } from "../../redux/actions/UserRoleActions";
import {
  addNewOrganisation,
  fetchOrganisationDropdown,
  fetchOrganisationType,
} from "../../redux/actions/OrganisationActions";
import { fetchUser } from "../../redux/actions/UserActions";
import { Layout } from "../layout/Layout";
import { subDays } from "date-fns";
import { StyledCreateSmallButton } from "../styles/ButtonStyles";
import { OrgCreateScreen } from "./OrgCreateScreen";
import {
  validateEmail,
  validatePassword,
  validatePhoneNumber,
} from "../helpers/ValidationHelper";
import { BuildSiteModal } from "../Modals/BuildSiteModal";
import { WindowRounded } from "@mui/icons-material";

export const OrganisationCreate = ({ }) => {
  const dispatch = useDispatch();
  const siteData = useSelector((state) => state.userRole.siteData);
  const orgDropdownData = useSelector(
    (state) => state.organisation.orgDropdown
  );

  const orgTypeData = useSelector(
    (state) => state.organisation.organisationType
  );
  const addOrgStatus = useSelector(
    (state) => state.organisation.addOrganisation
  );
  const [openBuildSite, setOpenBuildSite] = useState(false);
  const [sites, setSites] = useState([]);
  const [org_type, setOrg_Type] = useState([]);
  const [isDarkMode, setDarkMode] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("organisation");
  const [previewUrl, setPreviewUrl] = useState(null);

  const [orgID, setOrgId] = useState(0);
  const [organasitaionData, setOrganisationData] = useState({
    org_name: "",
    email: "",
    phone: "",
    description: "",
    license_no: "",
    password: "",
    //    site_id: "",
    org_type_id: [],
    type: "0",
    is_wisilica: false,
  });
  const [validationErrors, setValidationErrors] = useState({
    org_name: "",
    email: "",
    phone: "",
    description: "",
    license_no: "",
    password: "",
    //  site_id: "",
    org_type_id: "",
  });

  const [alertSuccess, setAlertSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    // dispatch(fetchOrganisationDropdown());
    // dispatch(fetchOrganisationType());
    const data = { is_wisilica: true }
    dispatch(fetchSites(data));

  }, []);
  useEffect(() => {
    if (
      !orgDropdownData.isFetching &&
      orgDropdownData.payload &&
      orgDropdownData.payload.success === true
    ) {
      const org = orgDropdownData.payload.data;

      // setSites(org);
      let tempdata = { ...organasitaionData };
      tempdata["org_type_id"] = org[0].id;
      setOrganisationData(tempdata);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        org_type_id: "",
      }));
    }

    if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp
        }
      })
      setSites(temp);
    }
  }, [orgDropdownData, siteData]);
  useEffect(() => {
    if (
      !addOrgStatus.isFetching &&
      addOrgStatus.payload &&
      addOrgStatus.payload.success === true
    ) {
      let temp = addOrgStatus.payload.org_id;
      sessionStorage.setItem("created_org_id", temp);
      let tempIswise = addOrgStatus.payload.is_wisilica;
      if (tempIswise == "false") {
        setOpenBuildSite(true);
        setOrgId(temp);
      } else {
        window.location.href = "/organisation";
      }
    }
    if (!addOrgStatus.isFetching && addOrgStatus.error) {
      //   const alert = addOrgStatus.errorMessage.messages[0].message;
      const alert = addOrgStatus.errorMessage.message;
      setAlertMessage(alert);
      setAlertSuccess(false);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [addOrgStatus]);
  useEffect(() => {
    if (
      !orgTypeData.isFetching &&
      orgTypeData.payload &&
      orgTypeData.payload.success === true
    ) {
      const orgTypeList = orgTypeData.payload.data.organizationList;
      const temp = orgTypeList.map((types) => {
        return {
          networkId: types.networkId,
          organizationId: types.organizationId,
          organizationName: types.organizationName,
          timestamp: types.timestamp,
        };
      });
      setOrg_Type(temp);
    }
  }, [orgTypeData]);
  const handleChange = (value, key) => {
    let temp = { ...organasitaionData };
    temp[key] = value;
    setOrganisationData(temp);
    if (key !== "org_type_id" && key !== "is_wisilica") {
      const valueLength = value.trim().length;
      let error = "";
      switch (key) {
        case "org_name":
        case "license_no":
          error =
            valueLength < 2 || valueLength >= 30
              ? "Field must be between 2 and 30 characters long"
              : "";
          break;
        case "password":
          const validPass = validatePassword(value);
          if (validPass.code === false) {
            error = validPass.msg;
          } else {
            error = "";
          }
          break;
        case "email":
          const validEmail = validateEmail(value);
          error = validEmail ? "" : "Invalid Email";
          break;
        case "phone":
          const validPhone = validatePhoneNumber(value);
          error = validPhone ? "" : "Invalid phone";
          break;
        case "description":
          error =
            valueLength < 10 || valueLength >= 250
              ? "Field must be between 10 and 250 characters long"
              : "";
          break;

        default:
          break;
      }

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [key]: error,
      }));
    }
    if (key === "org_type_id") {
      let error = value.length < 1 ? "This field is mandatory" : "";
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        org_type_id: error,
      }));
    }
  };
  const handleSubmit = (e) => {
    let data = {
      org_name: organasitaionData.org_name,
      email: organasitaionData.email,
      phone: organasitaionData.phone,
      description: organasitaionData.description,
      license_no: organasitaionData.license_no,
      password: organasitaionData.password,
      type: organasitaionData.type,
      is_wisilica:
        organasitaionData.type == 1 ? false : organasitaionData.is_wisilica,
      //  Location: parseInt(organasitaionData.site_id.organizationId),
      org_type_id: organasitaionData.org_type_id,
    };
    e.preventDefault();
    const isEmptyField = Object.values(organasitaionData).some((value) => {
      return (
        (typeof value === "string" && value.trim() === "") ||
        value === undefined ||
        value === null ||
        value === "" ||
        value === NaN
      );
    });
    const allClear = Object.values(validationErrors).every((value) => {
      return (
        (typeof value === "string" && value.trim() == "") ||
        value === undefined ||
        value === null
      );
    });
    if (isEmptyField) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        org_name:
          organasitaionData.org_name.trim() === ""
            ? "This field is mandatory"
            : prevErrors.org_name,
        phone: organasitaionData.phone
          ? !validatePhoneNumber(organasitaionData.phone)
            ? prevErrors.phone
            : ""
          : "This field is mandatory",
        email: organasitaionData.email
          ? !validateEmail(organasitaionData.email)
            ? prevErrors.email
            : ""
          : "This field is mandatory",
        description:
          organasitaionData.description.trim() === ""
            ? "This field is mandatory"
            : prevErrors.description,
        license_no:
          organasitaionData.license_no.trim() === ""
            ? "This field is mandatory"
            : prevErrors.license_no,
        password:
          organasitaionData.password.trim() === ""
            ? "This field is mandatory"
            : prevErrors.password,
        org_type_id:
          organasitaionData.org_type_id === ""
            ? "This field is mandatory"
            : prevErrors.org_type_id,
      }));
    }

    if (!isEmptyField && allClear) {
      setValidationErrors({
        org_name: "",
        email: "",
        phone: "",
        description: "",
        license_no: "",
        password: "",
        site_id: "",
        org_type_id: "",
      });

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("org_name", data.org_name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("description", data.description);
      formData.append("license_no", data.license_no);
      formData.append("password", data.password);
      formData.append("org_type_id", data.org_type_id);
      formData.append("type", data.type);
      formData.append("is_wisilica", data.is_wisilica);
      formData.append("created_user", parseInt(sessionStorage.getItem("id")));

      dispatch(addNewOrganisation(formData));
    }
  };
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };

  const handleDarkmode = () => {
    setDarkMode(!isDarkMode);
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
  };
  const handleBuildSiteModalOpen = () => {
    setOpenBuildSite(!openBuildSite);
  };
  const handleBuild = () => {
    window.location.href = `/organisation/site/create/${orgID}`;
  };
  const handleClose = () => {
    setOpenBuildSite(false);

    window.location.href = "/organisation";
  };
  const handlealertClose = () => {
    setShowAlert(false);
  }
  return (
    <Layout
      isDarkMode={isDarkMode}
      handleDarkmode={handleDarkmode}
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"organisation"}
      activeMenuKey={"Organisation"}
      isCreate={true}
    >
      <div style={{ marginRight: "2%", marginTop: "3%" }} className="">
        {/* {showAlert ? (
          <Alert
            variant="outlined"
            severity={!alertSuccess ? "error" : "success"}
          >
            {alertMessage}
          </Alert>
        ) : (
          <></>
        )} */}
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handlealertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handlealertClose}
            severity="error"
            variant="outlined"
            sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <BuildSiteModal
          handleBuildSiteModalOpen={handleBuildSiteModalOpen}
          openBuildSite={openBuildSite}
          handleBuild={handleBuild}
          handleClose={handleClose}
        />
        {addOrgStatus.isFetching ?
          <Grid item lg={12} md={12} sm={12} xs={12} container
            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress sx={{ color: '#FFAC0B' }} />
          </Grid>
          :
          <Grid
            container
            spacing={2}
            sx={{ mt: 3, pb: 5 }}
            className={isDarkMode ? "table_dark ml" : "table_light ml"}
          >

            <Grid item lg={12}>
              <Typography className="device_view_head">
                Create Organisation
              </Typography>
            </Grid>
            <OrgCreateScreen
              sites={sites}
              isDarkMode={isDarkMode}
              organasitaionData={organasitaionData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              ButtonName={"Create"}
              showpssword={true}
              validationErrors={validationErrors}
              previewUrl={previewUrl}
              handleFileInputChange={handleFileInputChange}
              update={false}
            />
          </Grid>
        }
      </div>
    </Layout>
  );
};
