import { Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

export const OrgDetails = ({ orgData ,previewUrl}) => {
    return (
        <>

            <div className='device_view'>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mt2" sx={{ mb: 2 }}>
                    <Grid item container className="alignCenter">
                {previewUrl ?
                    <img src={previewUrl} className="org_logo align_mr" alt="logo" crossorigin="anonymous" />
                    : 
                    <img className="org_logo align_mr" src={require("../../../assets/img/orglogo.png")}

                        alt="logo_pic" />
                        }
            </Grid>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_mr' >

                            <Typography className='device_view_text label_color'>Name Of Organisation</Typography>
                            <Typography className='device_view_text content_color'>
                                {orgData.org_name}

                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_ml'  >
                            <Typography className='device_view_text label_color'>Sub Organisations</Typography>
                            <Typography className='device_view_text content_color'>
                                {orgData.is_wisilica == true ? orgData.organization_name : "New Creation"}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_mr' >

                            <Typography className='device_view_text label_color'>Type Of Organisation</Typography>
                            <Typography className='device_view_text content_color'>
                            {orgData.type === 0 && 'Tracking'}
      {orgData.type === 1 && 'Surveillance'}
      {orgData.type === 2 && 'Tracking,Surveillance'}

                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_ml'  >
                            <Typography className='device_view_text label_color'>Wislica Organization</Typography>
                            <Typography className='device_view_text content_color'>
                                {orgData.is_wisilica == true ? "Yes" : "No"}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_mr' >
                            <Typography className='device_view_text label_color'>Mail Id</Typography>
                            <Typography className='device_view_text content_color'>
                                {orgData.email}

                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv borderbottom rowdiv_ml'  >

                            <Typography className='device_view_text label_color'>Phone No</Typography>
                            <Typography className='device_view_text content_color'>
                                {orgData.phone}

                            </Typography>
                        </div>
                    </Grid>
             
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv  rowdiv_mr'  >
                            <Typography className='device_view_text label_color'>License No</Typography>
                            <Typography className='device_view_text content_color'>
                                {orgData.license_no}

                            </Typography>
                        </div>
                    </Grid>
                    <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                        <div className='rowdiv  rowdiv_ml' >
                            <Typography className='device_view_text label_color'>About</Typography>
                            <Typography className='device_view_text content_color'>
                                {orgData.description}

                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </>
    )
}