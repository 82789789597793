import { Button, IconButton, styled } from "@mui/material";

export const StyledCreateButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    paddingTop: '3% !important',
    paddingBottom: '3% !important',
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius:'5px',
    backgroundColor: '#FFAC0B',
    borderColor: '#FFAC0B',
    fontWeight: 700,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
        backgroundColor: '#FFAC0B',
        borderColor: '#FFAC0B',
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFAC0B',
      borderColor: '#FFAC0B',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',
    },
  });
  export const StyledCreateBorderLessButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    paddingTop: '3% !important',
    paddingBottom: '3% !important',
    padding: '6px 12px',
    //border: '1px solid',
    lineHeight: 1.5,
    borderRadius:'5px',
    backgroundColor: 'transparent',
    borderColor: '#FFAC0B',
    fontWeight: 700,
    color:'#FFAC0B',
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#FFAC0B',
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderColor: '#FFAC0B',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',
    },
  });
  export const YellowBorderButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    padding: '12px 12px !important',
    lineHeight: 1.5,
    borderRadius:'5px',
    border: '1px solid #FFAC0B',
    color:'#FFAC0B',
    backgroundColor:'transparent',
    fontWeight: 600,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
      border: '1px solid #FFAC0B',
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',
      backgroundColor:'transparent',

    },
    '&:active': {
      boxShadow: 'none',
      border: '1px solid #FFAC0B',
      backgroundColor:'transparent',

    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',
      backgroundColor:'transparent',

    },
  });
  export const YellowBGButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    padding: '12px 12px !important',
    lineHeight: 1.5,
    borderRadius:'5px',
    border: '1px solid #FFAC0B',
    color:'#ffffff',
    backgroundColor: '#FFAC0B',
    fontWeight: 600,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
      backgroundColor: '#FFAC0B',
      borderColor: '#FFAC0B',
    boxShadow: '0 0 0 0.1rem rgb(255 172 11)',


    },
    '&:active': {
      backgroundColor: '#FFAC0B',
      borderColor: '#FFAC0B',
    boxShadow: '0 0 0 0.1rem rgb(255 172 11)',


    },
    '&:focus': {
      backgroundColor: '#FFAC0B',
      borderColor: '#FFAC0B',
    boxShadow: '0 0 0 0.1rem rgb(255 172 11)',


    },
  });
  export const YellowBorderWhiteButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    padding: '12px 12px !important',
    lineHeight: 1.5,
    borderRadius:'5px',
    border: '1px solid #FFAC0B',
    color:'#ffffff',
    backgroundColor:'transparent',
    fontWeight: 600,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
      border: '1px solid #FFAC0B',
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',
      backgroundColor:'transparent',

    },
    '&:active': {
      boxShadow: 'none',
      border: '1px solid #FFAC0B',
      backgroundColor:'transparent',

    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',
      backgroundColor:'transparent',

    },
  });
  export const PlainButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    padding: '12px 12px !important',
    lineHeight: 1.5,
    borderRadius:'5px',
    border: 'transparent',
    color:'#ffffff',
    backgroundColor:'transparent',
    fontWeight: 600,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
      border: 'transparent',
      boxShadow: 'transparent',
      backgroundColor:'transparent',
      color:'#FFAC0B',

    },
    '&:active': {
      boxShadow: 'none',
      border: 'transparent',
      backgroundColor:'transparent',
      color:'#FFAC0B',

    },
    '&:focus': {
      boxShadow: 'transparent',
      backgroundColor:'transparent',
      color:'#FFAC0B',

    },
  });
  export const PlainActiveButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    padding: '12px 12px !important',
    lineHeight: 1.5,
    borderRadius:'5px',
    border: 'transparent',
    color:'#FFAC0B',
    backgroundColor:'transparent',
    fontWeight: 600,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
      border: 'transparent',
      boxShadow: 'transparent',
      backgroundColor:'transparent',
      color:'#FFAC0B',

    },
    '&:active': {
      boxShadow: 'none',
      border: 'transparent',
      backgroundColor:'transparent',
      color:'#FFAC0B',

    },
    '&:focus': {
      boxShadow: 'transparent',
      backgroundColor:'transparent',
      color:'#FFAC0B',

    },
  });
  export const StyledCreateSmallButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    paddingTop: '3% !important',
    paddingBottom: '3% !important',
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    height:'50px !important',
    backgroundColor: '#FFAC0B',
    borderColor: '#FFAC0B',
    color:'#ffffff !important',
    fontWeight: 600,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
      backgroundColor: '#FFAC0B',
      borderColor: '#FFFFFF',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFAC0B',
      borderColor: '#FFFFFF',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem rgba(0,123,255,.5)',
    },
  });
  export const StyledBorderYellowButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    paddingTop: '3% !important',
    paddingBottom: '3% !important',
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    height:'50px !important',
    backgroundColor: '#FFAC0B',
    borderColor: '#FFAC0B',
    color:'#ffffff !important',
    fontWeight: 600,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
      backgroundColor: '#FFAC0B',
      borderColor: '#FFFFFF',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFAC0B',
      borderColor: '#FFFFFF',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem rgba(0,123,255,.5)',
    },
  });
  export const BorderTransparentButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    // padding: '6px 12px',
    border: '1px solid #E9E9E9',
    lineHeight: 1.5,
    // backgroundColor: '#FFAC0B',
    // borderColor: '#666666',
    color:'#161616',
    borderRadius:'5px',
     fontWeight: 500,
    fontFamily: [
      'Nunito, sans-serif !important',
  
    ].join(','),
    '&:hover': {
      // backgroundColor: '#000000',
      borderColor: '#000000',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      // backgroundColor: '#FFAC0B',
      borderColor: '#000000',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
  export const BorderButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    // padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    // backgroundColor: '#FFAC0B',
    borderColor: '#666666',
    color:'#161616',
    borderRadius:'5px',
     fontWeight: 600,
    fontFamily: [
      'Nunito, sans-serif !important',
  
    ].join(','),
    '&:hover': {
      // backgroundColor: '#000000',
      borderColor: '#000000',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      // backgroundColor: '#FFAC0B',
      borderColor: '#000000',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
  export const RedBorderButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    // padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    // backgroundColor: '#FFAC0B',
    borderColor: '#C61A1A',
    color:'#C61A1A',
    borderRadius:'5px',
     fontWeight: 600,
    fontFamily: [
      'Nunito, sans-serif !important',
  
    ].join(','),
    '&:hover': {
      // backgroundColor: '#000000',
      borderColor: '#C61A1A',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      // backgroundColor: '#FFAC0B',
      borderColor: '#C61A1A',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
  export const DeleteButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    // padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#C61A1A',
    borderColor: '#C61A1A',
    borderRadius:'5px',

    color:'#ffffff',
     fontWeight: 700,
    fontFamily: [
      'Nunito, sans-serif !important',
  
    ].join(','),
    '&:hover': {
      backgroundColor: '#C61A1A',
    borderColor: '#C61A1A',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#C61A1A',
    borderColor: '#C61A1A',
     
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
  export const ConfirmButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    // padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#2e7d32',
    borderColor: '#2e7d32',
    borderRadius:'5px',

    color:'#ffffff',
     fontWeight: 700,
    fontFamily: [
      'Nunito, sans-serif !important',
  
    ].join(','),
    '&:hover': {
      backgroundColor: '#2e7d32',
    borderColor: '#2e7d32',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2e7d32',
    borderColor: '#2e7d32',
     
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
  export const LightBorderButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    // paddingTop: '3% !important',
    // paddingBottom: '3% !important',
    // padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    // backgroundColor: '#FFAC0B',
    borderColor: '#E9E9E9',
    color:'#FFAC0B',
    borderRadius:'5px',
     fontWeight: 700,
    fontFamily: [
      'Nunito, sans-serif !important',
  
    ].join(','),
    '&:hover': {
      // backgroundColor: '#000000',
      borderColor: '#000000',
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',

    },
    '&:active': {
      boxShadow: 'none',
      // backgroundColor: '#FFAC0B',
      borderColor: '#000000',
    },
    '&:focus': {
      borderColor: '#000000',
      boxShadow: '0 0 0 0.1rem rgb(255 172 11)',

    },
  });
  export const LightBorderBlackButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    paddingTop: '14px !important',
    paddingBottom: '14px !important',
    paddingLeft: '20px !important',
    paddingRight: '20px !important',
    // padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    // backgroundColor: '#FFAC0B',
    borderColor: '#E9E9E9',
    color:'black',
    borderRadius:'5px',
     fontWeight: 700,
    fontFamily: [
      'Nunito, sans-serif !important',
  
    ].join(','),
    '&:hover': {
      // backgroundColor: '#000000',
      borderColor: '#FFAC0B',
      boxShadow: '#a6a6a6',

    },
    '&:active': {
      boxShadow: '#a6a6a6',
      // backgroundColor: '#FFAC0B',
      color:"#FFAC0B",
      borderColor: '#FFAC0B',
    },
    '&:focus': {
      borderColor: '#000000',
      boxShadow: '#a6a6a6',

    },
  });
  export const ExportIconButton = styled(IconButton)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    paddingTop: '14px !important',
    paddingBottom: '14px !important',
    paddingLeft: '13px !important',
    paddingRight: '16px !important',
    // padding: '6px 12px',
    border: '1px solid',
    borderRadius:'5px',
    lineHeight: 1.5,
    // backgroundColor: '#FFAC0B',
    borderColor: '#000000',
    color:'black',
    borderRadius:'5px',
     fontWeight: 700,
    fontFamily: [
      'Nunito, sans-serif !important',
  
    ].join(','),
    '&:hover': {
      // backgroundColor: '#000000',
      borderColor: '#000000',
      boxShadow: '#a6a6a6',

    },
    '&:active': {
      boxShadow: '#a6a6a6',
      // backgroundColor: '#FFAC0B',
      borderColor: '#E28909',
    },
    '&:focus': {
      borderColor: '#E28909',
      boxShadow: '#a6a6a6',

    },
  });
  export const StyledMoreOnButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    paddingTop: '1.8% !important',
    paddingBottom: '1.8% !important',
    marginLeft:"-2px !important",
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    height:'50px !important',
    backgroundColor: '#FFAC0B',
    borderColor: '#FFAC0B',
    color:'#ffffff !important',
    fontWeight: 600,
    fontFamily: [
      'Nunito , sans-serif !important',
  
    ].join(','),
    '&:hover': {
      backgroundColor: '#FFAC0B',
      borderColor: '#FFFFFF',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FFAC0B',
      borderColor: '#FFFFFF',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem rgba(0,123,255,.5)',
    },
  });