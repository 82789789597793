import { Alert, Grid, IconButton, Snackbar, Table, TableBody, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { CustomizedTableCell, CustomizedTableRow } from '../../styles/TablebButtonStyles';
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Paginations } from '../../sharable/pagination/Pagination';
import { useState } from 'react';
import { DeleteModal } from '../../Modals/DeleteModal';
import { useDispatch } from 'react-redux';
import { cleardeletePowerStatus, deletePower } from '../../../redux/actions/SurveillanceAction';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { utcTimeStampToLocaly } from '../../convertors/TimeConvertor';
export const PowerListTable = ({ powerList, onChange, page, pageCount }) => {
  const dispatch = useDispatch();
  const deletePowerStatus = useSelector(state => state.surveillance.deletePower);

  const [deletePowerName, setDeletePowerName] = useState("");
  const [deletePowerId, setDeletePowerId] = useState(0);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  useEffect(() => {
    if (!deletePowerStatus.isFetching && deletePowerStatus.payload && deletePowerStatus.payload.success === true) {

      setOpenDeleteModal(false);
      sessionStorage.setItem('surveillanceTab', "s3")

      window.location.href = '/surveillance';
    }
    if (!deletePowerStatus.isFetching && deletePowerStatus.error) {
      const error = deletePowerStatus.errorMessage.message;
      setAlertMessage(error);
      setOpenDeleteModal(false);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(cleardeletePowerStatus())

      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }
    , [deletePowerStatus])
  const handleDelete = (id, name) => {
    setDeletePowerName(name);
    setOpenDeleteModal(true);
    setDeletePowerId(id);
    // deletePower
  }
  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal)
  }
  const onDeleteConfirm = () => {
    const data = {
      created_user: parseInt(sessionStorage.getItem('id')),
      id: deletePowerId,
      org_id: parseInt(sessionStorage.getItem('org_Id')),
    }
    dispatch(deletePower(data))
  }
  const handleEdit = (id) => {
    window.location.href = `/surveillance/power/edit/${id}`;

  }
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <Grid Container>
      <Grid item lg={11.5} md={11.5} sm={11.5}>
        <div className="table_Scroll">
          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={handlealertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handlealertClose}
              severity="error"
              variant="outlined"
              sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

            >
              {alertMessage}
            </Alert>
          </Snackbar>
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            openDeleteModal={openDeleteModal}
            ModalHead={'Delete Power'}
            ModalBody={deletePowerName}
            onDeleteConfirm={onDeleteConfirm}
          />
          <Table className="table_mob" sx={{ m: 2, width: "100%" }}>
            <TableHead>
              <TableRow>
                <CustomizedTableCell>Battery Name</CustomizedTableCell>
                <CustomizedTableCell>Battery Id</CustomizedTableCell>
                <CustomizedTableCell>Battery Level</CustomizedTableCell>
                <CustomizedTableCell>Status</CustomizedTableCell>

                <CustomizedTableCell>Last Updated Time</CustomizedTableCell>
                <CustomizedTableCell>Assigned</CustomizedTableCell>

                <CustomizedTableCell>Actions</CustomizedTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {powerList.map((item, index) => (
                <CustomizedTableRow key={index}>
                  <CustomizedTableCell>{item.battery_name}</CustomizedTableCell>
                  <CustomizedTableCell>{item.battery_id}</CustomizedTableCell>
                  <CustomizedTableCell>{item.percentage === "" ? "NA" : item.percentage}{' '}%</CustomizedTableCell>
                  <CustomizedTableCell>
                    {item.online == false ? (
                      <div className="alert_stage_box alert_critical">
                        <span className="alert_stage_text critical_text">
                          Offline{" "}
                        </span>
                      </div>
                    ) :
                      (<div className="">
                        <span className="alert_stage_text valid_text">
                          Online{" "}
                        </span>
                      </div>)
                    }
                  </CustomizedTableCell>
                  <CustomizedTableCell>{item.timestamp == 0 ? "NA" : utcTimeStampToLocaly(item.timestamp)}</CustomizedTableCell>

                  <CustomizedTableCell>
                    {item.is_assigned == 0 ? (
                      <div className="alert_stage_box alert_critical">
                        <span className="alert_stage_text critical_text">
                          No{" "}
                        </span>
                      </div>
                    ) :
                      (<div className="">
                        <span className="alert_stage_text valid_text">
                          Yes{" "}
                        </span>
                      </div>)
                    }
                  </CustomizedTableCell>
                  <CustomizedTableCell>
                    {/* <IconButton
                    // onClick={() => handleView(item.id)}
                    >
                      <IoEyeOutline color="#161616" />
                    </IconButton> */}
                    <IconButton
                      onClick={() => handleEdit(item.id)}
                    >
                      <MdOutlineEdit color="black" /></IconButton>
                    <IconButton

                      onClick={() => handleDelete(item.id, item.battery_name)}
                    >
                      <RiDeleteBin6Line color="#C61A1A" /></IconButton>

                  </CustomizedTableCell>
                </CustomizedTableRow>
              ))}
            </TableBody>
          </Table>
          <div>
            <Paginations
              pageCount={pageCount}
              page={page}
              onChange={onChange}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}