import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { MenuProps } from "../Styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSites } from "../../redux/actions/UserRoleActions";
export const AlertFilter = ({ isDarkMode, selectedSite, handleSelectedSite, selectedType, handleSelectedType,
    selectedStage, handleSelectedStage }) => {
    const dispatch = useDispatch();
    const siteData = useSelector(state => state.userRole.siteData);
    const [sites, setSites] = useState([]);
    const [orgType, setOrgType] = useState(0);
    const [perFeature, setPerFeature] = useState([]);
    const [orgId,setOrgId] = useState("")

    useEffect(() => {
        dispatch(fetchSites())
        let orgId = sessionStorage.getItem('org_Id');
        setOrgId(orgId);
        const feature = sessionStorage.getItem('per_feature');
        if (feature) {
            setPerFeature(JSON.parse(feature)); // Assuming per_feature is stored as JSON in sessionStorage
        }
    }, []);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
            const siteId = sessionStorage.getItem('user_site_id');
            if (siteId != 0) {
                handleSelectedSite(siteId)
            }
        }

    }, [siteData]);
    return (
        <Grid container sx={{ mt: 5, ml: 3,'@media (max-width: 600px)':{ml:'0px !important'} }} spacing={2}>
            <Grid item lg={2} sm={12} xs={12} md={2}>
                <Typography sx={{ textAlign: 'left', mt: 2 }}
                    className={isDarkMode ? "contentHead  chartheading_color" : "contentHead  light_text"}>
                    List Of Alerts

                </Typography>
            </Grid>
            <Grid item lg={3} sm={12} xs={12} md={3}>
                <Grid container
                //className="mt2"
                >
                    <Grid item >
                        <Typography sx={{ textAlign: 'center', mt: 2 }}
                            className={isDarkMode ? "headersubhead aligncenter chartheading_color" : "headersubhead aligncenter light_text"}>
                            Sites :
                        </Typography>

                    </Grid>
                    <Grid item lg={9} sm={9} xs={9} md={8}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: isDarkMode ? "white !important" : "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                            value={selectedSite}
                            onChange={(e) => handleSelectedSite(e.target.value)}
                        >
                            <MenuItem value={''}>All</MenuItem>
                            {sites ?
                                sites.map((site, index) => (
                                    <MenuItem
                                        key={index}
                                        value={site.organizationId}>
                                        {site.organizationName}
                                    </MenuItem>
                                ))
                                : <MenuItem value={''}>No Data Found</MenuItem>}

                        </Select>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={3} sm={12} xs={12} md={3}>
                <Grid container
                //className="mt2"
                >
                    <Grid item >
                        <Typography sx={{ textAlign: 'center', mt: 2 }}
                            className={isDarkMode ? "headersubhead aligncenter chartheading_color" : "headersubhead aligncenter light_text"}>
                            Type :
                        </Typography>

                    </Grid>
                    <Grid item lg={9} sm={9} xs={9} md={9}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: isDarkMode ? "white !important" : "#161616",
                                }
                            }}
                            defaultValue={'patrol'}
                            displayEmpty
                            MenuProps={MenuProps}
                            className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                            value={selectedType}
                            onChange={(e) => handleSelectedType(e.target.value)}
                        >

                            {perFeature.includes(5) || orgId == 0 ? ( <MenuItem value={'patrol'}>Patrol Violations</MenuItem>) : null}
                            {perFeature.includes(5) || orgId == 0 ? ( <MenuItem value={'sentry'}>Sentry Violations</MenuItem>) : null}
                            {perFeature.includes(5) || orgId == 0 ? ( <MenuItem value={'device'}>Device Violations</MenuItem>) : null}
                            {perFeature.includes(7) || orgId == 0 ? ( <MenuItem value={'camera'}>VA Violations</MenuItem>) : null}
                            {perFeature.includes(7) || orgId == 0 ? ( <MenuItem value={'sensor'}>Sensor Violations</MenuItem>) : null}
                            {!perFeature.includes(5) || !orgId == 0 || !perFeature.includes(7) && <MenuItem value={''}>No Violation allowed</MenuItem> }

                        </Select>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={3} sm={12} xs={12} md={3}>
                <Grid container
                //className="mt2"
                >
                    <Grid item >
                        <Typography sx={{ textAlign: 'center', mt: 2 }}
                            className={isDarkMode ? "headersubhead aligncenter chartheading_color" : "headersubhead aligncenter light_text"}>
                            Stage :
                        </Typography>

                    </Grid>
                    <Grid item lg={8.5} sm={9} xs={9} md={8.5}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: isDarkMode ? "white !important" : "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                            value={selectedStage}
                            onChange={(e) => handleSelectedStage(e.target.value)}
                        >
                            <MenuItem value={''}>All</MenuItem>
                            <MenuItem value={1}>Critical</MenuItem>
                            <MenuItem value={2}>Medium</MenuItem>
                            <MenuItem value={3}>Low</MenuItem>

                        </Select>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}