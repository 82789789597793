import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import {
  CustomizedTableCell,
  CustomizedTableRow,
} from "../../styles/TablebButtonStyles";
import { IoEyeOutline } from "react-icons/io5";
import { Paginations } from "../pagination/Pagination";

export const AlertTable = ({
  isDarkMode,
  tableData,
  handleView,
  isSentry,
  isPatrol,
  isDevice,
  isSensor,
  pageCount,
  page,
  onChange,
  isDashboard,
  isVa
}) => {
  return (
    <Grid Container>

      <Grid item lg={11.5} md={11.5} sm={11.5}>
        <div className="table_Scroll">
          {tableData.length ? (
            <Table className="table_mob" sx={{ m: 2, width: "100%" }}>
              <TableHead>
                <TableRow>
                  <CustomizedTableCell>Date</CustomizedTableCell>
                  {isSentry ? (
                    <CustomizedTableCell>
                      Start Time & End Time
                    </CustomizedTableCell>
                  ) : (
                    <></>
                  )}
                  {isPatrol ? (
                    <CustomizedTableCell>
                      Start Time & End Time
                    </CustomizedTableCell>
                  ) : (
                    <></>
                  )}
                  {isDevice ? (
                    <CustomizedTableCell>Time</CustomizedTableCell>
                  ) : (
                    <></>
                  )}
                  {isVa ? (
                    <CustomizedTableCell>Time</CustomizedTableCell>
                  ) : (
                    <></>
                  )}
                  {!isSensor && !isVa && (
                    <CustomizedTableCell>Personnel Name</CustomizedTableCell>
                  )}
                  {isSensor ? (
                    <CustomizedTableCell>Sensor Name</CustomizedTableCell>
                  ) : (
                    <></>
                  )}
                  {isVa ? (
                    <CustomizedTableCell>Encoder Name</CustomizedTableCell>
                  ) : (
                    <></>
                  )}
                  {isDevice ? (
                    <>
                      <CustomizedTableCell>Assest Name</CustomizedTableCell>
                      <CustomizedTableCell>listener Name</CustomizedTableCell>
                    </>
                  ) : null}
                  {isSentry || isPatrol ? (
                    <CustomizedTableCell>
                      Tag Name
                    </CustomizedTableCell>
                  ) : (
                    <></>
                  )}
                  <CustomizedTableCell>Stage Of Alert</CustomizedTableCell>
                  <CustomizedTableCell>Site</CustomizedTableCell>
                  <CustomizedTableCell>Violation</CustomizedTableCell>
                  <CustomizedTableCell>View</CustomizedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((alert, index) => (
                  <CustomizedTableRow key={index}>
                    {isSentry || isPatrol ?
                      <CustomizedTableCell>{alert.trackDate}</CustomizedTableCell> :
                      isVa ? <CustomizedTableCell>{alert.isVaDate}</CustomizedTableCell> :
                        <CustomizedTableCell>{alert.date}</CustomizedTableCell>
                    }
                    {isSentry ? (
                      <CustomizedTableCell>
                        {alert.start_time} To {alert.end_time}
                      </CustomizedTableCell>
                    ) : (
                      <></>
                    )}
                    {isPatrol ? (
                      <CustomizedTableCell>
                        {alert.patrol_start_time} To {alert.patrol_end_time}
                      </CustomizedTableCell>
                    ) : (
                      <></>
                    )}
                    {isDevice ? (
                      <CustomizedTableCell>{alert.time}</CustomizedTableCell>
                    ) : (
                      <></>
                    )}
                    {isVa ? (
                      <CustomizedTableCell>{alert.isVatime}</CustomizedTableCell>
                    ) : (
                      <></>
                    )}
                    {!isSensor && !isVa && (
                      <CustomizedTableCell>
                        {alert.contractorname}
                      </CustomizedTableCell>
                    )}
                    {isSensor ? (
                      <CustomizedTableCell>
                        {alert.sensor_name}
                      </CustomizedTableCell>
                    ) :
                      (
                        <></>
                      )}
                    {isVa ? (
                      <CustomizedTableCell>
                        {alert.camera_name}
                      </CustomizedTableCell>
                    ) :
                      (
                        <></>
                      )}

                    {alert.duty_type === 0 ? (
                      <>
                        <CustomizedTableCell>
                          {alert.assetName}
                        </CustomizedTableCell>
                        <CustomizedTableCell>
                          {alert.listenerName}
                        </CustomizedTableCell>
                      </>
                    ) : null}
                    {isSentry || isPatrol ? (
                      <CustomizedTableCell>
                        {alert.tag}
                      </CustomizedTableCell>
                    ) : (
                      <></>
                    )}
                    <CustomizedTableCell>
                      {alert.violationtype === 1 ? (
                        <div className="alert_stage_box alert_critical">
                          <span className="alert_stage_text critical_text">
                            {" "}
                            Critical
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {alert.violationtype === 2 ? (
                        <div className="alert_stage_box alert_medium">
                          <span className="alert_stage_text medium_text">
                            {" "}
                            Medium
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {alert.violationtype === 3 ? (
                        <div className="alert_stage_box alert_low">
                          <span className="alert_stage_text low_text">
                            {" "}
                            Low
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </CustomizedTableCell>
                    <CustomizedTableCell>{alert.site}</CustomizedTableCell>
                    <CustomizedTableCell>{alert.violation}</CustomizedTableCell>
                    <CustomizedTableCell>
                      <IconButton
                        onClick={() => handleView(alert.id, alert.duty_type)}
                      >
                        <IoEyeOutline color="#161616" />
                      </IconButton>
                    </CustomizedTableCell>
                  </CustomizedTableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Grid
              item
              lg={11.8}
              sm={11.8}
              xs={11.8}
              sx={{ ml: "4% !important" }}
            >
              No Alerts Found
            </Grid>
          )}

          {!isDashboard && tableData.length ? (
            <div>
              <Paginations
                isDarkMode={isDarkMode}
                pageCount={pageCount}
                page={page}
                onChange={onChange}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
