import React from "react";
import { StyledCreateSmallButton } from "../styles/ButtonStyles";
import {
  Chip,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { RouteSwitch } from "../Styles";
import CancelIcon from "@mui/icons-material/Cancel";

export const OrgCreateScreen = ({
  isDarkMode,
  organasitaionData,
  sites,
  handleChange,
  handleSubmit,
  ButtonName,
  showpssword,
  validationErrors,
  previewUrl,
  handleFileInputChange,
  update,
  editMode
}) => {
  return (
    <>
      {/* <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                <InputLabel
                    className={isDarkMode ? "form_text" : "form_text_light"}
                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                    Upload Logo</InputLabel>
              <div class="file-input-container" className="logo_div" >
                <label for="image-upload" className=" logo_update" >
                <input
                    type="file"
                    accept="image/*"
                    id="image-upload"
                    onChange={handleFileInputChange}
                    style={{ display: 'none' }}
                />
               
                <span style={{ marginRight: "15px" }}></span>
                    Upload Photo
                    <span style={{ marginLeft: "15px" }}></span>
                </label>
                
            </div>

            </Grid> */}
      <Grid item container className="alignCenter">
        {previewUrl ? (
          <img
            src={previewUrl}
            className="org_logo align_mr"
            alt="logo"
            crossorigin="anonymous"
          />
        ) : (
          <img
            className="org_logo align_mr"
            src={require("../../assets/img/orglogo.png")}
            alt="logo"
          />
        )}
      </Grid>
      <Grid item container className="alignCenter align_mr">
        <div class="file-input-container">
          <label for="image-upload" className=" pic_upload">
            <span style={{ marginRight: "15px" }}></span>
            Upload Logo
            <span style={{ marginLeft: "15px" }}></span>
          </label>
          <input
            type="file"
            accept="image/*"
            id="image-upload"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
      </Grid>
      <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
        <InputLabel
          className={
            isDarkMode
              ? "form_text form_text_mob"
              : "form_text_light form_text_mob"
          }
          sx={{ color: isDarkMode ? "white" : "black", mb: 2 }}
        >
          Organisation Name
        </InputLabel>
        <TextField
          className={
            isDarkMode
              ? "form_field_right form_div"
              : "form_field_right form_field_light"
          }
          placeholder="Organisation Name"
          onChange={(e) => handleChange(e.target.value, "org_name")}
          value={organasitaionData.org_name}
          error={!!validationErrors.org_name}
          helperText={validationErrors.org_name}
          type="text"
          autoComplete="off" // Disable autocomplete
          id="org_name"
          name={`org_name${Math.random().toString(36).substr(2, 9)}`}
        />
      </Grid>
      <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
        <InputLabel
          className={isDarkMode ? "form_text" : "form_text_light"}
          sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2 }}
        >
          Type Of Organisation{" "}
        </InputLabel>

        <Select
          className={
            isDarkMode
              ? "form_field form_div noml"
              : "form_field form_field_light noml"
          }
          value={organasitaionData.type}
          onChange={(e) => handleChange(e.target.value, "type")}
        //  disabled={update}
        // error={!!validationErrors.org_type_id}
        >
          <MenuItem value={"0"}>Tracking </MenuItem>
          <MenuItem value={"1"}>Surveillance </MenuItem>

          <MenuItem value={"2"}>Tracking,Surveillance </MenuItem>
        </Select>

        {/*    orginisation                   
                <Select
                    className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}

                    value={organasitaionData.org_type_id}
                    onChange={(e) => handleChange(e.target.value, 'org_type_id')}
                    error={!!validationErrors.org_type_id}

                >

                    {
                        sites.length ?
                            sites.map((org, index) => (
                                <MenuItem
                                    key={index}
                                    value={org.id}>
                                    {org.organization_name}
                                </MenuItem>
                            ))
                            : <MenuItem>No Organisations Found</MenuItem>
                    }
                </Select>
                {validationErrors.org_type_id && (
                    <Typography
                        sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                        className={
                            isDarkMode
                                ? 'errormargin  errorStyle '
                                : ' errormargin errorStyle'
                        }
                    >
                        {validationErrors.org_type_id}
                    </Typography>
                )} */}
      </Grid>
      {organasitaionData.type != "1" &&
        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
          <InputLabel
            className={
              isDarkMode
                ? "form_text form_text_mob"
                : "form_text_light form_text_mob"
            }
            sx={{ color: isDarkMode ? "white" : "black", mb: 2 }}
          >
            WiSilica Organisation
          </InputLabel>
          <TextField
            className={
              isDarkMode
                ? "form_field_right form_div"
                : "form_field_right form_field_light"
            }
            // placeholder={}
            //   type="number"
            // onChange={(e) => handleChange(e.target.value, "wise_org")}
            value={organasitaionData.is_wisilica === false ? "New Organisation" : "Wislica Organisation"}
            InputProps={{
              endAdornment: (
                <RouteSwitch
                  checked={organasitaionData.is_wisilica}
                  onChange={() => handleChange(!organasitaionData.is_wisilica, "is_wisilica")}
                //    disabled={update}

                />
              )
            }}
          />
        </Grid>
      }
      {organasitaionData.is_wisilica !== false && organasitaionData.type != "1" &&
        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
          <InputLabel
            className={isDarkMode ? "form_text" : "form_text_light"}
            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2 }}
          >
            Sub Organisation
          </InputLabel>

          <Select
            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
            multiple
            value={organasitaionData.org_type_id}
            onChange={(e) => handleChange(e.target.value, 'org_type_id')}
            //  error={!!validationErrors.org_type_id}
            displayEmpty
            renderValue={(selected) =>
              <Stack gap={1} direction="row" flexWrap="wrap">


                {sites.length && sites !== undefined ?
                  selected?.map(key => <Chip
                    key={key}
                    label={sites.find(opt => opt.organizationId == key) ?
                      sites.find(opt => opt.organizationId == key).organizationName : ""}
                    onDelete={() =>
                      handleChange(
                        organasitaionData.org_type_id.filter((item) => item != key), "org_type_id"
                      )
                    }
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />)
                  : <></>
                }
              </Stack>
            }
          >

            {
              sites.length ?
                sites.map((org, index) => (
                  <MenuItem
                    key={index}
                    value={org.organizationId}>
                    {org.organizationName}
                  </MenuItem>
                ))
                : <MenuItem>No Organisations Found</MenuItem>
            }
          </Select>
          {/* {validationErrors.org_type_id && (
                      <Typography
                          sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                          className={
                              isDarkMode
                                  ? 'errormargin  errorStyle '
                                  : ' errormargin errorStyle'
                          }
                      >
                          {validationErrors.org_type_id}
                      </Typography>
                  )} */}
        </Grid>
      }
      <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
        <InputLabel
          className={
            isDarkMode
              ? "form_text form_text_mob"
              : "form_text_light form_text_mob"
          }
          sx={{ color: isDarkMode ? "white" : "black", mb: 2 }}
        >
          Phone No
        </InputLabel>
        <TextField
          className={
            isDarkMode
              ? "form_field_right form_div"
              : "form_field_right form_field_light"
          }
          placeholder="Phone No"
          ype="number"
          onChange={(e) => handleChange(e.target.value, "phone")}
          value={organasitaionData.phone}
          error={!!validationErrors.phone}
          helperText={validationErrors.phone}
        />
      </Grid>
      <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
        <div style={{ display: 'flex' }}>
          <InputLabel
            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
            {"Mail Id   "}</InputLabel>
          <InputLabel
            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
            sx={{ color: isDarkMode ? "white" : "black", mb: 2, fontSize: "16px !important" }}>
            {" "}{'    ( This is your LoginId )'}</InputLabel>
        </div>
        <TextField
          className={
            isDarkMode ? "form_field form_div" : "form_field form_field_light"
          }
          placeholder="Mail Id"
          onChange={(e) => handleChange(e.target.value, "email")}
          value={organasitaionData.email}
          error={!!validationErrors.email}
          helperText={validationErrors.email}
          type="email"
          inputProps={{ autoComplete: "off" }}
          id="userName"
          name={`userName${Math.random()}`}
        />
      </Grid>
      <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
        <InputLabel
          className={
            isDarkMode
              ? "form_text form_text_mob"
              : "form_text_light form_text_mob"
          }
          sx={{ color: isDarkMode ? "white" : "black", mb: 2 }}
        >
          License No
        </InputLabel>
        <TextField
          className={
            isDarkMode
              ? "form_field_right form_div"
              : "form_field_right form_field_light"
          }
          placeholder="License No"
          onChange={(e) => handleChange(e.target.value, "license_no")}
          value={organasitaionData.license_no} // error={!!validationErrors.emp_id}
          error={!!validationErrors.license_no}
          helperText={validationErrors.license_no}
        />
      </Grid>
      <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
        <InputLabel
          className={isDarkMode ? "form_text" : "form_text_light"}
          sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2 }}
        >
          About
        </InputLabel>
        <TextField
          className={
            isDarkMode ? "form_field form_div" : "form_field form_field_light"
          }
          placeholder="About"
          onChange={(e) => handleChange(e.target.value, "description")}
          value={organasitaionData.description} // error={!!validationErrors.emp_id}
          error={!!validationErrors.description}
          helperText={validationErrors.description}
        />
      </Grid>
      {showpssword ? (
        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
          <InputLabel
            className={
              isDarkMode
                ? "form_text form_text_mob"
                : "form_text_light form_text_mob"
            }
            sx={{ color: isDarkMode ? "white" : "black", mb: 2 }}
          >
            Password
          </InputLabel>
          <TextField
            className={
              isDarkMode
                ? "form_field_right form_div"
                : "form_field_right form_field_light"
            }
            placeholder="Password"
            onChange={(e) => handleChange(e.target.value, "password")}
            type="password"
            id="orgpassword"
            name={`orgpassword_${Math.random()}`}
            inputProps={{ autoComplete: "new-password" }}
            value={organasitaionData.password}
            error={!!validationErrors.password}
            helperText={validationErrors.password}
          />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item lg={12} md={12}>
        <StyledCreateSmallButton
          onClick={handleSubmit}
          sx={{ mt: 5, width: 150 }}
          variant="contained"
          disableRipple
        >
          {ButtonName}
        </StyledCreateSmallButton>
      </Grid>
    </>
  );
};
