import React, { useEffect, useState } from "react";
import { AppBar, Box, Grid, Hidden, IconButton, MenuItem, Select, Toolbar, Typography } from "@mui/material";
import { Search, SearchIconWrapper, StyledInputBase, GreySwitch, ResponsiveLogo, MenuProps } from "../../Styles";
import { subDays } from 'date-fns'
import './Header.css';
import { DatePick } from "../datePicker/DatePicker";
import { TrackingHeader } from "./TrackingHeader";
import { MainHeader } from "./MainHeader";
import { UserHeader } from "./UserHeader";
import { SurveillanceHeader } from "./SurvillanceHeader";
import { ReportHeader } from "./ReportHeader";

export const Header = ({ isDarkMode, handleDarkmode, userName, activeMenuItem, placeHolder,
    isCreate, navigation, handleEndDateChange, handleStartDateChange, selectedStartDate,
    selectedEndDate, activeSubtab, searchWord, onSearchWordChange, user_Pic, isSurveillacePrev }) => {
    // const [selectedStartDate, setSelectedStartDate] = useState(new Date())

    // const [selectedEndDate, setSelectedEndDate] = useState(subDays(new Date(), 5))
    // const handleEndDateChange = (date) => {
    //     setSelectedEndDate(date);
    // };
    // const handleStartDateChange = (date) => {
    //     setSelectedStartDate(date);
    // };
    const [orgUrl, setOrgUrl] = useState('');

    useEffect(()=>{
        const org_logo = sessionStorage.getItem('org_logo');
        if (org_logo !== "") {
            const tempUrl = process.env.REACT_APP_API_BASE_URL + '/media' + org_logo;
            setOrgUrl(tempUrl);
        }
    },[])
    return (
        <Box className={isDarkMode ? "main" : "main_light"} sx={{ mt: 5 }}>
            <Hidden mdUp>
                <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={6}>
                        {orgUrl && orgUrl !== "" ? <img className="head-logo" src={orgUrl}
                                crossorigin="anonymous" alt="logo" /> :
                                 <img className="head-logo" src={require("../../../assets/img/logo.png")} alt="logo" />
                        }
                    </Grid>
                    {/* <Grid item xs={6}>
                        <GreySwitch className="switch" sx={{ marginTop: 3 }} checked={isDarkMode}
                            onClick={handleDarkmode} />
                    </Grid> */}
                </Grid>
            </Hidden>
            <ResponsiveLogo>
                {orgUrl && orgUrl !== "" ? <img className="lap-logo" src={orgUrl}
                                crossorigin="anonymous"

                                alt="logo" />  : <img className="lap-logo" src={require("../../../assets/img/logo.png")} alt="logo" />
                }
            </ResponsiveLogo>
            {/* <Hidden mdDown>
                <GreySwitch className="switch switch-laptop" checked={isDarkMode} onClick={handleDarkmode} />
            </Hidden> */}
            <AppBar position="static" className={isDarkMode ? "app_bar_bg" : "app_bar_light_bg"}>
                <Toolbar>
                    <Grid container columnGap={1}>
                        { activeMenuItem === "Organisation" ||
                            activeMenuItem === "Alerts" || activeMenuItem === "Audit Trail" ?
                            <>

                                <MainHeader
                                    selectedStartDate={selectedStartDate}
                                    selectedEndDate={selectedEndDate}
                                    isDarkMode={isDarkMode}
                                    handleEndDateChange={handleEndDateChange}
                                    handleStartDateChange={handleStartDateChange}
                                    activeMenuItem={activeMenuItem}
                                    placeHolder={placeHolder}
                                    isCreate={isCreate}
                                    searchWord={searchWord}
                                    onSearchWordChange={onSearchWordChange}
                                /> :


                            </> :
                            <>
                            </>
                        }
                        {activeMenuItem === "Report" ?
                            <ReportHeader
                                isDarkMode={isDarkMode}
                                isCreate={isCreate}
                            />
                            : <></>
                        }

                        {activeMenuItem === "usermanagement" || activeMenuItem === "rolemanagement" ?
                            <>
                                <UserHeader
                                    isDarkMode={isDarkMode}
                                    isCreate={isCreate}
                                    activeSubtab={activeSubtab}
                                    searchWord={searchWord}
                                    onSearchWordChange={onSearchWordChange}
                                    activeMenuItem={activeMenuItem}
                                    placeholder={placeHolder}
                                />
                            </> :
                            <></>

                        }
                        {activeMenuItem === "tracking" ?
                            <TrackingHeader
                                isDarkMode={isDarkMode}
                                isCreate={isCreate}
                                activeSubtab={activeSubtab}
                            /> : <></>
                        }
                        {activeMenuItem === "Dashboard" || activeMenuItem === "videosurveillance" ?
                            <SurveillanceHeader
                                isCreate={isCreate}
                                activeSubtab={activeSubtab}
                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate}
                                handleEndDateChange={handleEndDateChange}
                                handleStartDateChange={handleStartDateChange}
                                isSurveillacePrev={isSurveillacePrev}
                                activeMenuItem={activeMenuItem}

                            /> : <></>
                        }
                        <Grid item
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                            lg={2} md={2} sm={12} xs={12}
                            sx={{ ml: 3 }}
                            className="mt2 noml " >
                            <Grid container sx={{ width: '100%', justifyContent:  { sm: 'flex-start', xs: 'flex-start', lg: 'flex-end' }, ml: 3 }} >
                                {/* <div className="hidden-mobile  hidden-tab">
                                </div> */}
                                <Grid item lg={4} className={isDarkMode ? "border_div_dark" : "border_div"} >
                                    {user_Pic !== "" ?
                                        <img className={isDarkMode ? "user_pro_pic" : "user_pro_pic"}
                                            src={user_Pic}
                                            crossorigin="anonymous"
                                            alt="user_icon" />
                                        :
                                        <img className={isDarkMode ? "user_icon" : "user_icon_light"}
                                            src={
                                                // isDarkMode ? require("../../../assets/icons/user.png") :
                                                require("../../../assets/icons/user_light.png")}
                                            alt="user_icon" />
                                    }

                                </Grid>
                                <Grid item lg={7} className={isDarkMode ? "daterange_to" : "daterange_to_light"}
                                >
                                    <Typography sx={{ textAlign: 'center' }} className="username_box">{userName}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item>
                        <GreySwitch className="switch" sx={{ marginTop: 3 }} checked={isDarkMode}
                            onClick={handleDarkmode} />
                        </Grid> */}
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    )
}