import { Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MenuProps } from '../Styles';
export const AuditTrailFilter = ({ selectedSites, handleSites, sites, handleSelectedType, selectedType }) => {
    const [perFeature, setPerFeature] = useState([]);
    const [orgId, setOrgId] = useState("")
    useEffect(() => {
        const feature = sessionStorage.getItem('per_feature');
        let orgId = sessionStorage.getItem('org_Id');
        setOrgId(orgId);
        if (feature) {
            setPerFeature(JSON.parse(feature)); // Assuming per_feature is stored as JSON in sessionStorage
        }
    }, [])


    return (
        <Grid container sx={{ mt: 4 }} columnSpacing={2}>

            <Grid item lg={3} md={3} xs={12} sm={12}>
                <Grid container className="mt2">
                    <Grid item sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}
                            className={"headersubhead light_text"}>
                            Sites :
                        </Typography>

                    </Grid>
                    <Grid item lg={4} sm={10} xs={10} md={4}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: "#161616", ml: 1, minWidth: 160,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={"headerSelect select_light"}
                            value={selectedSites}
                            onChange={(e) => handleSites(e.target.value)}
                        >   <MenuItem value={''}>All</MenuItem>
                            {sites.map((site, index) => (
                                <MenuItem
                                    key={index}
                                    value={site.organizationId}>
                                    {site.organizationName}
                                </MenuItem>
                            ))}


                        </Select>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item lg={2.7} md={2.7} xs={12} sm={12}>
                <Grid container className="mt2">
                    <Grid item sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}
                            className={"headersubhead light_text"}>
                            Type :
                        </Typography>

                    </Grid>
                    <Grid item lg={9} sm={10} xs={10} md={9}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: "#161616", ml: 1, minWidth: 160,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={"headerSelect select_light"}
                            value={selectedType}
                            onChange={(e) => handleSelectedType(e.target.value)}
                        >
                            <MenuItem value={''}>All</MenuItem>

                            {/* {perFeature.includes(3) || orgId == 0 && <MenuItem value={'1'}>Users</MenuItem>} */}
                            {perFeature.includes(3) || orgId == 0 ? (
                                <MenuItem value={'1'}>Users</MenuItem>
                            ) : null}

                            {perFeature.includes(5) || orgId == 0 ? (<MenuItem value={'2'}>CheckPoints</MenuItem>) : null}
                            {perFeature.includes(5) || orgId == 0 ? (<MenuItem value={'3'}>Routes</MenuItem>) : null}
                            {perFeature.includes(5) || orgId == 0 ? (<MenuItem value={'4'}>Personnels</MenuItem>) : null}
                            {perFeature.includes(8) || orgId == 0 ? (<MenuItem value={'5'}>Alerts</MenuItem>) : null}
                            {perFeature.includes(2) || orgId == 0 ? (<MenuItem value={'6'}>Organisation</MenuItem>) : null}
                            {perFeature.includes(7) || orgId == 0 ? (<MenuItem value={'7'}>Survillence</MenuItem>) : null}
                        </Select>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}