import { Alert, Box, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { iconDeleteStyle, iconStyle, statusUpdateModalstyle, TagModalstyle } from "../styles/ModalStyle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BorderButton, DeleteButton, StyledCreateSmallButton } from "../styles/ButtonStyles";
import { useState } from "react";
import { CameraAlt } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearUpdateCameraStatus, updateCameraStatus } from "../../redux/actions/SurveillanceAction";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { GoTag } from "react-icons/go";

export const CameraStatusModal = ({ openStatusModal, handleStatusModal, camera,handleUnAssign }) => {
  const dispatch = useDispatch();
  const camerachangestatus = useSelector((state) => state.surveillance.cameraStatus);
  const [showAlert, setShowAlert] = useState(false);

  const [statusData, setStatusData] = useState({
    id: camera?.id,
    status: camera?.status,
    description: camera?.description
  });
  useEffect(() => {
    if (camera) {
      setStatusData(camera)
    }
    if (
      !camerachangestatus.isFetching &&
      camerachangestatus.payload &&
      camerachangestatus.payload.success === true
    ) {
      // alert("hoo")
      // sessionStorage.setItem('surveillanceTab', "s3")

      // window.location.href = "/surveillance";
      handleStatusModal();
      window.location.reload();
      //setBatteryDrop(tempBatteryList);
    }
    if (
      !camerachangestatus.isFetching &&
      camerachangestatus.error
    ) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      dispatch(clearUpdateCameraStatus())
      return () => clearTimeout(timeoutId);
    }

  }, [camera, camerachangestatus])
  const onChangeStatus = (value, key) => {
    let temp = { ...statusData };
    temp[key] = value;
    setStatusData(temp);
  }
  const handleUpdate = () => {
    let data = {
      id: statusData.id,
      status: statusData.status,
      created_user: 1,
      description: statusData.description,
      created_user: parseInt(sessionStorage.getItem("id")),
      org_id: parseInt(sessionStorage.getItem("org_Id")),
    }
    dispatch(updateCameraStatus(data));
  }


  return (
    <>
      <Modal
        open={openStatusModal}
        onClose={handleStatusModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={statusUpdateModalstyle}>
         
            {showAlert ?
              <Alert variant="outlined" severity="error">Camera status Updation Failed </Alert> : <></>
            }
            <IconButton><GoTag color="#FFAC0B" /></IconButton>

              <Typography className="modalhead">
                UnAssign Camera

              </Typography>

            {/* <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton onClick={handleStatusModal}>
                <CloseOutlinedIcon
                  sx={{ fontSize: "30px", mr: "5px", color: "#161616" }}
                />
              </IconButton>
            </Grid> */}
          
              <Typography className="modalBody modallight" sx={{ mt: 2 }}>

                {`Are you sure you want to unassign ${camera?.title} ?`}

              </Typography>
            {/* <Grid item xs={10} lg={10} md={10} sx={{ mt: 2 }}>
              <InputLabel
                className={
                  "form_text_light form_text_mob"
                }
                sx={{ color: "black", mb: 2 }}
              >
                Status        </InputLabel>
              {/* <Select
                className={"form_field form_field_light noml"
                }
                value={statusData.status}
                onChange={(e) => onChangeStatus(e.target.value, "status")}
              // error={!!validationErrors.org_type_id}
              >
                <MenuItem value={1}>Active </MenuItem>

                <MenuItem value={2}>In-Active </MenuItem>
                <MenuItem value={3}> Archive</MenuItem>

              </Select> 
              <Typography className="device_view_text content_color rowdiv_text">
                  {camera?.online}
                </Typography>

            </Grid> */}
            {/* <Grid item xs={10} sm={10} lg={10} md={10} sx={{ mt: 3 }}>
              <InputLabel
                className={"form_text_light"}
                sx={{ color: "black", ml: 0, mb: 2 }}
              >
                Describe{" "}
              </InputLabel>

              <TextField
                className={
                  "form_field_right form_field_light"
                }
                placeholder="Describe"
                onChange={(e) => onChangeStatus(e.target.value, "description")}
                value={statusData.description}
                // error={!!validationErrors.org_name}
                // helperText={validationErrors.org_name}
                type="text"
                autoComplete="off" // Disable autocomplete
                id="username2"
                name={`username2_${Math.random().toString(36).substr(2, 9)}`}
              />


            </Grid> */}
          <div style={{ display: 'flex' }}>
            <StyledCreateSmallButton
              sx={{ mr: 2, mt: 3 }} variant="outlined"
              onClick={()=>handleUnAssign(camera.id)}
            >

              <AutorenewOutlinedIcon
                sx={{ fontSize: '20px', mr: '5px', color: "white" }} />
              Unassign
            </StyledCreateSmallButton>
            <BorderButton
              sx={{ mt: 3, }} variant="outlined" color="error"
              onClick={handleStatusModal}
            >
              <HighlightOffRoundedIcon
                sx={{ fontSize: '20px', mr: '5px', color: "#161616" }} />
              Cancel
            </BorderButton>
          </div>
        </Box>

      </Modal>
    </>
  );
};
