import { Grid, Typography } from '@mui/material';
import React from 'react';
import { dateOnly } from '../../convertors/TimeConvertor';
export const AssignedDeatails = ({AlertDetails,assigndDate}) => {
    return (
        <div className='device_view reducepb' >
            <Grid container >
            <Grid item container sx={{ mb: 1 }}>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                        <Typography className='device_view_text label_color rowdiv_label'>  Assigned By </Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                        {AlertDetails.assigned_details?.username}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }}>
                        <Typography className='device_view_text label_color rowdiv_label'>
                           Role
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                        {AlertDetails.assigned_details?.user_role}
                           
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                        <Typography className='device_view_text label_color rowdiv_label'> Assigned Date & Time </Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                        {assigndDate.date +', '+assigndDate.time}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }}>
                        <Typography className='device_view_text label_color rowdiv_label'>
                           Phone No
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                        {AlertDetails.assigned_details?.phone_number}
                           
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv  rowdiv_mr' style={{ paddingBottom: "20px" }} >
                        <Typography className='device_view_text label_color rowdiv_label'>  Mail Id </Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                        {AlertDetails.assigned_details?.email}
                        </Typography>
                    </div>
                </Grid>
                
                </Grid>
           
            </Grid>
        </div>
    )
}