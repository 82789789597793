import React from 'react';
import { Paginations } from '../../../sharable/pagination/Pagination';
import { Grid } from '@mui/material';
import { TagListItem } from './TagListItem';
export const TagList =({isDarkMode,tagListData,deviceTabListValue,pageCount,page,handlePageChange,isAssigned})=>{
    const handleView = (data) => {
        sessionStorage.setItem('viewListenerId', data);
        sessionStorage.setItem('DeviceName', deviceTabListValue);

        window.location.href = '/tracking/device/tag/view'
    }
    return(
        <Grid container columnSpacing={3}>
           {tagListData.length && tagListData !== undefined ?
            <>
        
            { tagListData.map((device,index)=>(
                    <Grid item xs={12} sm={12} lg={4} md={4} sx={{mt:5}} key={index}>
                 <TagListItem 
                 tagDetails={device}
                 handleView={handleView}
                 />
      
            </Grid>
            ))}
            <Grid item xs={12} sm={12} lg={12} md={12} sx={{ mt:1 }}>
                            <Paginations
                                isDarkMode={isDarkMode}
                                 pageCount={pageCount}
                                 page={page}
                                 onChange={handlePageChange}
                            />
                       </Grid>
            </>
             :<Grid item xs={12} sm={12} lg={4} md={4} sx={{mt:5}}>No Data Found</Grid>
}
        </Grid>
    )
}