import { Box, Menu, MenuItem, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MenuProps, StyledTab, StyledTabs } from "../Styles";
import { RiRoadMapLine } from "react-icons/ri";
import { LiaRouteSolid } from "react-icons/lia";
import { GrUserPolice } from "react-icons/gr";
import { MdTrackChanges } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";

import { IoMapOutline } from "react-icons/io5";
import { BsTags } from "react-icons/bs";
import { BiMobileAlt } from "react-icons/bi";
export const TrackingTab = ({
  handleTabChange,
  tabValue,
  deviceTabListValue,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={{ width: "100%" }}>
      <StyledTabs
        value={tabValue}
        onChange={handleTabChange}
        variant={isMobile ? "scrollable" : "standard"}
        // scrollButtons={isMobile ? 'auto' : 'off'}
        //   textColor="secondary"
        //   indicatorColor="#E28909"z
        //   aria-label="secondary tabs example"
      >
        <StyledTab
          value="one"
          label="Check Point"
          icon={
            <RiRoadMapLine color={tabValue === "one" ? "#000000" : "#666666"} />
          }
          iconPosition="start"
        />
        <StyledTab
          value="two"
          label="Route"
          icon={
            <LiaRouteSolid color={tabValue === "two" ? "#000000" : "#666666"} />
          }
          iconPosition="start"
        />
        <StyledTab
          value="three"
          label="Personnel"
          icon={
            tabValue === "three" ? (
              <img
                className="icon"
                src={require("../../assets/icons/personnel_active.png")}
                alt="icon"
              />
            ) : (
              <img
                className="icon"
                src={require("../../assets/icons/personnel.png")}
                alt="icon"
              />
            )
          }
          iconPosition="start"
        />

        <StyledTab
          value="four"
          label={
            deviceTabListValue === "tag"
              ? "Tag"
              : deviceTabListValue === "listener"
              ? "Listener"
              : deviceTabListValue === "bridge"
              ? "Bridge"
              : null
          }
          icon={
            deviceTabListValue === "tag" ? (
              <BsTags color={tabValue === "four" ? "#000000" : "#666666"} />
            ) : deviceTabListValue === "listener" ? (
              <BiMobileAlt
                color={tabValue === "four" ? "#000000" : "#666666"}
              />
            ) : deviceTabListValue === "bridge" ? (
              <img
                className="icon"
                src={require("../../assets/icons/bridge.png")}
                alt="icon"
              />
            ) : null
          }
          iconPosition="start"
        />

        <StyledTab
          value="five"
          label="Map"
          icon={
            <IoMapOutline color={tabValue === "five" ? "#000000" : "#666666"} />
          }
          iconPosition="start"
        />
        <StyledTab
          value="six"
          label="Settings"
          icon={
            <IoSettingsOutline color={tabValue === "six" ? "#000000" : "#666666"} />
          }
          iconPosition="start"
        />
      </StyledTabs>

      {/* Menu for "Security" options */}
      {/* <Menu
        id="security-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuProps={{
          MenuListProps: {
            style: {
              width: '200px', // Add your custom width here
            },
          },
        }}
      
      >
        <MenuItem value="bridge" onClick={ ()=>handleMenuClose (null,'bridge')}>Bridge</MenuItem>
        <MenuItem value="listener" onClick={() => handleMenuClose (null, 'listener')}>Listener</MenuItem>
        <MenuItem value="tag" onClick={() => handleMenuClose (null, 'tag')}>Tag</MenuItem>
      </Menu> */}
    </Box>
  );
};
