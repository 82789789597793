import {
  Alert,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "../../layout/Layout";
import {
  StyledCreateBorderButton,
  StyledCreateBorderLessButton,
  StyledCreateButton,
  StyledCreateSmallButton,
} from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import {
  editOrganisationSites,
  orgBuildLevel,
  orgBuildSite,
} from "../../../services/OrganisationServices";
import Card from "@mui/material/Card";
import L from "leaflet";
import { MapContainer, TileLayer, ImageOverlay } from "react-leaflet";
import { useParams } from "react-router-dom";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSingleLevel } from "../../../redux/actions/OrganisationActions";
import { ViewWisilicaMap } from "./ViewWisilicaMap";
export const ViewLevelMap = () => {
  const dispatch = useDispatch();
  const siteListData = useSelector((state) => state.userRole.siteData);
  const singleOrgLevel = useSelector((state) => state.organisation.singleLevel);

  const { id } = useParams();
  const mapRef = useRef(null);
  const [mapDetails, setMap] = useState({
    minZoom: 17,
    maxZoom: 21,
  });
  const [mapUpload, setMapUpload] = useState({});
  const [mapBounds, setMapBounds] = useState(null);
  const [mapUrl, setMapUrl] = useState(null);

  const [activeMenuItem, setActiveMenuItem] = useState("organisation");
  const [previewUrl, setPreviewUrl] = useState(null);

  const [is_wisilica, setIs_wisilica] = useState(false);
  const [mapData, setMapData] = useState([]);
  const [siteData, setSiteData] = useState({
    layer: "",

  });

  useEffect(() => {

    if (id !== null) {
      dispatch(fetchSingleLevel(id));
    }
    const is_wise = sessionStorage.getItem("orgsanisation_is_wisilica");
    //setIs_wisilica(is_wise);
  }, [id]);

  useEffect(() => {
    if (
      !singleOrgLevel.isFetching &&
      singleOrgLevel.payload &&
      singleOrgLevel.payload.success === true
    ) {
      const levelData = singleOrgLevel.payload.data;
      if (levelData?.is_wisilica == false) {
        setMapData(levelData.mapdata[0])

        const levelMap = singleOrgLevel.payload.data.mapdata[0]?.filePath;

        if (levelMap !== null && levelMap !== undefined) {
          const tempUrl =
            process.env.REACT_APP_API_BASE_URL + "/media" + levelMap;
          setMapUrl(tempUrl);
          setPreviewUrl(levelMap);

        }

        setSiteData({
          // org_id: orgId,
          layer: levelData.level_name,

        });
      }
      else {
        setIs_wisilica(true);
        setMapData(levelData.mapdata[0])

      }

    }
  }, [singleOrgLevel]);

  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };


  const onMapReady = (map) => {
    map.target.setMaxBounds(map.target.getBounds());
    const dimesion = {
      height: mapDetails?.imgHeightMeters,
      width: mapDetails?.imgWidthMeters,
      mapName: mapDetails?.mapName,
    };
    map.target.addControl(
      new customLegends(dimesion, { position: "topright" })
    );
  };

  const customLegends = L.Control.extend({
    container: null,
    maxZoom: undefined,
    maxScalingFactor: undefined,
    initialize: function (dimesion, options) {
      L.Util.setOptions(this, options);
      this.height = Math.round(dimesion.height);
      this.width = Math.round(dimesion.width);
      this.mapName = dimesion.mapName;
    },
    onAdd: function () {
      const div = L.DomUtil.create(
        "div",
        "leaflet-trak-custom-legend leaflet-bar"
      );
      div.innerHTML = `<div></div><p>Map Name: ${this.mapName} </p><p style='float:left;'> Height: ${this.height} meters</p><br><p style='float:left;'>Width: ${this.width} meters</p></div>`;
      return div;
    },
  });

  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"organisation"}
      activeMenuKey={"Organisation"}
      isCreate={true}
    >
      <div style={{ marginRight: "2%", marginTop: "3%" }} className="">
        <Grid
          container
          spacing={2}
          sx={{ mt: 3, pb: 5 }}
          className={"table_light ml"}
        >
          <Grid item lg={12}>
            <Typography className="device_view_head">
              {siteData.layer}
            </Typography>
          </Grid>

          <Grid item xs={11}>
          {mapUrl !== null  ?
            <Card>

            
                <MapContainer maxZoom={21} scrollWheelZoom={false} minZoom={1}
                  dragging={false}
                  bounds={[[-326.5, -35], [90.99, -540.85]]}
                >
                  <ImageOverlay crossOrigin="anonymous" url={mapUrl} bounds={[[-326.5, -35], [50.99, -640.85]]} />
                </MapContainer>
                </Card>

                :
                is_wisilica == false ?
                <Typography className="device_view_head">
                  No Map Found</Typography>
                  :<></>
              }
            {is_wisilica &&
              <ViewWisilicaMap
                map={mapData}
              />
            }
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};
