import { Grid, Typography } from '@mui/material';
import React from 'react';
export const ProjectDetails =({personnelData,personnelDates})=>{
    return(
        <div className='device_view'>
        <Grid container >
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv borderbottom  rowdiv_mr'>
                    <Typography className='device_view_text label_color rowdiv_label'>Permit Serial Number</Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelData.serial_no}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv borderbottom rowdiv_ml'>
                    <Typography className='device_view_text label_color rowdiv_label'>Permit Issuance Date</Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelDates.permitDate}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv borderbottom  rowdiv_mr'>
                    <Typography className='device_view_text label_color rowdiv_label'>Domain/ Unit</Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelData.domain}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv borderbottom rowdiv_ml'>
                    <Typography className='device_view_text label_color rowdiv_label'>Project title</Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelData.project}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv borderbottom  rowdiv_mr'>
                    <Typography className='device_view_text label_color rowdiv_label'>Start Date </Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelDates.startDate}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv borderbottom rowdiv_ml'>
                    <Typography className='device_view_text label_color rowdiv_label'>End Date  </Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelDates.endDate}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv borderbottom  rowdiv_mr'>
                    <Typography className='device_view_text label_color rowdiv_label'>Project Manager</Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelData.project_maneger}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv borderbottom rowdiv_ml'>
                    <Typography className='device_view_text label_color rowdiv_label'>Name of Awarded Contractor</Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelData.contractor_organisation_name}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv   rowdiv_mr'>
                    <Typography className='device_view_text label_color rowdiv_label'>Supervisor Name</Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelData.supervisor}
                    </Typography>
                </div>
            </Grid>
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                <div className='rowdiv  rowdiv_ml'>
                    <Typography className='device_view_text label_color rowdiv_label'>Supervisor Contact No</Typography>
                    <Typography className='device_view_text content_color rowdiv_text'>
                        {personnelData.supervisor_phone}
                    </Typography>
                </div>
            </Grid>

        </Grid>
    </div>
    )
}