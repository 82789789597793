import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './chart.css'


export const Barchart = ({data,clickOnChart}) => {
  return (
    <div className='chart_container'>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          onClick={clickOnChart}
          data={data}
          
          margin={{
            top: 30,
            right: 5,
            left: -5,
            bottom: 15,
          }}
        >
           <CartesianGrid horizontal={false} vertical={false} strokeDasharray="" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip cursor={false} />
          {/* <Legend /> */}
          <Bar dataKey="count" fill="rgba(45, 41, 252, 0.86)" 
          radius={5}
          background={{ fill: '#ECECEC' ,radius:5}}
          barSize={10} 

          activeBar={<Rectangle fill="rgba(45, 41, 252, 0.86)" stroke="rgba(75, 75, 231, 0.12)" 
          strokeWidth='20' strokeHeight='100%' radius={5} />} />

        </BarChart>
      </ResponsiveContainer>
    </div>

  );
}

