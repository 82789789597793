export const authenticationActionType = {
    FETCH_AUTHENTICATION_INFO: "FETCH_AUTHENTICATION_INFO",
    FETCH_AUTHENTICATION_INFO_SUCCESS: "FETCH_AUTHENTICATION_INFO_SUCCESS",
    FETCH_AUTHENTICATION_INFO_ERROR: "FETCH_AUTHENTICATION_INFO_ERROR",
    CLEAR_FETCH_AUTHENTICATION_INFO: "CLEAR_FETCH_AUTHENTICATION_INFO",

    FETCH_LOGGED_USER_INFO: "FETCH_LOGGED_USER_INFO",
    FETCH_LOGGED_USER_INFO_SUCCESS: "FETCH_LOGGED_USER_INFO_SUCCESS",
    FETCH_LOGGED_USER_INFO_ERROR: "FETCH_LOGGED_USER_INFO_ERROR",

    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
    CLEAR_FORGOT_PASSWORD: "CLEAR_FORGOT_PASSWORD",

    OTP_VERIFICATION: "OTP_VERIFICATION",
    OTP_VERIFICATION_SUCCESS: "OTP_VERIFICATION_SUCCESS",
    OTP_VERIFICATION_ERROR: "OTP_VERIFICATION_ERROR",
    CLEAR_OTP_VERIFICATION: "CLEAR_OTP_VERIFICATION",


    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
    CLEAR_RESET_PASSWORD: "CLEAR_RESET_PASSWORD",

    FETCH_REFRESH_TOKEN : "FETCH_REFRESH_TOKEN",
    FETCH_REFRESH_TOKEN_SUCCESS : "FETCH_REFRESH_TOKEN_SUCCESS",
    FETCH_REFRESH_TOKEN_ERROR : "FETCH_REFRESH_TOKEN_ERROR",
CLEAR_REFRESH_TOKEN :"CLEAR_REFRESH_TOKEN",

    USER_LOGOUT : "USER_LOGOUT",
    USER_LOGOUT_SUCCESS : "USER_LOGOUT_SUCCESS",
    USER_LOGOUT_ERROR : "USER_LOGOUT_ERROR",
    CLEAR_LOGOUT :"CLEAR_LOGOUT",

}

export const fetchAuthenticationInfo = (data) => {
    return {
        type: authenticationActionType.FETCH_AUTHENTICATION_INFO,
        data: data,

    }
}
export const fetchAuthenticationInfoSuccess = (data) => {
    return {
        type: authenticationActionType.FETCH_AUTHENTICATION_INFO_SUCCESS,
        payload: data
    }
}
export const fetchAuthenticationInfoError = (error) => {
    return {
        type: authenticationActionType.FETCH_AUTHENTICATION_INFO_ERROR,
      
        error
    }
}
export const clearFetchAuthenticationInfo = () => {
    return {
        type: authenticationActionType.CLEAR_FETCH_AUTHENTICATION_INFO,
      
        
    }
}
export const forgotPassword = (data) => {


    return {
        type: authenticationActionType.FORGOT_PASSWORD,
        data: data,

    }
}
export const forgotPasswordSuccess = (data) => {
    return {
        type: authenticationActionType.FORGOT_PASSWORD_SUCCESS,
        payload: data
    }
}
export const forgotPasswordError = (error) => {
    return {
        type: authenticationActionType.FORGOT_PASSWORD_ERROR,
        error
    }
}
export const clearForgotPassword = () => {
    return {
        type: authenticationActionType.CLEAR_FORGOT_PASSWORD,
      
    }
}
export const otpVerification = (data) => {

    return {
        type: authenticationActionType.OTP_VERIFICATION,
        data: data,

    }
}
export const otpVerificationSuccess = (data) => {
   

    return {
        type: authenticationActionType.OTP_VERIFICATION_SUCCESS,
        payload: data
    }
}
export const otpVerificationError = (error) => {
   
    return {
        type: authenticationActionType.OTP_VERIFICATION_ERROR,
        error
    }
}
export const clearOtpVerification = () => {
   
    return {
        type: authenticationActionType.CLEAR_OTP_VERIFICATION,
        
    }
}
export const resetPassword = (data) => {

    return {
        type: authenticationActionType.RESET_PASSWORD,
        data: data,

    }
}
export const resetPasswordSuccess = (data) => {
   
    return {
        type: authenticationActionType.RESET_PASSWORD_SUCCESS,
        payload: data
    }
}
export const resetPasswordError = (error) => {
   
    return {
        type: authenticationActionType.RESET_PASSWORD_ERROR,
        error
    }
}
export const clearResetPassword = () => {
   
    return {
        type: authenticationActionType.CLEAR_RESET_PASSWORD,
        
    }
}
//loger user info
export const fetchLoggedUserInfo = (id) => {

    return {
        type: authenticationActionType.FETCH_LOGGED_USER_INFO,
        id: id,

    }
}
export const fetchLoggedUserInfoSuccess = (data) => {
    return {
        type: authenticationActionType.FETCH_LOGGED_USER_INFO_SUCCESS,
        payload: data
    }
}
export const fetchLoggedUserInfoError = (data, error) => {
    return {
        type: authenticationActionType.FETCH_LOGGED_USER_INFO_ERROR,
        payload: data,
        error
    }
}
//getrefreshtoken

export const fetchRefreshToken = (data) => {

    return {
        type: authenticationActionType.FETCH_REFRESH_TOKEN,
        data: data,

    }
}
export const fetchRefreshTokenSuccess = (data) => {
   
    return {
        type: authenticationActionType.FETCH_REFRESH_TOKEN_SUCCESS,
        payload: data
    }
}
export const fetchRefreshTokenError = (error) => {
   
    return {
        type: authenticationActionType.FETCH_REFRESH_TOKEN_ERROR,
        error
    }
}
export const clearfetchRefreshToken = () => {
   
    return {
        type: authenticationActionType.CLEAR_REFRESH_TOKEN,
        
    }
}

//userlogout

export const userLogout = (data) => {

    return {
        type: authenticationActionType.USER_LOGOUT,
        data: data,

    }
}
export const userLogoutSuccess = (data) => {
   
    return {
        type: authenticationActionType.USER_LOGOUT_SUCCESS,
        payload: data
    }
}
export const userLogoutError = (error) => {
   
    return {
        type: authenticationActionType.USER_LOGOUT_ERROR,
        error
    }
}
export const clearuserLogout = () => {
   
    return {
        type: authenticationActionType.CLEAR_LOGOUT,
        
    }
}