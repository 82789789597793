import React, { useEffect, useState } from "react";
import './Dashboard.css'
import { Guagebox } from "./Guagebox";
import {Grid, Button, Drawer, Box} from "@mui/material";
import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
// import { MenuProps } from "../Styles";

import { DashboardBarchart } from "./DashboardBarchart";
import { DashboardLinechart } from "./DashboardLinechart";
// import { DatePick } from "../sharable/datePicker/DatePicker";
// import { darkTheme, lightTheme } from "../Styles";
// import { Tables } from "../sharable/tables/Table";
// import { DashboardTableData, DashboardTableHead } from "../../assets/data/data";
import { Layout } from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
// import { fetchUser } from "../../redux/actions/UserActions";
import { fetchUserRole } from "../../redux/actions/UserRoleActions";
import { subDays } from "date-fns";
// import DashboardGridLayout from "./DashboardGridLayout";
import WidgetsIcon from '@mui/icons-material/Widgets';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
// import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import Avatar from '@mui/material/Avatar';
// import { red } from '@mui/material/colors';
import AddBoxIcon from '@mui/icons-material/AddBox';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import AirplayOutlinedIcon from '@mui/icons-material/AirplayOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
// import { Barchart } from "../sharable/charts/Barchart";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import { DashboardTop10Alerts } from './DashboardTop10Alerts';
import { savePersona, getUserPersona } from "../../services/PersonaService";
import { CheckPointList } from "../tracking/checkPoint/CheckPointList";
import { RouteList } from "../tracking/route/RouteList";
// import { PersonnelList } from "../tracking/personnel/PersonnelList";
import { MapView } from "../mapView/Map";
import { Hardwares } from "./Hardwares";
import { fetchDashboardGuages } from "../../redux/actions/AlertActions";
import { DashboardPersonnels } from "./DashboardPersonnel";
import { dateSearchConverter } from "../convertors/TimeConvertor";
import { MapHome } from "../surveillance/map/MapHome";
import { SensorList } from "../surveillance/sensor/SensorList";
import { PowerList } from "../surveillance/power/PowerList";
import { CameraList } from "../surveillance/camera/CameraList";

export const Dashboard = () => {
 
  // const theme = useTheme();
  // const isSmallScreen = theme.breakpoints.down('sm');
  // const isExtraSmallScreen = theme.breakpoints.down('xs');
  const guageboxValues = useSelector(state => state.alert.gaugeValues)
  const roleDetails = useSelector(state => state.userRole.roleData);
  // const [roleData, setRoleData] = useState([]);
  const [alertDutyType, setAlertDutyType] = useState(-1);

  const [isDarkMode, setDarkMode] = useState(false);
  // const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const [selectedStartDate, setSelectedStartDate] = useState(subDays(new Date(), 7));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  // const [value, setValue] = React.useState('1');
  const [dashbaordGrids, setDashboardGrids] = useState([])
  const [selectedGridId, setSelectedGridId] = useState(null);
  const [dashBoardGuages, setDashBoardGuages] = useState({});
  const [userRole, setUserRole] = useState(0);
  const dispatch = useDispatch();
  // const [dashboardMenu, setDashboardMenu] = useState(false);
  // const [organisationMenu, setOrganisationMenu] = useState(false);
  // const [userManagementMenu, setUserManagementMenu] = useState(false);
  // const [roleManagementMenu, setRoleManagementMenu] = useState(false);
  const [trackingMenu, setTrackingMenu] = useState(false);
  // const [reportMenu, setReportMenu] = useState(false);
  const [videoSurvillanceMenu, setVideoSurvillanceMenu] = useState(false);
  const [alertMenu, setAlertMenu] = useState(false);
  const [deviceManagementMenu, setDeviceManagementMenu] = useState(false);
  // const [supportMenu, setSupportMenu] = useState(false);
  useEffect(() => {
    //role_id
   
    getPersonaDetails();
    //dispatch(fetchDashboardGuages(data));
    const use_role_id = sessionStorage.getItem("role_id");
    setUserRole(use_role_id);
    dispatch(fetchUserRole(use_role_id));

  }, []);
  useEffect(()=>{
    let data ={
      to_date: dateSearchConverter(selectedEndDate),
      from_date: dateSearchConverter(selectedStartDate),
    }
    dispatch(fetchDashboardGuages(data));

  },[selectedStartDate,selectedEndDate])
  useEffect(() => {
    if (!guageboxValues.isFetching && guageboxValues.payload && guageboxValues.payload.success === true) {
      const temp = guageboxValues.payload;
      setDashBoardGuages(temp);
    }
    if (!roleDetails.isFetching && roleDetails.payload && roleDetails.payload.success === true) {
      const roleData = roleDetails.payload.Role_details.role_feature[0].features;
      const roleArray = JSON.parse(roleData);
      if (roleArray) {
        const setMenuState = (feature, stateSetter) => {
          const hasFeature = roleArray.includes(feature);
          if (hasFeature) {
            stateSetter(true);
          }
        };
        const featuresStateMappings = [
          // { feature: 1, stateSetter: setDashboardMenu },
          // { feature: 2, stateSetter: setOrganisationMenu },
          // { feature: 3, stateSetter: setUserManagementMenu },
          // { feature: 4, stateSetter: setRoleManagementMenu },
          { feature: 5, stateSetter: setTrackingMenu },
          // { feature: 6, stateSetter: setReportMenu },
          { feature: 7, stateSetter: setVideoSurvillanceMenu },
          { feature: 8, stateSetter: setAlertMenu },
          { feature: 9, stateSetter: setDeviceManagementMenu },
          // { feature: 10, stateSetter: setSupportMenu },
        ];
        featuresStateMappings.forEach(mapping => setMenuState(mapping.feature, mapping.stateSetter));
      }
      // setRoleData(roleArray)
    }
  }, [guageboxValues, roleDetails]);

  const getPersonaDetails = async () => {
    const persona = await getUserPersona();
    if (persona && persona.persona_details && persona.persona_details.layout.length > 0) {
      setDashboardGrids(persona.persona_details.layout)
    }
  }
  const handleActiveMenuItems = () => {
    // setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  }
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleDarkmode = () => {
    setDarkMode(!isDarkMode);

  }



  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, gridId = null) =>
    (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' ||
          event.key === 'Shift')
      ) {
        return;
      }
      setSelectedGridId(gridId)
      setState({ ...state, [anchor]: open });
    };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }));

  const addWidget = (type) => {
    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    const columnSize = isMobile ? 12 : 6;
  
    const grids = [...dashbaordGrids];
    switch (type) {
      case 'layout1':
        grids.push({
          columnSize: 12,
          widget: null,
          column_id: uuidv4()
        })
        break;
      case 'layout2':
        grids.push({
          columnSize: columnSize,
          widget: null,
          column_id: uuidv4(),
          className: 'hide-on-mobile'

        })
        grids.push({
          columnSize: columnSize,
          widget: null,
          column_id: uuidv4(),
          className: 'hide-on-mobile'

        })
        break;
      case 'alertChart':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'alertChart';
        } else {
          grids.push({
            columnSize: columnSize,
            widget: 'alertChart',
            column_id: uuidv4()
          })
        }
        break;
      case 'devicesChart':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'devicesChart';
        } else {
          grids.push({
            columnSize: columnSize,
            widget: 'devicesChart',
            column_id: uuidv4()
          })
        }
        break;
      case 'top10Alerts':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'top10Alerts';
        } else {
          grids.push({
            columnSize: 12,
            widget: 'top10Alerts',
            column_id: uuidv4()
          })
        }
        break;
      case 'checkPoints':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'checkPoints';
        } else {
          grids.push({
            columnSize: 12,
            widget: 'checkPoints',
            column_id: uuidv4()
          })
        }
        break;
      case 'routes':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'routes';
        } else {
          grids.push({
            columnSize: 12,
            widget: 'routes',
            column_id: uuidv4()
          })
        }
        break;
      case 'securities':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'securities';
        } else {
          grids.push({
            columnSize: 12,
            widget: 'securities',
            column_id: uuidv4()
          })
        }
        break;
      case 'tags':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'tags';
        } else {
          grids.push({
            columnSize: 12,
            widget: 'tags',
            column_id: uuidv4()
          })
        }
        break;
      case 'bridges':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'bridges';
        } else {
          grids.push({
            columnSize: 12,
            widget: 'bridges',
            column_id: uuidv4()
          })
        }
        break;
      case 'listeners':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'listeners';
        } else {
          grids.push({
            columnSize: 12,
            widget: 'listeners',
            column_id: uuidv4()
          })
        }
        break;
      case 'map':
        if (selectedGridId) {
          const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
          grids[itemIndex]['widget'] = 'map';
        } else {
          grids.push({
            columnSize: 12,
            widget: 'map',
            column_id: uuidv4()
          })
        }
        break;



        case 'surveillancemap':
          if (selectedGridId) {
            const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
            grids[itemIndex]['widget'] = 'surveillancemap';
          } else {
            grids.push({
              columnSize: 12,
              widget: 'surveillancemap',
              column_id: uuidv4()
            })
          }
          break;
          case 'cameras':
            if (selectedGridId) {
              const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
              grids[itemIndex]['widget'] = 'cameras';
            } else {
              grids.push({
                columnSize: 12,
                widget: 'cameras',
                column_id: uuidv4()
              })
            }
            break;
            case 'sensors':
              if (selectedGridId) {
                const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
                grids[itemIndex]['widget'] = 'sensors';
              } else {
                grids.push({
                  columnSize: 12,
                  widget: 'sensors',
                  column_id: uuidv4()
                })
              }
              break;
              case 'powers':
                if (selectedGridId) {
                  const itemIndex = grids.findIndex((e) => { return e.column_id === selectedGridId });
                  grids[itemIndex]['widget'] = 'powers';
                } else {
                  grids.push({
                    columnSize: 12,
                    widget: 'powers',
                    column_id: uuidv4()
                  })
                }
                break;
      default:
        break;
    }
    setDashboardGrids(grids)
    if (!['layout1', 'layout2'].includes(type)) {
      updatePersona(grids)
    }
  }

  const removeWidget = (id, isUpdate = true) => {
    let grids = [...dashbaordGrids];
    grids = grids.filter(function (item) {
      return item.column_id !== id
    })
    setDashboardGrids(grids);
    if (isUpdate) {
      updatePersona(grids)
    }
  }

  const updatePersona = async (grids) => {
    try {
      const layout = grids.filter((e) => { return e.widget != null });
      await savePersona({
        layout,
        user_id: parseInt(sessionStorage.getItem('id'))
      });
    } catch (error) {
      console.log(error)
    }
  }
const handleAlertDutyType =(value)=>{
  setAlertDutyType(value);
}
  return (
    <Layout
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      handleEndDateChange={handleEndDateChange}
      handleStartDateChange={handleStartDateChange}
      isDarkMode={isDarkMode}
      handleDarkmode={handleDarkmode}
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"dashboard"}
      activeMenuKey={"Dashboard"}
      placeHolder={"Search here"}

    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button onClick={toggleDrawer('right', true)} style={{ color: '#FFAC0B', float: 'right' }}
            variant="outlined" startIcon={<WidgetsIcon />} endIcon={<ArrowRightAltIcon />}>Widgets</Button>
          <Drawer
            PaperProps={{
              sx: { width: "20%", 
                '@media (max-width: 600px)': {
                width: "80%"  // Adjust width for small screens (sm, xs)
              } },
            }}
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}

          >
            <Box sx={{ padding: '10px' }}>
              {!selectedGridId &&
                <>
                  <Card sx={{ mt: 3, mb: 3 }}>
                    <CardHeader
                      action={
                        <IconButton aria-label="settings" onClick={() => { addWidget('layout1') }}>
                          <AddBoxIcon style={{ color: '#FFAC0B' }} />
                        </IconButton>
                      }
                      titleTypographyProps={{
                        fontSize: 16,
                      }}
                      title='Layout 1 Column'
                    />

                    <CardContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Item>Widget 1</Item>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card sx={{ mt: 3, mb: 3,display:{sm:'none',xs:'none',md:'block',lg:'block'} }} >
                    <CardHeader
                      action={
                        <IconButton aria-label="settings" onClick={() => { addWidget('layout2') }}>
                          <AddBoxIcon style={{ color: '#FFAC0B' }} />
                        </IconButton>
                      }
                      titleTypographyProps={{
                        fontSize: 16,
                      }}
                      title='Layout 2 Column'
                    />
                    <CardContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Item>Widget 1</Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item>Widget 2</Item>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                  <Divider sx={{ mt: 3, mb: 3 }}></Divider>
                </>
              }
              {alertMenu &&
                <Card sx={{ mt: 3, mb: 3 }}>
                  <CardHeader
                    action={
                      <IconButton aria-label="settings">
                        <AddBoxIcon style={{ color: '#FFAC0B' }} onClick={() => { addWidget('alertChart') }} />
                      </IconButton>
                    }
                    titleTypographyProps={{
                      fontSize: 16,
                    }}
                    title='Alert Trend'
                  />
                  <CardContent>
                    <CardMedia
                      component="img"
                      image={require("../../assets/img/widgets/alert-trends.png")}
                      alt="Paella dish"
                    />
                  </CardContent>
                </Card>
              }
              {deviceManagementMenu &&
                <Card sx={{ mt: 3, mb: 3 }}>
                  <CardHeader
                    action={
                      <IconButton aria-label="settings">
                        <AddBoxIcon style={{ color: '#FFAC0B' }} onClick={() => { addWidget('devicesChart') }} />
                      </IconButton>
                    }
                    titleTypographyProps={{
                      fontSize: 16,
                    }}
                    title='Device'
                  />

                  <CardContent>
                    <CardMedia
                      component="img"
                      image={require("../../assets/img/widgets/devices.png")}
                      alt="Paella dish"
                    />
                  </CardContent>
                </Card>
              }
              {trackingMenu &&
                <>
                  <Divider sx={{ mt: 3, mb: 3 }}></Divider>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                    <AirplayOutlinedIcon sx={{ mr: 1 }} />
                    <h5>Tracking</h5>
                  </div>
                  <Card sx={{ mt: 3, mb: 3 }}>
                    <CardHeader
                      action={
                        <IconButton aria-label="settings">
                          <AddBoxIcon style={{ color: '#FFAC0B' }} onClick={() => { addWidget('map') }} />
                        </IconButton>
                      }
                      titleTypographyProps={{
                        fontSize: 16,
                      }}
                      title='Map'
                    />

                    <CardContent>
                      <CardMedia
                        component="img"
                        image={require("../../assets/img/widgets/map.png")}
                        alt="Paella dish"
                      />
                    </CardContent>
                  </Card>
                  <Grid container spacing={2}>
                    {[{
                      icon: 'C',
                      title: 'Checkpoints',
                      action: 'checkPoints'
                    }, {
                      icon: 'P',
                      title: 'Personnel',
                      action: 'securities'
                    }, {
                      icon: 'R',
                      title: 'Routes',
                      action: 'routes'
                    }].map((item, index) => {
                      return <Grid item xs={12} md={12} key={index}>
                        <Card sx={{ pb: 1 }}>
                          <CardHeader
                            avatar={
                              <Avatar sx={{ bgcolor: 'transparent', color: 'black' }} aria-label="recipe">
                                {item.icon}
                              </Avatar>
                            }
                            action={
                              <IconButton aria-label="settings" onClick={() => { addWidget(item.action) }}>
                                <AddBoxIcon style={{ color: '#FFAC0B' }} />
                              </IconButton>
                            }
                            title={item.title}
                          />
                        </Card>
                      </Grid>
                    })}
                  </Grid>

                </>
              }
              {videoSurvillanceMenu &&
                <>
                  <Divider sx={{ mt: 3, mb: 3 }}></Divider>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                    <OndemandVideoOutlinedIcon sx={{ mr: 1 }} />
                    <h5>Surveillance </h5>
                  </div>
                  <Card sx={{ mt: 3, mb: 3 }}>
                    <CardHeader
                      action={
                        <IconButton aria-label="settings">
                          <AddBoxIcon style={{ color: '#FFAC0B' }} onClick={() => { addWidget('surveillancemap') }} />
                        </IconButton>
                      }
                      titleTypographyProps={{
                        fontSize: 16,
                      }}
                      title='Map'
                    />

                    <CardContent>
                      <CardMedia
                        component="img"
                        image={require("../../assets/img/widgets/map.png")}
                        alt="Paella dish"
                      />
                    </CardContent>
                  </Card>
                  <Grid container spacing={2}>
                    {[{
                      icon: 'C',
                      title: 'Camera',
                      action: 'cameras'
                    }, {
                      icon: 'S',
                      title: 'Sensor',
                      action: 'sensors'
                    }, {
                      icon: 'P',
                      title: 'Power',
                      action: 'powers'
                    }].map((item, index) => {
                      return <Grid item xs={12} md={12} key={index}>
                        <Card sx={{ pb: 1 }}>
                          <CardHeader
                            avatar={
                              <Avatar sx={{ bgcolor: 'transparent', color: 'black' }} aria-label="recipe">
                                {item.icon}
                              </Avatar>
                            }
                            action={
                              <IconButton aria-label="settings" onClick={() => { addWidget(item.action) }}>
                                <AddBoxIcon style={{ color: '#FFAC0B' }} />
                              </IconButton>
                            }
                            title={item.title}
                          />
                        </Card>
                      </Grid>
                    })}
                  </Grid>

                </>
              }
              {trackingMenu &&
                <>
                  <Divider sx={{ mt: 3, mb: 3 }}></Divider>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                    <AirplayOutlinedIcon sx={{ mr: 1 }} />
                    <h5>Device Management</h5>
                  </div>
                </>
              }
              {trackingMenu &&
                <Grid container spacing={2}>
                  {[{
                    icon: 'B',
                    title: 'Bridge',
                    action: 'bridges'
                  }, {
                    icon: 'L',
                    title: 'Listener',
                    action: 'listeners'
                  }, {
                    icon: 'T',
                    title: 'Tag',
                    action: 'tags'
                  },
                    // {
                    //   icon: 'C',
                    //   title: 'Camera',
                    //   action: 'camera'
                    // }
                  ].map((item, index) => {
                    return <Grid item xs={12} md={12} key={index}>
                      <Card sx={{ pb: 1 }}>
                        <CardHeader
                          avatar={
                            <Avatar sx={{ bgcolor: 'transparent', color: 'black' }} aria-label="recipe">
                              {item.icon}
                            </Avatar>
                          }
                          action={
                            <IconButton aria-label="settings" onClick={() => { addWidget(item.action) }}>
                              <AddBoxIcon style={{ color: '#FFAC0B' }} />
                            </IconButton>
                          }
                          title={item.title}
                        />
                      </Card>
                    </Grid>
                  })}
                </Grid>
              }

           { alertMenu &&<>
            <Divider sx={{ mt: 3, mb: 3 }}></Divider>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
                <NotificationImportantOutlinedIcon sx={{ mr: 1 }} />
                <h5>Alerts</h5>
              </div>

              <Grid container spacing={2}>
                {[{
                  icon: 'T',
                  title: 'Top 10 Alerts'
                }
                ].map((item, index) => {
                  return <Grid item xs={12} md={12} key={index}>
                    <Card sx={{ pb: 1 }}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: 'transparent', color: 'black' }} aria-label="recipe">
                            {item.icon}
                          </Avatar>
                        }
                        action={
                          <IconButton aria-label="settings" onClick={() => { addWidget('top10Alerts') }}>
                            <AddBoxIcon style={{ color: '#FFAC0B' }} />
                          </IconButton>
                        }
                        title={item.title}
                      />
                    </Card>
                  </Grid>
                })}
              </Grid>
              </>}
            </Box>

          </Drawer>
        </Grid>
      </Grid>
      <Guagebox isDarkMode={isDarkMode}
        guageVals={dashBoardGuages}
        userRole={userRole}
      
      />

      {/* <DashboardGridLayout></DashboardGridLayout> */}

      <Grid container spacing={2} sx={{ mt: 5 }}>
        {dashbaordGrids.map((gridItem,index) => {
            const xsSize = 12; // Mobile size
            const smSize =12;
          return <Grid key={index} item 
          // xs={gridItem.columnSize} 
          style={{ textAlign: 'center' }} sm={smSize} xs={xsSize} lg={gridItem.columnSize} md={gridItem.columnSize}>
            {!gridItem.widget &&
              <Item >
                <Button onClick={toggleDrawer('right', true, gridItem.column_id)} style={{ color: '#FFAC0B', marginRight: 2 }}
                  variant="outlined" startIcon={<WidgetsIcon />} endIcon={<ArrowRightAltIcon />}>Select Widget</Button>
                <Button onClick={() => { removeWidget(gridItem.column_id, false) }} style={{ color: 'red' }}
                  variant="outlined">Remove Column</Button>
              </Item>
            }

            {gridItem.widget && gridItem.widget === 'alertChart' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', }}>
                <DashboardBarchart id={gridItem.column_id} isDarkMode={isDarkMode} removeWidget={(id) => { removeWidget(id) }} 
              handleAlertDutyType={handleAlertDutyType}       
              alertDutyType = {alertDutyType}
              /></Item>
            }
            {gridItem.widget && gridItem.widget === 'devicesChart' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll' }}>
                <DashboardLinechart id={gridItem.column_id} isDarkMode={isDarkMode} removeWidget={(id) => { removeWidget(id) }} />

                </Item>
            }
            {gridItem.widget && gridItem.widget === 'top10Alerts' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll' }}>
                <DashboardTop10Alerts id={gridItem.column_id} isDarkMode={isDarkMode} removeWidget={(id) => { removeWidget(id) }} /></Item>
            }
            {gridItem.widget && gridItem.widget === 'checkPoints' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                <CheckPointList id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></CheckPointList>
              </Item>
            }
            {gridItem.widget && gridItem.widget === 'routes' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                <RouteList id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></RouteList>
              </Item>
            }
            {gridItem.widget && gridItem.widget === 'securities' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                {/* <PersonnelList id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></PersonnelList> */}
                <DashboardPersonnels id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></DashboardPersonnels>

              </Item>
            }
            {gridItem.widget && gridItem.widget === 'map' &&
              <Item sm={smSize} xs={xsSize} style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll' , maxHeight: '520px'}}>
                <MapView columnSize={gridItem.columnSize} id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></MapView>
              </Item>
            }
            {gridItem.widget && gridItem.widget === 'tags' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                <Hardwares typeLabel="Tags List" type="tags" id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></Hardwares>
              </Item>
            }
            {gridItem.widget && gridItem.widget === 'bridges' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                <Hardwares typeLabel="Bridges List" type="bridges" id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></Hardwares>
              </Item>
            }
            {gridItem.widget && gridItem.widget === 'listeners' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                <Hardwares typeLabel="Listeners List" type="listeners" id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></Hardwares>
              </Item>
            }
            {gridItem.widget && gridItem.widget === 'surveillancemap' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px'  }}>
                <MapHome columnSize={gridItem.columnSize} id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></MapHome>
              </Item>
            }
             {gridItem.widget && gridItem.widget === 'sensors' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                <SensorList selectedEndDate={selectedEndDate} selectedStartDate={selectedStartDate} id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></SensorList>
              </Item>
            }
            {gridItem.widget && gridItem.widget === 'powers' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                <PowerList selectedEndDate={selectedEndDate} selectedStartDate={selectedStartDate} id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></PowerList>
              </Item>
            }
             {gridItem.widget && gridItem.widget === 'cameras' &&
              <Item style={{ minHeight: '420px', overflowX: 'scroll', overflowY: 'scroll', maxHeight: '520px' }}>
                <CameraList selectedEndDate={selectedEndDate} selectedStartDate={selectedStartDate} id={gridItem.column_id} isWidget={true} removeWidget={(id) => { removeWidget(id) }}></CameraList>
              </Item>
            }
          </Grid>
        })}
      </Grid>
    </Layout>
  )
} 