import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import { ExportModal } from '../../Modals/ExportModal';

highcharts3d(Highcharts);

export const SurveillancePerformanceChart =({trackingDates,data,handleChartClick,exportMenu,handleExportMenu,
 confirmExport,handleExporttype,exportModalAlert,size})=>{
    const barChartOptions = {
        chart: {
            type: 'column',
            width: size,
            height:600,
            // scrollablePlotArea: {
            //     minWidth: 800,
            //     scrollPositionX: 1
            //   },
            options3d: {
                enabled: true,
                alpha: 2,
                beta: 20,
                viewDistance: 0,
                depth: 10
            }
        },
    
        title: {
            text: ' ',
          
        },
    
        xAxis: {
           // categories: trackingDates,
           categories:trackingDates,
            labels: {
                skew3d: true,
                style: {
                    fontSize: '16px'
                },
                overflow: 'scroll'

            }
           
        },
    
        yAxis: {
            //categories:[0,1,2,3,4,5,6,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22],
          //categories:["00:00","06:00","12:00","18:00","24:00"],
            allowDecimals: false,
            min: 0,
            max: 24,
            title: {
                 text: 'Time',
                skew3d: true,
                style: {
                    fontSize: '16px'
                }
            }
        },
    
        tooltip: {
            headerFormat: '<b>{point.key}</b><br>',
            pointFormat: '<span style="color:{series.color}">\u25CF</span> ' +
                '{series.name}: {point.y} / {point.stackTotal} <br>'
                
        },
    
        plotOptions: {
            series: {
                 pointStart: 0,
                 cursor: 'pointer',
                events: {
                    click: function (event) {handleChartClick(event.point.category)}
                }
            },
            column: {
                stacking: 'normal',
                depth: 40
            },
           
        },
        legend: {
            enabled: true
        },
         series:data,
         navigation: {
            buttonOptions: {
                enabled: false
            }
        }
//         series: [
//             {name: 'online',data: [3,4,0],color: '#36F097'}, 
//             {name: 'offline',data: [5,3,0],color: '#C52525'},
//             {name: 'online',data: [7,0,10],color: '#36F097'},
//         {name: 'online',data: [0,2,0],color: '#36F097'},
//          {name: 'offline',data: [0,15,0],color: '#C52525'},
//          {name: 'online',data: [4,0,0],color: '#36F097'},
//          {name: 'online',data: [6,0,0],color: '#36F097'
//         },
       
//  ]
    };
    return(
        <div className="chart-div ">
             <ExportModal
                openModal={exportMenu}
                handleModalClose={handleExportMenu}
                modalHead="Export Alert Reports"
                handleExporttype={handleExporttype}
                confirmExport={confirmExport}
                exportModalAlert={exportModalAlert}
            />
            
        <HighchartsReact
         highcharts={Highcharts} 
        options={barChartOptions}
         />
      </div>
    )
}
