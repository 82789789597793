import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { InputBase,createTheme } from "@mui/material";
//const theme = useTheme();

export const SmallSearch = styled('div')(({ theme, isDarkMode }) => ({
    position: 'relative',
    
    borderRadius: ' 10px',
    borderWidth: '5px',
    border: '1px solid',
    paddingTop: 7,
    paddingBottom: 9,
    alignContent: 'center',
    borderColor: isDarkMode ? 'transparent' : '#E9E9E9',
    backgroundColor: isDarkMode ? '#1e1d2c' : 'transparent',
    '&:hover': {
      backgroundColor: isDarkMode ? 'transparent' : 'transparent',
    },
    //marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '374px !important',
    [theme.breakpoints.up('sm')]: {
      //marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  export const SearchIconWrapper = styled('div')(({ theme }) => ({
    //padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  export const StyledInputBase = styled(InputBase)(({ theme, isDarkMode }) => ({
    color: isDarkMode ? ' #ffffff' : "#8F8F91",
    fontFamily: 'Nunito, sans-serif !important',
  fontSize:'16px',
      fontWeight: 300,
    '& .MuiInputBase-input': {
     // padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
     // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '90%',
      },
    },
  }));