import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import { CustomizedTableCell, CustomizedTableRow } from "../../styles/TablebButtonStyles";
import { historyTimeOnly, dateOnly, timeOnly } from "../../convertors/TimeConvertor";
import { Paginations } from "../../sharable/pagination/Pagination";
export const CameraHistoryTable = ({ tableData,onChange,
    page,
    pageCount, }) => {
    return (
        <div className="table_Scroll">
        <Table className="table_mob" sx={{ mt: 2, mb: 2, width: "100%" }} >
            <TableHead>
                <TableRow>
                    <CustomizedTableCell>Date</CustomizedTableCell>
                    <CustomizedTableCell>Start Time & End Time</CustomizedTableCell>
                    <CustomizedTableCell>Status</CustomizedTableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {tableData?.map((item, index) => (
                    <CustomizedTableRow key={index}>
                        <CustomizedTableCell>{dateOnly(item.date)}</CustomizedTableCell>
                        <CustomizedTableCell>{historyTimeOnly(item.fromtime) + " To " + historyTimeOnly(item.totime) } </CustomizedTableCell>
                        <CustomizedTableCell>
                            {item.online === false ? (
                                <div className="alert_stage_box alert_critical">
                                    <span className="alert_stage_text critical_text">
                                        {" "}
                                        Offline
                                    </span>
                                </div>
                            ) :  (
                                <div className="alert_stage_box alert_low">
                                    <span className="alert_stage_text low_text">
                                        {" "}
                                        Online
                                    </span>
                                </div>
                            ) }
                        </CustomizedTableCell>

                    </CustomizedTableRow>
                ))}
            </TableBody>
        </Table>
        <div>
            <Paginations
              pageCount={pageCount}
              page={page}
              onChange={onChange}
            />
          </div>
        </div>
    )
}