export const organisationActionType={

    FETCH_ORGANISATION_TYPE: "FETCH_ORGANISATION_TYPE",
    FETCH_ORGANISATION_TYPE_SUCCESS: "FETCH_ORGANISATION_TYPE_SUCCESS",
    FETCH_ORGANISATION_TYPE_ERROR: "FETCH_ORGANISATION_TYPE_ERROR",

    ADD_NEW_ORGANISATION : "ADD_NEW_ORGANISATION",
    ADD_NEW_ORGANISATION_SUCCESS : "ADD_NEW_ORGANISATION_SUCCESS",
    ADD_NEW_ORGANISATION_ERROR : "ADD_NEW_ORGANISATION_ERROR",

    FETCH_ORGANISATION_LIST:"FETCH_ORGANISATION_LIST",
    FETCH_ORGANISATION_LIST_SUCCESS:"FETCH_ORGANISATION_LIST_SUCCESS",
    FETCH_ORGANISATION_LIST_ERROR:"FETCH_ORGANISATION_LIST_ERROR",

    FETCH_SINGLE_ORGANISATION:"FETCH_SINGLE_ORGANISATION",
    FETCH_SINGLE_ORGANISATION_SUCCESS:"FETCH_SINGLE_ORGANISATION_SUCCESS",
    FETCH_SINGLE_ORGANISATION_ERROR:"FETCH_SINGLE_ORGANISATION_ERROR",

    FETCH_ORGANISATION_BLOCKS: "FETCH_ORGANISATION_BLOCKS",
    FETCH_ORGANISATION_BLOCKS_SUCCESS: "FETCH_ORGANISATION_BLOCKS_SUCCESS",
    FETCH_ORGANISATION_BLOCKS_ERROR: "FETCH_ORGANISATION_BLOCKS_ERROR",
    
    DELETE_ORGANISATION : "DELETE_ORGANISATION",
    DELETE_ORGANISATION_SUCCESS : "DELETE_ORGANISATION_SUCCESS",
    DELETE_ORGANISATION_ERROR : "DELETE_ORGANISATION_ERROR",
    CLEAR_DELETE_ORGANISATION_STATUS : "CLEAR_DELETE_ORGANISATION_STATUS",

    
    UPDATE_ORGANISATION : "UPDATE_ORGANISATION",
    UPDATE_ORGANISATION_SUCCESS : "UPDATE_ORGANISATION_SUCCESS",
    UPDATE_ORGANISATION_ERROR : "UPDATE_ORGANISATION_ERROR",
    CLEAR_UPDATE_ORGANISATION_STATUS : "CLEAR_UPDATE_ORGANISATION_STATUS",

    FETCH_ORGANISATION_DROPDOWN:"FETCH_ORGANISATION_DROPDOWN",
    FETCH_ORGANISATION_DROPDOWN_SUCCESS:"FETCH_ORGANISATION_DROPDOWN_SUCCESS",
    FETCH_ORGANISATION_DROPDOWN_ERROR:"FETCH_ORGANISATION_DROPDOWN_ERROR",

    ADD_SITES : "ADD_SITES",
    ADD_SITES_SUCCESS : "ADD_SITES_SUCCESS",
    ADD_SITES_ERROR : "ADD_SITES_ERROR",

    EDIT_SITES : "EDIT_SITES",
    EDIT_SITES_SUCCESS : "EDIT_SITES_SUCCESS",
    EDIT_SITES_ERROR : "EDIT_SITES_ERROR",

    DELETE_SITES : "DELETE_SITES",
    DELETE_SITES_SUCCESS : "DELETE_SITES_SUCCESS",
    DELETE_SITES_ERROR : "DELETE_SITES_ERROR",
    DELETE_SITES_CLEAR_STATUS : "DELETE_SITES_CLEAR_STATUS",

    FETCH_SINGLE_LEVEL:"FETCH_SINGLE_LEVEL",
    FETCH_SINGLE_LEVEL_SUCCESS:"FETCH_SINGLE_LEVEL_SUCCESS",
    FETCH_SINGLE_LEVEL_ERROR:"FETCH_SINGLE_LEVEL_ERROR",

    ADD_ORG_TELEGRAM : "ADD_ORG_TELEGRAM",
    ADD_ORG_TELEGRAM_SUCCESS : "ADD_ORG_TELEGRAM_SUCCESS",
    ADD_ORG_TELEGRAM_ERROR : "ADD_ORG_TELEGRAM_ERROR",
    CLEAR_ADD_ORG_TELEGRAM_STATUS : "CLEAR_ADD_ORG_TELEGRAM_STATUS",

    FETCH_ORG_TELEGRAM:"FETCH_ORG_TELEGRAM",
    FETCH_ORG_TELEGRAM_SUCCESS:"FETCH_ORG_TELEGRAM_SUCCESS",
    FETCH_ORG_TELEGRAM_ERROR:"FETCH_ORG_TELEGRAM_ERROR",

    FETCH_SINGLE_ORG_TELEGRAM:"FETCH_SINGLE_ORG_TELEGRAM",
    FETCH_SINGLE_ORG_TELEGRAM_SUCCESS:"FETCH_SINGLE_ORG_TELEGRAM_SUCCESS",
    FETCH_SINGLE_ORG_TELEGRAM_ERROR:"FETCH_SINGLE_ORG_TELEGRAM_ERROR",


}
//fetch org type
export const fetchOrganisationType = () => {


    return {
        type: organisationActionType.FETCH_ORGANISATION_TYPE,
        

    }
}
export const fetchOrganisationTypeSuccess = (data) => {
    return {
        type: organisationActionType.FETCH_ORGANISATION_TYPE_SUCCESS,
        payload: data
    }
}
export const fetchOrganisationTypeError = (error) => {
    return {
        type: organisationActionType.FETCH_ORGANISATION_TYPE_ERROR,
        error
    }
}
//fetch org block
export const fetchOrganisationBlocks = (data) => {


    return {
        type: organisationActionType.FETCH_ORGANISATION_BLOCKS,
        data:data

    }
}
export const fetchOrganisationBlocksSuccess = (data) => {
    return {
        type: organisationActionType.FETCH_ORGANISATION_BLOCKS_SUCCESS,
        payload: data
    }
}
export const fetchOrganisationBlocksError = (error) => {
    return {
        type: organisationActionType.FETCH_ORGANISATION_BLOCKS_ERROR,
        error
    }
}
//add new org
export const addNewOrganisation = (data) => {


    return {
        type: organisationActionType.ADD_NEW_ORGANISATION,
        data:data

    }
}
export const addNewOrganisationSuccess = (data) => {
    return {
        type: organisationActionType.ADD_NEW_ORGANISATION_SUCCESS,
        payload: data
    }
}
export const addNewOrganisationrror = (error) => {
    return {
        type: organisationActionType.ADD_NEW_ORGANISATION_ERROR,
        error
    }
}
//fetch org list
export const fetchOrganisationList = (data) => {


    return {
        type: organisationActionType.FETCH_ORGANISATION_LIST,
        data:data

    }
}
export const fetchOrganisationListSuccess = (data) => {
    return {
        type: organisationActionType.FETCH_ORGANISATION_LIST_SUCCESS,
        payload: data
    }
}
export const fetchOrganisationListError = (error) => {
    return {
        type: organisationActionType.FETCH_ORGANISATION_LIST_ERROR,
        error
    }
}
//fetch single org
export const fetchSingleOrganisation = (id) => {


    return {
        type: organisationActionType.FETCH_SINGLE_ORGANISATION,
        id:id

    }
}
export const fetchSingleOrganisationSuccess = (data) => {
    return {
        type: organisationActionType.FETCH_SINGLE_ORGANISATION_SUCCESS,
        payload: data
    }
}
export const fetchSingleOrganisationError = (error) => {
    return {
        type: organisationActionType.FETCH_SINGLE_ORGANISATION_ERROR,
        error
    }
}
//dellete org
export const deleteOrganisation = (data) => {


    return {
        type: organisationActionType.DELETE_ORGANISATION,
        data:data

    }
}
export const deleteOrganisationSuccess = (data) => {
    return {
        type: organisationActionType.DELETE_ORGANISATION_SUCCESS,
        payload: data
    }
}
export const deleteOrganisationError = (error) => {
    return {
        type: organisationActionType.DELETE_ORGANISATION_ERROR,
        error
    }
}
export const clearDeleteOrganisationStatus = () => {
    return {
        type: organisationActionType.CLEAR_DELETE_ORGANISATION_STATUS,
        
    }
}
//edit org
export const updateOrganisation = (id,data) => {


    return {
        type: organisationActionType.UPDATE_ORGANISATION,
        id:id,
        data:data

    }
}
export const updateOrganisationSuccess = (data) => {
    return {
        type: organisationActionType.UPDATE_ORGANISATION_SUCCESS,
        payload: data
    }
}
export const updateOrganisationError = (error) => {
    return {
        type: organisationActionType.UPDATE_ORGANISATION_ERROR,
        error
    }
}
//fetch org dropdown
export const fetchOrganisationDropdown = () => {


    return {
        type: organisationActionType.FETCH_ORGANISATION_DROPDOWN,
       

    }
}
export const fetchOrganisationDropdownSuccess = (data) => {
    return {
        type: organisationActionType.FETCH_ORGANISATION_DROPDOWN_SUCCESS,
        payload: data
    }
}
export const fetchOrganisationDropdownError = (error) => {
    return {
        type: organisationActionType.FETCH_ORGANISATION_DROPDOWN_ERROR,
        error
    }
}

//add sites 
export const addSites = () => {


    return {
        type: organisationActionType.ADD_SITES,
       

    }
}
export const addSitesSuccess = (data) => {
    return {
        type: organisationActionType.ADD_SITES_SUCCESS,
        payload: data
    }
}
export const addSitesError = (error) => {
    return {
        type: organisationActionType.ADD_SITES_ERROR,
        error
    }
}
//edit sites 
export const editSites = () => {


    return {
        type: organisationActionType.EDIT_SITES,
       

    }
}
export const editSitesSuccess = (data) => {
    return {
        type: organisationActionType.EDIT_SITES_SUCCESS,
        payload: data
    }
}
export const editSitesError = (error) => {
    return {
        type: organisationActionType.EDIT_SITES_ERROR,
        error
    }
}

//delete sites 
export const deleteSites = (data) => {


    return {
        type: organisationActionType.DELETE_SITES,
       data:data

    }
}
export const deleteSitesSuccess = (data) => {
    return {
        type: organisationActionType.DELETE_SITES_SUCCESS,
        payload: data
    }
}
export const deleteSitesError = (error) => {
    return {
        type: organisationActionType.DELETE_SITES_ERROR,
        error
    }
}
export const deleteSitesClearStatus = () => {
    return {
        type: organisationActionType.DELETE_SITES_CLEAR_STATUS,
        
    }
}
//fetch single level
export const fetchSingleLevel = (id) => {


    return {
        type: organisationActionType.FETCH_SINGLE_LEVEL,
        id:id

    }
}
export const fetchSingleLevelSuccess = (data) => {
    return {
        type: organisationActionType.FETCH_SINGLE_LEVEL_SUCCESS,
        payload: data
    }
}
export const fetchSingleLevelError = (error) => {
    return {
        type: organisationActionType.FETCH_SINGLE_LEVEL_ERROR,
        error
    }
}

//add telegram 
export const addOrgTelegram = (data) => {
    return {
        type: organisationActionType.ADD_ORG_TELEGRAM,
       data:data

    }
}
export const addTelegramSuccess = (data) => {
    return {
        type: organisationActionType.ADD_ORG_TELEGRAM_SUCCESS,
        payload: data
    }
}
export const addTelegramError = (error) => {
    return {
        type: organisationActionType.ADD_ORG_TELEGRAM_ERROR,
        error
    }
}
export const addTelegramClearStatus = () => {
    return {
        type: organisationActionType.CLEAR_ADD_ORG_TELEGRAM_STATUS,
        
    }
}
//fetch telegram  list
export const fetchOrgTelegramList = (data) => {
    return {
        type: organisationActionType.FETCH_ORG_TELEGRAM,
       data:data

    }
}
export const fetchOrgTelegramListSuccess = (data) => {
    return {
        type: organisationActionType.FETCH_ORG_TELEGRAM_SUCCESS,
        payload: data
    }
}
export const fetchOrgTelegramListError = (error) => {
    return {
        type: organisationActionType.FETCH_ORG_TELEGRAM_ERROR,
        error
    }
}
//fetch telegram  single
export const fetchSingleOrgTelegram = (id) => {
    return {
        type: organisationActionType.FETCH_SINGLE_ORG_TELEGRAM,
       id:id

    }
}
export const fetchSingleOrgTelegramSuccess = (data) => {
    return {
        type: organisationActionType.FETCH_SINGLE_ORG_TELEGRAM_SUCCESS,
        payload: data
    }
}
export const fetchSingleOrgTelegramError = (error) => {
    return {
        type: organisationActionType.FETCH_SINGLE_ORG_TELEGRAM_ERROR,
        error
    }
}