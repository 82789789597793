import { Alert, Grid, IconButton, Snackbar, Table, TableBody, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomizedTableCell, CustomizedTableRow } from "../../styles/TablebButtonStyles";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import { DeleteModal } from "../../Modals/DeleteModal";
import { cleardeleteSensorStatus, deleteSensor } from "../../../redux/actions/SurveillanceAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Paginations } from "../../sharable/pagination/Pagination";
import { utcTimeStampToLocaly } from "../../convertors/TimeConvertor";
import '../../dashboard/Dashboard.css'

export const SensorListTable = ({ sensorList, onChange, page, pageCount }) => {
  const dispatch = useDispatch();
  const deleteSensorStatus = useSelector(state => state.surveillance.deleteSensor);

  const [deleteSensorName, setDeleteSensorName] = useState("");
  const [deleteSensorId, setDeleteSensorId] = useState(0);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  useEffect(() => {
    if (!deleteSensorStatus.isFetching && deleteSensorStatus.payload && deleteSensorStatus.payload.success === true) {

      setOpenDeleteModal(false);
      window.location.href = '/surveillance';
    }
    if (!deleteSensorStatus.isFetching && deleteSensorStatus.error) {
      const error = deleteSensorStatus.errorMessage.message;
      setAlertMessage(error);
      setOpenDeleteModal(false);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(cleardeleteSensorStatus())

      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }
    , [deleteSensorStatus])
  const handleEdit = (id) => {
    window.location.href = `/surveillance/sensor/edit/${id}`;
  }
  const handleDelete = (id, name) => {
    setDeleteSensorName(name);
    setOpenDeleteModal(true);
    setDeleteSensorId(id);
  }
  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal)
  }
  const onDeleteConfirm = () => {
    const data = {
      created_user: parseInt(sessionStorage.getItem('id')),
      id: deleteSensorId,
      org_id: parseInt(sessionStorage.getItem('org_Id')),
    }
    dispatch(deleteSensor(data));
  }
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <Grid Container>
      <Grid item lg={11.5} md={11.5} sm={11.5}>
        <div className="table_Scroll">
          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={handlealertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handlealertClose}
              severity="error"
              variant="outlined"
              sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

            >
              {alertMessage}
            </Alert>
          </Snackbar>
          <DeleteModal
            handleDeleteModal={handleDeleteModal}
            openDeleteModal={openDeleteModal}
            ModalHead={'Delete Sensor'}
            ModalBody={deleteSensorName}
            onDeleteConfirm={onDeleteConfirm}
          />
          <Table className="table_mob" sx={{ m: 2, width: "100%" }}>
            <TableHead>
              <TableRow>
                <CustomizedTableCell>Sensor Id</CustomizedTableCell>

                <CustomizedTableCell>Sensor Name</CustomizedTableCell>
                <CustomizedTableCell>Distance-1</CustomizedTableCell>
                <CustomizedTableCell>Distance-2</CustomizedTableCell>
                <CustomizedTableCell>Battery Level</CustomizedTableCell>
                <CustomizedTableCell>Status</CustomizedTableCell>
                <CustomizedTableCell>Last Updated Time</CustomizedTableCell>
                <CustomizedTableCell>Assigned</CustomizedTableCell>

                <CustomizedTableCell>Actions</CustomizedTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sensorList.map((item, index) => (
                <CustomizedTableRow key={index}>
                  <CustomizedTableCell>{item.sensor_id}</CustomizedTableCell>

                  <CustomizedTableCell>{item.sensor_name}</CustomizedTableCell>
                  <CustomizedTableCell>{item.distance1 == "" ? 0 : item.distance1}{' '} CM</CustomizedTableCell>
                  <CustomizedTableCell>{item.distance2 == "" ? 0 : item.distance2}{' '} CM</CustomizedTableCell>
                  <CustomizedTableCell>{item.percentage}{' '} %</CustomizedTableCell>
                  <CustomizedTableCell>
                    {item.device_status.toLowerCase() == "offline" ? (
                      <div className="alert_stage_box alert_critical">
                        <span className="alert_stage_text critical_text">
                          Offline{" "}
                        </span>
                      </div>
                    ) :
                      (<div className="">
                        <span className="alert_stage_text valid_text">
                          Online{" "}
                        </span>
                      </div>)
                    }
                  </CustomizedTableCell>
                  <CustomizedTableCell>{item.timestamp == 0 ? "NA" : utcTimeStampToLocaly(item.timestamp)}</CustomizedTableCell>
                  <CustomizedTableCell>
                    {item.is_assigned == 0 ? (
                      <div className="alert_stage_box alert_critical">
                        <span className="alert_stage_text critical_text">
                          No{" "}
                        </span>
                      </div>
                    ) :
                      (<div className="">
                        <span className="alert_stage_text valid_text">
                          Yes{" "}
                        </span>
                      </div>)
                    }
                  </CustomizedTableCell>
                  <CustomizedTableCell>
                    {/* <IconButton
                    // onClick={() => handleView(item.id)}
                    >
                      <IoEyeOutline color="#161616" />
                    </IconButton> */}
                    <IconButton
                      onClick={() => handleEdit(item.id)}
                    >
                      <MdOutlineEdit color="black" /></IconButton>
                    <IconButton
                      onClick={() => handleDelete(item.id, item.sensor_name)}
                    >
                      <RiDeleteBin6Line color="#C61A1A" /></IconButton>

                  </CustomizedTableCell>
                </CustomizedTableRow>
              ))}
            </TableBody>
          </Table>
          <div>
            <Paginations
              pageCount={pageCount}
              page={page}
              onChange={onChange}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
