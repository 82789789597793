import { Card, CardActionArea, CardContent, CardHeader, Grid, IconButton, Typography, } from "@mui/material";
import React from "react";
import { cardHeaderText } from "../../../styles/CardStyles";
import { IoEyeOutline } from "react-icons/io5";
export const ListenerListItem = ({ DeviceDetails,handleView }) => {
    
    return (
        <>
            <Card className="device_card">
                <CardHeader
                    avatar={
                        <IconButton sx={{ backgroundColor: '#F8E3C4', marginRight: -1 }}>
                            <img src={require("../../../../assets/img/listener.png")} alt="listener" />
                        </IconButton>
                    }
                    title={DeviceDetails.name}
                    subheader={DeviceDetails.deviceuuid}
                    titleTypographyProps={cardHeaderText.title}
                    subheaderTypographyProps={cardHeaderText.subHeader}
                />
                <div className="card_divider"></div>
                <CardContent>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography className="card_text_label">
                                Last reported Time 
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography className="card_text">
                                {DeviceDetails.lastSeen}
                            </Typography>
                        </Grid>
                    </Grid>

                </CardContent>
                <div className="card_divider"></div>
                <CardActionArea sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2 }}>
                    <IconButton 
                        onClick={()=>handleView(DeviceDetails.id)}
                    >
                        <IoEyeOutline color="#161616" />
                    </IconButton>
                </CardActionArea>
            </Card>
        </>
    )
}