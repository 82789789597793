import { API } from './Api';
const route ='/route'
//get route list
export const fetchRouteListData = async (data) => {
     const start = data && data.start !== undefined ? data.start : 0;
     const limit = data && data.limit !== undefined ? data.limit : 15;
    const site_id = data && data.site_id !== undefined ? data.site_id :'';
     const type = data && data.type !== undefined ? data.type : '';
     const org_id= parseInt(sessionStorage.getItem('org_Id'));

    const response = await API.get(`${route}?start=${start}&limit=${limit}&site_id=${site_id}&type=${type}&org_id=${org_id}`);
    return response.data;
}
export const addNewRouteData = async (data) => {
   const response = await API.post(`${route}`,data);
   return response.data;
}
export const editRouteData = async (data,id) => {
   const response = await API.put(`${route}/${id}`,data);
   return response.data;
}
export const singleRouteData = async (id) => {
   const response = await API.get(`${route}/${id}`);
   return response.data;
}
export const removeRouteData = async (body) => {
   const response = await API.put(`${route}`,body);
   return response.data;
}