import { CircularProgress, Grid, Pagination, ThemeProvider, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { StyledGreyButton, darkTheme, lightTheme } from "../Styles";
import { roleListTableData, roleListTableHead } from "../../assets/data/data";
import { Tables } from "../sharable/tables/Table";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserRole, fetchUserRoleList } from "../../redux/actions/UserRoleActions";
import { useState } from "react";
import { Layout } from "../layout/Layout";
import { subDays } from "date-fns";
import { fetchUser } from "../../redux/actions/UserActions";
import { StyledCreateButton } from "../styles/ButtonStyles";
import { RoleTables } from "./RoleTables";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { Paginations } from "../sharable/pagination/Pagination";

export const RoleList = ({ }) => {
  const [roleTableData, setRoleTableData] = useState([]);
  const [isDarkMode, setDarkMode] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(subDays(new Date(), 5));
  const roleListData = useSelector(state => state.userRole.roleList);
  const [searchWord, setSearchWord] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const dispatch = useDispatch();
  // const fetchApiData = useCallback(async () => {
  //   const start = (currentPage - 1) * itemsPerPage;
  //   const limit = itemsPerPage;
  //   let data={
  //     limit: limit,
  //     start: start
  //   }

  //   await dispatch(fetchUserRoleList(data));
  // }, []);
  const fetchApiData = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;
    let data = {
      limit: limit,
      start: start
    };
    return () => {
      dispatch(fetchUserRoleList(data));
    };
  }, [currentPage, itemsPerPage, dispatch]);

  useEffect(() => {
    fetchApiData();
  }, [fetchApiData]);
  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;
    let data = {
      searchWord: searchWord,
      limit: limit,
      start: start
    }
    dispatch(fetchUserRoleList(data))
  }, [searchWord, currentPage])

  useEffect(() => {
    if (!roleListData.isFetching && roleListData.payload && roleListData.payload.success === true) {
      const temp = roleListData.payload.list;
      const tempPagination = roleListData.payload.pagination;
      const tempRoles = [];
      const featureMapping = {
        1: "Dashboard",
        2: "Organisations",
        3: "User Management",
        4: "Role Management",
        5: "Tracking",
        6: "Report",
        7: "Surveillance",
        8: "Alert",
        9: "Device Management",
        10: "Support",
        11: "Audit Trail"
        // Add more mappings as needed
      };
      temp.map((item) => {
        if (item.id !== 1) {
          const roleFeatures = item.role_feature.map(roleFeature => {
            const featuresArray = JSON.parse(roleFeature.features);
            const featuresNames = featuresArray.map(featureValue => featureMapping[featureValue] || featureValue);
            return {
              id: roleFeature.id,
              role: item.role,
              features: featuresNames,
              created_at: roleFeature.created_at,
              updated_at: roleFeature.updated_at,
              // Add more properties as needed
            };
          })
          tempRoles.push(roleFeatures)
        }
      })
      setRoleTableData(tempRoles.flat())
      setPageCount(tempPagination.total_pages)

    }
  }, [roleListData]);
  const onSearchWordChange = (data) => {
    setSearchWord(data);
  }
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  }
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleDarkmode = () => {
    setDarkMode(!isDarkMode);

  }
  const handleCreate = () => {
    window.location.href = "/rolemanagement/create"
  }
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  }
  const handleEdit = (data) => {
    window.location.href = `/roleManagement/edit/${data.id}`

  }
  return (
    <Layout
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      handleEndDateChange={handleEndDateChange}
      handleStartDateChange={handleStartDateChange}
      isDarkMode={isDarkMode}
      handleDarkmode={handleDarkmode}
      placeHolder={'Search Role Name'}
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"rolemanagement"}
      activeMenuKey={"rolemanagement"}
      searchWord={searchWord}
      onSearchWordChange={onSearchWordChange}
    >
      <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
        <Grid container spacing={1} sx={{ pt: 5, mb: 2, ml: 2, mt: 3 }}
        //className={isDarkMode ? "chartbg_dark " : "chartbg_light "} 
        >
          <Grid item lg={6} sm={12} xs={12}>
            <Typography
              className={isDarkMode ? "contentHead chartheading_color pdl" : "pdl chartheading_color_light contentHead"}>
              Role & Feature List </Typography>
          </Grid>
          <Grid item lg={5.9} sm={12} xs={12} container className="alignR" sx={{ '@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important' } }} >
            <StyledCreateButton
              variant="contained"
              disableRipple
              onClick={handleCreate}
              startIcon={<MdOutlineAddToPhotos color="white" />}
            >Create Role & Feature</StyledCreateButton>
          </Grid>

          <div className="hidden-mobile chartmr15 ">
          </div>
        </Grid>
        {roleListData.isFetching ?
          <Grid item lg={12} md={12} sm={12} xs={12} container
            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress sx={{ color: '#FFAC0B' }} />
          </Grid>
          :
          <>
            <Grid container spacing={1} sx={{ pt: 2, ml: 1 }} >
              <Grid item lg={11.8} sm={11.8} xs={11.8}
                className={isDarkMode ? "chartbg_dark tableheight" : "tableheight chartbg_light "}>
                {roleTableData.length && roleTableData !== undefined ?
                  <RoleTables
                    isDarkMode={isDarkMode}
                    row={roleTableData}
                    handleEdit={handleEdit}
                  />
                  : <>No Roles found</>
                }
              </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{ marginTop: 2 }}>
              {/* <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <Pagination
              sx={{
                float: "right", mr: 0, ml: 2,
                fontFamily: 'Poppins , sans-serif !important',
                backgroundColor: 'transparent !important',
                '& .MuiPaginationItem-root': {
                  backgroundColor: 'transparent !important', // Set number box to transparent background
                },
                '& .Mui-selected': {
                  backgroundColor: 'transparent !important', // Set selected number box to grey background
                  fontWeight: 600
                },
                '& .MuiPaginationItem-icon': {
                  backgroundColor: '#7D8BAA',
                  color: "white",
                  borderRadius: '3px',
                  padding: 1// Set arrow icon box to grey background
                },
              }}
              count={2} variant="text" shape="rounded" />
          </ThemeProvider> */}
              {
                roleTableData.length && roleTableData !== undefined ?

                  <Paginations
                    isDarkMode={isDarkMode}
                    pageCount={pageCount}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                  : <></>}
            </Grid>
          </>
        }


      </div>
    </Layout>
  )
}