import { roleFeatureActionType } from "../actions/RoleFeatures"

const dataTemplate ={
    isFetching:false,
    error:false,
    payload:[],
    errorMessage:""
}
const initialData ={
    featureData:dataTemplate,
    
}
export const RoleFeatureReducer =(state = initialData,action) => {
    switch(action.type){
        case roleFeatureActionType.FETCH_ROLE_FEATURE:
            return{
                ...state,
                featureData:{
                    ...state.featureData,
                    isFetching:true,
                    error:false,
                    errorMessage:'',
                    payload:[]
                }
            }
            case roleFeatureActionType.FETCH_ROLE_FEATURE_SUCCESS:
            
                return{
                    ...state,
                    featureData:{
                        ...state.featureData,
                        isFetching:false,
                        error:false,
                        payload:action.data
                    }
                }
            case roleFeatureActionType.FETCH_ROLE_FEATURE_ERROR:
                return{
                    ...state,
                    featureData:{
                        ...state.featureData,
                        isFetching:false,
                        payload:[],
                        error:true,
                        errorMessage:action.error
                    }
                }
            
            default :
                return state;
        }
    }