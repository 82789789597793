import { Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from "react";
import { StyledCreateSmallButton } from "../../styles/ButtonStyles";
export const CreateScreen = ({ previewUrl, userDetails, handleChange, handleSubmit, isDarkMode, handleFileInputChange,
    sites, roles,validationErrors ,hideSite}) => {
    return (
        <>
            <Grid item container className="alignCenter">
                {previewUrl ?
                    <img
                        src={previewUrl}
                        className="userProfile align_mr"
                        alt="Preview" />
                    : <img
                        className="userProfile align_mr"
                        src={require("../../../assets/img/user.png")}

                        alt="user_pic" />}
            </Grid>
            <Grid item container className="alignCenter align_mr">

                <div class="file-input-container"  >
                    <label for="image-upload" className=" pic_upload" >
                        <span style={{ marginRight: "15px" }}></span>
                        Upload Photo
                        <span style={{ marginLeft: "15px" }}></span>
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        id="image-upload"
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                    />
                </div>
            </Grid>
            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                <InputLabel
                    className={isDarkMode ? "form_text" : "form_text_light"}
                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                     Name</InputLabel>
                <TextField
                    className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                    placeholder="Name"
                    value={userDetails.username}
                    onChange={(e) => handleChange(e.target.value, 'username')}
                    type="text"
                    inputProps={{ autoComplete: 'off' }}
                    id="worker"
                    name={`worker${Math.random()}`}
                   error={!!validationErrors?.username}
                   helperText={validationErrors?.username}

                />
            </Grid>
            <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                <InputLabel
                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                    Role</InputLabel>
                <Select
                    className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                    value={userDetails.role_id}
                    onChange={(e) => handleChange(e.target.value, "role_id")}
                    displayEmpty
                    renderValue={(selected) => {
                        if (selected == '') {
                            return <span style={{ color: '#a2a2a2' }}>Role</span>;
                        }


                        const selectedRole = roles.find(role => role.id === selected);

                        return selectedRole ? selectedRole.role : '';
                    }}
                    error={!!validationErrors?.role_id}

                >
                    {roles.length ?
                        roles.map((item, index) => (
                            <MenuItem
                                key={index}
                                value={item.id}
                            >
                                {item.role}

                            </MenuItem>
                        ))
                        :
                        <MenuItem value={""}>
                            No Roles Found

                        </MenuItem>
                    }
                </Select>
                {validationErrors?.role_id && (
                    <Typography
                        sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                        className={
                            isDarkMode
                                ? 'errormargin  errorStyle '
                                : ' errormargin errorStyle'
                        }
                    >
                        {validationErrors?.role_id}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                <InputLabel
                    className={isDarkMode ? "form_text" : "form_text_light"}
                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                    Site</InputLabel>
                <Select
                    className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                    value={userDetails.site_id}
                    onChange={(e) => handleChange(e.target.value, 'site_id')}
                    displayEmpty
                    disabled ={hideSite ? true : false}

                    renderValue={(selected) => {
                        if (selected == '') {
                            return <span style={{ color: '#a2a2a2' }}>Site</span>;
                        }

                        const selectedSite = sites.find(site => site.organizationId === selected);

                        return selectedSite ? selectedSite.organizationName : '';
                    }}
                    error={!!validationErrors?.site_id}

                >
                    {sites.length ?
                        sites.map((site, index) => (
                            <MenuItem
                                key={index}
                                value={site.organizationId}>
                                {site.organizationName}
                            </MenuItem>
                        ))
                        : <MenuItem>No Sites Found</MenuItem>
                    }
                </Select>
                {validationErrors?.site_id && (
                    <Typography
                        sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                        className={
                            isDarkMode
                                ? 'errormargin  errorStyle '
                                : ' errormargin errorStyle'
                        }
                    >
                        {validationErrors?.site_id}
                    </Typography>
                )}
            </Grid>

            <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                <div  style={{ display: 'flex'}}>
                <InputLabel
                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                    {"Mail Id   "}</InputLabel>
                    <InputLabel
                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, fontSize:"16px !important"}}>
                    {" "}{'    ( This is your LoginId )'}</InputLabel>
                </div>
              
                <TextField
                    className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                    placeholder='Mail Id (This is your LoginId)'
                    value={userDetails.email}
                    onChange={(e) => handleChange(e.target.value, 'email')}
                    type="email"
                    inputProps={{ autoComplete: 'off' }}
                    id="username"
                    name={`username`}
                    error={!!validationErrors?.email}
                    helperText={validationErrors?.email}
                />
            </Grid>
            <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                <InputLabel
                    className={isDarkMode ? "form_text" : "form_text_light"}
                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                    Phone No</InputLabel>
                <TextField
                    className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                    placeholder="Phone Number"
                    value={userDetails.phone_number}
                    onChange={(e) => handleChange(e.target.value, 'phone_number')}
                    error={!!validationErrors?.phone_number}
                    helperText={validationErrors?.phone_number}
                />

            </Grid>
            <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                <InputLabel
                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                    Year Of Experience</InputLabel>
                <TextField
                    className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                    placeholder='No. of experience in the field'
                    type="number"
                    value={userDetails.year_of_experience}
                    onChange={(e) => handleChange(e.target.value, 'year_of_experience')}
                    error={!!validationErrors?.year_of_experience}
                    helperText={validationErrors?.year_of_experience}
                    inputProps={{ min: 0 }}
                />
            </Grid>
            <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                <InputLabel
                    className={isDarkMode ? "form_text" : "form_text_light"}
                    sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                    Gender</InputLabel>
                <Select
                    className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                    value={userDetails.gender}
                    onChange={(e) => handleChange(e.target.value, "gender")}
                    displayEmpty
                    error={!!validationErrors?.gender}

                    renderValue={(selected) => {
                        if (selected == '') {
                            return <span style={{ color: '#a2a2a2' }}>Gender</span>;
                        }
                        return selected;
                    }}
                >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
                {validationErrors?.gender && (
                    <Typography
                        sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                        className={
                            isDarkMode
                                ? 'errormargin  errorStyle '
                                : ' errormargin errorStyle'
                        }
                    >
                        {validationErrors?.gender}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                <InputLabel
                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>

                    Describe</InputLabel>
                <TextField
                    className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                    placeholder='Describe about the user..'
                    multiline
                    maxRows={4}
                    value={userDetails.description}
                    onChange={(e) => handleChange(e.target.value, 'description')}
                    error={!!validationErrors?.description}
                    helperText={validationErrors?.description}
                />
            </Grid>
            <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                <InputLabel
                    className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                    sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                    Password</InputLabel>
                <TextField
                    className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                    placeholder='Create Password'
                    type="password"
                    id="userpassword"
                    name={`userpassword_${Math.random()}`}
                    inputProps={{ autoComplete: 'new-password' }}
                    value={userDetails.password}
                    onChange={(e) => handleChange(e.target.value, 'password')}
                    // Use "new-password" value for better compatibility
                    error={!!validationErrors?.password}
                    helperText={validationErrors?.password}
                />
            </Grid>
            <Grid item tem xs={10} sm={10} lg={10} md={10} >
                <StyledCreateSmallButton
                    onClick={handleSubmit}
                    sx={{ mt: 5, width: 150 }} variant="contained"
                    disableRipple>
                    Create
                </StyledCreateSmallButton>
            </Grid>
        </>
    )
}