import { checkPointActionType } from "../actions/CheckPointActions"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    checkPointList:dataTemplate,
    checkPointType:dataTemplate,
    addCheckPoint:dataTemplate,
    singleCheckPoint:dataTemplate,
    updateCheckPoint:dataTemplate,
    deleteCheckPoint:dataTemplate,
    clearCheckpointDelete:dataTemplate
}

const CheckPointListReducer = (state = initialData, action) => {
    switch (action.type) {
         //fetch org list
         case checkPointActionType.FETCH_CHECKPOINT_LIST:
            return {
                ...state,
                checkPointList: {
                    ...state.checkPointList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case checkPointActionType.FETCH_CHECKPOINT_LIST_SUCCESS:
            return {

                ...state,
                checkPointList: {
                    ...state.checkPointList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case checkPointActionType.FETCH_CHECKPOINT_LIST_ERROR:
            return {
                ...state,
                checkPointList: {
                    ...state.checkPointList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case checkPointActionType.FETCH_CHECKPOINT_TYPE:
                return {
                    ...state,
                    checkPointType: {
                        ...state.checkPointType,
                        isFetching: true,
                        payload: [],
                        error: false
                    }
                }
            case checkPointActionType.FETCH_CHECKPOINT_TYPE_SUCCESS:
                return {
    
                    ...state,
                    checkPointType: {
                        ...state.checkPointType,
                        isFetching: false,
                        error: false,
                        payload: action.payload
                    }
                }
                case checkPointActionType.FETCH_CHECKPOINT_TYPE_ERROR:
                    return {
                        ...state,
                        checkPointType: {
                            ...state.checkPointType,
                            isFetching: false,
                            payload: [],
                            error: true,
                            errorMessage: action.error
                        }
                    }
            case checkPointActionType.ADD_CHECKPOINT:
                return {
                    ...state,
                    addCheckPoint: {
                        ...state.addCheckPoint,
                        isFetching: true,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
                case checkPointActionType.ADD_CHECKPOINT_SUCCESS:
                return {
                    ...state,
                    addCheckPoint: {
                        ...state.addCheckPoint,
                        isFetching: false,
                        payload:action.payload,
                        error: false
                    }
                }
            case checkPointActionType.ADD_CHECKPOINT_ERROR:
                return {
    
                    ...state,
                    addCheckPoint: {
                        ...state.addCheckPoint,
                        isFetching: false,
                            error: true,
                            errorMessage: action.error,
                            payload: []
                    }
                }
                case checkPointActionType.CLEAR_ADD_CHECKPOINT_STATUS:
                    return {
        
                        ...state,
                        addCheckPoint: {
                            ...state.addCheckPoint,
                            isFetching: false,
                                error: false,
                                errorMessage: '',
                                payload: []
                        }
                    }


                case checkPointActionType.FETCH_CHECKPOINT:
                    return {
                        ...state,
                        singleCheckPoint: {
                            ...state.singleCheckPoint,
                            isFetching: true,
                            payload: [],
                            error: false,
                            errorMessage: ''
                        }
                    }
                    case checkPointActionType.FETCH_CHECKPOINT_SUCCESS:
                    return {
                        ...state,
                        singleCheckPoint: {
                            ...state.singleCheckPoint,
                            isFetching: false,
                            payload:action.payload,
                            error: false
                        }
                    }
                case checkPointActionType.FETCH_CHECKPOINT_ERROR:
                    return {
        
                        ...state,
                        singleCheckPoint: {
                            ...state.singleCheckPoint,
                            isFetching: false,
                            error: true,
                            errorMessage: action.error,
                            payload: []
                        }
                    }
                  //  updateCheckPoint
                    case checkPointActionType.UPDATE_CHECKPOINT:
                        return {
                            ...state,
                            updateCheckPoint: {
                                ...state.updateCheckPoint,
                                isFetching: true,
                                payload: [],
                                error: false,
                                errorMessage: ''
                            }
                        }
                        case checkPointActionType.UPDATE_CHECKPOINT_SUCCESS:
                        return {
                            ...state,
                            updateCheckPoint: {
                                ...state.updateCheckPoint,
                                isFetching: false,
                                payload:action.payload,
                                error: false
                            }
                        }
                    case checkPointActionType.UPDATE_CHECKPOINT_ERROR:
                        return {
            
                            ...state,
                            updateCheckPoint: {
                                ...state.updateCheckPoint,
                                isFetching: false,
                                error: true,
                                errorMessage: action.error,
                                payload: []
                            }
                        }
                        case checkPointActionType.CLEAR_UPDATE_CHECKPOINT_STATUS:
                            return {
                
                                ...state,
                                updateCheckPoint: {
                                    ...state.updateCheckPoint,
                                    isFetching: false,
                                        error: false,
                                        errorMessage: '',
                                        payload: []
                                }
                            }
                         //  deleteCheckPoint
                    case checkPointActionType.DELETE_CHECKPOINT:
                        return {
                            ...state,
                            deleteCheckPoint: {
                                ...state.deleteCheckPoint,
                                isFetching: true,
                                payload: [],
                                error: false,
                                errorMessage: ''
                            }
                        }
                        case checkPointActionType.DELETE_CHECKPOINT_SUCCESS:
                        return {
                            ...state,
                            deleteCheckPoint: {
                                ...state.deleteCheckPoint,
                                isFetching: false,
                                payload:action.payload,
                                error: false
                            }
                        }
                    case checkPointActionType.DELETE_CHECKPOINT_ERROR:
                        return {
            
                            ...state,
                            deleteCheckPoint: {
                                ...state.deleteCheckPoint,
                                isFetching: false,
                                error: true,
                                errorMessage: action.error,
                                payload:[],
                            }
                        }

                        //clear delete
                        case checkPointActionType.CLEAR_DELETE_CHECKPOINT_STATUS:
                            return {
                
                                ...state,
                                deleteCheckPoint: {
                                    ...state.deleteCheckPoint,
                                    isFetching: false,
                                    error: false,
                                    errorMessage: '',
                                    payload: []
                                }
                            }
        default:
            return state
    }

}
export default CheckPointListReducer;