export const checkPointActionType ={
    FETCH_CHECKPOINT_LIST : "FETCH_CHECKPOINT_LIST",
    FETCH_CHECKPOINT_LIST_SUCCESS : "FETCH_CHECKPOINT_LIST_SUCCESS",
    FETCH_CHECKPOINT_LIST_ERROR : "FETCH_CHECKPOINT_LIST_ERROR",

    FETCH_CHECKPOINT_TYPE : "FETCH_CHECKPOINT_TYPE",
    FETCH_CHECKPOINT_TYPE_SUCCESS : "FETCH_CHECKPOINT_TYPE_SUCCESS",
    FETCH_CHECKPOINT_TYPE_ERROR : "FETCH_CHECKPOINT_TYPE_ERROR",

    ADD_CHECKPOINT : "ADD_CHECKPOINT",
    ADD_CHECKPOINT_SUCCESS : "ADD_CHECKPOINT_SUCCESS",
    ADD_CHECKPOINT_ERROR : "ADD_CHECKPOINT_ERROR",
    CLEAR_ADD_CHECKPOINT_STATUS : "CLEAR_ADD_CHECKPOINT_STATUS",

    FETCH_CHECKPOINT : "FETCH_CHECKPOINT",
    FETCH_CHECKPOINT_SUCCESS : "FETCH_CHECKPOINT_SUCCESS",
    FETCH_CHECKPOINT_ERROR : "FETCH_CHECKPOINT_ERROR",

    UPDATE_CHECKPOINT : "UPDATE_CHECKPOINT",
    UPDATE_CHECKPOINT_SUCCESS : "UPDATE_CHECKPOINT_SUCCESS",
    UPDATE_CHECKPOINT_ERROR : "UPDATE_CHECKPOINT_ERROR",
    CLEAR_UPDATE_CHECKPOINT_STATUS : "CLEAR_UPDATE_CHECKPOINT_STATUS", 

   DELETE_CHECKPOINT : "DELETE_CHECKPOINT",
   DELETE_CHECKPOINT_SUCCESS : "DELETE_CHECKPOINT_SUCCESS",
   DELETE_CHECKPOINT_ERROR : "DELETE_CHECKPOINT_ERROR",
   CLEAR_DELETE_CHECKPOINT_STATUS : 'CLEAR_DELETE_CHECKPOINT_STATUS'
}

//fetch checkpoint list
export const fetchCheckPointList = (data) => {


    return {
        type: checkPointActionType.FETCH_CHECKPOINT_LIST,
        data:data

    }
}
export const fetchCheckPointListSuccess = (data) => {
    return {
        type: checkPointActionType.FETCH_CHECKPOINT_LIST_SUCCESS,
        payload: data
    }
}
export const fetchCheckPointListError = (error) => {
    return {
        type: checkPointActionType.FETCH_CHECKPOINT_LIST_ERROR,
        error
    }
}

//fetch checkpoint type
export const fetchCheckPointType = () => {


    return {
        type: checkPointActionType.FETCH_CHECKPOINT_TYPE,

    }
}
export const fetchCheckPointTypeSuccess = (data) => {
    return {
        type: checkPointActionType.FETCH_CHECKPOINT_TYPE_SUCCESS,
        payload: data
    }
}
export const fetchCheckPointTypeError = (error) => {
    return {
        type: checkPointActionType.FETCH_CHECKPOINT_TYPE_ERROR,
        error
    }
}
//add checkpoint 
export const addCheckPoint = (data) => {


    return {
        type: checkPointActionType.ADD_CHECKPOINT,
        data:data
    }
}
export const addCheckPointSuccess = (data) => {
    return {
        type: checkPointActionType.ADD_CHECKPOINT_SUCCESS,
        payload: data
    }
}
export const addCheckPointError = (error) => {
    return {
        type: checkPointActionType.ADD_CHECKPOINT_ERROR,
        error
    }
}
export const clearAddCheckpointStatus = () => {
    return {
        type: checkPointActionType.CLEAR_ADD_CHECKPOINT_STATUS,
       
    }
}
//fetch single checkpoint 
export const fetchCheckPoint = (id) => {


    return {
        type: checkPointActionType.FETCH_CHECKPOINT,
        id:id
    }
}
export const fetchCheckPointSuccess = (data) => {
    return {
        type: checkPointActionType.FETCH_CHECKPOINT_SUCCESS,
        payload: data
    }
}
export const fetchCheckPointError = (error) => {
    return {
        type: checkPointActionType.FETCH_CHECKPOINT_ERROR,
        error
    }
}
export const updateCheckPoint = (id,data) => {


    return {
        type: checkPointActionType.UPDATE_CHECKPOINT,
        id:id,
        data:data
    }
}
export const updateCheckPointSuccess = (data) => {
    return {
        type: checkPointActionType.UPDATE_CHECKPOINT_SUCCESS,
        payload: data
    }
}
export const updateCheckPointError = (error) => {
    return {
        type: checkPointActionType.UPDATE_CHECKPOINT_ERROR,
        error
    }
}
export const clearUpdateCheckpointStatus = () => {
    return {
        type: checkPointActionType.CLEAR_UPDATE_CHECKPOINT_STATUS,
       
    }
}


export const deleteCheckPoint = (data) => {


    return {
        type: checkPointActionType.DELETE_CHECKPOINT,
        data:data,
       
    }
}
export const deleteCheckPointSuccess = (data) => {
    return {
        type: checkPointActionType.DELETE_CHECKPOINT_SUCCESS,
        payload: data
    }
}
export const deleteCheckPointError = (error) => {
    return {
        type: checkPointActionType.DELETE_CHECKPOINT_ERROR,
        error
    }
}

export const clearDeleteCheckpointStatus =()=>{
    return {
        type: checkPointActionType.CLEAR_DELETE_CHECKPOINT_STATUS
    }
}