import React, { useState, useEffect } from "react";
import '../dashboard/Dashboard.css'
import { CircularProgress, Grid, MenuItem, Select, Typography } from "@mui/material";
import { MenuProps } from "../Styles";
import { Layout } from "../layout/Layout";
import { StyledCreateButton } from "../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { useDispatch } from "react-redux";
import { fetchUserList } from "../../redux/actions/UserActions";
import { useSelector } from "react-redux";
import { UserListTable } from "./UserListTable";
import { fetchSites } from "../../redux/actions/UserRoleActions";
export const UserList = () => {
  const dispatch = useDispatch();
  const userListData = useSelector(state => state.user.userList);
  const siteData = useSelector(state => state.userRole.siteData);
  const [isDarkMode, setDarkMode] = useState(false);
  // const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const [userList, setUserList] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  useEffect(() => {
    dispatch(fetchSites())
  }, [])
  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;
    let data = {
      parent_id: parseInt(sessionStorage.getItem('id')),
      site_id: selectedSite,
      searchWord: searchWord,
      limit: limit,
      start: start
    }

    dispatch(fetchUserList(data))
  }, [selectedSite, searchWord, currentPage])

  useEffect(() => {
    if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp
        }
      })
      setSites(temp);
      const siteId = sessionStorage.getItem('user_site_id');
      if (siteId != 0) {
        setSelectedSite(parseInt(siteId))
      }
    }

  }, [siteData]);
  useEffect(() => {
    if (!userListData.isFetching && userListData.payload && userListData.payload.success === true) {
      const tempUser = userListData.payload.user_list;

      const tempPagination = userListData.payload.pagination;
      setUserList(tempUser);
      setPageCount(tempPagination.total_pages)

    }
  }, [userListData]);
  const handlePageChange = ( newPage) => {
    setCurrentPage(newPage);
  }
  const handleActiveMenuItems = () => {
    // setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  }

  const handleDarkmode = () => {
    setDarkMode(!isDarkMode);

  }
  const handleCreate = () => {
    window.location.href = '/usermanagement/create';

  }
  const handleView = (id) => {
    sessionStorage.setItem("viewUserId", id);
    window.location.href = '/usermanagement/view'
  }
  const onSearchWordChange = (data) => {
    setSearchWord(data);
  }
  // const handleSelectedSite = (site) => {
  //   setSelectedSite(site);
  // }
  return (
    <Layout
      isDarkMode={isDarkMode}
      handleDarkmode={handleDarkmode}
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"usermanagement"}
      activeMenuKey={"usermanagement"}
      searchWord={searchWord}
      onSearchWordChange={onSearchWordChange}

      placeHolder={"Search User Name.."}
    >
      <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
        <Grid container sx={{ mt: 5, ml: 3 }}>
          <Grid item lg={2} sm={12} xs={12} md={2} sx={{ '@media (max-width: 600px)': { mb: 2 } }}>
            <Typography sx={{ textAlign: 'left', mt: 2, }}
              className={isDarkMode ? "contentHead  chartheading_color" : "contentHead  light_text"}>
              List Of Users
              {/* Coming  Soon */}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ '@media (max-width: 600px)': { mb: 2 } }}>
            <Grid container
            //className="mt2"
            >
              <Grid item >
                <Typography sx={{ textAlign: 'center', mt: 2 }}
                  className={isDarkMode ? "headersubhead aligncenter chartheading_color" : "headersubhead aligncenter light_text"}>
                  Sites :
                </Typography>

              </Grid>
              <Grid item lg={4} sm={8} xs={8} md={4}>
                <Select
                  //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                  sx={{
                    color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                      border: '',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(233, 233, 233, 1)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: isDarkMode ? "white !important" : "#161616",
                    }
                  }}

                  displayEmpty
                  MenuProps={MenuProps}
                  className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                  value={selectedSite}
                  onChange={(e) => setSelectedSite(e.target.value)}
                >
                  <MenuItem value={''}>All</MenuItem>
                  {sites ?
                    sites.map((site, index) => (
                      <MenuItem
                        key={index}
                        value={site.organizationId}>
                        {site.organizationName}
                      </MenuItem>
                    ))
                    : <MenuItem value={''}>No Data Found</MenuItem>}

                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3.5} sm={10} xs={10} md={3.5} container className="alignR"
            sx={{ '@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important', mt: 2 } }}>
            <StyledCreateButton

              variant="contained"
              disableRipple
              startIcon={<MdOutlineAddToPhotos color="white" />}
              onClick={handleCreate}
            >

              Create User
            </StyledCreateButton>
          </Grid>
        </Grid>
        {userListData.isFetching ?
          <Grid item lg={12} md={12} sm={12} xs={12} container
            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress sx={{ color: '#FFAC0B' }} />
          </Grid>
          :
          <UserListTable
            TableData={userList}
            handleView={handleView}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            pageCount={pageCount}
            isDarkMode={isDarkMode}
          />
        }
      </div>

    </Layout>
  )
}