import React from "react";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "../../Styles";
import { Pagination } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export const Paginations = ({isDarkMode,pageCount,page,onChange}) =>{
    return(
        <div>
              <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            
              <Pagination
            sx={{
              float: "right", mr: 0, ml: 2,
              fontFamily: 'Inter , sans-serif !important',
              backgroundColor: 'transparent !important',
              '& .MuiPaginationItem-root': {
                backgroundColor: 'transparent !important', // Set number box to transparent background
                // borderColor:"#5D5D5D !important",
                // border:'2px !important',
              },
              '& .Mui-selected': {
                backgroundColor: 'transparent !important', // Set selected number box to grey background
                fontWeight: 700
              },
              '& .MuiPaginationItem-icon': {
                backgroundColor: 'transparent !important',
              
                border: '1px solid  #5D5D5D !important',
                color: "#5D5D5D",
                borderRadius: '3px',
                padding: 1,
                '&:hover': {
                  backgroundColor: 'transparent !important',
                },
              },
            }}
            
            shape="rounded"
            page={page}
            onChange={onChange}
            count={pageCount} variant="text"
            nextIcon={<ArrowForwardIcon />}
            prevIcon={<ArrowBackIcon />}  />
            
            </ThemeProvider>
            
        </div>
    )
}