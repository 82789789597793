import React, { useEffect, useState } from "react";
import './Sidebar.css'
import { Box, Drawer, List,  useTheme, Hidden, IconButton, Grid } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { SidebarMenu } from "./SidebarMenu";
import PropTypes from 'prop-types';

export const Sidebar = ({ isDarkMode, handleActiveMenuItems, activeMenuItem, roleData, openLogoutodal }) => {
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [dashboardMenu, setDashboardMenu] = useState(false);
    const [organisationMenu, setOrganisationMenu] = useState(false);
    const [userManagementMenu, setUserManagementMenu] = useState(false);
    const [roleManagementMenu, setRoleManagementMenu] = useState(false);
    const [trackingMenu, setTrackingMenu] = useState(false);
    const [reportMenu, setReportMenu] = useState(false);
    const [videoSurvillanceMenu, setVideoSurvillanceMenu] = useState(false);
    const [alertMenu, setAlertMenu] = useState(false);
    // const [deviceManagementMenu, setDeviceManagementMenu] = useState(false);
    const [supportMenu, setSupportMenu] = useState(false);
    const [auditTrailMenu, setAuditTrailMenu] = useState(false);
    const [orgUrl, setOrgUrl] = useState('');
    useEffect(() => {

        if (roleData) {
            const setMenuState = (feature, stateSetter) => {
                const hasFeature = roleData.includes(feature);
                if (hasFeature) {
                    stateSetter(true);
                }
            };
            const featuresStateMappings = [
                { feature: 1, stateSetter: setDashboardMenu },
                { feature: 2, stateSetter: setOrganisationMenu },
                { feature: 3, stateSetter: setUserManagementMenu },
                { feature: 4, stateSetter: setRoleManagementMenu },
                { feature: 5, stateSetter: setTrackingMenu },
                { feature: 6, stateSetter: setReportMenu },
                { feature: 7, stateSetter: setVideoSurvillanceMenu },
                { feature: 8, stateSetter: setAlertMenu },
                // { feature: 9, stateSetter: setDeviceManagementMenu },
                { feature: 10, stateSetter: setSupportMenu },
                { feature: 11, stateSetter: setAuditTrailMenu },

            ];
            featuresStateMappings.forEach(mapping => setMenuState(mapping.feature, mapping.stateSetter));
        }
        const org_logo = sessionStorage.getItem('org_logo');
        if (org_logo !== "") {
            const tempUrl = process.env.REACT_APP_API_BASE_URL + '/media' + org_logo;
            setOrgUrl(tempUrl);
        }
    }, [roleData])
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // const handleHomeClick =()=>{
    //     window.location.href = '/more';

    // }
    return (
        <div className="sidebar">
            <Box sx={{ display: 'flex', width: '100%' }}>
                <nav>
                    <Hidden lgDown>
                        <Drawer
                            sx={{
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    minWidth: 220,
                                    boxSizing: 'border-box',
                                    border: '0px, 1px, 0px, 0px !important',
                                    borderColor: '#E9E9E9',
                                    backgroundColor: isDarkMode ? "#140F1E" : "#ffffff",
                                    [theme.breakpoints.down('sm')]: {
                                        minWidth: 130, // Adjust the width for small screens
                                        border: '0px, 1px, 0px, 0px !important',
                                        borderColor: '#E9E9E9',
                                    },
                                },
                                backgroundColor: "#140F1E"
                            }}
                            variant="permanent"
                            anchor="left"
                        >
                            {orgUrl && orgUrl !== "" ? <img className="logo" src={orgUrl}
                                crossOrigin="anonymous"

                                alt="logo" />
                                : <img className="logo" src={require("../../../assets/img/logo.png")} alt="org_logo" />
                            }
                            <List component="div" disablePadding id="sidebar">
                                <SidebarMenu
                                    isDarkMode={isDarkMode}
                                    activeMenuItem={activeMenuItem}
                                    handleActiveMenuItems={handleActiveMenuItems}
                                    organisationMenu={organisationMenu}
                                    roleManagementMenu={roleManagementMenu}
                                    userManagementMenu={userManagementMenu}
                                    trackingMenu={trackingMenu}
                                    reportMenu={reportMenu}
                                    alertMenu={alertMenu}

                                    videoSurvillanceMenu={videoSurvillanceMenu}
                                    supportMenu={supportMenu}

                                    dashboardMenu={dashboardMenu}
                                    auditTrailMenu={auditTrailMenu}
                                    openLogoutodal={openLogoutodal}
                                />
                            </List>

                            <Box sx={{ textAlign: 'left', bottom: 0, left: 0, right: 0 }}>
                                {orgUrl && orgUrl !== "" ?
                                    <img
                                        className="logo"
                                        src={require("../../../assets/img/logo.png")}
                                        alt="logo"
                                    />
                                    : <></>
                                }
                            </Box>
                        </Drawer>
                    </Hidden>
                    <Hidden mdUp implementation="css" only={"lg"}>
                        <Grid contaniner>
                            <Grid item >
                                <IconButton
                                    color="inherit"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                    sx={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 0, marginLeft: 2 }}
                                >
                                    <MenuIcon sx={{ color: isDarkMode ? "white" : "black" }} />
                                </IconButton>
                            </Grid>
                            {/* <Grid
                        item
                        lg={10}
                        md={10}
                        sm={10}
                        xs={10}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <PlainButton
                        
                        onClick={handleHomeClick}>Home</PlainButton>
    
                    </Grid> */}

                        </Grid>

                    </Hidden>
                    <IconButton
                        color="inherit"
                        onClick={handleDrawerToggle}
                        edge="start"
                        sx={{
                            display: 'none',
                            '@media (min-width: 900px) and (max-width: 1200px)': {
                                display: 'flex',
                                justifyContent: 'flex-start',
                                paddingLeft: 0,
                                marginLeft: 2,
                                height: "100 vh"
                            },
                        }}
                    >
                        <MenuIcon sx={{ color: isDarkMode ? "white" : "black" }} />
                    </IconButton>
                    {/* <Hidden mdUp> */}
                    {/* Only show the icon on small devices */}
                    <Drawer
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: '220px', // Adjust the width for small screens
                                boxSizing: 'border-box',
                                border: '0px, 1px, 0px, 0px !important',
                                borderColor: '#E9E9E9',
                                backgroundColor: isDarkMode ? "#140F1E" : "#ffffff",
                                [theme.breakpoints.down('sm')]: {
                                    minWidth: 130, // Adjust the width for small screens
                                    border: '0px, 1px, 0px, 0px !important',
                                    borderColor: '#E9E9E9',
                                },
                            },
                            backgroundColor: "#140F1E"
                        }}
                        variant="temporary"
                        anchor="left"
                    > {orgUrl && orgUrl !== "" ? <img className="logo" src={orgUrl}
                        crossOrigin="anonymous"

                        alt="logo" />
                        : <img className="logo" src={require("../../../assets/img/logo.png")} alt="logo" />
                        }
                        <List component="div" id="sidebar" disablePadding sx={{ backgroundColor: isDarkMode ? "#140F1E " : "#ffffff" }}>
                            {/* {dashboardMenu ?
                                <MenuItem
                                    className={isDarkMode ? `menu ${activeMenuItem === "dashboard" ? "active" : ""}` :
                                        `menu_light ${activeMenuItem === "dashboard" ? "active" : ""}`}
                                    component={Link}
                                    to="/dashboard"
                                    onClick={() => handleActiveMenuItems('dashboard')}>
                                    {activeMenuItem === "dashboard" ?
                                        <img className="icon" src={require("../../../assets/icons/active_dashboard.png")}
                                            alt="dashboard_icon" /> :
                                        isDarkMode ?
                                            <DashboardOutlinedIcon className="" sx={{ color: "#7d8baa" }} fontSize="small" />
                                            : <img className="icon" src={require("../../../assets/icons/dashboard_light.png")} alt="dashboard_icon" />
                                    }
                                    <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "dashboard" ? "active" : ""}` :
                                        `menuitems_light ${activeMenuItem === "dashboard" ? "active" : ""}`}>
                                        Dashboard
                                    </Typography>
                                    {/* {activeMenuItem === "dashboard" ?
                                    <ChevronRightOutlinedIcon sx={{ color: "white" }} fontSize="small" />
                                    : <></>
                                } */}
                            {/* </MenuItem> : <></>}
                            {organisationMenu ? <MenuItem
                                className={isDarkMode ? `menu ${activeMenuItem === "organisation" ? "active" : ""}` : `menu_light ${activeMenuItem === "organisation" ? "active" : ""}`}
                                component={Link}
                                to="/dashboard"
                                onClick={() => handleActiveMenuItems('organisation')} >
                                {activeMenuItem === "organisation" ? <img className="icon" src={require("../../../assets/icons/active_organisation.png")} alt="organisations_icon" />
                                    : <img className="icon" src={isDarkMode ? require("../../../assets/icons/organisation_dark.png") : require("../../../assets/icons/organisation_light.png")} alt="organisations_icon" />
                                }
                                <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "organisation" ? "active" : ""}` :
                                    `menuitems_light ${activeMenuItem === "organisation" ? "active" : ""}`}> Organisations</Typography>
                                {/* {activeMenuItem === "organisation" ?
                                    <ChevronRightOutlinedIcon className="arrow" sx={{ color: "white" }} fontSize="small" />
                                    : <></>
                                } */}
                            {/* </MenuItem> : <></>}
                            {roleManagementMenu ? <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "rolemanagement" ? "active" : ""}`
                                : `menu_light ${activeMenuItem === "rolemanagement" ? "active" : ""}`}
                                component={Link}
                                to="/dashboard"
                                onClick={() => handleActiveMenuItems('rolemanagement')} >
                                {activeMenuItem === "rolemanagement" ?
                                    <img className="icon" src={require("../../../assets/icons/role_light.png")}
                                        alt="rolemanagement_icon" />
                                    : <img className="icon" src={isDarkMode ?
                                        require("../../../assets/icons/role_dark.png") :
                                        require("../../../assets/icons/role_light.png")} alt="rolemanagement_icon" />
                                }
                                <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "rolemanagement" ? "active" : ""}` :
                                    `menuitems_light ${activeMenuItem === "rolemanagement" ? "active" : ""}`}> Role Management</Typography>
                                {/* {activeMenuItem === "rolemanagement" ?
                                    <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                    : <></>
                                } */}
                            {/* </MenuItem> : <></>}
                            {userManagementMenu ? <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "usermanagement" ? "active" : ""}`
                                : `menu_light ${activeMenuItem === "usermanagement" ? "active" : ""}`}
                                component={Link}
                                to="/dashboard"
                                onClick={() => handleActiveMenuItems('usermanagement')} >
                                {activeMenuItem === "usermanagement" ?
                                    <img className="icon" src={require("../../../assets/icons/active_usermanagement.png")}
                                        alt="usermanagement_icon" />
                                    : <img className="icon" src={isDarkMode ?
                                        require("../../../assets/icons/usermanagement_dark.png") :
                                        require("../../../assets/icons/usermanagement_light.png")} alt="usermanagement_icon" />
                                }
                                <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "usermanagement" ? "active" : ""}` :
                                    `menuitems_light ${activeMenuItem === "usermanagement" ? "active" : ""}`}> User Management</Typography>
                                {/* {activeMenuItem === "usermanagement" ?
                                    <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                    : <></>
                                } */}
                            {/* </MenuItem> : <></>}
                            {trackingMenu ? <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "tracking" ? "active" : ""}`
                                : `menu_light ${activeMenuItem === "tracking" ? "active" : ""}`}
                                component={Link}
                                to="/dashboard"
                                onClick={() => handleActiveMenuItems('tracking')} >
                                {activeMenuItem === "tracking" ?
                                    <img className="icon" src={require("../../../assets/icons/active_tracking.png")}
                                        alt="tracking_icon" />
                                    : <img className="icon" src={isDarkMode ?
                                        require("../../../assets/icons/tracking_dark.png") :
                                        require("../../../assets/icons/tracking_light.png")} alt="tracking_icon" />
                                }
                                <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "tracking" ? "active" : ""}` :
                                    `menuitems_light ${activeMenuItem === "tracking" ? "active" : ""}`}> Tracking</Typography>
                                {/* {activeMenuItem === "tracking" ?
                                    <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                    : <></>
                                } */}
                            {/* </MenuItem> : <></>}
                            {reportMenu ? <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "report" ? "active" : ""}`
                                : `menu_light ${activeMenuItem === "report" ? "active" : ""}`}
                                component={Link}
                                to="/dashboard"
                                onClick={() => handleActiveMenuItems('report')} >
                                {activeMenuItem === "report" ?
                                    <img className="icon" src={require("../../../assets/icons/active_report.png")}
                                        alt="report_icon" />
                                    : <img className="icon" src={isDarkMode ?
                                        require("../../../assets/icons/report_dark.png") :
                                        require("../../../assets/icons/report_light.png")} alt="report_icon" />
                                }
                                <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "report" ? "active" : ""}` :
                                    `menuitems_light ${activeMenuItem === "report" ? "active" : ""}`}> Reports</Typography>
                                {/* {activeMenuItem === "report" ?
                                        <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
                            {/* </MenuItem> : <></>}
                            {videoSurvillanceMenu ? <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "videosurveillance" ? "active" : ""}`
                                : `menu_light ${activeMenuItem === "videosurveillance" ? "active" : ""}`}
                                component={Link}
                                to="/dashboard"
                                onClick={() => handleActiveMenuItems('videosurveillance')} >
                                {activeMenuItem === "videosurveillance" ?
                                    <img className="icon" src={require("../../../assets/icons/active_video.png")}
                                        alt="videosurveillance_icon" />
                                    : <img className="icon" src={isDarkMode ?
                                        require("../../../assets/icons/video_dark.png") :
                                        require("../../../assets/icons/video_light.png")} alt="videosurveillance_icon" />
                                }
                                <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "videosurveillance" ? "active" : ""}` :
                                    `menuitems_light ${activeMenuItem === "videosurveillance" ? "active" : ""}`}> Videos & Surveillance</Typography>
                                {/* {activeMenuItem === "videosurveillance" ?
                                        <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
                            {/* </MenuItem> : <></>}
                            {supportMenu ? <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "support" ? "active" : ""}`
                                : `menu_light ${activeMenuItem === "support" ? "active" : ""}`}
                                component={Link}
                                to="/dashboard"
                                onClick={() => handleActiveMenuItems('support')}>
                                {activeMenuItem === "support" ?
                                    <img className="icon" src={require("../../../assets/icons/active_support.png")}
                                        alt="support_icon" />
                                    : <img className="icon" src={isDarkMode ?
                                        require("../../../assets/icons/support_dark.png") :
                                        require("../../../assets/icons/support_light.png")}
                                        alt="support_icon" />
                                }
                                <Typography
                                    className={isDarkMode ? `menuitems ${activeMenuItem === "support" ? "active" : ""}` :
                                        `menuitems_light ${activeMenuItem === "support" ? "active" : ""}`} > Support</Typography>
                                {/* {activeMenuItem === "support" ?
                                    <ChevronRightOutlinedIcon className="arrow" sx={{ color: "white" }} fontSize="small" />
                                    : <></>
                                } */}
                            {/* </MenuItem> : <></>} */}
                            <SidebarMenu
                                isDarkMode={isDarkMode}
                                activeMenuItem={activeMenuItem}
                                handleActiveMenuItems={handleActiveMenuItems}
                                organisationMenu={organisationMenu}
                                roleManagementMenu={roleManagementMenu}
                                userManagementMenu={userManagementMenu}
                                trackingMenu={trackingMenu}
                                reportMenu={reportMenu}
                                alertMenu={alertMenu}
                                openLogoutodal={openLogoutodal}

                                videoSurvillanceMenu={videoSurvillanceMenu}
                                supportMenu={supportMenu}
                                auditTrailMenu={auditTrailMenu}

                                dashboardMenu={dashboardMenu}
                            />
                        </List>
                        <Box sx={{ textAlign: 'left', bottom: 0, left: 0, right: 0 }}>

                            {orgUrl && orgUrl !== "" ?
                                <img
                                    className="logo"
                                    src={require("../../../assets/img/logo.png")}
                                    alt="logo"
                                />
                                : <></>
                            }

                        </Box>
                    </Drawer>
                    {/* </Hidden> */}
                </nav>
            </Box>
        </div>

    )
}
Sidebar.propTypes = {
    isDarkMode: PropTypes.bool.isRequired,
    openLogoutodal: PropTypes.bool.isRequired,

    handleActiveMenuItems: PropTypes.func.isRequired,
    activeMenuItem: PropTypes.string.isRequired,
    roleData: PropTypes.string.isRequired,

};