import React, { useState, useEffect, useRef } from "react";
import { MapContainer, Marker, TileLayer, Popup, ImageOverlay } from 'react-leaflet'
import { useSelector } from 'react-redux';
import L from "leaflet";
import { Grid, Select, MenuItem, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import { MenuProps, RefreshSwitch } from '../Styles';
import { getOrgLevelList, getOrgMapList, getOrgTagLivePosition } from "../../services/OrganisationServices";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './Map.css';
import IconButton from '@mui/material/IconButton';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { YellowBorderButton } from "../styles/ButtonStyles";
import SyncIcon from '@mui/icons-material/Sync';
import { useDispatch } from "react-redux";
import { fetchSites } from "../../redux/actions/UserRoleActions";
import PropTypes from 'prop-types';
export const MapView = ({  isDarkMode, isWidget, removeWidget, id,columnSize }) => {
    const mapRef = useRef(null)
    const [sites, setSites] = useState([]);
    const [mapList, setMapList] = useState([]);
    const [mapDetails, setMap] = useState(null);
    const [mapBounds, setMapBounds] = useState(null);
    const [mapUrl, setMapUrl] = useState();
    const siteData = useSelector(state => state.userRole.siteData);
    const [selectedSites, setSelectedSites] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('');
    const [levels, setLevels] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [autoRefresh, setAutoRefresh] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const dispatch = useDispatch();

    // const [previewUrl, setPreviewUrl] = useState(null);

    const [is_wisilica, setIs_wisilica] = useState(false);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const intervalIdRef = useRef(null);
    useEffect(() => {
        dispatch(fetchSites());

    }, [])
    useEffect(() => {
        intervalIdRef.current = intervalId;

        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const sites = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(sites)
            if (sites.length > 0) {
                setSelectedSites(sites[0].organizationId)
                getLevelList({ site_id: sites[0].organizationId });
            }
        }

        if (autoRefresh) {

            const id = setInterval(callGetRefreshed, 60000);
            setIntervalId(id);
        } else {
            clearInterval(intervalIdRef.current);

            setIntervalId(null);
        }

        return () => {
            clearInterval(intervalIdRef.current);

        };
    }, [siteData, autoRefresh]);


    const getMapList = async (filter = {}) => {
        let maps = mapList;
        if (maps.length === 0) {
            const res = await getOrgMapList(filter);
            maps = res.data

            const map = maps.find((e) => { return filter.orgId === e.site_id && filter.level_id === e.layerId });
            if (map.is_wisilica == false) {
                setMapList(res.data)
                setIs_wisilica(false);

                const levelMap = map.filePath;
                if (levelMap !== null) {
                    const tempUrl =
                        process.env.REACT_APP_API_BASE_URL + "/media" + levelMap;
                    setMapUrl(tempUrl);
                    // setPreviewUrl(levelMap);
                }
            }
            else {
                setIs_wisilica(true);
                setMapList(res.data)
            }
        }
        if (maps) {
            setMap(null);
            const map = maps.find((e) => { return filter.orgId === e.site_id && filter.level_id === e.layerId });
            if (map && map.is_wisilica == true) {
                const array0 = map.mapBoundaries[0].replace(/\(|\)/g, '').split(','); // splitting the map boundary coordinates
                const array2 = map.mapBoundaries[2].replace(/\(|\)/g, '').split(','); // splitting the map boundary coordinates
                setMapUrl(`${decodeURIComponent(map.filePath).replace('map.php', 'img.php')}&zxy=/{z}/{x}/{y}.png`);
                const mapBounds = L.latLngBounds(L.latLng(Number(array0[1]), Number(array0[0])), L.latLng(Number(array2[1]), Number(array2[0])));
                setMapBounds(mapBounds);
                const tagsList = await getOrgTagLivePosition({ site_id: filter.orgId, level_id: filter.level_id });
                let markerList = [];
                for (let index = 0; index < tagsList.data.length; index++) {
                    const tag = tagsList.data[index];
                    const markerIndex = markerList.findIndex((e) => { return e.listenerId === tag.tag?.listenerId });
                    const tagInfo = {
                        contractorname: tag?.contractorname,
                        checkpoint: tag.tag?.listenerName,

                    }
                    if (markerIndex === -1) {
                        markerList.push({
                            listenerId: tag.tag?.listenerId,
                            listenerName: tag.tag?.listenerName,
                            tags: [tagInfo],
                            latitude: tag.tag?.latitude,
                            longitude: tag.tag?.longitude,
                            checkpoint: tag.tag?.listenerName,
                            contractorname: tag?.contractorname
                        })
                    } else {
                        markerList[markerIndex]['tags'].push(tagInfo)
                    }
                }
                setMarkers(markerList);
                setMap(map);
            }
        }
        else {
            setMap(null);
        }
    }
    const getRefreshed = async (filter = {}) => {
        if (mapList) {
            setMap(null);
            const map = mapList.find((e) => { return filter.orgId === e.site_id && filter.level_id === e.layerId });
            if (map && is_wisilica == true) {
                const array0 = map.mapBoundaries[0].replace(/\(|\)/g, '').split(','); // splitting the map boundary coordinates
                const array2 = map.mapBoundaries[2].replace(/\(|\)/g, '').split(','); // splitting the map boundary coordinates
                setMapUrl(`${decodeURIComponent(map.filePath).replace('map.php', 'img.php')}&zxy=/{z}/{x}/{y}.png`);
                const mapBounds = L.latLngBounds(L.latLng(Number(array0[1]), Number(array0[0])), L.latLng(Number(array2[1]), Number(array2[0])));
                setMapBounds(mapBounds);
                const tagsList = await getOrgTagLivePosition({ site_id: filter.orgId, level_id: filter.level_id });
                let markerList = [];
                for (let index = 0; index < tagsList.data.length; index++) {
                    const tag = tagsList.data[index];
                    const markerIndex = markerList.findIndex((e) => { return e.listenerId === tag.tag?.listenerId });
                    const tagInfo = {
                        // fname: tag.tag.fname,
                        // lname: tag.tag.lname,
                        // infantId: tag.tag.infantId,
                        // tagId: tag.tag.tagId,
                        contractorname: tag?.contractorname,
                        checkpoint: tag.tag?.listenerName,

                    }
                    if (markerIndex === -1) {
                        markerList.push({
                            listenerId: tag.tag?.listenerId,
                            listenerName: tag.tag?.listenerName,
                            tags: [tagInfo],
                            latitude: tag.tag?.latitude,
                            longitude: tag.tag?.longitude,
                            checkpoint: tag.tag?.listenerName,
                            contractorname: tag?.contractorname
                        })
                    } else {
                        markerList[markerIndex]['tags'].push(tagInfo)
                    }
                }
                setMarkers(markerList);
                setMap(map);
            }
        }
        else {
            setMap(null);
        }
    }
    const getLevelList = async (filter = {}) => {
        const levels = await getOrgLevelList(filter);
        setLevels(levels.data.LevelList)
        if (levels.data.LevelList.length > 0) {
            setSelectedLevel(levels.data.LevelList[0].layerId);
            getMapList({ orgId: filter.site_id, level_id: levels.data.LevelList[0].layerId });
        }

    }
    const onMapReady = (map) => {
        map.target.setMaxBounds(map.target.getBounds())
        const dimesion = { 'height': mapDetails?.imgHeightMeters, 'width': mapDetails?.imgWidthMeters, 'mapName': mapDetails?.mapName };
        map.target.addControl(new customLegends(dimesion, { position: 'topright' }));
        // map.target.addControl(new customScalingFactorControl(Number(mapDetails?.scalingFactor), { position: 'bottomleft' }))
    }

    const customLegends = L.Control.extend({
        container: null,
        maxZoom: undefined,
        maxScalingFactor: undefined,
        initialize: function (dimesion, options) {
            L.Util.setOptions(this, options);
            this.height = Math.round(dimesion.height);
            this.width = Math.round(dimesion.width);
            this.mapName = dimesion.mapName;
        },
        onAdd: function () {
            const div = L.DomUtil.create('div', 'leaflet-trak-custom-legend leaflet-bar');
            div.innerHTML = `<div></div><p>Map Name: ${this.mapName} </p><p style='float:left;'> Height: ${this.height} meters</p><br><p style='float:left;'>Width: ${this.width} meters</p></div>`;
            return div;
        }
    })

    // const customScalingFactorControl = L.Control.extend({
    //     container: null,
    //     maxScalingFactor: undefined,
    //     initialize: function (scalingFactor, options) {
    //         L.Util.setOptions(this, options);
    //         this.maxScalingFactor = scalingFactor;
    //     },
    //     onAdd: function (map) {
    //         const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control-custom-scaling');
    //         L.DomEvent.addListener(container, 'dblclick', function (e) {
    //             L.DomEvent.stopPropagation(e);
    //         });
    //         map.on('zoomend', () => {
    //             this.changeScale(map.getZoom(), map.getMaxZoom());
    //         });
    //         this.container = container
    //         this.changeScale(map.getZoom(), map.getMaxZoom());
    //         return container;
    //     },
    //     onRemove: function () {
    //         L.DomEvent.removeListener(this.container, 'dblclick');
    //     },
    //     changeScale: function (zoom, maxZoom) {
    //         const scale = this.maxScalingFactor * (Math.pow(2, maxZoom - zoom));
    //         this.container.innerHTML = `<div style='padding: 3px 0px 3px 0px; border-left: 1px solid black; border-right: 1px solid black; display: inline-block;'><hr style='width: 30px; border:1px solid black; margin: 0;'></div><p style='margin: 0; '> ${(scale * 30).toFixed(2)}  meters </p></div>`;
    //     }
    // })

    const handleSelect = (value, type) => {
        if (type === 'site') {
            setSelectedSites(value);
            getLevelList({
                site_id: value
            })
        } else if (type === 'level') {
            setSelectedLevel(value)
            getMapList({
                orgId: selectedSites,
                level_id: value
            })
        }
    }

    const getDefaultMarker = () => {
        const defaultMarker = new L.icon({
            iconUrl: require('../../assets/icons/tagicon1.png'),
            iconSize: [35, 35],
            iconAnchor: [13, 0]
        });
        return defaultMarker;
    }
    const handleRefresh = () => {
        getRefreshed({
            orgId: selectedSites,
            level_id: selectedLevel
        });
    }
    const callGetRefreshed = () => {
        if (autoRefresh) {
            getRefreshed({
                orgId: selectedSites,
                level_id: selectedLevel
            });
        }
    };

    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>

            {!isWidget && <>
                <Grid item lg={2.9} md={2.9} xs={12} sm={12}>
                    <Grid container className="mt2">
                        <Grid item sx={{ mt: 2 }}>
                            <Typography sx={{ textAlign: 'center' }}
                                className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                Sites :
                            </Typography>

                        </Grid>
                        <Grid item lg={2.5} sm={4} xs={4} md={2.5}>
                            <Select
                                sx={{
                                    color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 180,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                        border: '',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: isDarkMode ? "white !important" : "#161616",
                                    }
                                }}

                                displayEmpty
                                MenuProps={MenuProps}
                                className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                value={selectedSites}
                                onChange={(e) => { handleSelect(e.target.value, 'site') }}
                            >
                                {sites.map((site, index) => (
                                    <MenuItem
                                        key={index}
                                        value={site.organizationId}>
                                        {site.organizationName}
                                    </MenuItem>
                                ))}


                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={2.9} md={2.9} xs={12} sm={12}>
                    <Grid container className="mt2">
                        <Grid item sx={{ mt: 2, ml: 2, '@media (max-width: 600px)': { ml: '0% !important' } }}>
                            <Typography sx={{ textAlign: 'center' }}
                                className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                Level :
                            </Typography>

                        </Grid>
                        <Grid item lg={2.5} sm={4} xs={4} md={2.5}>
                            <Select
                                sx={{
                                    color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 180,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                        border: '',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: isDarkMode ? "white !important" : "#161616",
                                    }
                                }}

                                displayEmpty
                                MenuProps={MenuProps}
                                className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                value={selectedLevel}
                                onChange={(e) => { handleSelect(e.target.value, 'level') }}
                            >
                                {levels.map((level, index) => (
                                    <MenuItem
                                        key={index}
                                        value={level.layerId}>
                                        {level.layerName}
                                    </MenuItem>
                                ))}


                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={6} md={6} xs={12} sm={12}>
                    <Grid container className="mt2">
                        <Grid item sx={{ mt: 2, ml: 2, '@media (max-width: 600px)': { ml: '0% !important' } }}
                            lg={7} sm={7} xs={7} md={7} style={{ display: 'flex' }}>
                            <Typography sx={{ textAlign: 'center', mr: 2 }}
                                className={"headersubhead light_text"}>
                                Auto Refresh
                            </Typography>

                            <RefreshSwitch
                                sx={{ mb: '1% !important' }}
                                checked={autoRefresh}
                                onChange={() => setAutoRefresh(!autoRefresh)}
                            />
                            {!autoRefresh &&
                                <Grid item sx={{ ml: 3, mb: 4, mt: "-2% !important" }}>
                                    <YellowBorderButton
                                        startIcon={<SyncIcon style={{ color: 'black' }} />}
                                        sx={{ pr: '0px !important' }}
                                        onClick={handleRefresh}
                                    >

                                    </YellowBorderButton>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>}
            {isWidget &&
                < Grid container >
                    <Grid item lg={6} sm={8} xs={8} md={6} 
                    className="mt2" sx={{ ml:columnSize == 12 ? 2 :  3, mb: 2 }}>
                        <Typography className='headersubhead light_text htl'>Tracking Map </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} lg={5} md={5} sx={{ mt: '0% !important' }}
                        style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
                            <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={columnSize == 12 ? 4 :12} sm={12} xs={12} md={columnSize == 12 ? 4 :12}
                        style={{ display: 'flex'}}
                        sx={{ ml: columnSize == 12 ? 0 : 3 }}>
                        <Grid item lg={12} sm={12} xs={12} md={12} className="mt2"
                                                sx={{ ml: columnSize == 12 ? 2 : 0 }}

                            style={{ display: 'flex'}}>
                            <Grid item sx={{ mt: 2, }}>
                                <Typography sx={{ textAlign: 'center' }}
                                    className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                    Sites :
                                </Typography>

                            </Grid>
                            <Grid item lg={8} sm={8} xs={8} md={8}>
                                <Select
                                    sx={{
                                        color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                        textAlign: 'left',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: isDarkMode ? "white !important" : "#161616",
                                        }
                                    }}

                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                    value={selectedSites}
                                    onChange={(e) => { handleSelect(e.target.value, 'site') }}
                                >
                                    {sites.map((site, index) => (
                                        <MenuItem
                                            key={index}
                                            value={site.organizationId}>
                                            {site.organizationName}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item lg={columnSize == 12 ? 4 :12} sm={12} xs={12} md={columnSize == 12 ? 4 :12}
                        style={{ display: 'flex'}}
                        sx={{ ml: columnSize == 12 ? 0 : 3 }}
>
                        <Grid item lg={12} sm={12} xs={12} md={12} className="mt2"
                            style={{ display: 'flex' }}>
                            <Grid item sx={{ mt: 2 }}>
                                <Typography sx={{ textAlign: 'center' }}
                                    className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                    Level :
                                </Typography>

                            </Grid>
                            <Grid item lg={8} sm={8} xs={8} md={8}>
                                <Select
                                    sx={{
                                        color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                        textAlign: 'left',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: isDarkMode ? "white !important" : "#161616",
                                        }
                                    }}

                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                    value={selectedLevel}
                                    onChange={(e) => { handleSelect(e.target.value, 'level') }}
                                >
                                    {levels.map((level, index) => (
                                        <MenuItem
                                            key={index}
                                            value={level.layerId}>
                                            {level.layerName}
                                        </MenuItem>
                                    ))}


                                </Select>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item lg={columnSize == 12 ? 4 :12} sm={12} xs={12} md={columnSize == 12 ? 4 :12}
                     style={{ display: 'flex',}}
                     sx={{ ml: columnSize == 12 ? 0 : 0 }}>
                        <Grid container className="">
                            <Grid item sx={{ mt: 0,  '@media (max-width: 600px)': { ml: '0% !important' } }}
                                lg={12} sm={12} xs={12} md={12} 
                                style={{ display: 'flex'}}>
                                <Grid item lg={4} sm={4} xs={4} md={4} sx={{mr:1}}>
                                    <Typography
                                    //  sx={{ textAlign: 'center', mr: 0 }}
                                        className={"headersubhead light_text"}>
                                        Auto Refresh
                                    </Typography>
                                </Grid>

                                <Grid item 
                                // lg={.5} sm={.5} xs={.5} md={.5}
                                >
                                    <RefreshSwitch
                                        sx={{ mb: '1% !important' }}
                                        checked={autoRefresh}
                                        onChange={() => setAutoRefresh(!autoRefresh)}
                                    />
                                </Grid>


                                {!autoRefresh &&
                                    <Grid item sx={{ ml: 0, }}>
                                        <YellowBorderButton
                                            startIcon={<SyncIcon style={{ color: 'black' }} />}
                                            sx={{ pr: '0px !important',pt:'6px !important',pb:'6px !important'}}
                                            onClick={handleRefresh}
                                        >

                                        </YellowBorderButton>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            }

            <Grid item xs={12}>
                <Card>
                    {mapUrl && is_wisilica == false &&
                        <MapContainer maxZoom={21} scrollWheelZoom={false} minZoom={1}
                            dragging={false}
                            bounds={[[-326.5, -35], [90.99, -540.85]]}
                        >
                            <ImageOverlay crossOrigin="anonymous" url={mapUrl} bounds={[[-326.5, -35], [50.99, -640.85]]} />
                        </MapContainer>
                    }
                </Card>
                <Card>
                    {mapDetails && is_wisilica == true &&
                        <MapContainer zoom={19}
                            minZoom={mapDetails.minZoom}
                            maxZoom={mapDetails.maxZoom}
                            scrollWheelZoom={false}
                            bounds={mapBounds}
                            ref={mapRef}
                            whenReady={(map) => {
                                setMap(map)
                                onMapReady(map)
                            }}>
                            <TileLayer
                                tms={true}
                                noWrap={true}
                                url={mapUrl}
                                zoom={19}
                                minZoom={mapDetails.minZoom}
                                maxZoom={mapDetails.maxZoom}
                            />
                            {markers.map((item, i) => {
                                return <div key={i}>
                                    <Marker position={[item.latitude, item.longitude]} icon={getDefaultMarker()} eventHandlers={
                                        {
                                            click: () => {

                                            },
                                        }
                                    }>
                                        <Popup minWidth={400}>
                                            <Grid container spacing={1}>
                                                {item.tags.map((item, i) => {
                                                    return <Grid item xs={4} key={i}>

                                                        <Item className="item">
                                                            <img alt=""
                                                                className="map_user"
                                                                src={require('../../assets/icons/personnel_active.png')} />
                                                            <Typography className="map_popup_label">
                                                                {/* {item.fname + ' ' + item.lname} */}
                                                                {item.contractorname}
                                                            </Typography>
                                                            <Typography className="map_popup_label" sx={{ mt: "-19% !important" }}>
                                                                {item.checkpoint}
                                                            </Typography>
                                                        </Item>


                                                    </Grid>
                                                })
                                                }
                                            </Grid>
                                        </Popup>
                                    </Marker>
                                </div>
                            })}
                        </MapContainer>
                    }
                </Card>
            </Grid>
        </Grid>

    );
}
MapView.propTypes = {
    isDarkMode: PropTypes.bool.isRequired,
    isWidget: PropTypes.bool.isRequired,

    removeWidget: PropTypes.func.isRequired,
    columnSize: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,

};