import { listnerActionType } from "../actions/ListenerActions"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    listenerList:dataTemplate,
    assignedListener:dataTemplate,
    assign:dataTemplate,
    unAssign:dataTemplate
  
}

const ListenerReducer = (state = initialData, action) => {
    switch (action.type) {
         //fetch listener list
         case listnerActionType.FETCH_LISTENER:
            return {
                ...state,
                listenerList: {
                    ...state.listenerList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case listnerActionType.FETCH_LISTENER_SUCCESS:
            return {

                ...state,
                listenerList: {
                    ...state.listenerList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case listnerActionType.FETCH_LISTENER_ERROR:
            return {
                ...state,
                listenerList: {
                    ...state.listenerList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case listnerActionType.FETCH_ASSINED_LISTENER:
                return {
                    ...state,
                    assignedListener: {
                        ...state.assignedListener,
                        isFetching: true,
                        payload: [],
                        error: false
                    }
                }
            case listnerActionType.FETCH_ASSINED_LISTENER_SUCCESS:
                return {
    
                    ...state,
                    assignedListener: {
                        ...state.assignedListener,
                        isFetching: false,
                        error: false,
                        payload: action.payload
                    }
                }
            case listnerActionType.FETCH_ASSINED_LISTENER_ERROR:
                return {
                    ...state,
                    assignedListener: {
                        ...state.assignedListener,
                        isFetching: false,
                        payload: [],
                        error: true,
                        errorMessage: action.error
                    }
                }
                case listnerActionType.ASSIGN_LISTENER:
                    return {
                        ...state,
                        assign: {
                            ...state.assign,
                            isFetching: true,
                            payload: [],
                            error: false
                        }
                    }
                case listnerActionType.ASSIGN_LISTENER_SUCCESS:
                    return {
        
                        ...state,
                        assign: {
                            ...state.assign,
                            isFetching: false,
                            error: false,
                            payload: action.payload
                        }
                    }
                case listnerActionType.ASSIGN_LISTENER_ERROR:
                    return {
                        ...state,
                        assign: {
                            ...state.assign,
                            isFetching: false,
                            payload: [],
                            error: true,
                            errorMessage: action.error
                        }
                    }
                    case listnerActionType.UNASSIGN_LISTENER:
                        return {
                            ...state,
                            unAssign: {
                                ...state.unAssign,
                                isFetching: true,
                                payload: [],
                                error: false
                            }
                        }
                    case listnerActionType.UNASSIGN_LISTENER_SUCCESS:
                        return {
            
                            ...state,
                            unAssign: {
                                ...state.unAssign,
                                isFetching: false,
                                error: false,
                                payload: action.payload
                            }
                        }
                    case listnerActionType.UNASSIGN_LISTENER_ERROR:
                        return {
                            ...state,
                            unAssign: {
                                ...state.unAssign,
                                isFetching: false,
                                payload: [],
                                error: true,
                                errorMessage: action.error
                            }
                        }
                   
                 
        default:
            return state
    }

}
export default ListenerReducer;