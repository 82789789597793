export const cardHeaderText={
title: {
    fontFamily: 'Nunito , sans-serif !important',
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
    maxWidth: '235px',
    marginBottom:1

  },
  subHeader:{
    fontFamily: 'Nunito , sans-serif !important',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'left',
    color:'#161616',
    maxWidth: '235px',
    marginBottom:1

  }

}
