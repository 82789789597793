import { all, put, takeLatest } from "redux-saga/effects";
import {
    addPersonnelError, addPersonnelSuccess, assignPersonnelError, assignPersonnelSuccess,
    deletePersonnelError, deletePersonnelSuccess, deletebulkPersonnelError, deletebulkPersonnelSuccess, 
    fetchPersonnelListError, fetchPersonnelListSuccess,
    fetchPersonnelTagError,
    fetchPersonnelTagSuccess,
    importPersonnelsError,
    importPersonnelsSuccess,
    personnelActionType, unassignPersonnelError, unassignPersonnelSuccess, updatePersonnelError,
     updatePersonnelSuccess, viewPersonnelError,
    viewPersonnelSuccess
} from "../actions/PersonnelAction";
import {
    addNewPersonnelDetails, assignPersonnelDetails, deleteBulkPersonnelDetails, deletePersonnelDetails, editPersonnelDetails,
    fetchPersonnelListData, getPerssonnelTag, importPersonnelsDetails, unassignPersonnelDetails, viewPersonnelDetails
} from "../../services/PersonnelService";
//assign personnel 
function* _unassignSinglePersonnel(action) {

    try {
        const personelInfo = yield unassignPersonnelDetails(action.data);
        yield put(unassignPersonnelSuccess(personelInfo));
    } catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(unassignPersonnelError( error.response.data));
        }else{
            yield put(unassignPersonnelError( error));
        }
       // yield put(assignPersonnelError("error"));
    }
}
function* unassignSinglePersonnel() {
    yield takeLatest(personnelActionType.UNASSIGN_PERSONEL, _unassignSinglePersonnel)
}
// personnel  import
function* _importPersonnelList(action) {

    try {
        const personelInfo = yield importPersonnelsDetails(action.data);

        yield put(importPersonnelsSuccess(personelInfo));
    }
    catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(importPersonnelsError( error.response.data));
        }else{
            yield put(importPersonnelsError( error));
        }
       // yield put(assignPersonnelError("error"));
    }
}
function* importPersonnelList() {
    yield takeLatest(personnelActionType.IMPORT_PERSONEL,_importPersonnelList)
}
//fetch personnel  tag 
function* _viewPersonnelTag(action) {

    try {
        const personelInfo = yield getPerssonnelTag(action.id);
        yield put(fetchPersonnelTagSuccess(personelInfo));
    } catch (e) {
        yield put(fetchPersonnelTagError("error"));
    }
}
function* viewPersonnelTag() {
    yield takeLatest(personnelActionType.FETCH_PERSONEL_TAG, _viewPersonnelTag)
}
//assign personnel 
function* _assignSinglePersonnel(action) {

    try {
        const personelInfo = yield assignPersonnelDetails(action.data);
        yield put(assignPersonnelSuccess(personelInfo));
    } catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(assignPersonnelError( error.response.data));
        }else{
            yield put(assignPersonnelError( error));
        }
       // yield put(assignPersonnelError("error"));
    }
}
function* assignSinglePersonnel() {
    yield takeLatest(personnelActionType.ASSIGN_PERSONEL, _assignSinglePersonnel)
}
//update personnel 
function* _editPersonnel(action) {

    try {
        const personelInfo = yield editPersonnelDetails(action.id, action.data);
        yield put(updatePersonnelSuccess(personelInfo));
    } 
    catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(updatePersonnelError( error.response.data));
        }else{
            yield put(updatePersonnelError( error));
        }
    }
}
function* editPersonnel() {
    yield takeLatest(personnelActionType.UPDATE_PERSONEL, _editPersonnel)
}
//view personnel 
function* _getSinglePersonnel(action) {

    try {
        const personelInfo = yield viewPersonnelDetails(action.id);
        yield put(viewPersonnelSuccess(personelInfo));
    } catch (e) {
        yield put(viewPersonnelError("error"));
    }
}
function* getSinglePersonnel() {
    yield takeLatest(personnelActionType.VIEW_PERSONEL, _getSinglePersonnel)
}
//delete bulk personnel 
function* _deleteBulkPersonnel(action) {

    try {
        const personelInfo = yield deleteBulkPersonnelDetails(action.data);
        yield put(deletebulkPersonnelSuccess(personelInfo));
    } catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(deletebulkPersonnelError( error.response.data));
        }else{
            yield put(deletebulkPersonnelError( error));
        }
}
}
function* deleteBulkPersonnel() {
    yield takeLatest(personnelActionType.DELETE_BULK_PERSONEL, _deleteBulkPersonnel)
}
//delete personnel 
function* _deletePersonnel(action) {

    try {
        const personelInfo = yield deletePersonnelDetails(action.data);
        yield put(deletePersonnelSuccess(personelInfo));
    } catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(deletePersonnelError( error.response.data));
        }else{
            yield put(deletePersonnelError( error));
        }
    }
}
function* deletePersonnel() {
    yield takeLatest(personnelActionType.DELETE_PERSONEL, _deletePersonnel)
}
//add personnel 
function* _addNewPersonnel(action) {

    try {
        const personelInfo = yield addNewPersonnelDetails(action.data);
        yield put(addPersonnelSuccess(personelInfo));
    } catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(addPersonnelError( error.response.data));
        }else{
            yield put(addPersonnelError( error));
        }
    }
}
function* addNewPersonnel() {
    yield takeLatest(personnelActionType.ADD_PERSONNEL, _addNewPersonnel)
}
//fetch personnel list
function* _getPersonnelList(action) {

    try {
        const routeListInfo = yield fetchPersonnelListData(action.data);
        yield put(fetchPersonnelListSuccess(routeListInfo));
    } catch (e) {
        yield put(fetchPersonnelListError("error"));
    }
}
function* getPersonnelList() {
    yield takeLatest(personnelActionType.FETCH_PERSONNEL_LIST, _getPersonnelList)
}
export function* PersonnelSaga() {
    yield all([getPersonnelList(), addNewPersonnel(), deletePersonnel(), getSinglePersonnel(), editPersonnel(),
    assignSinglePersonnel(), viewPersonnelTag(),importPersonnelList(),deleteBulkPersonnel(),unassignSinglePersonnel()])
}