import AlertReducer from "./AlertReducer";
import AuthenticationReducer from "./AuthenticationReducer";
import CheckPointListReducer from "./CheckPointReducer";
import DeviceReducer from "./DeviceReducer";
import ListenerReducer from "./ListenerReducer";
import OrganisationReducer from "./OrganisationReducer";
import PersonnelReducer from "./PersonnelReducer";
import { RoleFeatureReducer } from "./RoleFeatureReducer";
import RouteReducer from "./RouteReducer";
import SharableReducer from "./SharableReducer";
import SurveillanceReducer from "./SurveillanceReducer";
import UserReducer from "./UserReducer";
import { UserRoleReducer } from "./UserRoleReducer";

const rootReducer = {
    login: AuthenticationReducer,
    user: UserReducer,
    userRole:UserRoleReducer,
    feature:RoleFeatureReducer,
    organisation:OrganisationReducer,
    checkpoints:CheckPointListReducer,
    sharables:SharableReducer,
    listeners:ListenerReducer,
    routes:RouteReducer,
    device:DeviceReducer,
    personnel:PersonnelReducer,
    alert:AlertReducer,
    surveillance:SurveillanceReducer
}
export default rootReducer;