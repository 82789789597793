import { TableCell, TableRow, styled, tableCellClasses } from "@mui/material";

export const CustomizedTableCell =styled(TableCell)(({ theme, isDarkMode }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: isDarkMode ? "transparent !important" : "#ffffff",
      color: isDarkMode ? "#ffffff !important" : "#161616 !important",
     // borderColor: isDarkMode ? '#161616' : "#E9E9E9",
      fontFamily: 'Poppins , sans-serif !important',
      border: 'none !important',
      borderBottom:'0px !important',
  fontSize:"15px !important",
      fontWeight: 500, // Add the fontWeight here
      '&:not($head)': {
        fontWeight: 'inherit !important', // Reset fontWeight for body cells
      },
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize:"14px !important",
        fontWeight: 500,
        borderBottom:'0px !important',
        borderRadius:'5px !important',

      //backgroundColor: isDarkMode ? "transparent !important" : "#ffffff",
      color: isDarkMode ? "#ffffff !important" : "#666666 !important",
    //  borderColor: isDarkMode ? '#161616' : "#E9E9E9",
      fontFamily: 'Poppins , sans-serif  !important'
    },
  }));
export const CustomizedTableRow = styled(TableRow)(({ theme }) => ({
   
    '&:nth-of-type(odd)': {
        backgroundColor: "#F9FAFB !important",
        borderRadius:'5px !important',
        border: 'none !important',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));