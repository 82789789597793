import { MenuItem, Typography } from '@mui/material';
import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Link } from 'react-router-dom';
export const SidebarMenu = ({ isDarkMode, activeMenuItem, handleActiveMenuItems, dashboardMenu,
    organisationMenu, roleManagementMenu, userManagementMenu, trackingMenu, reportMenu,alertMenu, 
    videoSurvillanceMenu, supportMenu,openLogoutodal,auditTrailMenu
}) => {
    return (
        <>
            {dashboardMenu ?
                <MenuItem
                sx={{pt:2,pb:2}}
                    className={isDarkMode ? `menu ${activeMenuItem === "dashboard" ? "active" : ""}` :
                        `menu_light ${activeMenuItem === "dashboard" ? "active" : ""}`}
                    component={Link}
                    to="/dashboard"
                    onClick={() => handleActiveMenuItems('dashboard')}>
                    {activeMenuItem === "dashboard" ?
                        <img className="icon" src={require("../../../assets/icons/active_dashboard.png")}
                            alt="dashboard_icon" /> :
                        isDarkMode ?
                            <DashboardOutlinedIcon className="" sx={{ color: "#7d8baa" }} fontSize="small" />
                            : <img className="icon" src={require("../../../assets/icons/dashboard_light.png")} alt="dashboard_icon" />
                    }
                    <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "dashboard" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "dashboard" ? "active" : ""}`}>
                        Dashboard
                    </Typography>
                    {/* {activeMenuItem === "dashboard" ?
                                        <ChevronRightOutlinedIcon sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
                </MenuItem> : <></>}
            {organisationMenu ?
                <MenuItem
                    className={isDarkMode ? `menu ${activeMenuItem === "organisation" ? "active" : ""}` : `menu_light ${activeMenuItem === "organisation" ? "active" : ""}`}
                    component={Link}
                    to="/organisation"
                    onClick={() => handleActiveMenuItems('organisation')} >
                    {activeMenuItem === "organisation" ? <img className="icon"
                        src={require("../../../assets/icons/active_organisation.png")} alt="organisations_icon" />
                        : <img className="icon" src={isDarkMode ? require("../../../assets/icons/organisation_dark.png") :
                            require("../../../assets/icons/organisation_light.png")} alt="organisations_icon" />
                    }
                    <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "organisation" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "organisation" ? "active" : ""}`}> Organisations</Typography>
                    {/* {activeMenuItem === "organisation" ?
                                        <ChevronRightOutlinedIcon className="arrow" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
                </MenuItem>
                : <></>}
            {roleManagementMenu ?
                <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "rolemanagement" ? "active" : ""}`
                    : `menu_light ${activeMenuItem === "rolemanagement" ? "active" : ""}`}
                    component={Link}
                    to="/roleManagement"
                    onClick={() => handleActiveMenuItems('rolemanagement')} >
                    {activeMenuItem === "rolemanagement" ?
                        <img className="icon" src={require("../../../assets/icons/active_role.png")}
                            alt="rolemanagement_icon" />
                        : <img className="icon" src={isDarkMode ?
                            require("../../../assets/icons/role_dark.png") :
                            require("../../../assets/icons/role_light.png")} alt="rolemanagement_icon" />
                    }
                    <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "rolemanagement" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "rolemanagement" ? "active" : ""}`}> Roles & Features </Typography>
                    {/* {activeMenuItem === "rolemanagement" ?
                                        <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
                </MenuItem> : <></>}
            {userManagementMenu ?
                <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "usermanagement" ? "active" : ""}`
                    : `menu_light ${activeMenuItem === "usermanagement" ? "active" : ""}`}
                    component={Link}
                    to="/usermanagement"
                    onClick={() => handleActiveMenuItems('usermanagement')} >
                    {activeMenuItem === "usermanagement" ?
                        <img className="icon" src={require("../../../assets/icons/active_usermanagement.png")}
                            alt="usermanagement_icon" />
                        : <img className="icon" src={isDarkMode ?
                            require("../../../assets/icons/usermanagement_dark.png") :
                            require("../../../assets/icons/usermanagement_light.png")} alt="usermanagement_icon" />
                    }
                    <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "usermanagement" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "usermanagement" ? "active" : ""}`}> User Management</Typography>
                   
                </MenuItem> : <></>}
            {trackingMenu ?
                <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "tracking" ? "active" : ""}`
                    : `menu_light ${activeMenuItem === "tracking" ? "active" : ""}`}
                    component={Link}
                    to="/tracking"
                    onClick={() => handleActiveMenuItems('tracking')} >
                    {activeMenuItem === "tracking" ?
                        <img className="icon" src={require("../../../assets/icons/active_tracking.png")}
                            alt="tracking_icon" />
                        : <img className="icon" src={isDarkMode ?
                            require("../../../assets/icons/tracking_dark.png") :
                            require("../../../assets/icons/tracking_light.png")} alt="tracking_icon" />
                    }
                    <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "tracking" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "tracking" ? "active" : ""}`}> Tracking</Typography>
                    {/* {activeMenuItem === "tracking" ?
                                        <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
                </MenuItem> : <></>}
            {reportMenu ?
                <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "report" ? "active" : ""}`
                    : `menu_light ${activeMenuItem === "report" ? "active" : ""}`}
                    component={Link}
                    to="/report"
                    onClick={() => handleActiveMenuItems('report')} >
                    {activeMenuItem === "report" ?
                        <img className="icon" src={require("../../../assets/icons/active_report.png")}
                            alt="report_icon" />
                        : <img className="icon" src={isDarkMode ?
                            require("../../../assets/icons/report_dark.png") :
                            require("../../../assets/icons/report_light.png")} alt="report_icon" />
                    }
                    <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "report" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "report" ? "active" : ""}`}> Report</Typography>
                    {/* {activeMenuItem === "report" ?
                                        <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
                </MenuItem> : <></>}
                {auditTrailMenu ?
                <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "audittrail" ? "active" : ""}`
                : `menu_light ${activeMenuItem === "audittrail" ? "active" : ""}`}
                component={Link}
                to="/audittrail"
                onClick={() => handleActiveMenuItems('audittrail')} >
                {activeMenuItem === "audittrail" ?
                    <img className="icon" src={require("../../../assets/icons/active_audittrail.png")}
                        alt="audittrail_icon" />
                    : <img className="icon" src={isDarkMode ?
                        require("../../../assets/icons/audittrail.png") :
                        require("../../../assets/icons/audittrail.png")} alt="audittrail_icon" />
                }
                <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "audittrail" ? "active" : ""}` :
                    `menuitems_light ${activeMenuItem === "audittrail" ? "active" : ""}`}> Audit Trail</Typography>
                {/* {activeMenuItem === "report" ?
                                    <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                    : <></>
                                } */}
            </MenuItem> : <></>

                }
                {alertMenu ?
                <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "alert" ? "active" : ""}`
                    : `menu_light ${activeMenuItem === "alert" ? "active" : ""}`}
                    component={Link}
                    to="/alert"
                    onClick={() => handleActiveMenuItems('alert')} >
                    {activeMenuItem === "alert" ?
                        <img className="icon" src={require("../../../assets/icons/alert_active_icon.png")}
                            alt="alert_icon" />
                        : <img className="icon" src={isDarkMode ?
                            require("../../../assets/icons/alert_active_icon.png") :
                            require("../../../assets/icons/alert_icon.png")} alt="alert_icon" />
                    }
                    <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "alert" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "alert" ? "active" : ""}`}> Alerts</Typography>
                  
                </MenuItem> : <></>}
            {videoSurvillanceMenu ? <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "videosurveillance" ? "active" : ""}`
                : `menu_light ${activeMenuItem === "videosurveillance" ? "active" : ""}`}
                component={Link}
                to="/surveillance"
                onClick={() => handleActiveMenuItems('videosurveillance')} >
                {activeMenuItem === "videosurveillance" ?
                    <img className="icon" src={require("../../../assets/icons/active_surveillance.png")}
                        alt="videosurveillance_icon" />
                    : <img className="icon" src={isDarkMode ?
                        require("../../../assets/icons/surveillance.png") :
                        require("../../../assets/icons/surveillance.png")} alt="videosurveillance_icon" />
                }
                <Typography className={isDarkMode ? `menuitems ${activeMenuItem === "videosurveillance" ? "active" : ""}` :
                    `menuitems_light ${activeMenuItem === "videosurveillance" ? "active" : ""}`}>Surveillance</Typography>
                {/* {activeMenuItem === "videosurveillance" ?
                                        <ChevronRightOutlinedIcon className="arrowrole" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
            </MenuItem> : <></>}
            {supportMenu ? <MenuItem className={isDarkMode ? `menu ${activeMenuItem === "support" ? "active" : ""}`
                : `menu_light ${activeMenuItem === "support" ? "active" : ""}`}
                component={Link}
                to="/dashboard"
                onClick={() => handleActiveMenuItems('support')}>
                {activeMenuItem === "support" ?
                    <img className="icon" src={require("../../../assets/icons/active_support.png")}
                        alt="support_icon" />
                    : <img className="icon" src={isDarkMode ?
                        require("../../../assets/icons/support_dark.png") :
                        require("../../../assets/icons/support_light.png")}
                        alt="support_icon" />
                }
                <Typography
                    className={isDarkMode ? `menuitems ${activeMenuItem === "support" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "support" ? "active" : ""}`} > Support</Typography>
                {/* {activeMenuItem === "support" ?
                                        <ChevronRightOutlinedIcon className="arrow" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
            </MenuItem> : <></>}
            {/* <div id="bottom-menu"> */}
                <MenuItem
                    className={isDarkMode ? `menu ` : `menu_light`}
                    component={Link}
                   // to="/"
                    //
                    onClick={() => openLogoutodal()}>
                    {activeMenuItem === "logout" ?
                        <LogoutOutlinedIcon className="" sx={{ color: "#ffffff" }} fontSize="small" /> :
                    isDarkMode ?
                        <LogoutOutlinedIcon className="" sx={{ color: "#7d8baa" }} fontSize="small" />
                        :  <LogoutOutlinedIcon className="" sx={{ color: "#00000" }} fontSize="small" /> 
               
                    }
                    <Typography
                        className={isDarkMode ? `menuitems ${activeMenuItem === "logout" ? "active" : ""}` :
                        `menuitems_light ${activeMenuItem === "logout" ? "active" : ""}`}> Sign Out</Typography>
                    {/* {activeMenuItem === "support" ?
                                        <ChevronRightOutlinedIcon className="arrow" sx={{ color: "white" }} fontSize="small" />
                                        : <></>
                                    } */}
                </MenuItem>
            {/* </div> */}
        </>
    )
}