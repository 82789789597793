import { Grid, InputLabel, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import SearchIcon from '@mui/icons-material/Search';
import { SearchIconWrapper, StyledInputBase } from "../../../Styles";
import { SmallSearch } from "../../../styles/SerachStyles";
import { DraggableItem } from "./DraggableItem";
import { DroppableItem } from "./DroppableItem";
export const DragContainer = ({ onDragEnd, isDarkMode, checkPoints, handleAddCheckpoints, handleDeleteCheckPoint, 
    selectedCheckPoints,validationErrors,isOrder }) => {
    const theme = useTheme();
    const [search, setSearch] = useState('')

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="allcheckpoint" type="group">
                    {(provided) => (

                        <Grid item sm={6} sx={{ pb: 20, overflowY: "auto", height: "350px", position: "relative", }}
                            ref={provided.innerRef} {...provided.droppableProps} >
                            <div style={{
                                position: "sticky",
                                top: 0, // Stick to the top
                                zIndex: 3, backgroundColor: '#ffffff'
                            }}>
                                <InputLabel
                                    className={isDarkMode ? "form_text" : "form_text_light"}
                                    sx={{
                                        color: isDarkMode ? "white" : "black", ml: 0, mb: 4,

                                    }}>
                                    All Check Points</InputLabel>
                                <SmallSearch isDarkMode={isDarkMode} theme={theme}>
                                    <SearchIconWrapper>
                                        <SearchIcon sx={{ color: isDarkMode ? "white" : "#8F8F91" }} fontSize="medium" className="mb" />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        isDarkMode={isDarkMode}
                                        placeholder="Search check point"
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                    />
                                </SmallSearch>
                            </div>
                            {
                                checkPoints.
                                    filter(item => {
                                        return item.check_point_name.toLowerCase().includes(search.toLowerCase())
                                    }).
                                    map((item, index) => (
                                        <div key={"chkey"+index}>
                                            <DraggableItem
                                                id={item.id}
                                                key={item.id.toString()}
                                                index={index}
                                                checkPoints={item.check_point_name}
                                                handleAddCheckpoints={handleAddCheckpoints} />
                                        </div>
                                    ))}
                            {provided.placeholder}

                        </Grid>
                    )}
                </Droppable>
                <Droppable droppableId="routecheckpoint" type="group">
                    {(provided) => (
                        <Grid item sm={6}
                            sx={{ pb: 20, overflowY: "auto", height: "350px", pl: 5, position: "relative", }}
                            ref={provided.innerRef} {...provided.droppableProps}
                        // onDragOver={handleDragOver}
                        >
                             <div style={{
                                                    position: "sticky",
                                                    top: 0, // Stick to the top
                                                    paddingBottom:'14%',
                                                    zIndex: 3, backgroundColor: '#ffffff'
                                                }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{
                                    color: isDarkMode ? "white" : "black", ml: 0, mb: 4,
                                    position: "sticky",
                                    top: 0, // Stick to the top
                                    zIndex: 3, backgroundColor: '#ffffff'
                                }}>
                                Route Check Points</InputLabel>
                                {validationErrors.check_point_id && (
                                                        <Typography
                                                            sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                                            className={
                                                                isDarkMode
                                                                    ? 'errormargin  errorStyle '
                                                                    : ' errormargin errorStyle'
                                                            }
                                                        >
                                                            {validationErrors.check_point_id}
                                                        </Typography>
                                                    )}
                                </div>
                            {selectedCheckPoints.map((item, index, array) => (
                                <DroppableItem
                                    lastIndex={index === array.length - 1 ? true : false}
                                    id={item.id}
                                    key={item.id.toString()}
                                    index={index}
                                    checkPoints={item.check_point_name}
                                    handleDeleteCheckPoint={handleDeleteCheckPoint}
                                    isOrder={isOrder} />
                            ))}
                            {provided.placeholder}

                        </Grid>
                    )}
                </Droppable>

            </DragDropContext>
        </>
    )
}