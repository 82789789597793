import { API } from './Api';
const checkpoints ='/checkpoints/'
//get checkpoint list
export const fetchLevelListData = async (id) => {
    // const start = data && data.start !== undefined ? data.start : 0;
    // const limit = data && data.limit !== undefined ? data.limit : 50;
    
    const response = await API.get(`${checkpoints}/levels?site_id=${id}`);
    return response.data;


}
export const fetchBlockListData = async (data) => {
    const site_id=data.siteId;
    const level_id=data.levelId
    // const start = data && data.start !== undefined ? data.start : 0;
    // const limit = data && data.limit !== undefined ? data.limit : 50;
    
    const response = await API.get(`${checkpoints}blocks?site_id=${site_id}&level_id=${level_id}`);
    return response.data;


}