import { Grid, Typography } from "@mui/material";
import React from "react";
export const EncoderDetails = ({ AlertDetails }) => {
  return (
    <div className="device_view reducepb">
      <Grid container>
        <Grid item container sx={{ mb: 1 }}>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_mr"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Name
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text">
                {AlertDetails?.camera_details? AlertDetails?.camera_details?.title : "NA"}
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_ml"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Stream Id
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text ">
                {AlertDetails?.camera_details? AlertDetails?.camera_details?.machine_id : "NA"} 
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_mr"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Sensor Name
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text">
                {AlertDetails.sensor_name}
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_ml"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Battery Name
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text ">
                {AlertDetails.battery_name} 
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_mr"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Battery Id
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text">
                {AlertDetails.battery_id}
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_ml"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Distance 1{" "}
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text ">
              {AlertDetails.distance1 == "NA" ? 0 : AlertDetails.distance1}{' '}CM
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv  rowdiv_mr"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Distance 2
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text">
                {AlertDetails.distance2 == "NA" ? 0 : AlertDetails.distance2}{' '}CM

              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv  rowdiv_ml"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Battery Percentage{" "}
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text ">
                {AlertDetails.battery_percentage == "NA" ? AlertDetails.battery_percentage : AlertDetails.battery_percentage+" %"} 
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
