
import { API } from './Api';
const role ='/role/';
export const fetchRoleData = async (id) => {

    const response = await API.get(role+ id);
    return response.data;


}
export const addNewRoleData = async (data) => {

    const response = await API.post(role, data);
    return response.data;


    
}
export const fetchRoleLists = async (data) => {
    const start = data && data.start !== undefined ? data.start : 0;
    const limit = data && data.limit !== undefined ? data.limit : 20;
    const org_id = data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
    const searchWord = data && data.searchWord !== undefined ? data.searchWord : '';

    const response = await API.get(`${role}?start=${start}&limit=${limit}&org_id=${org_id}&search=${searchWord}`);
    return response.data;


}
//get site 
export const fetchSiteData = async (data) => {
    const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
    const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
    const is_wisilica = data && data.is_wisilica !== undefined ? data.is_wisilica :  parseInt(sessionStorage.getItem('org_Id')) == 0 ? "" : sessionStorage.getItem('is_wisilica');

    const response = await API.get(`${role}sites?org_id=${org_id}&site_id=${site_id}&is_wisilica=${is_wisilica}`);
    return response.data;


}
// {{api_url}}/{{api_version}}/role/dropdown?role_id=3&org_id=0
export const fetchRoleDropLists = async (data) => {
    const org_id = data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
    const role_id = data && data.role_id !== undefined ? data.role_id :'';
    const response = await API.get(`${role}/dropdown?role_id=${role_id}&org_id=${org_id}`);
    return response.data;


}
export const editUserRoleDetails = async (id,data) => {

    const response = await API.put(`${role}${id}`, data);
    return response.data;


}
export const postGetInTouchMail = async (data) => {

    const response = await API.post(`/dashboard/getintouch`, data);
    return response.data;


}
export const postContactUsDetails = async (data) => {

    const response = await API.post(`/dashboard/contact`, data);
    return response.data;


}