import { sharableActionType } from "../actions/SharableActions"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    LayerList:dataTemplate,
    BlockList:dataTemplate
}

const SharableReducer = (state = initialData, action) => {
    switch (action.type) {
         //fetch layer list
         case sharableActionType.FETCH_LEVEL:
            return {
                ...state,
                LayerList: {
                    ...state.LayerList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case sharableActionType.FETCH_LEVEL_SUCCESS:
            return {

                ...state,
                LayerList: {
                    ...state.LayerList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case sharableActionType.FETCH_LEVEL_ERROR:
            return {
                ...state,
                LayerList: {
                    ...state.LayerList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case sharableActionType.FETCH_BLOCK:
                return {
                    ...state,
                    BlockList: {
                        ...state.BlockList,
                        isFetching: true,
                        payload: [],
                        error: false
                    }
                }
            case sharableActionType.FETCH_BLOCK_SUCCESS:
                return {
    
                    ...state,
                    BlockList: {
                        ...state.BlockList,
                        isFetching: false,
                        error: false,
                        payload: action.payload
                    }
                }
            case sharableActionType.FETCH_BLOCK_ERROR:
                return {
                    ...state,
                    BlockList: {
                        ...state.BlockList,
                        isFetching: false,
                        payload: [],
                        error: true,
                        errorMessage: action.error
                    }
                }
            
        default:
            return state
    }

}
export default SharableReducer;