import React from 'react';
import './chart.css'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const data = [
    {
        name: '1st week',
        Online_Device: 10,
        Total_Device: 40,
        Offline_Device: 30,
    },
    {
        name: '2nd Week',
        Online_Device: 22,
        Total_Device: 60,
        Offline_Device: 48,
    },
    {
        name: '3rd Week',
        Online_Device: 55,
        Total_Device: 95,
        Offline_Device: 40,
    },
    {
        name: '4th Week',
        Online_Device: 20,
        Total_Device: 50,
        Offline_Device: 40,
    },

];
export const Linechart = () => {
    return (
        <div className='chart_container'>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart

                    data={data}
                    margin={{
                        top: 30,
            right: 5,
            left: -5,
            bottom: 15,
                    }}
                >
  <CartesianGrid horizontal={true} vertical={false} strokeDasharray="" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                    <Line type="monotone" dataKey="Online_Device" stroke="#28A745" activeDot={{ r: 8 }} legendType="square" />
                    <Line type="monotone" dataKey="Total_Device" stroke="#283CA7" activeDot={{ r: 8 }} legendType="square" />
                    <Line type="monotone" dataKey="Offline_Device" stroke="#E28909" activeDot={{ r: 8 }} legendType="square" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}