import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import { ExportModal } from '../../Modals/ExportModal';
highcharts3d(Highcharts);

export const VaAlertChart = ({ area, patrolData, trackingnames, sentryData, exportMenu, handleExportMenu, confirmExport,
    handleExporttype, exportModalAlert, size }) => {

    const barChartOptions = {
        chart: {
            type: 'column',
            width: size,
            height:600,
            // scrollablePlotArea: {
            //     minWidth: 800,
            //     scrollPositionX: 1
            // },
            options3d: {
                enabled: true,
                alpha: 2,
                beta: 20,
                viewDistance: 0,
                depth: 10
            }
        },

        title: {
            text: ' ',

        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },

        xAxis: {
            categories: trackingnames,
            labels: {
                skew3d: true,
                style: {
                    fontSize: '16px'
                },
                overflow: 'justify'

            }

        },

        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: 'Count',
                skew3d: true,
                style: {
                    fontSize: '16px'
                }
            }
        },

        tooltip: {
            headerFormat: '<b>{point.key}</b><br>',
            pointFormat: '<span style="color:{series.color}">\u25CF</span> ' +
                '{series.name}: {point.y} / {point.stackTotal}'
        },

        plotOptions: {
            series: {
                //   pointStart: 2016
            },
            column: {
                stacking: 'normal',
                depth: 40
            }
        },

        // series: [ {
        //     name: 'petrol',
        //     data: [650, 654, 643, 612, 572],
        //     stack: 'Europe',
        //     color:'#FF903E'

        // }, {
        //     name: 'Sentry',
        //     data: [564, 562, 582, 571, 533],
        //     stack: 'Europe',
        //     color:'#C52525'

        // }]
        series: [{
            name: 'PPE not found',
            data: patrolData,
            stack: 'Activity',
            color: '#C52525'
        }, {
            name: 'Person near machinery',
            data: sentryData,
            stack: 'Activity',
            color: '#FF903E'
        },
        {
            name: 'Intrusion into restricted access area',
            data: area,
            stack: 'Activity',
            color: '#C9D636'
        }
        ]
    };
    return (
        <div className="chart-div">
            <ExportModal
                openModal={exportMenu}
                handleModalClose={handleExportMenu}
                modalHead="Export Alert Reports"
                handleExporttype={handleExporttype}
                confirmExport={confirmExport}
                exportModalAlert={exportModalAlert}
            />
            <HighchartsReact
                highcharts={Highcharts}
                options={barChartOptions}
            />
        </div>
    )
}
