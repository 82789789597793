export const routeActionType ={
    FETCH_ROUTE: "FETCH_ROUTE",
    FETCH_ROUTE_SUCCESS : "FETCH_ROUTE_SUCCESS",
    FETCH_ROUTE_ERROR : "FETCH_ROUTE_ERROR",

    FETCH_SINGLE_ROUTE: "FETCH_SINGLE_ROUTE",
    FETCH_SINGLE_ROUTE_SUCCESS : "FETCH_SINGLE_ROUTE_SUCCESS",
    FETCH_SINGLE_ROUTE_ERROR : "FETCH_SINGLE_ROUTE_ERROR", 

    ADD_ROUTE : "ADD_ROUTE",
    ADD_ROUTE_SUCCESS : "ADD_ROUTE_SUCCESS",
    ADD_ROUTE_ERROR : "ADD_ROUTE_ERROR",
    CLEAR_ADD_ROUTE_STATUS : "CLEAR_ADD_ROUTE_STATUS",

    UPDATE_ROUTE : "UPDATE_ROUTE",
    UPDATE_ROUTE_SUCCESS : "UPDATE_ROUTE_SUCCESS",
    UPDATE_ROUTE_ERROR : "UPDATE_ROUTE_ERROR",
    CLEAR_UPDATE_ROUTE_STATUS : "CLEAR_UPDATE_ROUTE_STATUS", 


    DELETE_ROUTE : "DELETE_ROUTE",
    DELETE_ROUTE_SUCCESS : "DELETE_ROUTE_SUCCESS",
    DELETE_ROUTE_ERROR : "DELETE_ROUTE_ERROR",
    CLEAR_DELETE_ROUTE_STATUS : 'CLEAR_DELETE_ROUTE_STATUS'
}
//fetch route list
export const fetchRoute = (data) => {


    return {
        type: routeActionType.FETCH_ROUTE,
        data:data
      
    }
}
export const fetchRouteSuccess = (data) => {
    return {
        type: routeActionType.FETCH_ROUTE_SUCCESS,
        payload: data
    }
}
export const fetchRouteError = (error) => {
    return {
        type: routeActionType.FETCH_ROUTE_ERROR,
        error
    }
}//fetch singleroute list
export const fetchSingleRoute = (id) => {


    return {
        type: routeActionType.FETCH_SINGLE_ROUTE,
        id:id
      
    }
}
export const fetchSingleRouteSuccess = (data) => {
    return {
        type: routeActionType.FETCH_SINGLE_ROUTE_SUCCESS,
        payload: data
    }
}
export const fetchSingleRouteError = (error) => {
    return {
        type: routeActionType.FETCH_SINGLE_ROUTE_ERROR,
        error
    }
}
export const addRoute = (data) => {


    return {
        type: routeActionType.ADD_ROUTE,
        data:data
      
    }
}
export const addRouteSuccess = (data) => {
    return {
        type: routeActionType.ADD_ROUTE_SUCCESS,
        payload: data
    }
}
export const addRouteError = (error) => {
    return {
        type: routeActionType.ADD_ROUTE_ERROR,
        error
    }
}
export const clearAddRouteStatus = () => {
    return {
        type: routeActionType.CLEAR_ADD_ROUTE_STATUS,
        
    }
}
export const updateRoute = (data,id) => {


    return {
        type: routeActionType.UPDATE_ROUTE,
        data:data,
        id:id
      
    }
}
export const updateRouteSuccess = (data) => {
    return {
        type: routeActionType.UPDATE_ROUTE_SUCCESS,
        payload: data
    }
}
export const updateRouteError = (error) => {
    return {
        type: routeActionType.UPDATE_ROUTE_ERROR,
        error
    }
}
export const clearUpdateRouteStatus = () => {
    return {
        type: routeActionType.CLEAR_UPDATE_ROUTE_STATUS,
        
    }
}
export const deleteRoute = (data) => {
    return {
        type: routeActionType.DELETE_ROUTE,
        data:data
    }
}
export const deleteRouteSuccess = (data) => {
    return {
        type: routeActionType.DELETE_ROUTE_SUCCESS,
        payload: data
    }
}
export const deleteRouteError = (error) => {
    return {
        type: routeActionType.DELETE_ROUTE_ERROR,
        error
    }
}
export const clearDeleteRouteStatus = () => {
    return {
        type: routeActionType.CLEAR_DELETE_ROUTE_STATUS,
        
    }
}