import React from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { IoEyeOutline } from "react-icons/io5";
import { cardHeaderText } from "../../../styles/CardStyles";

export const TagListItem = ({ tagDetails, handleView }) => {
  return (
    <>
      <Card className="device_card">
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: "#F8E3C4", color: "#FFAC0B" }}
              alt={tagDetails.infant_name}
            >
              {" "}
              {tagDetails.infant_name.charAt(0)}
            </Avatar>
          }
          title={tagDetails.infant_name}
          //subheader={DeviceDetails.deviceuuid}
          titleTypographyProps={cardHeaderText.title}
          // subheaderTypographyProps={cardHeaderText.subHeader}
        />
        <div className="card_divider"></div>
        <CardContent>
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text_label">Tag</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text">
                {tagDetails.tag_name}
              </Typography>
            </Grid>
          </Grid>
          <div className="card_divider"></div>
          <Grid container sx={{ mt: 2 }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text_label">Room</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text">
                {tagDetails.block_name}
              </Typography>
            </Grid>
          </Grid>
          <div className="card_divider"></div>
          <Grid container sx={{ mt: 2 }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text_label">Checked Out</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text">
                {tagDetails.checkoutstatus}
              </Typography>
            </Grid>
          </Grid>
          <div className="card_divider"></div>
          <Grid container sx={{ mt: 2 }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text_label">Tag type</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text">
                {tagDetails.asset_type}
              </Typography>
            </Grid>
          </Grid>
          <div className="card_divider"></div>
          <Grid container sx={{ mt: 2 }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text_label">Battery Level</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text">
                {tagDetails.battery == null ? "NA" : tagDetails.battery+"%"}
              </Typography>
            </Grid>
          </Grid>
          <div className="card_divider"></div>
          <Grid container sx={{ mt: 2 }}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text_label">Last Reported</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="card_text">
                {tagDetails.lastSeen}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <div className="card_divider"></div>
        <CardActionArea
          sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}
        >
          <IconButton onClick={() => handleView(tagDetails.infant_id)}>
            <IoEyeOutline color="#161616" />
          </IconButton>
        </CardActionArea>
      </Card>
    </>
  );
};
