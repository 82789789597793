
import React, { useState } from "react";
import { StyledGreyButton } from "../Styles";
import { Grid, IconButton, InputAdornment, InputLabel, TextField, Button, Alert, Typography, Snackbar, Hidden } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from "react-redux";
import { clearFetchAuthenticationInfo, fetchAuthenticationInfo } from "../../redux/actions/AuthenticationActions";
import { useEffect } from "react";
import { validateEmail } from "../helpers/ValidationHelper";
import { getSocketAuthToken } from '../../services/AuthenticationServices';
import { socket } from '../../services/SocketService';
import { FaArrowRightLong } from "react-icons/fa6";
import { PlainButton, StyledCreateButton, YellowBGButton, YellowBorderWhiteButton } from "../styles/ButtonStyles";

export const UserLogin = ({ }) => {
    const dispatch = useDispatch();
    const [isShowAlert, setShowAlert] = useState(false);
    const [alerText, setAlertText] = useState("");
    const [loginDetails, setLoginDetails] = useState({
        username: "",
        password: ""
    });
    const [validationErrors, setValidationErrors] = useState({
        username: '',
        password: '',
    });

    const userLoginStatus = useSelector(state => state.login.loginData);
    useEffect(() => {
        if (!userLoginStatus.isFetching && userLoginStatus.payload && userLoginStatus.payload.success === true) {
            const loggedUser = userLoginStatus.payload.data.userDetails;
            const loggedUserOrg = userLoginStatus.payload.data;
            const token = userLoginStatus.payload.data.token;
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('refreshToken', loggedUserOrg.refreshtoken);

            sessionStorage.setItem('id', loggedUser.id);
            sessionStorage.setItem('role_id', loggedUser.role_id);
            sessionStorage.setItem('username', loggedUser.username);
            sessionStorage.setItem('username', loggedUser.username);
            sessionStorage.setItem('org_Id', loggedUser.organisation_id);
            sessionStorage.setItem('user_pro_pic', loggedUser.profilepic);
            sessionStorage.setItem('per_feature', loggedUser.features)
            sessionStorage.setItem("user_site_id", loggedUser.site_id)
            sessionStorage.setItem("user_name", loggedUser.username);
            if (loggedUser?.org_details?.logo) {
                sessionStorage.setItem("org_logo", loggedUser?.org_details?.logo);
            }
            else {
                sessionStorage.setItem("org_logo", "");

            }
            if (loggedUser?.organisation_id != 0) {
                sessionStorage.setItem("is_wisilica", loggedUser.org_details.is_wisilica);
            }
            if (loggedUser.org_details != null && loggedUser.org_details) {
                sessionStorage.setItem('organisation_type_data', loggedUser.org_details.type);

            }
            if (loggedUser.org_details == null) {
                sessionStorage.setItem('organisation_type_data', null);
            }
            async function getToken() {
                const resp = await getSocketAuthToken();
                if (resp && resp.success) {
                    sessionStorage.setItem('socketAuthToken', resp.token);
                    window.location.href = "/loading..";
                }

            }
            getToken();
        }
        else if (!userLoginStatus.isFetching && userLoginStatus.error === true) {
            if (userLoginStatus.errorMessage.success === false) {
                const message = userLoginStatus.errorMessage.message;
                setAlertText(message)
            }
            // else {
            //     const message ="Invalid email id";
            //     setAlertText(message)

            // }
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearFetchAuthenticationInfo());

            }, 5000); // 20 seconds in milliseconds

            // Clean up the timeout when the component unmounts or when alert is hidden
            return () => clearTimeout(timeoutId);
        }
    }, [userLoginStatus])
    const [showPassword, setShowPassword] = useState(false);
    const handleLoginDetailChange = (value, key) => {
        let temp = { ...loginDetails };
        temp[key] = value;
        setLoginDetails(temp);
        let error = '';
        switch (key) {
            case 'username':
                const validEmail = validateEmail(value);
                error = validEmail ? '' : 'Invalid Email';
                break;
            default:
                break;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: error,
        }));
    }
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleLogin = (e) => {
        let tempData = {
            email: loginDetails.username,
            password: loginDetails.password
        }
        e.preventDefault();
        const isEmptyField = Object.values(tempData).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
        });

        if (isEmptyField) {

            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                username: loginDetails.username.trim() === '' ? 'This field is mandatory' : '',
                password: loginDetails.password.trim() === '' ? 'This field is mandatory' : ''
            }));

        }
        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (allClear && !isEmptyField) {
            setValidationErrors({
                username: '',
                password: '',

            });
            dispatch(fetchAuthenticationInfo(tempData));
        }
        //  window.location.href = '/dashboard';
    }
    const handleForgot = () => {
        window.location.href = '/forgot-password';
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    const handleMoreOn = () => {
        window.location.href = '/more';
    }
    const handleContactUs = () => {
        window.location.href = '/contactus'

    }
    const handleHomeClick = () => {
        window.location.href = '/more';

    }
    return (
        <div style={{ height: '100%', marginBottom: '0px !important ' }}>
            <Snackbar
                open={isShowAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    {alerText}
                </Alert>
            </Snackbar>
            <Grid container style={{ height: '100%' }} className="light_grey_bg">
                <Grid item lg={6} md={6} sm={0}
                    sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', alignItems: 'center' }, }}
                >
                    <div className="login">
                        {/* <img
                            src={require('../../assets/img/loginlogo.png')}
                            alt="login logo"
                            className="loginlogostyle" /> */}
                        <Typography className="loginhead">Simplify Safety and Security Operations with</Typography>
                        <Grid container sx={{ mt: 5 }}
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            <Grid item lg={4} md={4} sm={6} xs={6} >
                                <YellowBGButton
                                    className="full_width"
                                    variant="contained"
                                    disableRipple
                                    onClick={handleMoreOn}
                                    endIcon={<FaArrowRightLong color="white" />}

                                >
                                    More On VIDSafe
                                </YellowBGButton>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6} sx={{ ml: 4 }}>
                                <YellowBorderWhiteButton
                                    className="full_width"
                                    variant="outlined"
                                    disableRipple
                                    onClick={handleContactUs}
                                    endIcon={<FaArrowRightLong color="white" />}
                                // onClick={handleLogin}
                                >
                                    Contact US
                                </YellowBorderWhiteButton>
                            </Grid>
                        </Grid>
                    </div>

                </Grid>
                <Grid item lg={6} md={6} sm={12} className="light_grey_bg login_div_container"
                    // style={{ justifyContent: 'center' }}
                    style={{ display: "flex", justifyContent: "center" }}

                >
                    {/* <Grid container> */}

                    <Grid item lg={9} md={9} sm={12} xs={12} sx={{ pl: "2%", pr: "2%" }}>
                        <div className="login_div1">
                            {/* <Grid item lg={6} md={6} sm={12}  
                         sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', alignItems: 'center' } }} 
                        >*/}
                            <div className="logincontentdiv">
                                <Hidden mdUp implementation="css" only={"lg"}>

                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        style={{ display: "flex", justifyContent: "flex-end" }}
                                    >
                                        <PlainButton
                                            sx={{ color: '#ffac0b' }}
                                            onClick={handleHomeClick}>Home</PlainButton>


                                    </Grid>

                                </Hidden>
                                <img
                                    src={require('../../assets/img/logo.png')}
                                    alt="login logo"
                                    className="loginlogostyle" />
                                {/* </Grid> */}
                                {/* <Typography className="logintext">LOGIN</Typography> */}
                                {/* {isShowAlert ?
<Alert variant="outlined" severity="error">{alerText}</Alert> : <></>
} */}
                                <InputLabel
                                    className="loginlabel"
                                    sx={{ color: "black", fontSize: 14, mb: 2, mt: 2, fontWeight: 600 }}>
                                    Mail Id
                                </InputLabel>
                                <TextField
                                    className={"text"}
                                    type="email"
                                    value={loginDetails.username}
                                    onChange={(e) => handleLoginDetailChange(e.target.value, 'username')}
                                    error={!!validationErrors.username}
                                    helperText={validationErrors.username}
                                />
                                <InputLabel
                                    className="loginlabel"
                                    sx={{ color: "black", fontSize: 14, mb: 2, mt: 3, fontWeight: 600 }}>
                                    Password</InputLabel>
                                <TextField
                                    error={!!validationErrors.password}
                                    helperText={validationErrors.password}
                                    type={showPassword ? "text" : "password"}
                                    value={loginDetails.password}
                                    onChange={(e) => handleLoginDetailChange(e.target.value, 'password')}
                                    className={"text form_text_light"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                                <div className="forget_div">

                                    <Button
                                        variant="text"
                                        onClick={handleForgot}
                                        className="forgotlabel"
                                        style={{ textTransform: 'capitalize', color: '#E28909', fontWeight: 700 }}>
                                        Forgot Password
                                    </Button>
                                </div>

                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <StyledGreyButton
                                            className="full_width"
                                            variant="contained"
                                            disableRipple
                                            onClick={handleLogin}>
                                            SUBMIT
                                        </StyledGreyButton>
                                    </Grid>
                                </Grid>
                            </div>

                            <Grid item lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                style={{ display: "flex", justifyContent: "center" }}>
                                <div className="">
                                    <Typography className="apx_watermark">
                                        APX Technologies Pte Ltd All rights reserved. 2024
                                    </Typography>
                                </div>

                            </Grid>
                        </div>
                    </Grid>

                </Grid>
            </Grid>
            {/* </Grid> */}
        </div>
    )
}