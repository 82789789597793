import { Grid, Typography } from "@mui/material";
import React from "react";
export const SensorDetails = ({ AlertDetails }) => {
  return (
    <div className="device_view reducepb">
      <Grid container>
        <Grid item container sx={{ mb: 1 }}>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_mr"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Name
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text">
                {AlertDetails.sensor_details.sensor_name}
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_ml"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Battery Percentage
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text ">
                {AlertDetails.sensor_details.percentage} %
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_mr"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Distance 1
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text">
                {AlertDetails.sensor_details.distance1== ""? 0 :AlertDetails.sensor_details.distance1}{' '}CM
              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv borderbottom rowdiv_ml"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
              Distance 2
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text ">
              {AlertDetails.sensor_details.distance2 == ""? 0 :AlertDetails.sensor_details.distance2}{' '}CM

              </Typography>
            </div>
          </Grid>
          <Grid item sx={{ pb: 7 }} lg={6} md={6} sm={12} xs={12}>
            <div
              className="rowdiv  rowdiv_mr"
              style={{ paddingBottom: "20px" }}
            >
              <Typography className="device_view_text label_color rowdiv_label">
                Status
              </Typography>
              <Typography className="device_view_text content_color rowdiv_text">
                {AlertDetails.sensor_details.device_status}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
