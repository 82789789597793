import { all, put, takeLatest } from "redux-saga/effects";
import {  addNewOrganisationSuccess, addNewOrganisationrror, deleteOrganisationError,
     deleteOrganisationSuccess, fetchOrganisationBlocksError, fetchOrganisationBlocksSuccess, 
     fetchOrganisationDropdownError, 
     fetchOrganisationDropdownSuccess, 
     fetchOrganisationListError, fetchOrganisationListSuccess, fetchOrganisationTypeError, 
     fetchOrganisationTypeSuccess,fetchSingleOrganisationError, fetchSingleOrganisationSuccess, 
      organisationActionType, 
      updateOrganisationError, 
      updateOrganisationSuccess,
      addSitesSuccess,
      addSitesError,
      editSitesSuccess,
      editSitesError,
      deleteSitesSuccess,
      deleteSitesError,
      fetchSingleLevelSuccess,
      fetchSingleLevelError,
      addTelegramSuccess,
      addTelegramError,
      fetchOrgTelegramListSuccess,
      fetchOrgTelegramListError,
      fetchSingleOrgTelegramSuccess,
      fetchSingleOrgTelegramError} from "../actions/OrganisationActions";
import { addNewOrgData, addNewTelegramData, addOrganisationSites, editOrganisationSites, editOrgDetails, fetchOrgBlockData, 
    fetchOrgListData, fetchOrgTypeData, fetchSingleOrgData,getOrgDropDowList,getOrgLevelDetails,
    getSingleTelegramData,
    getTelegramListData,
    removeSingleOrgData, removeSingleSitesData} from "../../services/OrganisationServices";
               //fetch org telegramlist
      function* _getSingleOrgTelegram(action) {
        try {
            const telegram = yield getSingleTelegramData(action.id);
            yield put(fetchSingleOrgTelegramSuccess(telegram));
        } 
        catch  (error) {
            if(error.response && error.response.data && error.response.data){
                yield put(fetchSingleOrgTelegramError( error.response.data));
            }else{
                yield put(fetchSingleOrgTelegramError( error));
            }
        }
    
    }
    function* getSingleOrgTelegram() {
        yield takeLatest(organisationActionType.FETCH_SINGLE_ORG_TELEGRAM, _getSingleOrgTelegram)
    }    
      //fetch org telegramlist
      function* _getOrgTelegramList(action) {
        try {
            const telegram = yield getTelegramListData(action.data);
            yield put(fetchOrgTelegramListSuccess(telegram));
        } 
        catch  (error) {
            if(error.response && error.response.data && error.response.data){
                yield put(fetchOrgTelegramListError( error.response.data));
            }else{
                yield put(fetchOrgTelegramListError( error));
            }
        }
    
    }
    function* getOrgTelegramList() {
        yield takeLatest(organisationActionType.FETCH_ORG_TELEGRAM, _getOrgTelegramList)
    }
      //add org telegrams
      function* _addOrgTelegramss(action) {
        try {
            const telegram = yield addNewTelegramData(action.data);
            yield put(addTelegramSuccess(telegram));
        } 
        catch  (error) {
            if(error.response && error.response.data && error.response.data){
                yield put(addTelegramError( error.response.data));
            }else{
                yield put(addTelegramError( error));
            }
        }
    
    }
    function* addOrgTelegramss() {
        yield takeLatest(organisationActionType.ADD_ORG_TELEGRAM, _addOrgTelegramss)
    }
    //get org levels
       function* _getOrgLevel(action) {

        try {
            const siteList = yield getOrgLevelDetails(action.id);
            yield put(fetchSingleLevelSuccess(siteList));
        } catch (e) {
            yield put(fetchSingleLevelError("error"));
        }
    }
    function* getOrgLevel() {
        yield takeLatest(organisationActionType.FETCH_SINGLE_LEVEL, _getOrgLevel)
    }
      //delete org sites
      function* _deleteOrgSites(action) {

        try {
            const siteList = yield removeSingleSitesData(action.data);
            yield put(deleteSitesSuccess(siteList));
        } 
        catch  (error) {
            if(error.response && error.response.data && error.response.data){
                yield put(deleteSitesError( error.response.data));
            }else{
                yield put(deleteSitesError( error));
            }
        }
    
    }
    function* deleteOrgSites() {
        yield takeLatest(organisationActionType.DELETE_SITES, _deleteOrgSites)
    }
     //edit org sites
     function* _editOrgSites(action) {

        try {
            const siteList = yield editOrganisationSites(action);
            yield put(editSitesSuccess(siteList));
        } catch (e) {
            yield put(editSitesError("error"));
        }
    }
    function* editOrgSites() {
        yield takeLatest(organisationActionType.EDIT_SITES, _editOrgSites)
    }
     //add org sites
     function* _addOrgSites(action) {

        try {
            const siteList = yield addOrganisationSites(action);
            yield put(addSitesSuccess(siteList));
        } catch (e) {
            yield put(addSitesError("error"));
        }
    }
    function* addOrgSites() {
        yield takeLatest(organisationActionType.ADD_SITES, _addOrgSites)
    }
     //fetch org dropdown
function* _getOrgDropdown(action) {

    try {
        const OrgListInfo = yield getOrgDropDowList(action);
        yield put(fetchOrganisationDropdownSuccess(OrgListInfo));
    } catch (e) {
        yield put(fetchOrganisationDropdownError("error"));
    }
}
function* getOrgDropdown() {
    yield takeLatest(organisationActionType.FETCH_ORGANISATION_DROPDOWN, _getOrgDropdown)
}//update org 
function* _editOrganisation(action) {
    try {
        const SingleOrgInfo = yield editOrgDetails(action.id,action.data);
        yield put(updateOrganisationSuccess(SingleOrgInfo));
    } catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(updateOrganisationError( error.response.data));
        }else{
            yield put(updateOrganisationError( error));
        }
    }
}
function* editOrganisation() {
    yield takeLatest(organisationActionType.UPDATE_ORGANISATION, _editOrganisation)
}
//delete org 
function* _removeOrganisation(action) {
    try {
        const SingleOrgInfo = yield removeSingleOrgData(action.data);
        yield put(deleteOrganisationSuccess(SingleOrgInfo));
    } catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(deleteOrganisationError( error.response.data));
        }else{
            yield put(deleteOrganisationError( error));
        }
    }
}
function* removeOrganisation() {
    yield takeLatest(organisationActionType.DELETE_ORGANISATION, _removeOrganisation)
}
//fetch org list
function* _getSingleOrganisation(action) {
    try {
        const SingleOrgInfo = yield fetchSingleOrgData(action.id);
        yield put(fetchSingleOrganisationSuccess(SingleOrgInfo));
    } catch (e) {
        yield put(fetchSingleOrganisationError("error"));
    }
}
function* getSingleOrganisation() {
    yield takeLatest(organisationActionType.FETCH_SINGLE_ORGANISATION, _getSingleOrganisation)
}
//fetch org list
function* _getOrganisationList(action) {

    try {
        const OrgListInfo = yield fetchOrgListData(action.data);
        yield put(fetchOrganisationListSuccess(OrgListInfo));
    } catch (e) {
        yield put(fetchOrganisationListError("error"));
    }
}
function* getOrganisationList() {
    yield takeLatest(organisationActionType.FETCH_ORGANISATION_LIST, _getOrganisationList)
}
//add org 
function* _addOrganisationDetails(action) {

    try {
        const newOrgInfo = yield addNewOrgData(action.data);
        yield put(addNewOrganisationSuccess(newOrgInfo));
    } 
    catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(addNewOrganisationrror( error.response.data));
        }else{
            yield put(addNewOrganisationrror( error));
        }
    }
}
function* addOrganisationDetails() {
    yield takeLatest(organisationActionType.ADD_NEW_ORGANISATION, _addOrganisationDetails)
} 
//fetch org block
function* _fetchOrganisationBlocks(action) {

    try {
        const OrgBlockInfo = yield fetchOrgBlockData(action.data);
        yield put(fetchOrganisationBlocksSuccess(OrgBlockInfo));
    } catch (e) {
        yield put(fetchOrganisationBlocksError("error"));
    }
}
function* fetchOrganisationBlocks() {
    yield takeLatest(organisationActionType.FETCH_ORGANISATION_BLOCKS, _fetchOrganisationBlocks)
}
//fetch org type
function* _fetchOrganisationDetails(action) {

    try {
        const OrgTypeInfo = yield fetchOrgTypeData(action);
        yield put(fetchOrganisationTypeSuccess(OrgTypeInfo));
    } catch (e) {
        yield put(fetchOrganisationTypeError("error"));
    }
}
function* fetchOrganisationDetails() {
    yield takeLatest(organisationActionType.FETCH_ORGANISATION_TYPE, _fetchOrganisationDetails)
} 
export function* OrgaisationSaga(){
    yield all([fetchOrganisationDetails(),addOrganisationDetails(),getOrganisationList(),getSingleOrganisation(),
        fetchOrganisationBlocks(),removeOrganisation(),getOrgDropdown(),editOrganisation(),addOrgSites()
        ,editOrgSites(),deleteOrgSites(),getOrgLevel(),addOrgTelegramss(),getOrgTelegramList(),getSingleOrgTelegram()])
}