import { Alert, Box, CircularProgress, FormHelperText, Grid, InputLabel, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearRssiLevelUpdatestatus, getRssiLevel, updateRssiLevel } from "../../../redux/actions/DeviceActions";
import { StyledCreateSmallButton } from "../../styles/ButtonStyles";
export const RssiEdit = () => {
  const dispatch = useDispatch();
  const rssiUpdateStatus = useSelector(state => state.device.rssi);
  const rssidata = useSelector(state => state.device.rssiPrev)
  const [orgId, setOrgId] = useState("")
  const [rssi, setRssi] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertmessage] = useState(false);
  useEffect(() => {
    const id = sessionStorage.getItem('org_Id');
    setOrgId(id);
    if (id !== null) {
      dispatch(getRssiLevel(id))
    }
  }, [])
  useEffect(() => {
    if (!rssidata.isFetching && rssidata.payload && rssidata.payload.success === true) {
      const temp = rssidata.payload.organization_details.rssi;
      setRssi(temp);
    }
    if (!rssiUpdateStatus.isFetching && rssiUpdateStatus.payload && rssiUpdateStatus.payload.success === true) {
      setShowAlert(true);
      const msg = rssiUpdateStatus.payload.message;
      setAlertmessage(msg);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearRssiLevelUpdatestatus());
        dispatch(getRssiLevel(orgId))
        // sessionStorage.setItem('trackingTab', 'six')
        //     window.location.href = '/tracking';
      }, 2000);
      return () => clearTimeout(timeoutId);

    }
    if (!rssiUpdateStatus.isFetching && rssiUpdateStatus.error) {
      setShowAlert(true);
      setIsError(true);
      const msg = rssiUpdateStatus.payload.message;
      setAlertmessage(msg);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setIsError(false);
        dispatch(clearRssiLevelUpdatestatus());
        dispatch(getRssiLevel(orgId))


      }, 2000);
      return () => clearTimeout(timeoutId);
    }

  }, [rssiUpdateStatus, rssidata])
  const onUpdate = () => {
    let data = {
      org_id: parseInt(sessionStorage.getItem('org_Id')),
      rssi: parseInt(rssi),
    };
    dispatch(updateRssiLevel(data))
  };
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <>
      <Grid container>
        {/* <Grid item lg={12} md={12} sm={12} xs={12} className="mtdash2">
          <Typography className="headersubhead light_text htl">RSSI</Typography>
        </Grid> */}

        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handlealertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handlealertClose}
            severity={isError ? "error" : "success"}
            variant="outlined"
            sx={{ width: '100%', color: isError ? '#C61A1A' : '#11b311', backgroundColor: isError ? '#FCDFDF' : '#5bdb5b73' }}

          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {rssiUpdateStatus.isFetching || rssidata.isFetching?
                    <Box
                        sx={{ display: 'flex', justifyContent: 'center' ,mt:10}}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Box>
                    :
        <>
          <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              RSSI
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="RSSI"
              value={rssi}
              onChange={(e) => setRssi(e.target.value)}
            // error={!!validationErrors.route_name}

            />
            <FormHelperText>RSSI Indicate that the value is for the distance sensing logic.</FormHelperText>
          </Grid>
          <Grid item xs={10} sm={10} lg={10} md={10} sx={{ mt: 3 }}>
            <StyledCreateSmallButton
              onClick={onUpdate}
              sx={{ mt: 5, width: 150 }}
              variant="contained"
              disableRipple
            >
              Update
            </StyledCreateSmallButton>
          </Grid>
        </>
}
      </Grid>
    </>
  );
};
