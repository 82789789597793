import { personnelActionType } from "../actions/PersonnelAction"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    personnelList:dataTemplate,
    addPerson:dataTemplate,
    deletedPerson:dataTemplate,
    viewPersonnel:dataTemplate,
    updatePersonnel:dataTemplate,
    assignPersonnel:dataTemplate,
    perssonelTag:dataTemplate,
    importPersonnel:dataTemplate,
    bulkDeletedPersonnels:dataTemplate,
    unassignPersonnel:dataTemplate,

}
const PersonnelReducer = (state = initialData, action) => {
    switch (action.type) {
         //fetch personnel list
         case personnelActionType.FETCH_PERSONNEL_LIST:
            return {
                ...state,
                personnelList: {
                    ...state.personnelList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.FETCH_PERSONNEL_LIST_SUCCESS:
            return {

                ...state,
                personnelList: {
                    ...state.personnelList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.FETCH_PERSONNEL_LIST_ERROR:
            return {
                ...state,
                personnelList: {
                    ...state.personnelList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
               //add personnel 
         case personnelActionType.ADD_PERSONNEL:
            return {
                ...state,
                addPerson: {
                    ...state.addPerson,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.ADD_PERSONNEL_SUCCESS:
            return {

                ...state,
                addPerson: {
                    ...state.addPerson,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.ADD_PERSONNEL_ERROR:
            return {
                ...state,
                addPerson: {
                    ...state.addPerson,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case personnelActionType.CLEAR_ADD_PERSONNEL_STATUS:
            return {
                ...state,
                addPerson: {
                    ...state.addPerson,
                    isFetching: false,
                    payload: [],
                    error: false,
                    errorMessage: ''
                }
            }
                   //delete personnel 
         case personnelActionType.DELETE_PERSONEL:
            return {
                ...state,
                deletedPerson: {
                    ...state.deletedPerson,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.DELETE_PERSONEL_SUCCESS:
            return {

                ...state,
                deletedPerson: {
                    ...state.deletedPerson,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.DELETE_PERSONEL_ERROR:
            return {
                ...state,
                deletedPerson: {
                    ...state.deletedPerson,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case personnelActionType.CLEAR_DELETE_PERSONNEL_STATUS:
                return {
                    ...state,
                    deletedPerson: {
                        ...state.deletedPerson,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
                //delete bulk personnel 
         case personnelActionType.DELETE_BULK_PERSONEL:
            return {
                ...state,
                bulkDeletedPersonnels: {
                    ...state.bulkDeletedPersonnels,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.DELETE_BULK_PERSONEL_SUCCESS:
            return {

                ...state,
                bulkDeletedPersonnels: {
                    ...state.bulkDeletedPersonnels,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.DELETE_BULK_PERSONEL_ERROR:
            return {
                ...state,
                bulkDeletedPersonnels: {
                    ...state.bulkDeletedPersonnels,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case personnelActionType.CLEAR_DELETE_BULK_PERSONEL_STATUS:
                return {
                    ...state,
                    bulkDeletedPersonnels: {
                        ...state.bulkDeletedPersonnels,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
                      //view personnel 
         case personnelActionType.VIEW_PERSONEL:
            return {
                ...state,
                viewPersonnel: {
                    ...state.viewPersonnel,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.VIEW_PERSONEL_SUCCESS:
            return {

                ...state,
                viewPersonnel: {
                    ...state.viewPersonnel,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.VIEW_PERSONEL_ERROR:
            return {
                ...state,
                viewPersonnel: {
                    ...state.viewPersonnel,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
              //update personnel 
         case personnelActionType.UPDATE_PERSONEL:
            return {
                ...state,
                updatePersonnel: {
                    ...state.updatePersonnel,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.UPDATE_PERSONEL_SUCCESS:
            return {

                ...state,
                updatePersonnel: {
                    ...state.updatePersonnel,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.UPDATE_PERSONEL_ERROR:
            return {
                ...state,
                updatePersonnel: {
                    ...state.updatePersonnel,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case personnelActionType.CLEAR_UPDATE_PERSONNEL_STATUS:
                return {
                    ...state,
                    updatePersonnel: {
                        ...state.updatePersonnel,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
              //assign personnel 
         case personnelActionType.ASSIGN_PERSONEL:
            return {
                ...state,
                assignPersonnel: {
                    ...state.assignPersonnel,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.ASSIGN_PERSONEL_SUCCESS:
            return {

                ...state,
                assignPersonnel: {
                    ...state.assignPersonnel,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.ASSIGN_PERSONEL_ERROR:
            return {
                ...state,
                assignPersonnel: {
                    ...state.assignPersonnel,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case personnelActionType.CLEAR_ASSIGN_PERSONEL_STATUS:
                return {
                    ...state,
                    assignPersonnel: {
                        ...state.assignPersonnel,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
                          // personnel tag
         case personnelActionType.FETCH_PERSONEL_TAG:
            return {
                ...state,
                perssonelTag: {
                    ...state.perssonelTag,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.FETCH_PERSONEL_TAG_SUCCESS:
            return {

                ...state,
                perssonelTag: {
                    ...state.perssonelTag,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.FETCH_PERSONEL_TAG_ERROR:
            return {
                ...state,
                perssonelTag: {
                    ...state.perssonelTag,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
                               // personnel import
         case personnelActionType.IMPORT_PERSONEL:
            return {
                ...state,
                importPersonnel: {
                    ...state.importPersonnel,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.IMPORT_PERSONEL_SUCCESS:
            return {

                ...state,
                importPersonnel: {
                    ...state.importPersonnel,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.IMPORT_PERSONEL_ERROR:
            return {
                ...state,
                importPersonnel: {
                    ...state.importPersonnel,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            } 
            case personnelActionType.CLEAR_IMPORT_PERSONEL_STATUS:
                return {
                    ...state,
                    importPersonnel: {
                        ...state.importPersonnel,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ""
                    }
                } 
                                          // personnel unassign
         case personnelActionType.UNASSIGN_PERSONEL:
            return {
                ...state,
                unassignPersonnel: {
                    ...state.unassignPersonnel,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case personnelActionType.UNASSIGN_PERSONEL_SUCCESS:
            return {

                ...state,
                unassignPersonnel: {
                    ...state.unassignPersonnel,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case personnelActionType.UNASSIGN_PERSONEL_ERROR:
            return {
                ...state,
                unassignPersonnel: {
                    ...state.unassignPersonnel,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case personnelActionType.CLEAR_UNASSIGN_PERSONNEL_STATUS:
                return {
                    ...state,
                    unassignPersonnel: {
                        ...state.unassignPersonnel,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
            default:
                return state
        }
    
    }
    export default PersonnelReducer;