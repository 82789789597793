import React, { useRef } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import * as XLSX from "xlsx";
import { ExportModal } from '../../Modals/ExportModal';

highcharts3d(Highcharts);
// require("highcharts/modules/exporting")(Highcharts);


/// // Initialize exporting module
// exporting(Highcharts);
// exportData(Highcharts);

export const ReportSquareChart =({trackingDatas,patrolData,trackingDates,sentryData,exportMenu,handleExportMenu,confirmExport,
    handleExporttype,exportModalAlert,size,chartRef})=>{

    //  const exportChartToImage = (trackingDatas) => {
    // //     if (chartRef.current) {
    // //         const firstEntryDate = Object.values(trackingDatas)[0].date;
    // //         const firstEntryMonth = new Date(firstEntryDate).toLocaleString('default', { month: 'long' });
    // //         const firstEntryYear = new Date(firstEntryDate).getFullYear();
        
    // //         // Convert data to array of arrays
    // //         const exportData = Object.keys(trackingDatas).map(key => {
    // //           const { date, patrol, sentry, total } = trackingDatas[key];
    // //           return [date, patrol, sentry, total];
    // //         });
        
    // //         // Create a new workbook
    // //         const wb = XLSX.utils.book_new();
    // //         const ws = XLSX.utils.aoa_to_sheet([
    // //           [`Tracking Violation Alerts - ${firstEntryMonth} ${firstEntryYear}`], // Title row
    // //           [], // Empty row for spacing
    // //           ['Date', 'Patrol', 'Sentry', 'Total'], // Header row
    // //           ...exportData // Data rows
    // //         ]);
        
    // //         // Add worksheet to workbook
    // //         XLSX.utils.book_append_sheet(wb, ws, 'Tracking Data');
        
    // //         // Construct filename based on the first entry's date
    // //         const filename = `trackingviolationalert_${firstEntryMonth.toLowerCase()}${firstEntryYear}.xlsx`;
        
    // //         // Export the workbook as a .xlsx file with the constructed filename
    // //         XLSX.writeFile(wb, filename);
        
    // //       const chart = chartRef.current.chart;
    // //       chart.exportChart({
    // //         type: "image/png",
    // //                //type: "image/svg+xml",

    // //         filename: "chart-image",
    // //         callback: function (url) {
    // //             // Create an anchor element to trigger download
    // //             const link = document.createElement("a");
    // //             link.href = url;
    // //             link.download = "chart-image.png"; // or "chart-image.jpeg"
    // //             document.body.appendChild(link);
    // //             link.click();
    // //             document.body.removeChild(link);
      
    // //             // Automatically open the downloaded image in Excel (if Excel is the default application for PNG/JPEG)
    // //             window.open(url);
    // //           },
    // //       });
    // //     }
    // // const wb = XLSX.utils.book_new();

    // // const chart = chartRef.current.chart; // Assuming chartRef.current contains the chart instance
    // // chart.exportChart({
    // //   type: "image/png",
    // //   filename: "chart-image",
    // //   callback: function (url) {
    // //     const wsChart = XLSX.utils.aoa_to_sheet([
    // //       [`Chart Image - `], // Title row
    // //       [], // Empty row for spacing
    // //       [ // Chart image
    // //         { t: "s", v: url, l: { Target: url } },
    // //       ]
    // //     ]);
    // //     console.log("chart",chart)

    // //     XLSX.utils.book_append_sheet(wb, wsChart, 'Chart Image');
    // //     // Construct filename based on the first entry's date
    // //     const filename = `trackingviolationalert_.xlsx`;

    // //     // Export the workbook as a .xlsx file with the constructed filename
    // //     XLSX.writeFile(wb, filename);

    // //     // Automatically open the downloaded image in Excel (if Excel is the default application for PNG/JPEG)
    // //     window.open(url);
    // //   },
    // // });
   
    //     console.log(chartRef)
    //     if (chartRef.current  ) {
    //         console.log("trackingDatas",trackingDatas)

    //       const firstEntryDate = Object.values(trackingDatas)[0].date;
    //       const firstEntryMonth = new Date(firstEntryDate).toLocaleString('default', { month: 'long' });
    //       const firstEntryYear = new Date(firstEntryDate).getFullYear();
      
    //       // Create a new workbook
    //       const wb = XLSX.utils.book_new();
      
    //       // Worksheet for tracking data
    //       const exportData = Object.keys(trackingDatas).map(key => {
    //         const { date, patrol, sentry, total } = trackingDatas[key];
    //         return [date, patrol, sentry, total];
    //       });
    //       const wsData = XLSX.utils.aoa_to_sheet([
    //         [`Tracking Violation Alerts - ${firstEntryMonth} ${firstEntryYear}`], // Title row
    //         [], // Empty row for spacing
    //         ['Date', 'Patrol', 'Sentry', 'Total'], // Header row
    //         ...exportData // Data rows
    //       ]);
      
    //       // Worksheet for chart image
    //       if (chartRef && chartRef.current) {
    //       const chart = chartRef.current.chart; // Assuming chartRef.current contains the chart instance
    //       chart.exportChart({
    //         type: "image/png",
    //         filename: "chart-image",
    //         callback: function (url) {
    //           const wsChart = XLSX.utils.aoa_to_sheet([
    //             [`Chart Image - ${firstEntryMonth} ${firstEntryYear}`], // Title row
    //             [], // Empty row for spacing
    //             [ // Chart image
    //               { t: "s", v: url, l: { Target: url } },
    //             ]
    //           ]);
            
    //           console.log("chart",chart)
            
    //           XLSX.utils.book_append_sheet(wb, wsData, 'Tracking Data');
    
    //           XLSX.utils.book_append_sheet(wb, wsChart, 'Chart Image');
    //           // Construct filename based on the first entry's date
    //           const filename = `trackingviolationalert_${firstEntryMonth.toLowerCase()}${firstEntryYear}.xlsx`;
      
    //           // Export the workbook as a .xlsx file with the constructed filename
    //           XLSX.writeFile(wb, filename);
      
    //           // Automatically open the downloaded image in Excel (if Excel is the default application for PNG/JPEG)
    //           //window.open(url);
    //         },
          
    //       });
    //     }}
    //   };
        
    const barChartOptions = {
        chart: {
            type: 'column',
            width: size,
            height:600,
            // scrollablePlotArea: {
            //     minWidth: 800,
            //     scrollPositionX: 1
            //   },
            options3d: {
                enabled: true,
                alpha: 2,
                beta: 20,
                viewDistance: 0,
                depth: 10
            }
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: ' ',
          
        },
    
        xAxis: {
            categories: trackingDates,
            labels: {
                skew3d: true,
                style: {
                    fontSize: '16px'
                },
                overflow: 'justify'

            }
           
        },
        exporting: {
            sourceWidth: 4000,
            sourceHeight: 400,
            // scale: 2 (default)
            chartOptions: {
                subtitle: null
            }
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                 text: 'Count',
                skew3d: true,
                style: {
                    fontSize: '16px'
                }
            }
        },
    
        tooltip: {
            headerFormat: '<b>{point.key}</b><br>',
            pointFormat: '<span style="color:{series.color}">\u25CF</span> ' +
                '{series.name}: {point.y} / {point.stackTotal}'
        },
        navigation: {
            buttonOptions: {
                enable: true,
                align: 'right'
            }
        },
        plotOptions: {
            series: {
              //   pointStart: 2016
            },
            column: {
                stacking: 'normal',
                depth: 40
            }
        },
    
        // series: [ {
        //     name: 'petrol',
        //     data: [650, 654, 643, 612, 572],
        //     stack: 'Europe',
        //     color:'#FF903E'

        // }, {
        //     name: 'Sentry',
        //     data: [564, 562, 582, 571, 533],
        //     stack: 'Europe',
        //     color:'#C52525'

        // }]
        series: [{
            name: 'Patrol',
            data: patrolData,
            stack: 'Activity',
            color: '#C52525'
        }, {
            name: 'Sentry',
            data: sentryData,
            stack: 'Activity',
            color: '#FF903E'
        }]
    };
    return(
        <div className="chart-div">
            
            <ExportModal
                openModal={exportMenu}
                handleModalClose={handleExportMenu}
                modalHead="Export Alert Reports"
                handleExporttype={handleExporttype}
                confirmExport={confirmExport}
                exportModalAlert={exportModalAlert}
            />
        <HighchartsReact
         highcharts={Highcharts} 
        options={barChartOptions}
        ref={chartRef}
        />
        {/* <button onClick={()=>exportChartToImage(trackingDatas)}>Export</button> */}
      </div>
    )
}
