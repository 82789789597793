import { all, put, takeLatest } from "redux-saga/effects";
import { assignListenerError, assignListenerSuccess, fetchAssignedListenerError, fetchAssignedListenerSuccess, fetchListenerError, 
    fetchListenerSuccess, listnerActionType, unAssignListenerError, unAssignListenerSuccess } from "../actions/ListenerActions";
import { assignListenerData, fetchAssignedListenerData, fetchListenerListData, unAssignListenerData } from "../../services/ListenerServices";
// unassign listener
function* _UnassignListener(action) {

    try {
        const unAssignedInfo = yield unAssignListenerData(action.data);
        yield put(unAssignListenerSuccess(unAssignedInfo));
    } catch (e) {
        yield put(unAssignListenerError("error"));
    }
}
function* UnassignListener() {
    yield takeLatest(listnerActionType.UNASSIGN_LISTENER, _UnassignListener)
}
// assign listener
function* _assignListener(action) {

    try {
        const assignedInfo = yield assignListenerData(action.data);
        yield put(assignListenerSuccess(assignedInfo));
    } catch (e) {
        yield put(assignListenerError("error"));
    }
}
function* assignListener() {
    yield takeLatest(listnerActionType.ASSIGN_LISTENER, _assignListener)
}
//fetch assigned listener
function* _assignedListeners(action) {

    try {
        const assignedInfo = yield fetchAssignedListenerData(action.id);
        yield put(fetchAssignedListenerSuccess(assignedInfo));
    } catch (e) {
        yield put(fetchAssignedListenerError("error"));
    }
}
function* assignedListeners() {
    yield takeLatest(listnerActionType.FETCH_ASSINED_LISTENER, _assignedListeners)
}
//fetch listener list
function* _getListenerList(action) {

    try {
        const listenerInfo = yield fetchListenerListData(action.id);
        yield put(fetchListenerSuccess(listenerInfo));
    } catch (e) {
        yield put(fetchListenerError("error"));
    }
}
function* getListenerList() {
    yield takeLatest(listnerActionType.FETCH_LISTENER, _getListenerList)
}
export function* ListenerSaga(){
    yield all([getListenerList(),assignedListeners(),assignListener(),UnassignListener()])
}