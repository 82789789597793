import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { BorderButton, ConfirmButton, StyledCreateSmallButton } from "../styles/ButtonStyles";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { TagModalstyle } from "../styles/ModalStyle";

export const LogoutModal = ({ openLogOutModal, onLogoutModalClose, onLogOutConform }) => {
    return (
        <>
            <Modal
                open={openLogOutModal}
                onClose={onLogoutModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={TagModalstyle}>
                    <IconButton>
                        <LogoutOutlinedIcon sx={{ fontSize: '32px', mr: '5px', color: "#FFAC0B" }} />
                    </IconButton>
                    <Typography className="modalhead">
                        Sign Out
                    </Typography>
                    <Typography className="modalBody modallight" sx={{ mt: 2 }}>
                        Are you sure you want to Sign Out?
                    </Typography>
                    <div style={{ display: 'flex' }} >
                        <BorderButton
                            sx={{ mr: 2, mt: 3 }} variant="outlined"
                            onClick={onLogoutModalClose}
                        >
                            <HighlightOffRoundedIcon
                                sx={{ fontSize: '20px', mr: '5px', color: "#161616" }} />
                            Cancel
                        </BorderButton>
                        <StyledCreateSmallButton
                            startIcon={<LogoutOutlinedIcon color="white" />}
                            sx={{ mt: 3 }}
                            variant="contained"
                            onClick={onLogOutConform}
                        >

                            Sign Out
                        </StyledCreateSmallButton>

                    </div>

                </Box>
            </Modal >
        </>
    )
}