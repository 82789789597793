import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Alert, Box, Chip, CircularProgress, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { StyledCreateSmallButton } from "../../styles/ButtonStyles";
import { useDispatch, useSelector } from "react-redux";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { fetchBlock, fetchLevel } from "../../../redux/actions/SharableActions";
import { fetchAssignedListener, fetchListener } from "../../../redux/actions/ListenerActions";
import { addCheckPoint, clearAddCheckpointStatus, fetchCheckPointType } from "../../../redux/actions/CheckPointActions";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

export const CheckPointCreate = () => {
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
    const [sites, setSites] = useState([]);
    const [levels, setLevels] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [checkPointType, setCheckPointType] = useState([]);
    const [availableListener, setAvailableListener] = useState([]);
    const navigate = useNavigate();
    const siteData = useSelector(state => state.userRole.siteData);
    const LevelData = useSelector(state => state.sharables.LayerList);
    const BlockData = useSelector(state => state.sharables.BlockList);
    const ListenerData = useSelector(state => state.listeners.listenerList);
    const AssignedListenerData = useSelector(state => state.listeners.assignedListener);
    const CheckPointTypeData = useSelector(state => state.checkpoints.checkPointType);
    const NewCheckPointStatus = useSelector(state => state.checkpoints.addCheckPoint);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('')

    const [checkPointDetails, setCheckPointDetails] = useState({
        checkPointName: '',
        blockId: '',
        checkPointTypeId: '',
        listenerId: []
    });
    const [validationErrors, setValidationErrors] = useState({
        checkPointName: '',
        site_id: '',
        level_id: '',
        // blockId: '',
        listenerId: '',
        checkPointTypeId: '',
        organization_id: '',
    });
    const [selectedSites, setSelectedSites] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchSites());
        dispatch(fetchCheckPointType());
    }, [])
    useEffect(() => {
        if (!ListenerData.isFetching && ListenerData.payload && ListenerData.payload.success === true) {
            const tempListenerList = ListenerData.payload.data;
            if (!AssignedListenerData.isFetching && AssignedListenerData.payload && AssignedListenerData.payload.success === true) {
                const tempAssignedListenerList = AssignedListenerData.payload.data;
                const tempAvailableListener = [];
                tempListenerList.forEach((item) => {
                    if (tempAssignedListenerList.length === 0 || !tempAssignedListenerList.some(obj => obj.tag_id === item.id)) {
                        tempAvailableListener.push(item);
                    }
                });
                setAvailableListener(tempAvailableListener);
            }
        }
    }, [ListenerData, AssignedListenerData]);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp);
            const siteId = sessionStorage.getItem('user_site_id');
            if (siteId != 0) {
                setSelectedSites(parseInt(siteId))
            }
        }
        if (!CheckPointTypeData.isFetching && CheckPointTypeData.payload && CheckPointTypeData.payload.success === true) {
            const checkPointType = CheckPointTypeData.payload.data;
            setCheckPointType(checkPointType)
        }
        if (!NewCheckPointStatus.isFetching && NewCheckPointStatus.payload && NewCheckPointStatus.payload.success === true) {
            sessionStorage.setItem('trackingTab', 'one')
            window.location.href = '/tracking'

        }
        if (!NewCheckPointStatus.isFetching && NewCheckPointStatus.error) {
            const error = NewCheckPointStatus.errorMessage.message;
            setAlertMessage(error);

            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            dispatch(clearAddCheckpointStatus())
            return () => clearTimeout(timeoutId);
        }
    }, [siteData, CheckPointTypeData, NewCheckPointStatus]);

    useEffect(() => {
        dispatch(fetchLevel(selectedSites))
        if (selectedSites && selectedLevel) {
            levels.map((item) => {
                if (selectedSites != item.site_id)
                    setSelectedLevel('');
                let temp = { ...checkPointDetails };

                temp['blockId'] = '';
                setCheckPointDetails(temp);
            })

        }

        if (selectedSites) {
            dispatch(fetchListener(selectedSites))
            dispatch(fetchAssignedListener(selectedSites))
            if (selectedSites !== '') {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    site_id: '',
                }));
            }
        }
        if (selectedLevel !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                level_id: '',
            }));
        }
        if (selectedSites && selectedLevel) {
            const data = {
                siteId: selectedSites,
                levelId: selectedLevel
            }
            dispatch(fetchBlock(data));
        }
    }, [selectedSites, selectedLevel])
    useEffect(() => {
        if (!LevelData.isFetching && LevelData.payload && LevelData.payload.success === true) {
            const levelList = LevelData.payload.data.LevelList;
            setLevels(levelList)
        }
    }, [LevelData]);
    useEffect(() => {
        if (!BlockData.isFetching && BlockData.payload && BlockData.payload.success === true) {
            const BlockList = BlockData.payload.data.BlockList;
            setBlocks(BlockList)
        }
    }, [BlockData]);
    const handleChange = (value, key) => {
        let temp = { ...checkPointDetails };
        temp[key] = value;
        setCheckPointDetails(temp);
        if (key === 'checkPointName') {
            const valueLength = value.trim().length;
            const nameLengthError =
                valueLength < 2 || valueLength >= 30
                    ? 'Checkpoint name must be between 2 and 30 characters long'
                    : '';

            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                checkPointName: nameLengthError,
            }));
        }

        if (key === 'listenerId') {

            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                listenerId: '',
            }));
        }
        if (key === 'checkPointTypeId') {

            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                checkPointTypeId: '',
            }));
        }
        // if (key === 'blockId') {

        //     setValidationErrors((prevErrors) => ({
        //         ...prevErrors,
        //         blockId: '',
        //     }));
        // }

    }
    const handleCheckPointCreate = (e) => {
        const selectedListner_id = checkPointDetails.listenerId.map(item => String(item));

        let tempdata = {
            check_point_name: checkPointDetails.checkPointName,
            site_id: parseInt(selectedSites),
            level_id: parseInt(selectedLevel),
            block_id: Object.keys(checkPointDetails.blockId).length != 0 ? checkPointDetails.blockId.roomId === '' ? '' : parseInt(checkPointDetails.blockId.roomId) : 0,
            tag_id: selectedListner_id.length == 0 ? [] : selectedListner_id,
            checkpoint_type: checkPointDetails.checkPointTypeId,
            organization_id: parseInt(sessionStorage.getItem('org_Id')),
            created_user: parseInt(sessionStorage.getItem('id')),

        }
        e.preventDefault();
        const isEmptyField = Object.values(tempdata).some((value) => {
            return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
        });

        if (isEmptyField) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                checkPointName: checkPointDetails.checkPointName.trim() === '' ? 'This field is mandatory' : '',
                site_id: selectedSites != '' ? '' : 'This field is mandatory',
                level_id: selectedLevel != '' ? '' : 'This field is mandatory',
                // blockId: checkPointDetails.blockId != '' ? '' : 'This field is mandatory',
                checkPointTypeId: checkPointDetails.checkPointTypeId != '' ? '' : 'This field is mandatory',
                listenerId: checkPointDetails.listenerId != '' ? '' : 'This field is mandatory',
            }));

        }

        const allClear = Object.values(validationErrors).every((value) => {
            return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
        });
        if (allClear && !isEmptyField) {
            setValidationErrors({
                checkPointName: '',
                site_id: '',
                level_id: '',
                // blockId: '',
                checkPointTypeId: '',
                listenerId: '',
            });
            dispatch(addCheckPoint(tempdata))
        }
    }
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <>
            <Layout
                isDarkMode={isDarkMode}
                handleDarkmode={handleDarkmode}
                handleActiveMenuItems={handleActiveMenuItems}
                activeMenuItem={"tracking"}
                activeMenuKey={"tracking"}
                activeSubtab={"one"}

                isCreate={true}
            >
                <Snackbar
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={handlealertClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handlealertClose}
                        severity="error"
                        variant="outlined"
                        sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
                {NewCheckPointStatus.isFetching ?
                    <Box sx={{ display: 'flex', justifyContent: 'center',mt:10 }}>
                        <CircularProgress sx={{color:'#FFAC0B'}}/>
                    </Box>
                    :
                    <Grid container spacing={2} sx={{ mt: 3, pb: 5 }} className={isDarkMode ? "table_dark ml" : "table_light ml"} >
                        <Grid item lg={12} >
                            <Typography
                                className='device_view_head'>Create Check Point</Typography>
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Name Of Check Point</InputLabel>
                            <TextField
                                className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                                placeholder="Create check point name"
                                value={checkPointDetails.checkPointName}
                                onChange={(e) => handleChange(e.target.value, 'checkPointName')}
                                error={!!validationErrors.checkPointName}
                                helperText={validationErrors.checkPointName}
                            />
                        </Grid>

                        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>

                            <InputLabel
                                className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                Sites</InputLabel>
                            <Select
                                className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                                value={selectedSites}
                                onChange={(e) => setSelectedSites(e.target.value)}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected == '') {
                                        return <span style={{ color: '#a2a2a2' }}>Site</span>;
                                    }
                                    const selectedSite = sites.find(site => site.organizationId === selected);
                                    return selectedSite ? selectedSite.organizationName : '';
                                }}
                                error={!!validationErrors.site_id}
                                helperText={validationErrors.site_id}
                            >
                                {sites.length > 0 ?
                                    sites.map((site, index) => (
                                        <MenuItem
                                            key={index}
                                            value={site.organizationId}>
                                            {site.organizationName}
                                        </MenuItem>
                                    ))
                                    : <MenuItem value="">No Data Found</MenuItem>}
                            </Select>
                            {validationErrors.site_id && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.site_id}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Level</InputLabel>
                            <Select
                                className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                value={selectedLevel}
                                onChange={(e) => setSelectedLevel(e.target.value)}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected == '') {
                                        return <span style={{ color: '#a2a2a2' }}>Level</span>;
                                    }
                                    const selectedLevel = levels.find(level => level.layerId === selected);
                                    return selectedLevel ? selectedLevel.layerName : '';
                                }}
                                error={!!validationErrors.level_id}
                                helperText={validationErrors.level_id}
                            >
                                {levels.length > 0 ?
                                    levels.map((level, index) => (
                                        <MenuItem
                                            key={index}
                                            value={level.layerId}>
                                            {level.layerName}
                                        </MenuItem>
                                    ))
                                    : <MenuItem value="">No Data Found</MenuItem>}
                            </Select>
                            {validationErrors.level_id && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.level_id}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Block</InputLabel>
                            <Select
                                className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                                value={checkPointDetails.blockId}
                                onChange={(e) => handleChange(e.target.value, 'blockId')}
                                disabled={selectedSites && selectedLevel ? false : true}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected == '') {
                                        return <span style={{ color: '#a2a2a2' }}>Block</span>;
                                    }
                                    return selected.block_name;
                                }}
                                error={!!validationErrors.blockId}
                                helperText={validationErrors.blockId}
                            >
                                {blocks.length > 0 ?
                                    blocks.map((block, index) => (
                                        <MenuItem
                                            key={index}
                                            value={block}>
                                            {block.block_name}
                                        </MenuItem>
                                    ))
                                    : <MenuItem value="">No Data Found</MenuItem>}
                            </Select>
                            {validationErrors.blockId && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.blockId}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text" : "form_text_light"}
                                sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                                Check Point Type</InputLabel>
                            <Select
                                className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                                value={checkPointDetails.checkPointTypeId}
                                onChange={(e) => handleChange(e.target.value, 'checkPointTypeId')}
                                error={!!validationErrors.checkPointTypeId}
                                helperText={validationErrors.checkPointTypeId}
                            >
                                {checkPointType.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item.id}>
                                        {item.point_type_name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {validationErrors.checkPointTypeId && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.checkPointTypeId}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                                sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                                Bridge/Listener
                            </InputLabel>
                            <Select
                                className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                                value={checkPointDetails.listenerId}
                                onChange={(e) => handleChange(e.target.value, 'listenerId')}
                                multiple
                                error={!!validationErrors.listenerId}
                                helperText={validationErrors.listenerId}
                                displayEmpty
                                renderValue={(selected) =>
                                    <Stack gap={1} direction="row" flexWrap="wrap">


                                        {availableListener.length && availableListener !== undefined ?
                                            selected?.map(key => <Chip
                                                key={key}
                                                label={availableListener.find(opt => opt.id == key) ?
                                                    availableListener.find(opt => opt.id == key).tag_name : ""}
                                                onDelete={() =>
                                                    handleChange(
                                                        checkPointDetails.listenerId.filter((item) => item != key), "listenerId"
                                                    )
                                                }
                                                deleteIcon={
                                                    <CancelIcon
                                                        onMouseDown={(event) => event.stopPropagation()}
                                                    />
                                                }
                                            />)
                                            : <></>
                                        }
                                    </Stack>
                                }
                            >
                                {!availableListener.length == 0 ? availableListener.map((listener, index) => (
                                    <MenuItem
                                        key={index}
                                        value={listener.id}>
                                        {listener.tag_name}
                                    </MenuItem>
                                )) :
                                    <MenuItem value="">No listener available</MenuItem>
                                }
                            </Select>
                            {validationErrors.listenerId && (
                                <Typography
                                    sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                    className={
                                        isDarkMode
                                            ? 'errormargin  errorStyle '
                                            : ' errormargin errorStyle'
                                    }
                                >
                                    {validationErrors.listenerId}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item>
                            <StyledCreateSmallButton
                                onClick={handleCheckPointCreate}
                                sx={{ mt: 5, width: 150 }} variant="contained"
                                disableRipple>
                                Create
                            </StyledCreateSmallButton>
                        </Grid>
                    </Grid>
                }
            </Layout>
        </>
    )
}