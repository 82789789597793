import { Alert, Grid, Typography } from '@mui/material';
import React from 'react';
import { alertTimeOnly, hoursConverter } from '../../convertors/TimeConvertor';
export const ViolationDetails = ({ AlertDetails, unAuthDuration }) => {


    return (
        <div className='device_view reducepb' >
            {AlertDetails.duty_type === 2 ?
                <Grid container >
                    <Grid item lg={2.3} md={2} sm={12} xs={12}>
                        <Typography className='device_view_text label_color rowdiv_label' sx={{ pb: '16% !important','@media (max-width: 600px)': {pb:"5px !important"} }}>
                            Route
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text' sx={{ pl: '2%','@media (max-width: 600px)': {pb:"10px !important"} }}>
                            {AlertDetails.RouteData?.routename}
                        </Typography>
                    </Grid>
                    <Grid item lg={4.2} md={4} sm={12}>
                        <Typography className='device_view_text label_color rowdiv_label' sx={{ pb: '6% !important','@media (max-width: 600px)': {pb:"5px !important"} }}>
                            Checkpoints
                        </Typography>

                        <div className="violation_box">
                            {AlertDetails?.RouteData?.checkpoints?.map((checkpoint, index) => (
                                <span className={checkpoint.firstseenat == "" ? "alert_stage_text default_text "
                                    : "alert_stage_text valid_text"} key={index}>
                                    {checkpoint.checkpoint}
                                </span>

                            ))}

                        </div>
                    </Grid>

                    {AlertDetails && AlertDetails.violationreason && AlertDetails.violationreason.length > 0 && AlertDetails.violationreason[0].lastseenat ? (
                        <Grid item lg={1.3} md={1.3} sm={12}>
                            <Typography className='device_view_text label_color' sx={{ pb: '16% !important','@media (max-width: 600px)': {pb:"5px !important",pt:"10px !important"} }}>
                                Duration
                            </Typography>
                            <span className="alert_stage_text critical_text">{unAuthDuration}</span>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {AlertDetails.violationtime > 0 ? (
                        <Grid item lg={1.3} md={1.3} sm={12}>
                            <Typography className='device_view_text label_color' sx={{ pb: '16% !important','@media (max-width: 600px)': {pb:"5px !important"} }}>
                                Duration
                            </Typography>
                            <span className="alert_stage_text critical_text">{hoursConverter(AlertDetails.violationtime)}</span>
                        </Grid>
                    ) : (
                        <Grid item lg={4.2} md={4} sm={12}>
                            <Typography className='device_view_text label_color rowdiv_label' sx={{ pb: '6% !important','@media (max-width: 600px)': {pb:"5px !important",pt:"10px !important"} }}>
                                Violations
                            </Typography>
                            <div className="violation_box">
                                {AlertDetails?.violationreason?.map((item, index) => (
                                    <span className="alert_stage_text critical_text alert_span" key={index}>
                                        {item.check_point_name}
                                    </span>
                                ))}
                            </div>
                        </Grid>
                    )}
                </Grid> : <></>
            }
            {AlertDetails.duty_type === 1 ?
                <Grid container >
                    {/* <Grid item lg={6} md={6} sm={12}>
                 <Typography className=' label_color device_view_text ' sx={{ pb: '6% !important' }}>
                 Sentry Points / Checkpoints
                 </Typography>
                 <Typography className='device_view_text content_color rowdiv_text' sx={{ pl: '2%' }}>
                     {AlertDetails.RouteData?.routename}
                 </Typography>
             </Grid> */}

                    <Grid item lg={5} md={5} sm={12}>
                        <Typography className='device_view_text label_color ' sx={{ pb: '6% !important','@media (max-width: 600px)': {pb:"5px !important",pt:"10px !important"} }}>
                            Sentry Points / Checkpoints
                        </Typography>

                        <div className="violation_box">
                            {AlertDetails?.RouteData?.checkpoints?.map((checkpoint, index) => (
                                <span className={checkpoint.firstseenat == "" ? "alert_stage_text default_text "
                                    : "alert_stage_text valid_text"} key={index}>
                                    {checkpoint.checkpoint}
                                </span>

                            ))}

                        </div>
                    </Grid>
                    {AlertDetails.violationtime > 0 ?
                        <> <Grid item lg={4} md={5} sm={12}>
                            <Typography className='device_view_text label_color ' sx={{ pb: '16% !important','@media (max-width: 600px)': {pb:"5px"} }}>
                                Duration
                            </Typography>
                            <span className="alert_stage_text critical_text " >{hoursConverter(AlertDetails.violationtime)}</span>
                        </Grid>
                        </> :
                        <Grid item lg={5} md={5} sm={12}>
                            <Typography className='device_view_text label_color ' sx={{ pb: '5% !important', pt: '6% !important' ,'@media (max-width: 600px)': {pb:"5px"}}}>
                                Violation
                            </Typography>
                            <div className="violation_box">
                                {AlertDetails?.violationreason?.map((item, index) => (
                                    <span className="alert_stage_text critical_text alert_span" key={index}>
                                        {item.check_point_name}
                                    </span>
                                ))}
                            </div>
                        </Grid>
                    }
                    {AlertDetails && AlertDetails.violationreason && AlertDetails.violationreason.length > 0 && AlertDetails.violationreason[0].lastseenat ? (
                        <Grid item lg={1.3} md={1.3} sm={12}>
                            <Typography className='device_view_text label_color' sx={{ pb: '28% !important', pt: '20% !important','@media (max-width: 600px)': {pb:"5px"} }}>
                                Duration
                            </Typography>
                            <span className="alert_stage_text critical_text">{unAuthDuration}</span>
                        </Grid>
                    ) : (
                        <></>
                    )}



                </Grid>
                : <></>}
        </div>
    )
}