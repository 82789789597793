import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSites } from "../../redux/actions/UserRoleActions";
import { MenuProps } from "../Styles";
import { PersonnelListTable } from "../tracking/personnel/PersonnelListTable";
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

export const DashboardPersonnels = ({removeWidget,id}) => {
    const dispatch = useDispatch();
    const [isDarkMode, setDarkMode] = useState(false);

    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const siteData = useSelector(state => state.userRole.siteData);

    useEffect(() => {

        dispatch(fetchSites());
    }, []);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
        }
    }, [siteData]);
    return (
        <>
            <Grid container sx={{ mt: 4 }} columnSpacing={{ lg: 4, md: 1 }} rowSpacing={{ sm: 3, xs: 3 }}>
                <Grid item container lg={12} md={12} sm={12}>
                    <Grid item lg={3} md={3} sm={12} xs={12} className="mtdash4">
                        <Typography className='headersubhead light_text htl'>Personnel List</Typography>
                    </Grid>
                    <Grid item  lg={7} md={7} sm={10} xs={10} 
                    // style={{ dispaly:'flex',justfyContent:'end !important' }}
                    >
                        <Grid  item className="mtdash4" 
                        // style={{ justfyContent:'end !important'}}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Grid item sx={{ mt: 2 }}>
                                <Typography sx={{ textAlign: 'center' }}
                                    className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                    Sites :
                                </Typography>
                            </Grid>
                            <Grid item lg={4} sm={5} xs={5} md={4}>
                                <Select
                                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                    sx={{
                                        color: "#161616", ml: 1, minWidth: 140,
                                        textAlign:'left',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: "#161616",
                                        }
                                    }}

                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={"headerSelect select_light"}
                                    value={selectedSites}
                                    onChange={(e) => setSelectedSites(e.target.value)}
                                >
                                    <MenuItem value={''}>All</MenuItem>
                                    {sites.map((site, index) => (
                                        <MenuItem
                                            key={index}
                                            value={site.organizationId}>
                                            {site.organizationName}
                                        </MenuItem>
                                    ))}


                                </Select>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={1} sm={1} lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
                            <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
                        </IconButton>
                    </Grid>
                </Grid>

                {/* <Grid Container sx={{ mt: 5 }}> */}
                    <Grid item lg={12} md={12}>
                        <PersonnelListTable
                            isDarkMode={isDarkMode}
                            selectedSites={selectedSites}
                        //searchWord={search}
                        />
                    </Grid>
                {/* </Grid> */}
            </Grid>

        </>
    )
}