import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchLevel } from "../../../../redux/actions/SharableActions";
import { fetchSites } from "../../../../redux/actions/UserRoleActions";
import { Layout } from "../../../layout/Layout";
import { SearchIconWrapper, StyledInputBase } from "../../../Styles";
import { SmallSearch } from "../../../styles/SerachStyles";
import SearchIcon from "@mui/icons-material/Search";

export const AddCamera = ({}) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const siteData = useSelector((state) => state.userRole.siteData);
  const LevelData = useSelector((state) => state.sharables.LayerList);

  const [selectedSites, setSelectedSites] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
  const [sites, setSites] = useState([]);
  const [levels, setLevels] = useState([]);
  useEffect(() => {
    dispatch(fetchSites());
  }, []);
  useEffect(() => {
    if (
      !siteData.isFetching &&
      siteData.payload &&
      siteData.payload.success === true
    ) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp,
        };
      });
      setSites(temp);
      const siteId = sessionStorage.getItem("user_site_id");
      // setSelectedSites(siteId)
      if (siteId != 0) {
        setSelectedSites(siteId);
      }
    }
  }, [siteData]);
  useEffect(() => {
    if (
      !LevelData.isFetching &&
      LevelData.payload &&
      LevelData.payload.success === true
    ) {
      const levelList = LevelData.payload.data.LevelList;
      setLevels(levelList);
    }
  }, [LevelData]);
  useEffect(() => {
    dispatch(fetchLevel(selectedSites));
  }, [selectedSites]);

  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"videosurveillance"}
      activeMenuKey={"videosurveillance"}
      isCreate={true}
    >
      <div
        style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%" }}
        className=""
      >
        <Grid container>
          <Grid item lg={12} sm={12} xs={12} md={12} sx={{ ml: 2 }}>
            <Typography
              sx={{ textAlign: "left" }}
              className={"headersubhead light_text"}
            >
              Camera
            </Typography>
          </Grid>
          <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Site
            </InputLabel>
            <Select
              className={"form_field form_field_light noml"}
              value={selectedSites}
              onChange={(e) => setSelectedSites(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                if (selected == "") {
                  return <span style={{ color: "#a2a2a2" }}>Site</span>;
                }
                const selectedSite = sites.find(
                  (site) => site.organizationId === selected
                );
                return selectedSite ? selectedSite.organizationName : "";
              }}
            >
              {sites.length > 0 ? (
                sites.map((site, index) => (
                  <MenuItem key={index} value={site.organizationId}>
                    {site.organizationName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Data Found</MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light form_text_mob"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Level
            </InputLabel>
            <Select
              className={"form_field_right form_field_light"}
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                if (selected == "") {
                  return <span style={{ color: "#a2a2a2" }}>Level</span>;
                }
                const selectedLevel = levels.find(
                  (level) => level.layerId === selected
                );
                return selectedLevel ? selectedLevel.layerName : "";
              }}
            >
              {levels.length > 0 ? (
                levels.map((level, index) => (
                  <MenuItem key={index} value={level.layerId}>
                    {level.layerName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Data Found</MenuItem>
              )}
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={11.2}
            md={11.2}
            sx={{ mt: 3 }}
            className={"form_field form_field_light noml"}
          >
            <div className="drag_box">
              <Grid container>
                <div
                  style={{
                    position: "sticky",
                    top: 0, // Stick to the top

                    zIndex: 3,
                    backgroundColor: "#ffffff",
                  }}
                >
                  <InputLabel
                    className={"form_text_light"}
                    sx={{
                      color: "black",
                      ml: 0,
                      mb: 4,
                    }}
                  >
                    Device List
                  </InputLabel>
                  <SmallSearch theme={theme}>
                    <SearchIconWrapper>
                      <SearchIcon
                        sx={{ color: "#8F8F91" }}
                        fontSize="medium"
                        className="mb"
                      />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Camera Id"
                      inputProps={{ "aria-label": "search" }}
                      // onChange={(e) => setSearch(e.target.value)}
                      // value={search}
                    />
                  </SmallSearch>
                </div>
                
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};
