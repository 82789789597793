import { userActionType } from "../actions/UserActions"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    userData: dataTemplate,
    adduser: dataTemplate,
    userList:dataTemplate,
    deleteUser:dataTemplate,
    updateUser:dataTemplate,
    uploadPic:dataTemplate,
}
const UserReducer = (state = initialData, action) => {
    switch (action.type) {
        //fetch single user
        case userActionType.FETCH_USER:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case userActionType.FETCH_USER_SUCCESS:
            return {

                ...state,
                userData: {
                    ...state.userData,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case userActionType.FETCH_USER_ERROR:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //add  user
        case userActionType.ADD_USER:
            return {
                ...state,
                adduser: {
                    ...state.adduser,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case userActionType.ADD_USER_SUCCESS:
            return {

                ...state,
                adduser: {
                    ...state.adduser,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case userActionType.ADD_USER_ERROR:
            return {
                ...state,
                adduser: {
                    ...state.adduser,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case userActionType.CLEAR_ADD_USER_STATUS:
                return {
                    ...state,
                    adduser: {
                        ...state.adduser,
                        isFetching: false,
                        payload: [],
                        error: false
                    }
                }
               //fetch  userlist
        case userActionType.FETCH_USER_LIST:
            return {
                ...state,
                userList: {
                    ...state.userList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case userActionType.FETCH_USER_LIST_SUCCESS:
            return {

                ...state,
                userList: {
                    ...state.userList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case userActionType.FETCH_USER_LIST_ERROR:
            return {
                ...state,
                userList: {
                    ...state.userList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
                 //delete user
        case userActionType.DELETE_USER:
            return {
                ...state,
                deleteUser: {
                    ...state.deleteUser,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case userActionType.DELETE_USER_SUCCESS:
            return {

                ...state,
                deleteUser: {
                    ...state.deleteUser,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case userActionType.DELETE_USER_ERROR:
            return {
                ...state,
                deleteUser: {
                    ...state.deleteUser,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case userActionType.CLEAR_DELETE_USER_STATUS:
            return {
                ...state,
                deleteUser: {
                    ...state.deleteUser,
                    isFetching: false,
                    payload: [],
                    error: false,
                    errorMessage: ''
                }
            }
                      //update user
        case userActionType.UPDATE_USER:
            return {
                ...state,
                updateUser: {
                    ...state.updateUser,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case userActionType.UPDATE_USER_SUCCESS:
            return {

                ...state,
                updateUser: {
                    ...state.updateUser,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case userActionType.UPDATE_USER_ERROR:
            return {
                ...state,
                updateUser: {
                    ...state.updateUser,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case userActionType.CLEAR_USER_UPDATE_STATUS:
                return {
                    ...state,
                    updateUser: {
                        ...state.updateUser,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
                       //upload user pic
        case userActionType.UPLOAD_USER_PIC:
            return {
                ...state,
                uploadPic: {
                    ...state.uploadPic,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case userActionType.UPLOAD_USER_PIC_SUCCESS:
            return {

                ...state,
                uploadPic: {
                    ...state.uploadPic,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case userActionType.UPLOAD_USER_PIC_ERROR:
            return {
                ...state,
                uploadPic: {
                    ...state.uploadPic,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        default:
            return state
    }

}
export default UserReducer;