import { Alert, Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import { ExportModalstyle } from '../styles/ModalStyle';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { BorderButton, ExportIconButton, StyledCreateSmallButton } from '../styles/ButtonStyles';
export const ExportModal = ({ openModal, handleModalClose, modalHead, handleExporttype,confirmExport,exportModalAlert }) => {
    return (
        <>
            <Modal
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={ExportModalstyle}>
                    {exportModalAlert &&
                    <Alert variant="outlined" severity="error">Choose type of Export</Alert>

                    }
                    <IconButton>
                        <img src={require('../../assets/icons/export_modal_icon.png')} alt='export icon' />
                    </IconButton>
                    <Typography className="modalhead">
                        {modalHead}
                    </Typography>
                    <div style={{ display: 'flex', marginTop: 3 }} >
                        <ExportIconButton
                            sx={{ ml: '-6% !important' }}
                            onClick={() => handleExporttype('excel')}
                        >
                            <img src={require('../../assets/icons/excel.png')} alt='excel' />
                        </ExportIconButton>
                        <ExportIconButton sx={{ ml: "15% !important" }}
                            onClick={() => handleExporttype('pdf')}
                        >
                            <img src={require('../../assets/icons/export_pdf.png')} alt='pdf' />
                        </ExportIconButton>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                        <BorderButton
                            sx={{ mr: 2, mt: 3 }} variant="outlined"
                            onClick={handleModalClose}
                        >
                            <HighlightOffRoundedIcon
                                sx={{ fontSize: '20px', mr: '5px', color: "#161616" }} />
                            Cancel
                        </BorderButton>
                        <StyledCreateSmallButton
                            startIcon={<img src={require("../../assets/icons/export_button_icon.png")} alt="export" />}
                            sx={{ mt: 3 }}
                            variant="contained"
                          onClick={confirmExport}
                        >
                            {/* <ThumbUpOffAltIcon sx={{ fontSize: '20px', mr: '5px', color: "#ffffff" }} /> */}
                            {/* {assignTag ? 'Un-Assign' : 'Assign'} */}
                            Export
                        </StyledCreateSmallButton>

                    </div>
                </Box>
            </Modal>
        </>
    )
}