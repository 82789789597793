import React from "react";
import { TbDeviceCctv } from "react-icons/tb";

export const DraggableCamera = ({ id, name, machine_id, setCameraPosition }) => {
  return (
    <div className="cam_box cursor-pointer" onClick={() => { setCameraPosition({ id, name, machine_id }) }}>
      <span className="camSpace">
        <TbDeviceCctv color="#FFAC0B" size="30px" />
      </span>
      <span>
        {name}</span>
    </div>
  );
};
