import React, { useEffect, useState } from "react";
import { Layout } from "../../../layout/Layout";
import { fetchSites } from "../../../../redux/actions/UserRoleActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchBlock,
  fetchLevel,
} from "../../../../redux/actions/SharableActions";
import { CameraFilter } from "../CameraFilter";
import { Grid, Typography } from "@mui/material";
import { StyledCreateButton } from "../../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { ViewFilter } from "./ViewFilter";
import { ViewMap } from "./ViewMap";
import { DragAndDrop } from "./Drag&Drop/DragAndDrop";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const CameraView = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");

  const dispatch = useDispatch();
  const siteData = useSelector((state) => state.userRole.siteData);
  const LevelData = useSelector((state) => state.sharables.LayerList);
  const BlockData = useSelector((state) => state.sharables.BlockList);
  const mapData = useSelector(
    (state) => state.surveillance.mapData
  );
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState("");
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [isDrag, setIsDrag] = useState(false);
  const [search, setSearch] = useState("")
  useEffect(() => {
    dispatch(fetchSites());
  }, []);
  useEffect(() => {
    if (
      !siteData.isFetching &&
      siteData.payload &&
      siteData.payload.success === true
    ) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp,
        };
      });
      setSites(temp);
     // setSelectedSites(temp[0].organizationId);
      const siteId = sessionStorage.getItem("user_site_id");
      // setSelectedSites(siteId)
      if (siteId != 0) {
        setSelectedSites(siteId);
      }
      else{
        setSelectedSites(temp[0]?.organizationId)

      }
    }
   
   
  }, [siteData]);
  useEffect(()=>{
    if (
      !BlockData.isFetching &&
      BlockData.payload &&
      BlockData.payload.success === true
    ) {
      const BlockList = BlockData.payload.data.BlockList;
      setBlocks(BlockList);
      if(BlockList.length > 0){
      setSelectedBlocks(BlockList[0]?.roomId)}
      else
      setSelectedBlocks("")
    }
  },[BlockData])
  useEffect(()=>{
    if (
      !LevelData.isFetching &&
      LevelData.payload &&
      LevelData.payload.success === true
    ) {
      const levelList = LevelData.payload.data.LevelList;
      setLevels(levelList);
      setSelectedLevel(levelList[0]?.layerId);
    }
  },[LevelData])
  useEffect(() => {
    dispatch(fetchLevel(selectedSites));
    if (selectedSites && selectedLevel) {
      const data = {
        siteId: selectedSites,
        levelId: selectedLevel,
      };
      dispatch(fetchBlock(data));
    }
  }, [selectedSites, selectedLevel]);
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };

  const handleSites = (value) => {
    setSelectedSites(value);
  };
  const handleLevels = (value) => {
    setSelectedLevel(value);
  };
  const handleBlocks = (value) => {
    setSelectedBlocks(value);
  };
  const handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };
  const handleAdd = () => {
    setIsDrag(!isDrag);
  };
  const handleSearch = (word) => {
    setSearch(word);
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"videosurveillance"}
      activeMenuKey={"videosurveillance"}
      isCreate={true}
    >
      <div
        style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%" }}
        className=""
      >
        <Grid container>
          <Grid item lg={6} sm={6} xs={6} md={6} sx={{ ml: 2 }}>
            <Typography
              sx={{ textAlign: "left" }}
              className={"headersubhead light_text"}
            >
              Camera
            </Typography>
          </Grid>
          <Grid
            item
            lg={5.7}
            sm={6}
            xs={6}
            md={5.7}
            container
            className="alignR"
            // sx={{'@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important',mt:'5px !important' }}}
          >
            <StyledCreateButton
              disabled={isDrag}
              variant="contained"
              disableRipple
              startIcon={<MdOutlineAddToPhotos color="white" />}
              onClick={handleAdd}
            >
              Add Device
            </StyledCreateButton>
          </Grid>
        </Grid>
      </div>
      <ViewFilter
        selectedSites={selectedSites}
        handleSites={handleSites}
        sites={sites}
        levels={levels}
        selectedLevel={selectedLevel}
        handleLevels={handleLevels}
        blocks={blocks}
        selectedBlocks={selectedBlocks}
        handleBlocks={handleBlocks}
        handleSelectedStatus={handleSelectedStatus}
        selectedStatus={selectedStatus}
        isDrag={isDrag}
      />

      {!isDrag ?
        levels.length > 0 ?
        <ViewMap selectedSites={selectedSites}
          selectedLevel={selectedLevel}
          selectedBlocks={selectedBlocks}

          search={search} />
          : <Grid
          item
          lg={11.8}
          sm={11.8}
          xs={11.8}
          sx={{ mt: "6% !important", display: 'flex', justifyContent: 'center' }}
        >
          No Maps Found
        </Grid>
        :
        levels.length > 0 ?
        <DragAndDrop
          selectedSites={selectedSites}
          selectedLevel={selectedLevel}
          selectedBlocks={selectedBlocks}

          search={search}
          handleSearch={handleSearch}
          handleAdd={handleAdd}
        />
        : <Grid
          item
          lg={11.8}
          sm={11.8}
          xs={11.8}
          sx={{ mt: "6% !important", display: 'flex', justifyContent: 'center' }}
        >
          No Maps Found
        </Grid>
      }
    </Layout>
  );
};
