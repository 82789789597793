export const sharableActionType ={
    FETCH_LEVEL : "FETCH_LEVEL",
    FETCH_LEVEL_SUCCESS : "FETCH_LEVEL_SUCCESS",
    FETCH_LEVEL_ERROR : "FETCH_LEVEL_ERROR",

    FETCH_BLOCK : "FETCH_BLOCK",
    FETCH_BLOCK_SUCCESS : "FETCH_BLOCK_SUCCESS",
    FETCH_BLOCK_ERROR : "FETCH_BLOCK_ERROR",


}
//fetch layer list
export const fetchLevel = (id) => {


    return {
        type: sharableActionType.FETCH_LEVEL,
        id:id,
    }
}
export const fetchLevelSuccess = (data) => {
    return {
        type: sharableActionType.FETCH_LEVEL_SUCCESS,
        payload: data
    }
}
export const fetchLevelError = (error) => {
    return {
        type: sharableActionType.FETCH_LEVEL_ERROR,
        error
    }
}

//fetch block list
export const fetchBlock = (data) => {


    return {
        type: sharableActionType.FETCH_BLOCK,
        data:data,
    }
}
export const fetchBlockSuccess = (data) => {
    return {
        type: sharableActionType.FETCH_BLOCK_SUCCESS,
        payload: data
    }
}
export const fetchBlockError = (error) => {
    return {
        type: sharableActionType.FETCH_BLOCK_ERROR,
        error
    }
}