import { userRoleActionType } from "../actions/UserRoleActions";

const dataTemplate ={
    isFetching:false,
    error:false,
    payload:[],
    errorMessage:""
}
const initialData ={
    roleData:dataTemplate,
    addUserRole:dataTemplate,
    roleList:dataTemplate,
    siteData:dataTemplate,
    roleDropdown:dataTemplate,
    editUserRole:dataTemplate,
    getInTouch : dataTemplate,
    contactUs : dataTemplate
}
export const UserRoleReducer =(state = initialData,action) => {
    switch(action.type){
        case userRoleActionType.FETCH_USER_ROLE:
            return{
                ...state,
                roleData:{
                    ...state.roleData,
                    isFetching:true,
                    error:false,
                    errorMessage:'',
                    payload:[]
                }
            }
            case userRoleActionType.FETCH_USER_ROLE_SUCCESS:
            
                return{
                    ...state,
                    roleData:{
                        ...state.roleData,
                        isFetching:false,
                        error:false,
                        payload:action.data
                    }
                }
            case userRoleActionType.FETCH_USER_ROLE_ERROR:
                return{
                    ...state,
                    roleData:{
                        ...state.roleData,
                        isFetching:false,
                        payload:[],
                        error:true,
                        errorMessage:action.error
                    }
                }
                case userRoleActionType.ADD_USER_ROLE:
                    return{
                        ...state,
                        addUserRole:{
                            ...state.addUserRole,
                            isFetching:true,
                            error:false,
                            errorMessage:'',
                            payload:[]
                        }
                    }
                    case userRoleActionType.ADD_USER_ROLE_SUCCESS:
                    
                        return{
                            ...state,
                            addUserRole:{
                                ...state.addUserRole,
                                isFetching:false,
                                error:false,
                                payload:action.data
                            }
                        }
                    case userRoleActionType.ADD_USER_ROLE_ERROR:
                        return{
                            ...state,
                            addUserRole:{
                                ...state.addUserRole,
                                isFetching:false,
                                payload:[],
                                error:true,
                                errorMessage:action.error
                            }
                        }

                        case userRoleActionType.FETCH_USER_ROLE_LIST:
                            return{
                                ...state,
                                roleList:{
                                    ...state.roleList,
                                    isFetching:true,
                                    error:false,
                                    errorMessage:'',
                                    payload:[]
                                }
                            }
                            case userRoleActionType.FETCH_USER_ROLE_LIST_SUCCESS:
                            
                                return{
                                    ...state,
                                    roleList:{
                                        ...state.roleList,
                                        isFetching:false,
                                        error:false,
                                        payload:action.data
                                    }
                                }
                            case userRoleActionType.FETCH_USER_ROLE_LIST_ERROR:
                                return{
                                    ...state,
                                    roleList:{
                                        ...state.roleList,
                                        isFetching:false,
                                        payload:[],
                                        error:true,
                                        errorMessage:action.error
                                    }
                                }
                //fetch sites
                case userRoleActionType.FETCH_SITE:
                    return{
                        ...state,
                        siteData:{
                            ...state.siteData,
                            isFetching:true,
                            error:false,
                            errorMessage:'',
                            payload:[]
                        }
                    }
                    case userRoleActionType.FETCH_SITE_SUCCESS:
                    
                        return{
                            ...state,
                            siteData:{
                                ...state.siteData,
                                isFetching:false,
                                error:false,
                                payload:action.data
                            }
                        }
                    case userRoleActionType.FETCH_SITE_ERROR:
                        return{
                            ...state,
                            siteData:{
                                ...state.siteData,
                                isFetching:false,
                                payload:[],
                                error:true,
                                errorMessage:action.error
                            }
                        }
                        //fetch role dropdown
                case userRoleActionType.FETCH_ROLE_DROPDOWN_LIST:
                    return{
                        ...state,
                        roleDropdown:{
                            ...state.roleDropdown,
                            isFetching:true,
                            error:false,
                            errorMessage:'',
                            payload:[]
                        }
                    }
                    case userRoleActionType.FETCH_ROLE_DROPDOWN_LIST_SUCCESS:
                    
                        return{
                            ...state,
                            roleDropdown:{
                                ...state.roleDropdown,
                                isFetching:false,
                                error:false,
                                payload:action.data
                            }
                        }
                    case userRoleActionType.FETCH_ROLE_DROPDOWN_LIST_ERROR:
                        return{
                            ...state,
                            roleDropdown:{
                                ...state.roleDropdown,
                                isFetching:false,
                                payload:[],
                                error:true,
                                errorMessage:action.error
                            }
                        }
                                  //edit user role
                case userRoleActionType.EDIT_USER_ROLE:
                    return{
                        ...state,
                        editUserRole:{
                            ...state.editUserRole,
                            isFetching:true,
                            error:false,
                            errorMessage:'',
                            payload:[]
                        }
                    }
                    case userRoleActionType.EDIT_USER_ROLE_SUCCESS:
                    
                        return{
                            ...state,
                            editUserRole:{
                                ...state.editUserRole,
                                isFetching:false,
                                error:false,
                                payload:action.data
                            }
                        }
                    case userRoleActionType.EDIT_USER_ROLE_ERROR:
                        return{
                            ...state,
                            editUserRole:{
                                ...state.editUserRole,
                                isFetching:false,
                                payload:[],
                                error:true,
                                errorMessage:action.error
                            }
                        }
                        case userRoleActionType.CLEAR_EDIT_USER_ROLE_STATUS:
                        return{
                            ...state,
                            editUserRole:{
                                ...state.editUserRole,
                                isFetching:false,
                                payload:[],
                                error:false,
                               // errorMessage:action.error
                            }
                        }
                                             //getInTouch
                case userRoleActionType.GET_IN_TOUCH:
                    return{
                        ...state,
                        getInTouch:{
                            ...state.getInTouch,
                            isFetching:true,
                            error:false,
                            errorMessage:'',
                            payload:[]
                        }
                    }
                    case userRoleActionType.GET_IN_TOUCH_SUCCESS:
                    
                        return{
                            ...state,
                            getInTouch:{
                                ...state.getInTouch,
                                isFetching:false,
                                error:false,
                                payload:action.data
                            }
                        }
                    case userRoleActionType.GET_IN_TOUCH_ERROR:
                        return{
                            ...state,
                            getInTouch:{
                                ...state.getInTouch,
                                isFetching:false,
                                payload:[],
                                error:true,
                                errorMessage:action.error
                            }
                        }
                        case userRoleActionType.CLEAR_GET_IN_TOUCH_STATUS:
                        return{
                            ...state,
                            getInTouch:{
                                ...state.getInTouch,
                                isFetching:false,
                                payload:[],
                                error:false,
                               // errorMessage:action.error
                            }
                        }
                                              //contact us
                case userRoleActionType.CONTACT_US:
                    return{
                        ...state,
                        contactUs:{
                            ...state.contactUs,
                            isFetching:true,
                            error:false,
                            errorMessage:'',
                            payload:[]
                        }
                    }
                    case userRoleActionType.CONTACT_US_SUCCESS:
                    
                        return{
                            ...state,
                            contactUs:{
                                ...state.contactUs,
                                isFetching:false,
                                error:false,
                                payload:action.data
                            }
                        }
                    case userRoleActionType.CONTACT_US_ERROR:
                        return{
                            ...state,
                            contactUs:{
                                ...state.contactUs,
                                isFetching:false,
                                payload:[],
                                error:true,
                                errorMessage:action.error
                            }
                        }
                        case userRoleActionType.CLEAR_CONTACT_US_STATUS:
                        return{
                            ...state,
                            contactUs:{
                                ...state.contactUs,
                                isFetching:false,
                                payload:[],
                                error:false,
                               // errorMessage:action.error
                            }
                        }
        default :
            return state;
    }
}