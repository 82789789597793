import { organisationActionType } from "../actions/OrganisationActions"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    organisationType: dataTemplate,
    addOrganisation: dataTemplate,
    organasationList: dataTemplate,
    singleOrganisation: dataTemplate,
    orgBlocks: dataTemplate,
    deleteOrg: dataTemplate,
    updateOrg: dataTemplate,
    orgDropdown: dataTemplate,
    addSites: dataTemplate,
    editSites: dataTemplate,
    deleteSites: dataTemplate,
    singleLevel: dataTemplate,
    addTelegram: dataTemplate,
    telilist:dataTemplate,
    singleTeligram:dataTemplate
}
const OrganisationReducer = (state = initialData, action) => {
    switch (action.type) {
        //fetorg type
        case organisationActionType.FETCH_ORGANISATION_TYPE:
            return {
                ...state,
                organisationType: {
                    ...state.organisationType,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.FETCH_ORGANISATION_TYPE_SUCCESS:
            return {

                ...state,
                organisationType: {
                    ...state.organisationType,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.FETCH_ORGANISATION_TYPE_ERROR:
            return {
                ...state,
                organisationType: {
                    ...state.organisationType,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetorg block
        case organisationActionType.FETCH_ORGANISATION_BLOCKS:
            return {
                ...state,
                orgBlocks: {
                    ...state.orgBlocks,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.FETCH_ORGANISATION_BLOCKS_SUCCESS:
            return {

                ...state,
                orgBlocks: {
                    ...state.orgBlocks,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.FETCH_ORGANISATION_BLOCKS_ERROR:
            return {
                ...state,
                orgBlocks: {
                    ...state.orgBlocks,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //add new org
        case organisationActionType.ADD_NEW_ORGANISATION:
            return {
                ...state,
                addOrganisation: {
                    ...state.addOrganisation,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.ADD_NEW_ORGANISATION_SUCCESS:
            return {

                ...state,
                addOrganisation: {
                    ...state.addOrganisation,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.ADD_NEW_ORGANISATION_ERROR:
            return {
                ...state,
                addOrganisation: {
                    ...state.addOrganisation,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch org list
        case organisationActionType.FETCH_ORGANISATION_LIST:
            return {
                ...state,
                organasationList: {
                    ...state.organasationList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.FETCH_ORGANISATION_LIST_SUCCESS:
            return {

                ...state,
                organasationList: {
                    ...state.organasationList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.FETCH_ORGANISATION_LIST_ERROR:
            return {
                ...state,
                organasationList: {
                    ...state.organasationList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch single org
        case organisationActionType.FETCH_SINGLE_ORGANISATION:
            return {
                ...state,
                singleOrganisation: {
                    ...state.singleOrganisation,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.FETCH_SINGLE_ORGANISATION_SUCCESS:
            return {

                ...state,
                singleOrganisation: {
                    ...state.singleOrganisation,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.FETCH_SINGLE_ORGANISATION_ERROR:
            return {
                ...state,
                singleOrganisation: {
                    ...state.singleOrganisation,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //delete org 
        case organisationActionType.DELETE_ORGANISATION:
            return {
                ...state,
                deleteOrg: {
                    ...state.deleteOrg,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.DELETE_ORGANISATION_SUCCESS:
            return {

                ...state,
                deleteOrg: {
                    ...state.deleteOrg,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.DELETE_ORGANISATION_ERROR:
            return {
                ...state,
                deleteOrg: {
                    ...state.deleteOrg,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case organisationActionType.CLEAR_DELETE_ORGANISATION_STATUS:
            return {
                ...state,
                deleteOrg: {
                    ...state.deleteOrg,
                    isFetching: false,
                    payload: [],
                    error: false,
                    errorMessage: ''
                }
            }
        //fetch org dropdown
        case organisationActionType.FETCH_ORGANISATION_DROPDOWN:
            return {
                ...state,
                orgDropdown: {
                    ...state.orgDropdown,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.FETCH_ORGANISATION_DROPDOWN_SUCCESS:
            return {

                ...state,
                orgDropdown: {
                    ...state.orgDropdown,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.FETCH_ORGANISATION_DROPDOWN_ERROR:
            return {
                ...state,
                orgDropdown: {
                    ...state.orgDropdown,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //update org 
        case organisationActionType.UPDATE_ORGANISATION:
            return {
                ...state,
                updateOrg: {
                    ...state.updateOrg,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.UPDATE_ORGANISATION_SUCCESS:
            return {

                ...state,
                updateOrg: {
                    ...state.updateOrg,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.UPDATE_ORGANISATION_ERROR:
            return {
                ...state,
                updateOrg: {
                    ...state.updateOrg,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case organisationActionType.ADD_SITES:
            return {
                ...state,
                addSites: {
                    ...state.addSites,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.ADD_SITES_SUCCESS:
            return {

                ...state,
                addSites: {
                    ...state.addSites,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.ADD_SITES_ERROR:
            return {
                ...state,
                addSites: {
                    ...state.addSites,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case organisationActionType.EDIT_SITES:
            return {
                ...state,
                editSites: {
                    ...state.editSites,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.EDIT_SITES_SUCCESS:
            return {

                ...state,
                editSites: {
                    ...state.editSites,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.EDIT_SITES_ERROR:
            return {
                ...state,
                editSites: {
                    ...state.editSites,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case organisationActionType.DELETE_SITES:
            return {
                ...state,
                deleteSites: {
                    ...state.deleteSites,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.DELETE_SITES_SUCCESS:
            return {

                ...state,
                deleteSites: {
                    ...state.deleteSites,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.DELETE_SITES_ERROR:
            return {
                ...state,
                deleteSites: {
                    ...state.deleteSites,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case organisationActionType.DELETE_SITES_CLEAR_STATUS:
            return {
                ...state,
                deleteSites: {
                    ...state.deleteSites,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case organisationActionType.FETCH_SINGLE_LEVEL:
            return {
                ...state,
                singleLevel: {
                    ...state.singleLevel,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.FETCH_SINGLE_LEVEL_SUCCESS:
            return {

                ...state,
                singleLevel: {
                    ...state.singleLevel,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.FETCH_SINGLE_LEVEL_ERROR:
            return {
                ...state,
                singleLevel: {
                    ...state.singleLevel,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }

        case organisationActionType.ADD_ORG_TELEGRAM:
            return {
                ...state,
                addTelegram: {
                    ...state.addTelegram,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case organisationActionType.ADD_ORG_TELEGRAM_SUCCESS:
            return {

                ...state,
                addTelegram: {
                    ...state.addTelegram,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case organisationActionType.ADD_ORG_TELEGRAM_ERROR:
            return {
                ...state,
                addTelegram: {
                    ...state.addTelegram,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case organisationActionType.CLEAR_ADD_ORG_TELEGRAM_STATUS:
            return {
                ...state,
                addTelegram: {
                    ...state.addTelegram,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }

            case organisationActionType.FETCH_ORG_TELEGRAM:
                return {
                    ...state,
                    telilist: {
                        ...state.telilist,
                        isFetching: true,
                        payload: [],
                        error: false
                    }
                }
            case organisationActionType.FETCH_ORG_TELEGRAM_SUCCESS:
                return {
    
                    ...state,
                    telilist: {
                        ...state.telilist,
                        isFetching: false,
                        error: false,
                        payload: action.payload
                    }
                }
            case organisationActionType.FETCH_ORG_TELEGRAM_ERROR:
                return {
                    ...state,
                    telilist: {
                        ...state.telilist,
                        isFetching: false,
                        payload: [],
                        error: true,
                        errorMessage: action.error
                    }
                }
          
                case organisationActionType.FETCH_SINGLE_ORG_TELEGRAM:
                    return {
                        ...state,
                        singleTeligram: {
                            ...state.singleTeligram,
                            isFetching: true,
                            payload: [],
                            error: false
                        }
                    }
                case organisationActionType.FETCH_SINGLE_ORG_TELEGRAM_SUCCESS:
                    return {
        
                        ...state,
                        singleTeligram: {
                            ...state.singleTeligram,
                            isFetching: false,
                            error: false,
                            payload: action.payload
                        }
                    }
                case organisationActionType.FETCH_SINGLE_ORG_TELEGRAM_ERROR:
                    return {
                        ...state,
                        singleTeligram: {
                            ...state.singleTeligram,
                            isFetching: false,
                            payload: [],
                            error: true,
                            errorMessage: action.error
                        }
                    }
        default:
            return state
    }

}
export default OrganisationReducer;