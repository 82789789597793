import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Layout } from '../../../layout/Layout';
import { useDispatch } from 'react-redux';
import { fetchSingleDevice } from '../../../../redux/actions/DeviceActions';
import { useSelector } from 'react-redux';
export const ListenerView = () => {
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
    const [deviceDetails, setDeviceDeatails] = useState({});
    const dispatch = useDispatch();
    const deviceData = useSelector(state => state.device.singleDevice);
    useEffect(() => {
        const id = sessionStorage.getItem('viewListenerId')
        dispatch(fetchSingleDevice(id));
    }, [])
    useEffect(() => {
        if (!deviceData.isFetching && deviceData.payload && deviceData.payload.success === true) {
            const tempDevice = deviceData.payload.data;
            const timestamp = tempDevice.last_reported_time * 1000; // Convert seconds to milliseconds
            const lastReportedDate = new Date(timestamp).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            });
            const lastReportedTime = new Date(timestamp).toLocaleTimeString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            });
            const result = {
                ...tempDevice,
                lastReportedDate: tempDevice.last_reported_time == 0 || tempDevice.last_reported_time == null ? "NA" : lastReportedDate,
                // lastReportedTime: tempDevice.last_reported_time == 0 ? "NA" : lastReportedTime,
            };
            setDeviceDeatails(result);
        }
    }, [deviceData]);
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);
    }
    return (
        <Layout
            isDarkMode={isDarkMode}
            handleDarkmode={handleDarkmode}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"tracking"}
            activeMenuKey={"tracking"}
            isCreate={true}
            activeSubtab={"four"}
        >
            {deviceData.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
                <Grid container spacing={2} sx={{ mt: 3, pb: 5, ml: '3% !important', '@media (max-width: 600px)': { ml: '0% !important' } }}
                    className={isDarkMode ? "table_dark " : "table_light"} >
                    <Grid item lg={11}>
                        <Typography
                            className='device_view_head'>
                            {deviceDetails.type === 11001 ? "Listener " : "Bridge"}
                        </Typography>
                    </Grid>
                    <Grid item lg={11} sx={{ mt: 2 }}>
                        <div className='device_view'>
                            <Typography
                                className='device_view_head'
                                sx={{ mb: 5 }}>{deviceDetails.tag_name}</Typography>
                            <Grid container>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "32px" }}>
                                        <Typography className='device_view_text label_color rowdiv_label'>Device Name</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {deviceDetails.tag_name}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Last reported Date & Time
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {deviceDetails.lastReportedDate}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Device LongId</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {deviceDetails.id}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Device Mesh Id
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {deviceDetails.mesh_id}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Device Type</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {deviceDetails.type}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            {deviceDetails.type === 11001 ? "Device Capability" : "IP Address"}
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {deviceDetails.type === 11001 ? deviceDetails.device_capability : deviceDetails.ip_address}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>Device Type</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {deviceDetails.hw_version}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv borderbottom rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Software Version
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {deviceDetails.sw_version}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv  rowdiv_mr'  >
                                        <Typography className='device_view_text label_color rowdiv_label'>CSR Firmware Version</Typography>
                                        <Typography className='device_view_text content_color rowdiv_text'>
                                            {deviceDetails.fm_version}

                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                                    <div className='rowdiv  rowdiv_ml' >
                                        <Typography className='device_view_text label_color rowdiv_label'>
                                            Device GUID
                                        </Typography>
                                        <Typography className='device_view_text content_color rowdiv_text '>
                                            {deviceDetails.deviceuuid}

                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>




                    {/* 
                <Grid item lg={11} sx={{ mt: 2 }}>
                    <div className='device_view'>
                        <Typography
                            className='device_view_head'
                            sx={{ mb: 5 }}>{deviceDetails.tag_name}</Typography>




                        <Grid container>
                            <Grid item sx={{ pb: 7, }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Device Name</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.tag_name}
                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3.5} md={3.5} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Last reported Date & Time</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.lastReportedDate}
                                </Typography>

                            </Grid>

                            <Grid item sx={{ pb: 7 }} lg={2.5} md={2.5} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Device LongId</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.id}
                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Device Mesh Id</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.mesh_id}
                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Device Type</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.type}
                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>
                                    {deviceDetails.type === 11001 ? "Device Capability" : "IP Address"}
                                </Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.type === 11001 ? deviceDetails.device_capability : deviceDetails.ip_address}
                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Hardware Version</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.hw_version}
                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Software Version</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.sw_version}
                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>CSR Firmware Version</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.fm_version}
                                </Typography>

                            </Grid>
                            <Grid item sx={{ pb: 7 }} lg={3} md={3} sm={12} xs={12}>
                                <Typography className='device_view_text label_color'>Device GUID</Typography>
                                <Typography className='device_view_text content_color'>
                                    {deviceDetails.deviceuuid}
                                </Typography>

                            </Grid>
                        </Grid>
                    </div>
                </Grid> */}
                </Grid>
            }
        </Layout>
    )
}