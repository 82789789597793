import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from '../../layout/Layout';
import { Alert, CircularProgress, Grid, InputLabel, Snackbar, TextField, Typography } from '@mui/material';
import { StyledCreateSmallButton } from '../../styles/ButtonStyles';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { cleareditPowerStatus, editPower, fetchSinglePower } from '../../../redux/actions/SurveillanceAction';
import { useSelector } from 'react-redux';
export const EditPower = ({ }) => {
  const dispatch = useDispatch();
  const powerData = useSelector((state) => state.surveillance.singlePower);
  const updatePowerStatus = useSelector((state) => state.surveillance.editPower);

  const { id } = useParams();
  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("")

  const [batteryDetails, setBatteryDetails] = useState({

    battery_name: "",
    battery_id: ""
  });
  const [validationErrors, setValidationErrors] = useState({
    battery_name: "",
    battery_id: ""
  });
  useEffect(() => {

    dispatch(fetchSinglePower(id));
  }, [])
  useEffect(() => {
    if (
      !powerData.isFetching &&
      powerData.payload &&
      powerData.payload.success === true
    ) {
      // let tempData={...sensorData};
      let tempData = powerData.payload.data;
      setBatteryDetails(tempData);
    }
    if (
      !updatePowerStatus.isFetching &&
      updatePowerStatus.payload &&
      updatePowerStatus.payload.success === true
    ) {
      sessionStorage.setItem('surveillanceTab', "s3")

      window.location.href = "/surveillance";

      //setBatteryDrop(tempBatteryList);
    }
    if (
      !updatePowerStatus.isFetching &&
      updatePowerStatus.error
    ) {
      let tempalert = updatePowerStatus.message;
      setAlertMessage(tempalert);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      dispatch(cleareditPowerStatus());

      return () => clearTimeout(timeoutId);
    }
  }, [powerData, updatePowerStatus])
  const onChange = (value, key) => {
    let temp = { ...batteryDetails };
    temp[key] = value;
    setBatteryDetails(temp);
    const strln = value.toString().trim();
    const valueLength = strln.length;
    let error = "";
    switch (key) {
      case "battery_id":
      case "battery_name":

        // case 'license_no':

        error =
          valueLength < 3 || valueLength >= 30
            ? "Field must be between 2 and 30 characters long"
            : "";
        break;
      default:
        break;

    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  }
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  const handleUpdate = (e) => {
    let data = {
      battery_name: batteryDetails.battery_name,
      battery_id: batteryDetails.battery_id,
      org_id: parseInt(sessionStorage.getItem("org_Id")),
      created_user: parseInt(sessionStorage.getItem("id")),

    }
    e.preventDefault();
    const isEmptyField = Object.values(data).some((value) => {
      return (
        (typeof value === "string" && value.trim() === "") ||
        value === undefined ||
        value === null
      );
    });
    const allClear = Object.values(validationErrors).every((value) => {
      return (
        (typeof value === "string" && value.trim() == "") ||
        value === undefined ||
        value === null
      );
    });
    if (isEmptyField) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        battery_name:
          data.battery_name.trim() === ""
            ? "This field is mandatory"
            : prevErrors.battery_name,
        battery_id: data.battery_id ? "" : "This field is mandatory",
      }));
    }

    if (!isEmptyField && allClear) {
      setValidationErrors({
        battery_name: "",
        battery_id: "",

      });
      dispatch(editPower(id, data));
    }
  }
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"videosurveillance"}
      activeMenuKey={"videosurveillance"}
      isCreate={true}
    >
      <div
        style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%" }}
        className=""
      >

        <Grid container>
          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={handlealertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handlealertClose}
              severity="error"
              variant="outlined"
              sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

            >
              {alertMessage}
            </Alert>
          </Snackbar>
          {updatePowerStatus.isFetching || powerData.isFetching ?
            <Grid item lg={12} md={12} sm={12} xs={12} container
              sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
              <CircularProgress sx={{ color: '#FFAC0B' }} />
            </Grid>
            :
            <>
            </>
          }
          <Grid item lg={12} sm={12} xs={12} md={12} sx={{ ml: 2 }}>
            <Typography
              sx={{ textAlign: "left" }}
              className={"headersubhead light_text"}
            >
              Update Battery
            </Typography>
          </Grid>
          <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3, ml: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Battery Name
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="Battery Name"
              value={batteryDetails.battery_name}
              onChange={(e) => onChange(e.target.value, "battery_name")}
              error={!!validationErrors.battery_name}
              helperText={validationErrors.battery_name}
            />
          </Grid>
          <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3, ml: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Battery Id
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="Battery Id"
              disabled={true}
              value={batteryDetails.battery_id}
              onChange={(e) => onChange(e.target.value, "battery_id")}
              error={!!validationErrors.battery_id}
              helperText={validationErrors.battery_id}
            />
          </Grid>
          <Grid item tem xs={10} sm={10} lg={10} md={10} sx={{ ml: 3 }}>
            <StyledCreateSmallButton
              onClick={handleUpdate}
              sx={{ mt: 5, width: 150 }}
              variant="contained"
              disableRipple
            >
              Update
            </StyledCreateSmallButton>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}