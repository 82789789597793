import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { ModalHead, TagModalstyle, iconStyle } from "../styles/ModalStyle";
import { BorderButton, DeleteButton } from "../styles/ButtonStyles";
export const DeleteModal = ({ handleDeleteModal, openDeleteModal, ModalHead, ModalBody ,onDeleteConfirm}) => {
    return (
        <>
            <Modal
                open={openDeleteModal}
                onClose={handleDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={TagModalstyle}>
                    <IconButton><RiDeleteBin6Line color="#C61A1A" /></IconButton>
                    <Typography className="modalhead">
                        {ModalHead}
                    </Typography>
                    <Typography className="modalBody modallight" sx={{ mt: 2 }}>
                        Are you sure you want to delete {' '}{ModalBody} {' '}?
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        <BorderButton
                            sx={{ mr: 2, mt: 3 }} variant="outlined"
                            onClick={handleDeleteModal}>
                            <HighlightOffRoundedIcon
                                sx={{ fontSize: '20px', mr: '5px', color: "#161616" }} />
                            Cancel
                        </BorderButton>
                        <DeleteButton
                            sx={{ mt: 3 }} variant="contained" color="error"
                         onClick={onDeleteConfirm}
                        >
                            <RiDeleteBin6Line style={iconStyle} />
                            Delete
                        </DeleteButton>
                    </div>

                </Box>
            </Modal>
        </>
    )
}