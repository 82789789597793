import axios from 'axios';
import { toastr } from 'react-redux-toastr';

let baseUrl = process.env.REACT_APP_API_BASE_URL + '/' + process.env.REACT_APP_API_VERSION;
/**
 * axios instance
 */
 const logoutFromAll=()=>{
    // sessionStorage.setItem("organisation_type_data",null);
    // sessionStorage.removeItem('token');
    // sessionStorage.removeItem('refreshToken');
    // sessionStorage.removeItem('id');
    // sessionStorage.removeItem('role_id');
    // sessionStorage.removeItem('username');
    // sessionStorage.removeItem('username');
    // sessionStorage.removeItem('org_Id');
    // sessionStorage.removeItem('user_pro_pic');
    // sessionStorage.removeItem('per_feature')
    // sessionStorage.removeItem("user_site_id")
    // sessionStorage.removeItem("user_name");
    // sessionStorage.removeItem("org_logo");
    // sessionStorage.removeItem("is_wisilica");
    // sessionStorage.removeItem("alert_stage");
    // sessionStorage.removeItem("alert_type");
    // sessionStorage.removeItem("organisationTab");
    // sessionStorage.removeItem("alertViewId");
    // sessionStorage.removeItem("surveillanceTab");
    // sessionStorage.removeItem("organisation_type_data");
    // sessionStorage.removeItem("trackingTab");
    // sessionStorage.removeItem('alert_stage');
    sessionStorage.clear();
window.location.href = "/"
}
const instance = axios.create({
    baseURL: baseUrl
});
// request header
instance.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }




       config.headers['Content-Type'] = 'application/json';




       
        return config;
    },
    error => {
        
        Promise.reject(error)
    });


    /**
 *
 * parse error response
 */
// function parseError(messages) {
//     // error
//     if (messages) {
//         if (messages instanceof Array) {
//             return Promise.reject({ messages: messages })
//         } else if(typeof messages === "object" && messages.hasOwnProperty('message')){
//             return Promise.reject({ messages: messages.message })
//         } else {
//             return Promise.reject({ messages: [messages] })
//         }
//     } else {
//         return Promise.reject({ messages: [] })
//     }
// }

// response parse
// instance.interceptors.response.use((response) => {
//     if (response.data.errorCode === -10) {
//     }
//     return response;
// }, error => {
//     if (error.response) {
//         if (401 === error.response.status) {
//             logoutFromAll();
//             return Promise.reject(error.response.data);
//         } else {
//             return Promise.reject(error.response.data)
//         }
//     }

//     return Promise.reject({ messages: [{ 'message': 'Network error please try later' }] })
// })
instance.interceptors.response.use(
    response => {
        if (response.data.errorCode === -10) {
            // Handle specific error code if needed
        }
        return response;
    },
    error => {
        if (error.response) {
            if (error.response.status === 401) {
                logoutFromAll();
                return Promise.reject(error.response.data);
            } else {
                return Promise.reject(error.response.data);
            }
        }

        return Promise.reject({ messages: [{ 'message': 'Network error, please try later' }] });
    }
);
export const displayErrorMessage = (error, toastTitle) => {
    if (error.response) {// client received an error response (5xx, 4xx)
        toastr.error(toastTitle, error.response.data.message || error.response.data.errors)
    } else if (error.request) {
        console.log(error.request);
    } else if (error.messages) {
        error.messages.forEach(element => {
            if (element.errors) {
                element.errors.forEach(item => {
                    toastr.error(toastTitle, item.msg)
                })
            } else {
                toastr.error(toastTitle, element.message)
            }
        });
        // client never received a response, or request never left
    } else {
        // anything else
    }
    return false
}

export const API = instance
