export const userRoleActionType={
    FETCH_USER_ROLE: "FETCH_USER_ROLE",
    FETCH_USER_ROLE_SUCCESS: "FETCH_USER_ROLE_SUCCESS",
    FETCH_USER_ROLE_ERROR: "FETCH_USER_ROLE_ERROR",

    ADD_USER_ROLE: "ADD_USER_ROLE",
    ADD_USER_ROLE_SUCCESS: "ADD_USER_ROLE_SUCCESS",
    ADD_USER_ROLE_ERROR: "ADD_USER_ROLE_ERROR",

    FETCH_USER_ROLE_LIST: "FETCH_USER_ROLE_LIST",
    FETCH_USER_ROLE_LIST_SUCCESS: "FETCH_USER_ROLE_LIST_SUCCESS",
    FETCH_USER_ROLE_LIST_ERROR: "FETCH_USER_ROLE_LIST_ERROR",


    FETCH_SITE : "FETCH_SITE",
    FETCH_SITE_SUCCESS : "FETCH_SITE_SUCCESS",
    FETCH_SITE_ERROR : "FETCH_SITE_ERROR",

    FETCH_ROLE_DROPDOWN_LIST: "FETCH_ROLE_DROPDOWN_LIST",
    FETCH_ROLE_DROPDOWN_LIST_SUCCESS: "FETCH_ROLE_DROPDOWN_LIST_SUCCESS",
    FETCH_ROLE_DROPDOWN_LIST_ERROR: "FETCH_ROLE_DROPDOWN_LIST_ERROR",

    EDIT_USER_ROLE: "EDIT_USER_ROLE",
    EDIT_USER_ROLE_SUCCESS: "EDIT_USER_ROLE_SUCCESS",
    EDIT_USER_ROLE_ERROR: "EDIT_USER_ROLE_ERROR",
    CLEAR_EDIT_USER_ROLE_STATUS:"CLEAR_EDIT_USER_ROLE_STATUS",

    GET_IN_TOUCH : "GET_IN_TOUCH",
    GET_IN_TOUCH_SUCCESS : "GET_IN_TOUCH_SUCCESS",
    GET_IN_TOUCH_ERROR : "GET_IN_TOUCH_ERROR",
    CLEAR_GET_IN_TOUCH_STATUS : "CLEAR_GET_IN_TOUCH_STATUS",

    CONTACT_US : "CONTACT_US",
    CONTACT_US_SUCCESS : "CONTACT_US_SUCCESS",
    CONTACT_US_ERROR : "CONTACT_US_ERROR",
    CLEAR_CONTACT_US_STATUS : "CLEAR_CONTACT_US_STATUS",

}
export const fetchUserRole = (id)=>{
    return{
        type :userRoleActionType.FETCH_USER_ROLE,
        id:id
    }
}
export const fetchUserRoleSuccess =(data)=>{
    return{
        type :userRoleActionType.FETCH_USER_ROLE_SUCCESS,
        data:data
    }
}
export const fetchUserRoleError =(error)=>{
    return{
        type :userRoleActionType.FETCH_USER_ROLE_ERROR,
        error
    }
}
export const addUserRole = (data)=>{
    return{
        type :userRoleActionType.ADD_USER_ROLE,
        data:data
    }
}
export const addUserRoleSuccess =(data)=>{
    return{
        type :userRoleActionType.ADD_USER_ROLE_SUCCESS,
        data:data
    }
}
export const addUserRoleError =(data,error)=>{
    return{
        type :userRoleActionType.ADD_USER_ROLE_ERROR,
      
        error,
        
    }
}
export const fetchUserRoleList = (data)=>{
    return{
        type :userRoleActionType.FETCH_USER_ROLE_LIST,
        data:data
    }
}
export const fetchUserRoleListSuccess =(data)=>{
    return{
        type :userRoleActionType.FETCH_USER_ROLE_LIST_SUCCESS,
        data:data
    }
}
export const fetchUserRoleListError =(error)=>{
    return{
        type :userRoleActionType.FETCH_USER_ROLE_LIST_ERROR,
        error
    }
}


//fetch sites
export const fetchSites = (data)=>{
    return{
        type :userRoleActionType.FETCH_SITE,
        data:data
        
    }
}
export const fetchSitesSuccess =(data)=>{
    return{
        type :userRoleActionType.FETCH_SITE_SUCCESS,
        data:data
    }
}
export const fetchSitesError =(error)=>{
    return{
        type :userRoleActionType.FETCH_SITE_ERROR,
        error
    }
}
//role dropdwon
export const fetchRoleDropdownList = (data)=>{
    return{
        type :userRoleActionType.FETCH_ROLE_DROPDOWN_LIST,
        data:data
    }
}
export const fetchRoleDropdownListSuccess =(data)=>{
    return{
        type :userRoleActionType.FETCH_ROLE_DROPDOWN_LIST_SUCCESS,
        data:data
    }
}
export const fetchRoleDropdownListError =(error)=>{
    return{
        type :userRoleActionType.FETCH_ROLE_DROPDOWN_LIST_ERROR,
        error
    }
}

export const editUserRole = (id,data)=>{
    return{
        type :userRoleActionType.EDIT_USER_ROLE,
        id:id,
        data:data
    }
}
export const editUserRoleSuccess =(data)=>{
    return{
        type :userRoleActionType.EDIT_USER_ROLE_SUCCESS,
        data:data
    }
}
export const editUserRoleError =(error)=>{
    return{
        type :userRoleActionType.EDIT_USER_ROLE_ERROR,
        error
    }
}
export const clearEditUserRoleStatus =()=>{
    return{
        type :userRoleActionType.CLEAR_EDIT_USER_ROLE_STATUS,
        
    }
}
//get in touch
export const addGetInTouch = (data)=>{
    return{
        type :userRoleActionType.GET_IN_TOUCH,
     
        data:data
    }
}
export const addGetInTouchSuccess =(data)=>{
    return{
        type :userRoleActionType.GET_IN_TOUCH_SUCCESS,
        data:data
    }
}
export const addGetInTouchError =(error)=>{
    return{
        type :userRoleActionType.GET_IN_TOUCH_ERROR,
        error
    }
}
export const clearAddGetInTouchStatus =()=>{
    return{
        type :userRoleActionType.CLEAR_GET_IN_TOUCH_STATUS,
        
    }
}
//get in touch
export const addContactUs = (data)=>{
    return{
        type :userRoleActionType.CONTACT_US,
     
        data:data
    }
}
export const addContactUsSuccess =(data)=>{
    return{
        type :userRoleActionType.CONTACT_US_SUCCESS,
        data:data
    }
}
export const addContactUsError =(error)=>{
    return{
        type :userRoleActionType.CONTACT_US_ERROR,
        error
    }
}
export const clearaddContactUsStatus =()=>{
    return{
        type :userRoleActionType.CLEAR_CONTACT_US_STATUS,
        
    }
}
