import React, { useEffect, useState } from 'react';
import { CameraHistoryTable } from '../../surveillance/camera/CameraHistoryTable';
import { CameraHistory } from './CameraHistory';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { Layout } from '../../layout/Layout';
import { convertMinutesToHMS, dateSearchConverter, historyTimeOnly, timeOnly } from '../../convertors/TimeConvertor';
import { fetchCameraHistory, getSingleEncoder } from '../../../redux/actions/SurveillanceAction';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { subDays } from 'date-fns';
import { useSelector } from 'react-redux';
export const CameraSingleHistory = ({ }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
  const singleEncoder = useSelector((state) => state.surveillance.SingleEncoder);
  const prevHistory = useSelector((state) => state.surveillance.cameraHistory);
  const [prevHistoryTable, setPreHistoryTable] = useState([])
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [trackingDatas, setTrackingDatas] = useState("");
  const [totalDatas, setTotalDatas] = useState("");
  const [trackingnames, setTrackingNames] = useState("");
  const [singleCameraDetails, setSingleCameraDetails] = useState({});
  const [selectedStartDate, setSelectedStartDate] = useState(subDays(new Date(), 3));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedMachineId, setSelectedMachineId] = useState("");
  const [reDirection, setRedirection] = useState(false);
  const [streamId, setStreamId] = useState("");
  const [size, setSize] = useState(400)
  //huy const [camTableData,setCamtableData] = useState([]);
  useEffect(() => {



    if (id != null) {
      setStreamId(id);
      // dispatch(getSingleEncoder(id));
      let data = {

        machine_id: id,
        from_date: sessionStorage.getItem("redirect_Date"),
        to_date: sessionStorage.getItem("redirect_Date")
      }
      //setSingleCameraDetails(singleCam.machine_id)
      dispatch(fetchCameraHistory(data));
    }
  }, []);

  useEffect(() => {
    if (
      !singleEncoder.isFetching &&
      singleEncoder.payload &&
      singleEncoder.payload.success === true
    ) {
      const singleCam = singleEncoder.payload.data;
      // let data = {
      //   machine_id: singleCam.machine_id
      // }
      setSingleCameraDetails(singleCam)
      setSelectedMachineId(singleCam?.machine_id)
      // dispatch(fetchCameraHistory(data));
    }
    if (
      !prevHistory.isFetching &&
      prevHistory.payload &&
      prevHistory.payload.success === true
    ) {
      const trackingReport = prevHistory.payload.graph;
      const tempPrev = prevHistory.payload.data;
      const tempPagination = prevHistory.payload.pagination;

      setPageCount(tempPagination.total_pages)

      setTrackingDatas(trackingReport);

      setPreHistoryTable(tempPrev);
      const category = [];
      let seriesLength = 0;
      trackingReport.map((e) => {
        category.push(e.date);
        const historyLength = category.length;


        if (historyLength > seriesLength) {
          seriesLength = historyLength;
        }
      })
      const series = []
      trackingReport.map((e) => {
        const historyLength = e.gateway_history.length;
        if (historyLength > 0) {
          const item = new Array(seriesLength).fill(0);
          for (let i = 0; i < historyLength; i++) {
            const history = e.gateway_history[i];

            const updatedItem = [...item];  // Clone the item array
            // updatedItem[e.dayOrder - 1] = parseFloat((history.differenceInMinutes / 60).toFixed(2));
            updatedItem[e.dayOrder - 1] = {
              y: parseFloat((history.differenceInMinutes / 60).toFixed(2)),
              custom: {
                from_time: historyTimeOnly(history.fromtime),
                to_time: historyTimeOnly(history.totime),
                duration: convertMinutesToHMS(history.differenceInMinutes)
              }
            };


            series.push({
              name: history.online === true ? 'Online' : 'Offline',
              color: history.online ? '#36F097' : '#C52525',
              data: updatedItem,
              value: history.differenceInMinutes,
              key: e.date,


            })
          }
          //)
        } else {
          series.push({
            name: 'Offline',
            color: '#C52525',
            data: new Array(seriesLength).fill(0),
            key: e.date

          })
        }
      })
      const formattedSeries = updateLegendVisibility(series)
      function updateLegendVisibility(data) {
        let onlineShown = false;
        let offlineShown = false;

        return data.map(series => {
          if (series.name === 'Online' && !onlineShown) {
            onlineShown = true;
            return series; // Keep the first 'Online' series as is
          }
          if (series.name === 'Offline' && !offlineShown) {
            offlineShown = true;
            return series; // Keep the first 'Offline' series as is
          }
          return { ...series, showInLegend: false }; // Set 'showInLegend' to false for all others
        });
      }
      setTrackingNames(category);
      setTotalDatas(formattedSeries)
    }

  }, [singleEncoder, prevHistory]);
  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;
    if (selectedMachineId != "") {
      let data = {
        start: start,
        limit: limit,
        machine_id: id,
        from_date: sessionStorage.getItem("redirect_Date"),
        to_date: sessionStorage.getItem("redirect_Date")
      }
      //setSingleCameraDetails(singleCam.machine_id)
      dispatch(fetchCameraHistory(data));
    }
  }, [selectedMachineId, currentPage, id])
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  const handleEndDateChange = (date) => {

    setSelectedEndDate(date);
  };
  const handleStartDateChange = (date) => {
    sessionStorage.setItem("redirect_Date")

    setSelectedStartDate(date);

  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"Report"}
      activeMenuKey={"Report"}
      isCreate={true}
      handleEndDateChange={handleEndDateChange}
      handleStartDateChange={handleStartDateChange}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
    >
      <div
        style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%" }}
        className=""
      >
        <Grid container>
          <Grid item lg={12} sm={12} xs={12} md={12} sx={{ ml: 2 }}>
            <Typography
              sx={{ textAlign: "left" }}
              className={"headersubhead light_text"}
            >
              Camera History
            </Typography>
          </Grid>
          {prevHistory.isFetching ?
            <Grid item lg={12} md={12} sm={12} xs={12} container
              sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
              <CircularProgress sx={{ color: '#FFAC0B' }} />
            </Grid>
            :
            <>
              <Grid item lg={12} sm={12} xs={12} md={12} sx={{ ml: 2, mt: 2 }}>
                <Typography
                  sx={{ textAlign: "left" }}
                  className={"device_view_head light_text"}
                >
                  Stream Id:{id}
                </Typography>
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={12} sx={{ mt: 5 }}>
                <CameraHistory
                  redirection={true}
                  data={totalDatas}
                  //sentryData={sentryDatas}
                  trackingDates={trackingnames}
                  size={size}

                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={12} sx={{ mt: 3, ml: 2 }}>
                <CameraHistoryTable
                  tableData={prevHistoryTable}
                  pageCount={pageCount}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Grid>
            </>
          }
        </Grid>
      </div>
    </Layout>
  )
}