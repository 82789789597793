import { API } from './Api';

const auth = '/auth/'
export const loginData = async (body) => {

    const response = await API.post(auth + 'login', body)

    return response.data;
}
export const forgottedData = async (body) => {

    const response = await API.post(auth + 'forgotpassword', body)
    return response.data;
}
export const otpData = async (body) => {

    const response = await API.post(auth + 'verifyforgotpassword', body)
    return response.data;


}

export const createNewPassword = async (body) => {
    const response = await API.post(auth + 'resetpassword', body)
    return response.data;

}
export const getLoggedUserData = async (id) => {
    const response = await API.get('user/loginuser/' + id)
    return response.data;

}
export const getRefreshToken = async (data) => {
    const response = await API.post(auth+'refreshtoken',data)
    return response.data;

}
export const MakeUserLogout = async (data) => {
    const response = await API.post(auth+'logout',data)
    return response.data;

}

/**
 * savePersona 
 *
 * @param
 */
export function getSocketAuthToken() {
    return new Promise((resolve, reject) => {
        API.get("/auth/socket-token").then(res => {
            resolve(res.data);
        }).catch(err => {
            // Check for error response from the server
            if (err.response) {
              // Client received an error response (4xx, 5xx)
              console.error('Error response:', err.response);
              reject(err.response.data || 'An error occurred while saving persona.');
            } else if (err.request) {
              // Client never received a response or request never left
              console.error('Error request:', err.request);
              reject('No response received from the server.');
            } else {
              // Anything else
              console.error('Error message:', err.message);
              reject('An error occurred: ' + err.message);
            }
          });
    })
}
