import { all, put, takeLatest } from "redux-saga/effects";
import { addContactUsError, addContactUsSuccess, addGetInTouchError, addGetInTouchSuccess, addUserRoleError, addUserRoleSuccess, editUserRoleError, editUserRoleSuccess, fetchRoleDropdownListError, 
    fetchRoleDropdownListSuccess,fetchSitesError, fetchSitesSuccess, fetchUserRoleError,fetchUserRoleListError, 
    fetchUserRoleListSuccess,  fetchUserRoleSuccess, userRoleActionType } from "../actions/UserRoleActions";
import { addNewRoleData, editUserRoleDetails, fetchRoleData, fetchRoleDropLists, fetchRoleLists,
 fetchSiteData, 
 postContactUsDetails, 
 postGetInTouchMail} from "../../services/UserRoleServices";
   //getIntouch
function* _uploadContactUsk(action) {

    try {
        const info = yield postContactUsDetails(action.data);
        yield put(addContactUsSuccess(info));
    } catch (e) {
        yield put(addContactUsError("error"));
    }
}
function* uploadContactUs (){
    yield takeLatest(userRoleActionType.CONTACT_US,_uploadContactUsk)
}
  //getIntouch
function* _postGetInTouch(action) {

    try {
        const info = yield postGetInTouchMail(action.data);
        yield put(addGetInTouchSuccess(info));
    } catch (e) {
        yield put(addGetInTouchError("error"));
    }
}
function* postGetInTouch (){
    yield takeLatest(userRoleActionType.GET_IN_TOUCH,_postGetInTouch)
}
 //edit user role
function* _updateUserRole(action) {

    try {
        const roleInfo = yield editUserRoleDetails(action.id,action.data);
        yield put(editUserRoleSuccess(roleInfo));
    } catch (e) {
        yield put(editUserRoleError("error"));
    }
}
function* updateUserRole (){
    yield takeLatest(userRoleActionType.EDIT_USER_ROLE,_updateUserRole)
}
//fetching roledropdown
function* _fetchRoleDropDown(action) {

    try {
        const roleDropInfo = yield fetchRoleDropLists(action.data);
        yield put(fetchRoleDropdownListSuccess(roleDropInfo));
    } catch (e) {
        yield put(fetchRoleDropdownListError("error"));
    }
}
function* fetchRoleDropDown (){
    yield takeLatest(userRoleActionType.FETCH_ROLE_DROPDOWN_LIST,_fetchRoleDropDown)
}
//fetching sites
function* _fetchSiteDetails(action) {

    try {
        const siteInfo = yield fetchSiteData(action.data);
        yield put(fetchSitesSuccess(siteInfo));
    } catch (e) {
        yield put(fetchSitesError("error"));
    }
}
function* fetchSiteDetails (){
    yield takeLatest(userRoleActionType.FETCH_SITE,_fetchSiteDetails)
}
//add role
function* _fetchRoleList(action) {

    try {
        const addRole = yield fetchRoleLists(action.data);
        yield put(fetchUserRoleListSuccess(addRole));
    } catch (e) {
        yield put(fetchUserRoleListError("error",e));
    }
}
function* fetchRoleList (){
    yield takeLatest(userRoleActionType.FETCH_USER_ROLE_LIST,_fetchRoleList)
}
//add role
function* _addNewRole(action) {

    try {
        const addRole = yield addNewRoleData(action.data);
        yield put(addUserRoleSuccess(addRole));
    } catch (e) {
        yield put(addUserRoleError("error",e));
    }
}
function* addNewRole (){
    yield takeLatest(userRoleActionType.ADD_USER_ROLE,_addNewRole)
}
//fetch single user
function* _fetchUserRoleDetails(action) {

    try {
        const roleInfo = yield fetchRoleData(action.id);
        yield put(fetchUserRoleSuccess(roleInfo));
    } catch (e) {
        yield put(fetchUserRoleError("error"));
    }
}
function* fetchUserRoleDetails (){
    yield takeLatest(userRoleActionType.FETCH_USER_ROLE,_fetchUserRoleDetails)
}


export function* UserRoleSaga(){
    yield all([fetchUserRoleDetails(),fetchSiteDetails(),addNewRole(),fetchRoleList(),fetchRoleDropDown(),
        updateUserRole(),postGetInTouch(),uploadContactUs()])
}