export const userActionType={
    FETCH_USER: "FETCH_USER",
    FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
    FETCH_USER_ERROR: "FETCH_USER_ERROR",

    ADD_USER : "ADD_USER",
    ADD_USER_SUCCESS : "ADD_USER_SUCCESS",
    ADD_USER_ERROR : "ADD_USER_ERROR",
    CLEAR_ADD_USER_STATUS : "CLEAR_ADD_USER_STATUS",

    FETCH_USER_LIST: "FETCH_USER_LIST",
    FETCH_USER_LIST_SUCCESS: "FETCH_USER_LIST_SUCCESS",
    FETCH_USER_LIST_ERROR: "FETCH_USER_LIST_ERROR",

    DELETE_USER : "DELETE_USER",
    DELETE_USER_SUCCESS : "DELETE_USER_SUCCESS",
    DELETE_USER_ERROR : "DELETE_USER_ERROR",
    CLEAR_DELETE_USER_STATUS:"CLEAR_DELETE_USER_STATUS",

    UPDATE_USER : "UPDATE_USER",
    UPDATE_USER_SUCCESS : "UPDATE_USER_SUCCESS",
    UPDATE_USER_ERROR : "UPDATE_USER_ERROR",
    CLEAR_USER_UPDATE_STATUS:"CLEAR_USER_UPDATE_STATUS",

    UPLOAD_USER_PIC : "UPLOAD_USER_PIC",
    UPLOAD_USER_PIC_SUCCESS : "UPLOAD_USER_PIC_SUCCESS",
    UPLOAD_USER_PIC_ERROR : "UPLOAD_USER_PIC_ERROR",
}
//fetch single user
export const fetchUser = (id) => {


    return {
        type: userActionType.FETCH_USER,
        id: id,

    }
}
export const fetchUserSuccess = (data) => {
    return {
        type: userActionType.FETCH_USER_SUCCESS,
        payload: data
    }
}
export const fetchUserError = (error) => {
    return {
        type: userActionType.FETCH_USER_ERROR,
        error
    }
}
//add user

export const addUser = (data) => {


    return {
        type: userActionType.ADD_USER,
        data: data,

    }
}
export const addUserSuccess = (data) => {
    return {
        type: userActionType.ADD_USER_SUCCESS,
        payload: data
    }
}
export const addUserError = (error) => {
    return {
        type: userActionType.ADD_USER_ERROR,
        error
    }
}
export const clearAddUserstatus = () => {
    return {
        type: userActionType.CLEAR_ADD_USER_STATUS,
        
    }
}

//fetch user list

export const fetchUserList = (data) => {


    return {
        type: userActionType.FETCH_USER_LIST,
        data: data,

    }
}
export const fetchUserListSuccess = (data) => {
    return {
        type: userActionType.FETCH_USER_LIST_SUCCESS,
        payload: data
    }
}
export const fetchUserListError = (error) => {
    return {
        type: userActionType.FETCH_USER_LIST_ERROR,
        error
    }
}

//delete user

export const deleteUser = (data) => {


    return {
        type: userActionType.DELETE_USER,
       
        data:data

    }
}
export const deleteUserSuccess = (data) => {
    return {
        type: userActionType.DELETE_USER_SUCCESS,
        payload: data
    }
}
export const deleteUserError = (error) => {
    return {
        type: userActionType.DELETE_USER_ERROR,
        error
    }
}
export const clearDeleteUserStatus = () => {
    return {
        type: userActionType.CLEAR_DELETE_USER_STATUS,
       
    }
}
//update user

export const updateUser = (id,data) => {


    return {
        type: userActionType.UPDATE_USER,
        id: id,
        data:data

    }
}
export const updateUserSuccess = (data) => {
    return {
        type: userActionType.UPDATE_USER_SUCCESS,
        payload: data
    }
}
export const updateUserError = (error) => {
    return {
        type: userActionType.UPDATE_USER_ERROR,
        error
    }
}
export const clearUserUpdateStaus = () => {
    return {
        type: userActionType.CLEAR_USER_UPDATE_STATUS,
        
    }
}

//upload pic of user

export const uploadUserPic = (data) => {


    return {
        type: userActionType.UPLOAD_USER_PIC,
        data:data

    }
}
export const uploadUserPicSuccess = (data) => {
    return {
        type: userActionType.UPLOAD_USER_PIC_SUCCESS,
        payload: data
    }
}
export const uploadUserPicError = (error) => {
    return {
        type: userActionType.UPLOAD_USER_PIC_ERROR,
        error
    }
}