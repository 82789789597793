import { API } from './Api';
const videosoft = '/videosoft';
export const fetchVmsDeviceListData = async (data) => {
  const start = data && data.start !== undefined ? data.start : 0;
  const limit = data && data.limit !== undefined ? data.limit : 15;
  // const site_id = data && data.site_id !== undefined ? data.site_id :'';
  const searchWord = data && data.searchWord !== undefined ? data.searchWord : '';
  const from_date = data && data.from_date !== undefined ? data.from_date : '';
  const to_date = data && data.to_date !== undefined ? data.to_date : '';
  const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
  const type = data && data.type !== undefined ? data.type : '';
  const site_id = data && data.site_id !== undefined ? data.site_id : 0;
  const level_id = data && data.level_id !== undefined ? data.level_id : 0;

  const is_assigned = data && data.is_assigned !== undefined ? data.is_assigned : '';

  const response = await API.get(`${videosoft}?start=${start}&limit=${limit}&from_date=${from_date}&to_date=${to_date}&site_id=${site_id}&search=${searchWord}&type=${type}&org_id=${org_id}&is_assigned=${is_assigned}&level_id=${level_id}`)

  return response.data;


}
export const fetchSensorListData = async (data) => {
  const start = data && data.start !== undefined ? data.start : 0;
  const limit = data && data.limit !== undefined ? data.limit : 15;
  // const site_id = data && data.site_id !== undefined ? data.site_id :'';
  const searchWord = data && data.searchWord !== undefined ? data.searchWord : '';
  const from_date = data && data.from_date !== undefined ? data.from_date : '';
  const to_date = data && data.to_date !== undefined ? data.to_date : '';
  const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
  const response = await API.get(`/sensor?start=${start}&limit=${limit}&from_date=${from_date}&to_date=${to_date}&search=${searchWord}&org_id=${org_id}`)

  return response.data;


}
export const addSensorDetails = async (data) => {
  const response = await API.post(`sensor`, data);
  return response.data;

}
export const getSingleSesorDetails = async (id) => {
  const response = await API.get(`sensor/` + id);
  return response.data;

}
export const editSensorDetails = async (id, data) => {
  const response = await API.put(`sensor/` + id, data);
  return response.data;

}
export const deleteSensorData = async (data) => {
  const response = await API.post(`sensor/delete`, data);
  return response.data;

}
export const getGatewayDropdown = async (data) => {
  const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
  const is_assigned = data && data.is_assigned !== undefined ? data.is_assigned : '';

  const response = await API.get(`${videosoft}/gatewaydropdown?org_id=${org_id}&is_assigned=${is_assigned}`);
  return response.data;

}
export const getSensorDropdownlist = async (data) => {
  const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
  const is_assigned = data && data.is_assigned !== undefined ? data.is_assigned : '';

  const response = await API.get(`/sensor/sensordropdown?org_id=${org_id}&is_assigned=${is_assigned}`);
  return response.data;

}
export const getBatteryDropdownlist = async (data) => {
  const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
  const is_assigned = data && data.is_assigned !== undefined ? data.is_assigned : '';

  const response = await API.get(`/sensor/batterydropdown?org_id=${org_id}&is_assigned=${is_assigned}`);
  return response.data;

}
export const addEncoderDetails = async (data) => {
  const response = await API.post(`${videosoft}/`, data);
  return response.data;

}
export const editEncoderDetails = async (id, data) => {
  const response = await API.put(`${videosoft}/` + id, data);
  return response.data;

}
export const deleteEncoderDetails = async (data) => {
  const response = await API.post(`${videosoft}/delete`, data);
  return response.data;

}
export const fetchSingleEncoder = async (data) => {
  const response = await API.get(`${videosoft}/` + data);
  return response.data;

}
export const addPowerDetails = async (data) => {
  const response = await API.post(`battery`, data);
  return response.data;

}
export const getPowerListData = async (data) => {
  const start = data && data.start !== undefined ? data.start : 0;
  const limit = data && data.limit !== undefined ? data.limit : 15;
  // const site_id = data && data.site_id !== undefined ? data.site_id :'';
  const searchWord = data && data.searchWord !== undefined ? data.searchWord : '';
  const from_date = data && data.from_date !== undefined ? data.from_date : '';
  const to_date = data && data.to_date !== undefined ? data.to_date : '';
  const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
  //  const type =  data && data.type !== undefined ? data.type : '';
  //  const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));
  //  const is_assigned =  data && data.is_assigned !== undefined ? data.is_assigned : '';

  const response = await API.get(`/battery?start=${start}&limit=${limit}&from_date=${from_date}&to_date=${to_date}&search=${searchWord}&org_id=${org_id}`)

  return response.data;


}
export const fetchsingleMap = async (data) => {
  const site_id = data && data.site_id !== undefined ? data.site_id : '';
  const level_id = data && data.level_id !== undefined ? data.level_id : '';

  const response = await API.get(`/map/get-map?site_id=${site_id}&level_id=${level_id}`)

  return response.data;


}
export const deletePowerData = async (data) => {
  const response = await API.post(`battery/delete`, data);
  return response.data;

}
export const updatePowerDetails = async (id, data) => {
  const response = await API.put(`battery/` + id, data);
  return response.data;

}
export const getSinglePowerData = async (id) => {
  const response = await API.get(`battery/` + id);
  return response.data;

}
export const editCameraStatus = async (data) => {
  const response = await API.post(`${videosoft}/changestatus`, data);
  return response.data;

}
export const getCameraHistoryDetails = async (data) => {

  const start = data && data.start !== undefined ? data.start : 0;
  const limit = data && data.limit !== undefined ? data.limit : 15;
  const from_date = data && data.from_date !== undefined ? data.from_date : '';
  const to_date = data && data.to_date !== undefined ? data.to_date : '';
  const searchWord = data && data.searchWord !== undefined ? data.searchWord : '';
  const machine_id = data && data.machine_id !== undefined ? data.machine_id : '';
  const is_download = data && data.is_download !== undefined ? data.is_download : '';

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const response = await API.get(`/camera/camerahistory?start=${start}&limit=${limit}&from_date=${from_date}&to_date=${to_date}&search=${searchWord}&machine_id=${machine_id}&is_download=${is_download}&timezone=${timezone}`)

  return response.data;


}
export const uploadDevices = async (data) => {
  const response = await API.post(`${videosoft}/importdevice`, data);
  return response.data;

}
/**
 * saveDevicePosition 
 *
 * @param
 */
// export function saveDevicePosition(data) {
//   return new Promise((resolve, reject) => {
//     API.post("/videosoft/adddeviceMap", data).then(res => {
//       resolve(res.data);
//     }).catch(err => {
//       if (err.response) {// client received an error response (5xx, 4xx)
//       } else if (err.request) {
//       } else if (err.messages) {
//         // client never received a response, or request never left
//       } else {
//         // anything else
//       }
//       return false;
//     });
//   })
// }

export function saveDevicePosition(data) {
  return new Promise((resolve, reject) => {
    API.post("/videosoft/adddeviceMap", data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        // Check for error response from the server
        if (err.response) {
          // Client received an error response (4xx, 5xx)
          console.error('Error response:', err.response);
          reject(err.response.data || 'An error occurred while saving device position.');
        } else if (err.request) {
          // Client never received a response or request never left
          console.error('Error request:', err.request);
          reject('No response received from the server.');
        } else {
          // Anything else
          console.error('Error message:', err.message);
          reject('An error occurred: ' + err.message);
        }
      });
  });
}
