import { Alert, CircularProgress, Grid, InputLabel, Snackbar, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addSensor, clearAddSensorStatus, clearupdateSensorStatus, getSingleSensor, updateSensor } from "../../../redux/actions/SurveillanceAction";
import { Layout } from "../../layout/Layout";
import { StyledCreateSmallButton } from "../../styles/ButtonStyles";
import { useParams } from "react-router-dom";
export const EditSensor = ({ }) => {
  const dispatch = useDispatch();
  const singleSensor = useSelector((state) => state.surveillance.singleSensor);
  const updateStatus = useSelector((state) => state.surveillance.updateSensor);

  const { id } = useParams();
  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('')

  const [sensorData, setSensorData] = useState({
    sensor_id: "",
    sensor_name: "",
    distance1: "",
    distance2: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    sensor_name: "",
    // distance1: "",
    // distance2: "",
  });
  useEffect(() => {
    dispatch(getSingleSensor(id))
  }, [id]);

  useEffect(() => {
    if (
      !singleSensor.isFetching &&
      singleSensor.payload &&
      singleSensor.payload.success === true
    ) {
      // let tempData={...sensorData};
      let tempData = singleSensor.payload.Feature_details;
      setSensorData(tempData);
    }
    if (
      !updateStatus.isFetching &&
      updateStatus.payload &&
      updateStatus.payload.success === true
    ) {
      window.location.href = "/surveillance";
    }
    if (!updateStatus.isFetching && updateStatus.error) {
      const error = updateStatus.errorMessage.message;
      setAlertMessage(error);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearupdateSensorStatus());

      }, 2000);
      return () => clearTimeout(timeoutId);
    }

  }, [singleSensor, updateStatus]);
  const handleSensorData = (value, key) => {
    let temp = { ...sensorData };
    temp[key] = value;
    setSensorData(temp);
    const strln = value.toString().trim();
    const valueLength = strln.length;
    let error = "";
    switch (key) {
      case "sensor_name":
        // case 'license_no':

        error =
          valueLength < 3 || valueLength >= 30
            ? "Field must be between 2 and 30 characters long"
            : "";
        break;
      default:
        break;
      // case 'distance1':
      //   case 'distance2':
      //         error = (value === '') ? 'This field is mandatory' : (value >= 0 ? '' : 'Minimum limit reached')
      //         break;
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  };
  const handleCrete = (e) => {
    let data = {
      sensor_id: sensorData.sensor_id,

      sensor_name: sensorData.sensor_name,
      d1: sensorData.distance1,
      d2: sensorData.distance2,
      created_user: parseInt(sessionStorage.getItem("id")),
      org_id: parseInt(sessionStorage.getItem("org_Id")),
    };
    e.preventDefault();
    const isEmptyField = typeof data.sensor_name === "string" && data.sensor_name.trim() === "" || data.sensor_name === undefined || data.sensor_name === null;

    const allClear = Object.values(validationErrors).every((value) => {
      return (
        (typeof value === "string" && value.trim() == "") ||
        value === undefined ||
        value === null
      );
    });
    if (isEmptyField) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        sensor_name:
          data.sensor_name.trim() === ""
            ? "This field is mandatory"
            : prevErrors.sensor_name,
        // distance1: data.d1 ? "" : "This field is mandatory",
        // distance2: data.d2 ? "" : "This field is mandatory",
      }));
    }

    if (!isEmptyField && allClear) {
      setValidationErrors({
        sensor_name: "",
        d1: "",
        d2: "",
      });
      dispatch(updateSensor(id, data));
    }
  };
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
  };
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"videosurveillance"}
      activeMenuKey={"videosurveillance"}
      isCreate={true}
    >
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handlealertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handlealertClose}
          severity="error"
          variant="outlined"
          sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {updateStatus.isFetching || singleSensor.isFetching ?
        <Grid item lg={12} md={12} sm={12} xs={12} container
          sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress sx={{ color: '#FFAC0B' }} />
        </Grid>
        :
        <Grid
          container
          spacing={2}
          sx={{ mt: 3, pb: 5 }}
          className={"table_light ml"}
        >
          <Grid item lg={12}>
            <Typography className="device_view_head">Edit Sensor</Typography>
          </Grid>
          <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Sensor Id
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="Sensor Id"
              // onChange={(e) => handleSensorData(e.target.value, "sensor_name")}
              disabled
              value={sensorData.sensor_id}
            // error={!!validationErrors.sensor_name}
            // helperText={validationErrors.sensor_name}
            />
          </Grid>
          <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Sensor Name
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="Sensor Name"
              onChange={(e) => handleSensorData(e.target.value, "sensor_name")}

              value={sensorData.sensor_name}
              error={!!validationErrors.sensor_name}
              helperText={validationErrors.sensor_name}
            />
          </Grid>
          <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light form_text_mob"}
              sx={{ color: "black", mb: 2 }}
            >
              Distance-1
            </InputLabel>

            <TextField
              className={"form_field_right form_field_light"}
              placeholder="Distance-1"
              value={sensorData.distance1}
              type="number"
              onChange={(e) => handleSensorData(e.target.value, "distance1")}
              error={!!validationErrors.distance1}
              helperText={validationErrors.distance1}
            />
          </Grid>
          <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Distance-2
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="Distance-2"
              value={sensorData.distance2}
              type="number"
              onChange={(e) => handleSensorData(e.target.value, "distance2")}
              error={!!validationErrors.distance2}
              helperText={validationErrors.distance2}
            />
          </Grid>
          <Grid item tem xs={10} sm={10} lg={10} md={10}>
            <StyledCreateSmallButton
              onClick={handleCrete}
              sx={{ mt: 5, width: 150 }}
              variant="contained"
              disableRipple
            >
              Update
            </StyledCreateSmallButton>
          </Grid>
        </Grid>
      }
    </Layout>
  );
};
