import { Grid } from '@mui/material';
import React from 'react';
import { ListenerListItem } from './ListenerListItem';
import { Paginations } from '../../../sharable/pagination/Pagination';
export const ListenerList =({deviceListData,deviceTabListValue,isDarkMode,pageCount,page,
    handlePageChange,search})=>{
    const handleView=(data)=>{
        sessionStorage.setItem('viewListenerId',data);
        sessionStorage.setItem('DeviceName',deviceTabListValue);

        window.location.href='/tracking/device/view' 
    }
    return(
        <Grid container columnSpacing={3}>
            {deviceListData.length && deviceListData !== 'undefined' ?
            <>
           { deviceListData.map((device,index)=>(
            <Grid item xs={12} sm={12} lg={4} md={4} sx={{mt:5}} key={index}>
                <ListenerListItem
                DeviceDetails={device}
                handleView={handleView}
                />
            </Grid>
            ))}
            <Grid item xs={12} sm={12} lg={12} md={12} sx={{ mt:1 }}>
                            <Paginations
                                isDarkMode={isDarkMode}
                                pageCount={pageCount}
                                page={page}
                                onChange={handlePageChange}
                            />
                       </Grid>
            </>
            :<Grid item xs={12} sm={12} lg={4} md={4} sx={{mt:5}}>No Data Found</Grid>
            }
        </Grid>
    )
}