import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchEncoderDropDown,
  fetchSensorReport,
  fetchSurveillancePerformance,
  fetchTagBatteryAlert,
} from "../../../redux/actions/AlertActions";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { convertMinutesToHMS, sensorDatefieldConvertor, utcDateTimeToLocalTime } from "../../convertors/TimeConvertor";
import { MonthYearFilter } from "../trackingAlerts/MonthYearFilter";
import { SensorAlertChart } from "./SensorSingleAlertChart";
import { SurveillancePerformanceChart } from "./SurveillancePerformanceChart";
import { CircularProgress, Grid } from "@mui/material";
import * as XLSX from "xlsx";

export const SurveillancePerformance = ({ handlePdfExport }) => {
  const dispatch = useDispatch();
  const siteData = useSelector((state) => state.userRole.siteData);
  const trackingReportData = useSelector(
    (state) => state.alert.surveillancePerformance
  );
  const encoderData = useSelector((state) => state.alert.encoderDropdown);

  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState("");
  const [encoder, SetEncoder] = useState([]);
  const [selectedEncoder, setSelectedEncoder] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [trackingDatas, setTrackingDatas] = useState("");
  const [trackingDataList, setTrackingDataList] = useState("");
  const [size, setSize] = useState(550)

  const [petrolDatas, setPetrolDatas] = useState("");
  const [totalDatas, setTotalDatas] = useState("");
  const [sentryDatas, setSentryDatas] = useState("");
  const [trackingnames, setTrackingNames] = useState("");
  const [exportMenu, setExportMenu] = useState(false);
  const [exportType, setExportType] = useState('')
  const [exportModalAlert, setExportModalAlert] = useState(false);

  useEffect(() => {
    dispatch(fetchSites());
    const currentDate = new Date();
    setSelectedYear(currentDate.getFullYear());
    setSelectedMonth(String(currentDate.getMonth() + 1).padStart(2, "0")); // Adding 1 since getMonth() returns 0-11
    // fetchTrackingReport
  }, []);
  useEffect(() => {
    if (
      !siteData.isFetching &&
      siteData.payload &&
      siteData.payload.success === true
    ) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp,
        };
      });
      setSites(temp);
      const siteId = sessionStorage.getItem("user_site_id");
      // setSelectedSites(siteId)
      if (siteId != 0) {
        setSelectedSites(siteId);
      }
    }
    if (
      !trackingReportData.isFetching &&
      trackingReportData.payload &&
      trackingReportData.payload.success === true
    ) {
      const trackingReport = trackingReportData.payload.data;
      setTrackingDatas(trackingReport);
      setTrackingDataList(trackingReportData.payload.list);
      const length = trackingReport.length;
      const ChartSize = calculateSize(length);

      setSize(ChartSize)
      const category = [];
      let seriesLength = 0;
      trackingReport.map((e) => {
        category.push(e.date);
        const historyLength = category.length;


        if (historyLength > seriesLength) {
          seriesLength = historyLength;
        }
      })
      const series = []
      trackingReport.map((e) => {
        const historyLength = e.gateway_history.length;
        if (historyLength > 0) {
          const item = new Array(seriesLength).fill(0);
          for (let i = 0; i < historyLength; i++) {
            const history = e.gateway_history[i];

            const updatedItem = [...item];  // Clone the item array
            updatedItem[e.dayorder - 1] = parseFloat((history.differenceInMinutes / 60).toFixed(2));

            series.push({
              name: history.online === true ? 'Online' : 'Offline',
              color: history.online ? '#36F097' : '#C52525',
              data: updatedItem,
              value: history.differenceInMinutes,
              key: e.date,
              from_time: utcDateTimeToLocalTime(history.from_date),
              to_time: utcDateTimeToLocalTime(history.to_date)

            })
          }
          //)
        } else {
          series.push({
            name: 'Offline',
            color: '#C52525',
            data: new Array(seriesLength).fill(0),
            key: e.date

          })
        }
      })
      const formattedSeries = updateLegendVisibility(series)
      function updateLegendVisibility(data) {
        let onlineShown = false;
        let offlineShown = false;

        return data.map(series => {
          if (series.name === 'Online' && !onlineShown) {
            onlineShown = true;
            return series; // Keep the first 'Online' series as is
          }
          if (series.name === 'Offline' && !offlineShown) {
            offlineShown = true;
            return series; // Keep the first 'Offline' series as is
          }
          return { ...series, showInLegend: false }; // Set 'showInLegend' to false for all others
        });
      }

      setTrackingNames(category);
      setTotalDatas(formattedSeries)
    }


  }, [siteData, trackingReportData]);
  useEffect(() => {
    if (
      !encoderData.isFetching &&
      encoderData.payload &&
      encoderData.payload.success === true
    ) {
      const encoderList = encoderData.payload.list;
      SetEncoder(encoderList);
      if (encoderList.length != 0) {
        const tempEncoder = encoderList[0]?.machine_id;
        setSelectedEncoder(tempEncoder)
      }
      else {
        setSelectedEncoder("")
      }
    }
  }, [encoderData])
  useEffect(() => {
    let data = {
      // site_id: selectedSites,
      month: selectedMonth,
      year: selectedYear,
      machine_id: selectedEncoder,
    };

    dispatch(fetchSurveillancePerformance(data));
  }, [selectedMonth, selectedYear, selectedEncoder]);
  useEffect(() => {
    let data = {
      site_id: selectedSites,
    };
    dispatch(fetchEncoderDropDown(data));
  }, [selectedSites]);
  const handleSites = (value) => {
    setSelectedSites(value);
  };
  const handleEncoder = (value) => {
    setSelectedEncoder(value);
  };
  const handleSelectedYear = (value) => {
    setSelectedYear(value);
  };
  const handleSelectedMonth = (value) => {
    setSelectedMonth(value);
  };
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => currentYear - index); // Last 10 years
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const handleChartClick = (value) => {
    const id = selectedEncoder;
    window.location.href = `/report/view/${id}`;
    sessionStorage.setItem("redirect_Date", value)
  }
  const handleExportModal = () => {
    setExportMenu(!exportMenu);
    setExportType('');
    setExportModalAlert(false);
  }
  const handleExporttype = (value) => {
    // setExportMenu(false);

    setExportType(value)
  }
  const handleExcelExport = () => {
    const firstEntryDate = Object.values(trackingDatas)[0].date;
    const firstEntryMonth = new Date(firstEntryDate).toLocaleString('default', { month: 'long' });
    const firstEntryYear = new Date(firstEntryDate).getFullYear();

    // Convert data to array of arrays
    const exportData = Object.keys(trackingDataList).map(key => {
      const { title, machine_id, online, from_date, to_date, differenceInMinutes } = trackingDataList[key];
      return [title, machine_id, online == true ? "Online" : "Offline", sensorDatefieldConvertor(from_date), sensorDatefieldConvertor(to_date), convertMinutesToHMS(differenceInMinutes)];
    });

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      [`Surveillance Performance Alerts - ${selectedMonth} ${selectedYear}`], // Title row
      [], // Empty row for spacing
      ['title', 'Machine Id', 'Status', 'From Date & Time', 'To Date & Time', 'Duration'], // Header row
      ...exportData // Data rows
    ]);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Surveillance Performance Data');

    // Construct filename based on the first entry's date
    const filename = `surveillanceperformancealert_${selectedMonth.toLowerCase()}/${selectedYear}.xlsx`;

    // Export the workbook as a .xlsx file with the constructed filename
    XLSX.writeFile(wb, filename);

  }
  const confirmExport = () => {
    if (exportType == "") {
      setExportModalAlert(true);
    }
    else {
      setExportModalAlert(false);
      if (exportType == "excel") {
        handleExcelExport();
        setExportType('');
        setExportMenu(!exportMenu);
      }
      else {
        handlePdfExport("surveillanceReport");
        setExportType('');
        setExportMenu(!exportMenu);
      }
    }
  }
  const calculateSize = (length) => {
    if (length <= 5) {
      return 550;
    }
    else
      return 550 + ((length - 5) * 75);
  }
  return (
    <div id="print" className="print">
      <MonthYearFilter
        sites={sites}
        selectedSite={selectedSites}
        handleSelectedSite={handleSites}
        handleSelectedYear={handleSelectedYear}
        selectedYear={selectedYear}
        years={years}
        selectedMonth={selectedMonth}
        handleSelectedMonth={handleSelectedMonth}
        months={months}
        isSurveillance={true}
        handleEncoder={handleEncoder}
        selectedEncoder={selectedEncoder}
        encoder={encoder}
        handleExport={handleExportModal}

      />
      {trackingReportData.isFetching ?
        <Grid item lg={12} md={12} sm={12} xs={12} container
          sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress sx={{ color: '#FFAC0B' }} />
        </Grid>
        :
        totalDatas.length != 0 ?
          <SurveillancePerformanceChart
            data={totalDatas}
            //sentryData={sentryDatas}
            trackingDates={trackingnames}
            handleChartClick={handleChartClick}
            handleExportMenu={handleExportModal}
            exportMenu={exportMenu}
            confirmExport={confirmExport}
            handleExporttype={handleExporttype}
            exportModalAlert={exportModalAlert}
            size={size}

          />
          :
          <Grid
            item
            lg={11.8}
            sm={11.8}
            xs={11.8}
            sx={{ mt: "6% !important", display: 'flex', justifyContent: 'center' }}
          >
            No Data Found
          </Grid>
      }
    </div>
  );
};
