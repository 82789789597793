import { all, put, takeLatest } from "redux-saga/effects";
import { deviceActionType, fetchDeviceError, fetchDeviceSuccess, fetchSingleDeviceError, fetchSingleDeviceSuccess, fetchSingleTagError, fetchSingleTagSuccess, fetchTagError, fetchTagSuccess, getRssiLevelError, getRssiLevelSuccess, updateRssiLevelError, updateRssiLevelSuccess } from "../actions/DeviceActions";
import { fetchDeviceListData, fetchSingleDeviceData, fetchSingleTagData, fetchTagListData, getRssiLevelDetails, updateRssiLevelDetails } from "../../services/DeviceServices";
//upddate 
function* _editRssiLevel(action) {

    try {
        const rssi = yield updateRssiLevelDetails(action.data);
        yield put(updateRssiLevelSuccess(rssi));
    } catch (e) {
        yield put(updateRssiLevelError("error"));
    }
}
function* editRssiLevel() {
    yield takeLatest(deviceActionType.UPDATE_RSSI, _editRssiLevel)
}
//get rssi 
function* _fetchRssiLevel(action) {

    try {
        const rssi = yield getRssiLevelDetails(action.data);
        yield put(getRssiLevelSuccess(rssi));
    } catch (e) {
        yield put(getRssiLevelError("error"));
    }
}
function* fetchRssiLevel() {
    yield takeLatest(deviceActionType.GET_RSSI, _fetchRssiLevel)
}
//fetch single tag 
function* _getSingleTag(action) {

    try {
        const tagInfo = yield fetchSingleTagData(action.id);
        yield put(fetchSingleTagSuccess(tagInfo));
    } catch (e) {
        yield put(fetchSingleTagError("error"));
    }
}
function* getSingleTag() {
    yield takeLatest(deviceActionType.FETCH_SINGLE_TAG, _getSingleTag)
}
//fetch taglist 
function* _getTagList(action) {

    try {
        const tagList = yield fetchTagListData(action.id);
        yield put(fetchTagSuccess(tagList));
    } catch (e) {
        yield put(fetchTagError("error"));
    }
}
function* getTagList() {
    yield takeLatest(deviceActionType.FETCH_TAG, _getTagList)
}
//fetch single listener/bridge 
function* _getSingleDevice(action) {

    try {
        const deviceInfo = yield fetchSingleDeviceData(action.id);
        yield put(fetchSingleDeviceSuccess(deviceInfo));
    } catch (e) {
        yield put(fetchSingleDeviceError("error"));
    }
}
function* getSingleDevice() {
    yield takeLatest(deviceActionType.FETCH_SINGLE_DEVICE, _getSingleDevice)
}
//fetch listener/bridge list
function* _getDeviceList(action) {

    try {
        const deviceInfo = yield fetchDeviceListData(action.id);
        yield put(fetchDeviceSuccess(deviceInfo));
    } catch (e) {
        yield put(fetchDeviceError("error"));
    }
}
function* getDeviceList() {
    yield takeLatest(deviceActionType.FETCH_DEVICE, _getDeviceList)
}
export function* DeviceSaga(){
    yield all([getDeviceList(),getSingleDevice(),getTagList(),getSingleTag(),fetchRssiLevel(),editRssiLevel()])
}