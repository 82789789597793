import { all, put, takeLatest } from "redux-saga/effects";
import {
    addCheckPointError, addCheckPointSuccess, checkPointActionType,
    deleteCheckPointError,
    deleteCheckPointSuccess,
    fetchCheckPointError,
    fetchCheckPointListError, fetchCheckPointListSuccess, fetchCheckPointSuccess, fetchCheckPointTypeError,
    fetchCheckPointTypeSuccess,
    updateCheckPointError,
    updateCheckPointSuccess
} from "../actions/CheckPointActions";
import {
    addNewCheckPointData, deleteCheckPointData, editCheckPointData, fetchCheckPointListData, fetchCheckPointTypeData,
    getSingleCheckPointData
} from "../../services/CheckPointServices";
//delete checkpoint 
function* _deleteCheckPoint(action) {

    try {
        const singleCheckPoint = yield deleteCheckPointData(action.data);

        yield put(deleteCheckPointSuccess(singleCheckPoint));
    } catch (error) {
      
        if(error.response && error.response.data && error.response.data){
            yield put(deleteCheckPointError( error.response.data));
        }else{
            yield put(deleteCheckPointError( error));
        }
       
    }
}
function* deleteCheckPoint() {
    yield takeLatest(checkPointActionType.DELETE_CHECKPOINT, _deleteCheckPoint)
}
//update checkpoint 
function* _editCheckPoint(action) {

    try {
        const singleCheckPoint = yield editCheckPointData(action.id,action.data);
        yield put(updateCheckPointSuccess(singleCheckPoint));
    } catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(updateCheckPointError( error.response.data));
        }else{
            yield put(updateCheckPointError( error));
        }
        //yield put(updateCheckPointError("error"));
    }
}
function* editCheckPoint() {
    yield takeLatest(checkPointActionType.UPDATE_CHECKPOINT, _editCheckPoint)
}
//fetch single checkpoint 
function* _getCheckPoint(action) {

    try {
        const singleCheckPoint = yield getSingleCheckPointData(action.id);
        yield put(fetchCheckPointSuccess(singleCheckPoint));
    } catch (e) {
        yield put(fetchCheckPointError("error"));
    }
}
function* getCheckPoint() {
    yield takeLatest(checkPointActionType.FETCH_CHECKPOINT, _getCheckPoint)
}
//add checkpoint 
function* _addNewCheckPoint(action) {

    try {
        const checkPointInfo = yield addNewCheckPointData(action.data);
        yield put(addCheckPointSuccess(checkPointInfo));
    } catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(addCheckPointError( error.response.data));
        }else{
            yield put(addCheckPointError( error));
        }
      
    }
}
function* addNewCheckPoint() {
    yield takeLatest(checkPointActionType.ADD_CHECKPOINT, _addNewCheckPoint)
}
//fetch checkpoint type
function* _getCheckPointType(action) {

    try {
        const CheckPointTypeInfo = yield fetchCheckPointTypeData(action);
        yield put(fetchCheckPointTypeSuccess(CheckPointTypeInfo));
    } catch (e) {
        yield put(fetchCheckPointTypeError("error"));
    }
}
function* getCheckPointType() {
    yield takeLatest(checkPointActionType.FETCH_CHECKPOINT_TYPE, _getCheckPointType)
}
//fetch checkpoint list
function* _getCheckPointList(action) {

    try {
        const CheckPointListInfo = yield fetchCheckPointListData(action.data);
        yield put(fetchCheckPointListSuccess(CheckPointListInfo));
    } catch (e) {
        yield put(fetchCheckPointListError("error"));
    }
}
function* getCheckPointList() {
    yield takeLatest(checkPointActionType.FETCH_CHECKPOINT_LIST, _getCheckPointList)
}
export function* CheckPointSaga() {
    yield all([getCheckPointList(), getCheckPointType(), addNewCheckPoint(), getCheckPoint(),editCheckPoint(),
        deleteCheckPoint()])
}