import React, { useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { subDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css'
import '../../dashboard/Dashboard.css'
export const DatePick = ({ handleDateChange, isDarkMode, selectedDate, tableDatePick }) => {

    return (
        <DatePicker
            // selected={selectedStartDate}
            onChange={handleDateChange}
            className={tableDatePick ?
                isDarkMode ? "table_datepic_color_dark table_datepic" : "table_datepic table_datepic_color" :
                isDarkMode ? "datepic" : "datepic_light"}

            customInput={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CiCalendar
                        style={{
                            marginLeft: '8px',
                            cursor: 'pointer',
                            marginRight: '8px',
                            fontSize: '25px',
                            color: isDarkMode ? "white" : "#8F8F91"
                        }}

                    />
                    <input
                        type="text"
                        placeholder="select date"
                        className={isDarkMode ? "datepic_bg" : "datepic_bg_light"}
                        value={selectedDate ? selectedDate.toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',}) : ''}
                        readOnly
                    />

                </div>
            }
            dateFormat="MM/DD/yyyy"
            placeholderText="Select a date"
        />
    )
}