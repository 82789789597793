import { Grid, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { MenuProps } from '../../../Styles';

export const ViewFilter = ({ selectedSites, handleSites, sites, levels,isDrag,
    selectedLevel, handleLevels, blocks, selectedBlocks, handleBlocks, handleSelectedStatus, selectedStatus ,isAssigned}) => {
    return (
        <Grid container sx={{ mt: 4 }} columnSpacing={2}>

            <Grid item lg={2.5} md={2.3} xs={12} sm={12}>
                <Grid container className="mt2">
                    <Grid item sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}
                            className={"headersubhead light_text"}>
                            Sites :
                        </Typography>

                    </Grid>
                    <Grid item lg={4} sm={5} xs={5} md={4}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: "#161616", ml: 1, minWidth: 135,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={"headerSelect select_light"}
                            value={selectedSites}
                            onChange={(e) => handleSites(e.target.value)}
                        >   
                        {/* <MenuItem value={''}>All</MenuItem> */}
                            {sites.length >0 ?
                            sites.map((site, index) => (
                                <MenuItem
                                    key={index}
                                    value={site.organizationId}>
                                    {site.organizationName}
                                </MenuItem>
                            ))
                        :
                        <MenuItem
                        >
                            No Sites Found
                    </MenuItem>
                        }


                        </Select>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item lg={2.5} md={2.3} xs={12} sm={12} sx={{ mr: 1 }}>
                <Grid container className="mt2">
                    <Grid item sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}
                            className={"headersubhead light_text"}>
                            Level :
                        </Typography>

                    </Grid>
                    <Grid item lg={4} sm={5} xs={5} md={4}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: "#161616", ml: 1, minWidth: 140,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={"headerSelect select_light"}
                            value={selectedLevel}
                            onChange={(e) => handleLevels(e.target.value)}
                        >
                            {/* <MenuItem value={''}>All</MenuItem> */}
                            {levels.length >0 ?
                            levels.map((level, index) => (
                                <MenuItem
                                    key={index}
                                    value={level.layerId}>
                                    {level.layerName}
                                </MenuItem>
                            )) :
                            <MenuItem
                            value=""
                            >
                                No Levels Found
                        </MenuItem>
                            }


                        </Select>
                    </Grid>

                </Grid>
            </Grid>
            {isDrag &&
            <Grid item lg={2.5} md={2.2} xs={12} sm={12}>
                <Grid container className="mt2">
                    <Grid item sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}
                            className={"headersubhead light_text"}>
                            Block :
                        </Typography>

                    </Grid>
                    <Grid item lg={4} sm={5} xs={5} md={4}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: "#161616", ml: 1, minWidth: 130,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={"headerSelect select_light"}
                            value={selectedBlocks}
                            onChange={(e) => handleBlocks(e.target.value)}
                        >   
                            {blocks.length > 0 ?
                            blocks.map((block, index) => (
                                <MenuItem
                                    key={index}
                                    value={block.roomId}>
                                    {block.block_name}
                                </MenuItem>
                            )):
                            <MenuItem
                            value=""
                            >
                                No Blocks Found
                        </MenuItem>
                        }


                        </Select>
                    </Grid>

                </Grid>
            </Grid>
           }
        </Grid>
    )
}