import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { MenuProps, Search, SearchIconWrapper, StyledInputBase } from "../../Styles";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

export const ReportHeader = ({ isDarkMode, isCreate,activeSubtab,searchWord ,activeMenuItem,onSearchWordChange,
  placeholder}) => {
    const navigate = useNavigate();
    const handleBack = () => {
      navigate(-1);
    }
  return (
    <>  {isCreate === true ?
      <>
        <Grid item lg={8.5} sm={12} xs={12} md={8.5} className="" sx={{ textAlign: 'center' }}>
          <Grid container>
            <Grid item>
              <IconButton sx={{ mt: 0, }}
                onClick={handleBack}
              >
                <ArrowBackIosNewOutlinedIcon sx={{ color: isDarkMode ? "white" : "#000000" }} fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className={isDarkMode ? "headerhead headerhead_dark" : "headerhead headerhead_light"}> 
              Report
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </> :
      <>
        <Grid item
          lg={8.5} sm={12} xs={12} md={8.5}
          className="ml" sx={{ textAlign: 'center' }}>
          <Typography sx={{ textAlign: 'center', mt: 1 }} className={isDarkMode ? "headerhead headerhead_dark" : "headerhead headerhead_light"}>
         Report
          </Typography>
        </Grid>
        {/* <Grid item lg={6.9} sm={12} xs={12} md={6.9}>
            <Grid item lg={9} sm={11} xs={11} md={9}>
            <Search isDarkMode={isDarkMode}>
                            <SearchIconWrapper>
                                <SearchIcon sx={{ color: isDarkMode ? "white" : "#8F8F91" }} fontSize="medium" className="mb" />
                            </SearchIconWrapper>
                            <StyledInputBase
                                isDarkMode={isDarkMode}
                                placeholder={placeholder}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchWord}
                                onChange={(e)=>onSearchWordChange(e.target.value)}
                            />
                        </Search>
            </Grid>
                        
            <Grid item lg={2} sm={1} xs={1} md={2}> 
            </Grid>  
                    </Grid> */}

      </>}
      </>
  
  )
}