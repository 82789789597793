import { Grid, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { UserListTable } from '../../userManagement/UserListTable';
import { Paginations } from '../../sharable/pagination/Pagination';
import { MenuProps } from '../../Styles';
import { StyledCreateButton } from '../../styles/ButtonStyles';
import { MdOutlineAddToPhotos } from "react-icons/md";

export const OrgUsers = ({ orgUserList, currentUserPage, pageCount, handlePageChange, isDarkMode, sites, 
    selectedUserSiteId , handleUserSites }) => {
    const handleView = (id) => {
        sessionStorage.setItem("orgsanisation_org_userid",id)
        window.location.href='/organisation/user/view';

    }
    const handleCreate = (id) => {
        // alert(id)
        window.location.href='/organisation/user/create'

    }
    return (
        <Grid container>
            <Grid item lg={2} md={2} sm={12} xs={12} className="mt2" sx={{ mt: 2 }}>
                <Typography className='alert_text_light'>
                    List Of Users
                </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
                <Grid container className="mt2">
                    <Grid item sx={{ mt: 2 }}>
                        <Typography sx={{ textAlign: 'center' }}
                            className={isDarkMode ? "alert_text_light " : "alert_text_light "}>
                            Sites :
                        </Typography>

                    </Grid>
                    <Grid item lg={4} sm={9} xs={9} md={4}>
                        <Select
                            //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                            sx={{
                                color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 180,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                    border: '',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(233, 233, 233, 1)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: isDarkMode ? "white !important" : "#161616",
                                }
                            }}

                            displayEmpty
                            MenuProps={MenuProps}
                            className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                            value={selectedUserSiteId}
                            onChange={(e) => handleUserSites(e.target.value)}
                            
                        ><MenuItem value={""}> All</MenuItem>
                            {sites.length ? 
                        
                            sites.map((site, index) => (
                                <MenuItem
                                    key={index}
                                    value={site.organizationId}>
                                    {site.organizationName}
                                </MenuItem>
                            ))


                            :
                            <MenuItem>No Sites Found </MenuItem>
                        }
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={3.8} sm={11} xs={11} md={3.8} container className="alignR"
            sx={{'@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important',mt:"2px !important" }}} >
                <StyledCreateButton
                
                    variant="contained"
                    disableRipple
                    startIcon={<MdOutlineAddToPhotos color="white" />}
                 onClick={handleCreate}
                >
                    Create User
                </StyledCreateButton>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="mt2" sx={{ mb: 2 }}>
                <UserListTable
                    TableData={orgUserList}
                    handleView={handleView}
                    pageCount={pageCount}
                    currentPage={currentUserPage}
                    handlePageChange={handlePageChange}
                    
                />
                {/* {orgUserList.length && orgUserList != undefined ?
                    <div>
                        <Paginations
                            isDarkMode={isDarkMode}
                            pageCount={pageCount}
                            page={currentUserPage}
                            onChange={handlePageChange}
                        />
                    </div> : <></>
                } */}
            </Grid>

        </Grid>
    )
}