import { API } from "./Api";


/**
 * savePersona 
 *
 * @param
 */
// export function savePersona(data) {
//     return new Promise((resolve, reject) => {
//         API.post("/persona/", data).then(res => {
//             resolve(res.data);
//         }).catch(err => {
//             if (err.response) {// client received an error response (5xx, 4xx)
//             } else if (err.request) {
//             } else if (err.messages) {
//                 // client never received a response, or request never left
//             } else {
//                 // anything else
//             }
//             return false;
//         });
//     })
// }

export function savePersona(data) {
  return new Promise((resolve, reject) => {
    API.post("/persona/", data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        // Check for error response from the server
        if (err.response) {
          // Client received an error response (4xx, 5xx)
          console.error('Error response:', err.response);
          reject(err.response.data || 'An error occurred while saving persona.');
        } else if (err.request) {
          // Client never received a response or request never left
          console.error('Error request:', err.request);
          reject('No response received from the server.');
        } else {
          // Anything else
          console.error('Error message:', err.message);
          reject('An error occurred: ' + err.message);
        }
      });
  });
}

/**
 * savePersona 
 *
 * @param
 */
export function getUserPersona() {
    const id = sessionStorage.getItem('id');
    return new Promise((resolve, reject) => {
        API.get("/persona/" + id).then(res => {
            resolve(res.data);
        })
        // .catch(err => {
        //     if (err.response) {// client received an error response (5xx, 4xx)
        //     } else if (err.request) {
        //     } else if (err.messages) {
        //         // client never received a response, or request never left
        //     } else {
        //         // anything else
        //     }
        //     return false;
        // });
        .catch(err => {
            // Check for error response from the server
            if (err.response) {
              // Client received an error response (4xx, 5xx)
              console.error('Error response:', err.response);
              reject(err.response.data || 'An error occurred while saving persona.');
            } else if (err.request) {
              // Client never received a response or request never left
              console.error('Error request:', err.request);
              reject('No response received from the server.');
            } else {
              // Anything else
              console.error('Error message:', err.message);
              reject('An error occurred: ' + err.message);
            }
          });
    })
}