
import axios from 'axios';
import { API } from './Api';
const organisation = '/organisation/';
const baseUrl = process.env.REACT_APP_API_BASE_URL + '/' + process.env.REACT_APP_API_VERSION;

export const fetchOrgTypeData = async () => {
    const response = await API.get(organisation + 'org_type')
    return response.data;


}
//org block
export const fetchOrgBlockData = async (data) => {
    const start = data && data.start !== undefined ? data.start : 0;
    const limit = data && data.limit !== undefined ? data.limit : 20;
    const site_id = data && data.id !== undefined ? data.id : 0;

    const response = await API.get(organisation + `org_block?site_id=${site_id}&start=${start}&limit=${limit}`)
    return response.data;


}
export const addNewOrgData = async (data) => {
    // // console.log("response data ser",data)
    // // console.log("before hiiii")
    // const response = await API.post(organisation, data)
    // // console.log("hiiii")
    // if (response.status >= 200 && response.status < 300) {
    //     //    console.log("Success:", response.data);
    // } else {
    //     //    console.error("Error:", response.status, response.data);
    // }
    // return response.data;
    const response = await axios({
        method: "post",
        url: `${baseUrl}${organisation}`,
        data: data,
        headers: { "Content-Type": "multipart/form-data",
            'Authorization':'Bearer ' +sessionStorage.getItem('token')

         },


    })
    return response.data;
}
//get org list
export const fetchOrgListData = async (data) => {
    const start = data && data.start !== undefined ? data.start : 0;
    const limit = data && data.limit !== undefined ? data.limit : 20;
    const searchword = data && data.searchword !== undefined ? data.searchword : '';

    const from_date = data && data.from_date !== undefined ? data.from_date : '';
    const to_date = data && data.to_date !== undefined ? data.to_date : '';
    const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
    const is_wisilica = data && data.is_wisilica !== undefined ? data.is_wisilica : '';


    const response = await API.get(`${organisation}?start=${start}&limit=${limit}&search=${searchword}&from_date=${from_date}&to_date=${to_date}&org_id=${org_id}&is_wisilica=${is_wisilica}`);
    return response.data;


}
//get single org 
export const fetchSingleOrgData = async (id) => {

    const response = await API.get(organisation + id);
    //   console.log("Success:", response.data);
    return response.data;
}
//delete single org 
export const removeSingleOrgData = async (data) => {

    const response = await API.put(organisation, data);
    //   console.log("Success:", response.data);
    return response.data;
}
//orgdropdown
export const getOrgDropDowList = async () => {

    const response = await API.get(`${organisation}org_dropdown`);
    //   console.log("Success:", response.data);
    return response.data;
}


export const editOrgDetails = async (id, data) => {

    const response = await axios({
        method: "put",
        url: `${baseUrl}${organisation}${id}`,
        data: data,
        headers: { "Content-Type": "multipart/form-data",
            'Authorization':'Bearer ' +sessionStorage.getItem('token')

         },

    })
    //   console.log("Success:", response.data);
    return response.data;
}

//delete single sites 
export const removeSingleSitesData = async (data) => {

    const response = await API.post(organisation + 'deletelayer', data);
    //   console.log("Success:", response.data);
    return response.data;
}
//get single sites 
export const getOrgLevelDetails = async (id) => {

    const response = await API.get(organisation + 'getlayer/' + id);
    //   console.log("Success:", response.data);
    return response.data;
}








export const getOrgMapList = () => {
    return new Promise((resolve, reject) => {
        API.get("/map?start=0&limit=100").then(res => {
            resolve(res.data);
        })
        // .catch(err => {
        // })
        .catch(err => {
            // Check for error response from the server
            if (err.response) {
              // Client received an error response (4xx, 5xx)
              console.error('Error response:', err.response);
              reject(err.response.data || 'An error occurred while saving persona.');
            } else if (err.request) {
              // Client never received a response or request never left
              console.error('Error request:', err.request);
              reject('No response received from the server.');
            } else {
              // Anything else
              console.error('Error message:', err.message);
              reject('An error occurred: ' + err.message);
            }
          });
    })
}


export const getOrgLevelList = (filter) => {
    return new Promise((resolve, reject) => {
        API.get("/checkpoints/levels?" + new URLSearchParams(Object.assign({}, filter))).then(res => {
            resolve(res.data);
        })
        .catch(err => {
            // Check for error response from the server
            if (err.response) {
              // Client received an error response (4xx, 5xx)
              console.error('Error response:', err.response);
              reject(err.response.data || 'An error occurred while saving persona.');
            } else if (err.request) {
              // Client never received a response or request never left
              console.error('Error request:', err.request);
              reject('No response received from the server.');
            } else {
              // Anything else
              console.error('Error message:', err.message);
              reject('An error occurred: ' + err.message);
            }
          });
    })
}

// export const getOrgTagLivePosition = (filter) => {
//     return new Promise((resolve, reject) => {
//         API.get("/map/tags?" + new URLSearchParams(Object.assign({}, filter))).then(res => {
//             resolve(res.data);
//         }).catch(err => {
//         })
//     })
// }

export const getOrgTagLivePosition = (filter) => {
    return new Promise((resolve, reject) => {
        API.get("/map/contractortags?" + new URLSearchParams(Object.assign({}, filter))).then(res => {
            resolve(res.data);
        }).catch(err => {
            // Check for error response from the server
            if (err.response) {
              // Client received an error response (4xx, 5xx)
              console.error('Error response:', err.response);
              reject(err.response.data || 'An error occurred while saving persona.');
            } else if (err.request) {
              // Client never received a response or request never left
              console.error('Error request:', err.request);
              reject('No response received from the server.');
            } else {
              // Anything else
              console.error('Error message:', err.message);
              reject('An error occurred: ' + err.message);
            }
          });
    })
}


export const addOrganisationSites = async (data) => {
    const response = await axios({
        method: "post",
        url: `${baseUrl}${organisation + 'buildsite'}`,
        data: data,
        headers: { "Content-Type": "multipart/form-data",
            'Authorization':'Bearer ' +sessionStorage.getItem('token')
         },

    })
    return response.data;
}
export const editOrganisationSites = async (data) => {
    const response = await axios({
        method: "post",
        url: `${baseUrl}${organisation + 'editbuildsite'}`,
        data: data,
        headers: { "Content-Type": "multipart/form-data",
            'Authorization':'Bearer ' +sessionStorage.getItem('token')
         },

    })
    return response.data;
}

export function orgBuildSite(payload) {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${baseUrl}${organisation + '/buildsite'}`,
            data: payload,
            headers: { "Content-Type": "multipart/form-data" },

        }).then(res => {
            if (res.data.success) {
                resolve(res.data)
            } 
        })
        // .catch(err => {
        //     if (err.response) {// client received an error response (5xx, 4xx)
        //     } else if (err.request) {
        //     } else if (err.messages) {
        //         err.messages.forEach(element => {
        //         });
        //         // client never received a response, or request never left
        //     } else {
        //         // anything else
        //     }
        //     return false;
        // });
        .catch(err => {
            // Check for error response from the server
            if (err.response) {
              // Client received an error response (4xx, 5xx)
              console.error('Error response:', err.response);
              reject(err.response.data || 'An error occurred while saving persona.');
            } else if (err.request) {
              // Client never received a response or request never left
              console.error('Error request:', err.request);
              reject('No response received from the server.');
            } else {
              // Anything else
              console.error('Error message:', err.message);
              reject('An error occurred: ' + err.message);
            }
          });
    })
}
export function orgBuildLevel(payload) {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${baseUrl}${organisation + 'buildlevel'}`,
            data: payload,
            headers: { "Content-Type": "multipart/form-data" },

        }).then(res => {
            if (res.data.success) {
                resolve(res.data)
            }
        })
        // .catch(err => {
        //     if (err.response) {// client received an error response (5xx, 4xx)
        //     } else if (err.request) {
        //     } else if (err.messages) {
        //         err.messages.forEach(element => {
        //         });
        //         // client never received a response, or request never left
        //     } else {
        //         // anything else
        //     }
        //     return false;
        // });
        .catch(err => {
            // Check for error response from the server
            if (err.response) {
              // Client received an error response (4xx, 5xx)
              console.error('Error response:', err.response);
              reject(err.response.data || 'An error occurred while saving persona.');
            } else if (err.request) {
              // Client never received a response or request never left
              console.error('Error request:', err.request);
              reject('No response received from the server.');
            } else {
              // Anything else
              console.error('Error message:', err.message);
              reject('An error occurred: ' + err.message);
            }
          });
    })
}

//add telegram
export const addNewTelegramData = async (data) => {
    const response = await API.post(organisation +'updatetelegram',data);
    return response.data;


}
export const getTelegramListData = async (data) => {
    const start = data && data.start !== undefined ? data.start : 0;
    const limit = data && data.limit !== undefined ? data.limit : 20;
    const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
    const is_wisilica = data && data.is_wisilica !== undefined ? data.is_wisilica : '';
    const response = await API.get(`${organisation}getallsite/${org_id}?start=${start}&limit=${limit}&is_wisilica=${is_wisilica}`);
    return response.data;


}
export const getSingleTelegramData = async (id) => {
    
    // const site_id = data && data.site_id !== undefined ? data.site_id : '';
    const response = await API.get(`${organisation}getsitedetails/${id}`);
    return response.data;


}