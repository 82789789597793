import { Grid, IconButton, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Search, SearchIconWrapper, StyledInputBase } from "../../Styles";
import SearchIcon from '@mui/icons-material/Search';
import { StyledCreateButton } from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { SensorListTable } from "./SensorListTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSensorList } from "../../../redux/actions/SurveillanceAction";
import { subDays } from "date-fns";
import { dateSearchConverter } from "../../convertors/TimeConvertor";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

export const SensorList = ({ selectedStartDate, selectedEndDate, isWidget, removeWidget, id }) => {
  const dispatch = useDispatch();
  const sensorListData = useSelector(state => state.surveillance.sensorList);
  const [sensorList, setSensorList] = useState([]);
  // const [selectedStartDate, setSelectedStartDate] = useState(subDays(new Date(), 20));
  // const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;


  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;
    let data = {
      start: start,
      limit: limit,
      searchWord: search,
      to_date: selectedEndDate != undefined ? dateSearchConverter(selectedEndDate) : "",
      from_date: selectedStartDate != undefined ? dateSearchConverter(selectedStartDate) : "",
    }
    dispatch(fetchSensorList(data));
  }, [currentPage, search, selectedEndDate, selectedStartDate]);
  useEffect(() => {
    if (
      !sensorListData.isFetching &&
      sensorListData.payload &&
      sensorListData.payload.success === true
    ) {
      const tempSensorList = sensorListData.payload.data;
      const tempPagination = sensorListData.payload.pagination;
      setPageCount(tempPagination.total_pages)

      setSensorList(tempSensorList);
    }
  }, [sensorListData]);

  const handleAddSensor = ({ }) => {
    window.location.href = '/surveillance/sensor/create';
  }
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  }
  return (
    <Grid container sx={{ mt: 3 }}
    >
      {
        isWidget &&
        <>
          <Grid
            item
            lg={10}
            sm={10}
            md={10}
            xs={10}
            style={{ display: "flex", justifyContent: "flex-start" }}
          // sx={{ ml: -2 }}
          > <Typography className="headersubhead light_text htl">
              Sensor List
            </Typography>
          </Grid>

          <Grid
            item
            lg={2}
            sm={2}
            md={2}
            xs={2}
            style={{ display: "flex", justifyContent: "flex-end" }}
            sx={{ ml: -2 }}
          >
            <IconButton
              aria-label="settings"
              onClick={() => {
                removeWidget(id);
              }}
            >
              <IndeterminateCheckBoxOutlinedIcon
                style={{ color: "#FFAC0B" }}
              />
            </IconButton>
          </Grid>
          <Grid
            item
            lg={5}
            sm={10}
            md={5}
            xs={10}
            style={{ display: "flex", justifyContent: "flex-start" }}
            sx={{ ml: -2 }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#8F8F91" }} fontSize="medium" className="mb" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={"Search Sensor Name"}
                inputProps={{ 'aria-label': 'search' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Search>
          </Grid>
        </>
      }

      {!isWidget &&
        <>
          <Grid
            item
            lg={4}
            sm={10}
            md={4}
            xs={10}
            style={{ display: "flex", justifyContent: "flex-start" }}
            sx={{ ml: -2 }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#8F8F91" }} fontSize="medium" className="mb" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={"Search Sensor Name"}
                inputProps={{ 'aria-label': 'search' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Search>
          </Grid>
          <Grid
            item
            lg={7}
            sm={10}
            md={7}
            xs={10}
            sx={{ '@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important', mt: '5px !important' } }}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <StyledCreateButton
              sx={{ paddingBottom: "1% !important", paddingTop: "1% !important" }}
              variant="contained"
              disableRipple
              startIcon={<MdOutlineAddToPhotos color="white" />}
              onClick={handleAddSensor}
            >
              Add Sensor
            </StyledCreateButton>
          </Grid>
        </>

      }
      <Grid item lg={12} sm={12} md={12} xs={12}>
        {
          sensorListData.isFetching ?
            <Grid item lg={12} md={12} sm={12} xs={12} container
              sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
              <CircularProgress sx={{ color: '#FFAC0B' }} />
            </Grid>
            :
            sensorList.length && sensorList != 'undefined' ?
              <SensorListTable
                sensorList={sensorList}
                pageCount={pageCount}
                page={currentPage}
                onChange={handlePageChange}

              />
              :
              <Grid item lg={12} md={12} sm={6} xs={6} className="mt2" sx={{ ml: 3, mt: 6 }}>
                <Typography >No Sensor found to Show </Typography>
              </Grid>
        }
      </Grid>
    </Grid>
  )
};
