import React, { useState, useEffect } from "react";
import { Alert, Avatar, Box, Card, CardHeader, Grid, IconButton, MenuItem, Modal, Select, Snackbar, Table, TableHead, TableRow, Typography } from "@mui/material";
import { ImportModalstyle } from "../styles/ModalStyle";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

import CloseIcon from '@mui/icons-material/Close';
import './ModalStyle.css';
import { StyledCreateSmallButton } from "../styles/ButtonStyles";
import { importDateConverter, importDateTimeConverter } from "../convertors/TimeConvertor";
import { useDispatch } from "react-redux";
import { clearImportPersonnelsStatus, importPersonnels } from "../../redux/actions/PersonnelAction";
import { useSelector } from "react-redux";
import { MenuProps } from "../Styles";
import { fetchSites } from "../../redux/actions/UserRoleActions";
import dayjs from "dayjs";
export const PersonnelImportModal = ({ handleImportModal, CloseSuccessImportModal, importModalOpen,
    dataFromExcel, handlePersonDelete, CloseImportModal }) => {
    const dispatch = useDispatch();
    const importStatus = useSelector(state => state.personnel.importPersonnel);
    const siteData = useSelector(state => state.userRole.siteData);
    const [sites, setSites] = useState([]);
    const [isError, setIsError] = useState(false);

    const [selectedSites, setSelectedSites] = useState('');
    const [validationErrors, setValidationErrors] = useState('')
    const [showAlert, setShowAlert] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const [alertMessage, setAlertmessage] = useState('')
    useEffect(() => {
        dispatch(fetchSites());
    }, [])
    useEffect(() => {
        if (!importStatus.isFetching && importStatus.payload && importStatus.payload.success === true && importStatus.payload.error === false) {
            CloseImportModal();


            sessionStorage.setItem('trackingTab', 'three')
            window.location.href = '/tracking';
        }
        if (!importStatus.isFetching && importStatus.payload && importStatus.payload.success === false && importStatus.payload.error === true) {
            const msg = importStatus.payload.message;
            setShowAlert(true);
            setIsError(true);
            setAlertmessage(msg)
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearImportPersonnelsStatus());
                CloseImportModal();
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
        if (!importStatus.isFetching && importStatus.payload && importStatus.payload.success === true && importStatus.payload.error === true) {
            const msg = importStatus.payload.message;
            setShowWarning(true);
            //setIsError(true);
            setAlertmessage(msg)
            const timeoutId = setTimeout(() => {
                setShowWarning(false);
                dispatch(clearImportPersonnelsStatus());

                CloseSuccessImportModal();
                // sessionStorage.setItem('trackingTab', 'three')
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
        }
    }, [importStatus, siteData])
    const datetoGmt = (item) => {
        let temp = dayjs(item, 'DD/MM/YYYY hh:mm A');
        const tempDate = temp.$d.toISOString();
        return tempDate;
    }
    const handleCreateAll = () => {
        if (selectedSites !== '') {
            setValidationErrors('');
            let permissionDate = dataFromExcel?.map(item => item["Permit Issuance Date"]);
            let tempStartDate = dataFromExcel?.map(item => item["Start Date & Time"]);
            let tempEndDate = dataFromExcel?.map(item => item["End Date & Time"]);
            //             let tempEndDate = dataFromExcel?.map(item => dayjs(item["End Date & Time"],'DD/MM/YYYY hh:mm A'));

            let pro_end = tempEndDate.map(item => {
                if (item.toUpperCase() == "NA") {
                    return null
                }
                else
                    return datetoGmt(item)
            }
            );
            let pro_start = tempStartDate.map(item => {
                if (item.toUpperCase() == "NA") {
                    return null
                }
                else
                    return datetoGmt(item)
            }
            )
            let data = {
                site_id: selectedSites,
                contractor_name: dataFromExcel.map(item => { return item["Name"] }),
                emp_id: dataFromExcel.map(item => { return item["Employee Id"] }),
                phone: dataFromExcel.map(item => { return String(item["Phone No"]) }),
                email: dataFromExcel.map(item => { return item["Mail Id"] }),
                organisation_name: dataFromExcel.map(item => { return item["Name of Awarded Contractor"] }),
                serial_no: dataFromExcel.map(item => { return item["Permit Serial Number"] }),
                insurace_date: permissionDate.map(item => {
                    if (item.toUpperCase() == "NA") {
                        return null
                    }
                    else
                        return importDateConverter(item)
                }
                ),
                domain: dataFromExcel.map(item => { return item["Domain/ Unit"] }),
                project: dataFromExcel.map(item => { return item["Project title"] }),
                project_startdate: pro_start,
                project_enddate: pro_end,
                project_maneger: dataFromExcel.map(item => { return item["Project Manager"] }),
                supervisor_phone: dataFromExcel.map(item => { return String(item["Supervisor Phone No"]) }),
                supervisor: dataFromExcel.map(item => { return item["Supervisor Name"] }),
                count: dataFromExcel.length,
                organization_id: parseInt(sessionStorage.getItem('org_Id')),
                created_user: parseInt(sessionStorage.getItem('id')),


            }


            dispatch(importPersonnels(data))
        }
        else {
            setValidationErrors('This field is mandatory')
        }
    }
    const handleSelectSite = (val) => {
        setSelectedSites(val);
        if (val !== '') {
            setValidationErrors('')
        }
        else {
            setValidationErrors('This field is mandatory')

        }
    }
    const handlealertClose = () => {
        setShowAlert(false)
        setShowWarning(false)
    }
    return (
        <Modal
            open={importModalOpen}
            onClose={CloseImportModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={ImportModalstyle}>

                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={5.5} xs={5.5}  >
                        <Typography
                            className='import_main_head'>Imported</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} container className="alignR">
                        <IconButton onClick={CloseImportModal}>
                            <CloseIcon sx={{ color: "#161616F" }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="card_divider" ></div>
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: '4% !important', pl: '1% !important' }}>
                        <Typography
                            className='import_main_sub'>Personal List</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: '4% !important', pl: '1% !important' }}>
                        <Grid container className="negativemt3">
                            <Grid item sx={{ mt: 2 }}>
                                <Typography sx={{ textAlign: 'center' }}
                                    className={"headersubhead light_text"}>
                                    Sites :
                                </Typography>
                            </Grid>
                            <Grid item lg={4} sm={5} xs={5} md={4}>
                                <Select
                                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                    sx={{
                                        color: "#161616", ml: 1, minWidth: 140,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: "#161616",
                                        }
                                    }}

                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={"headerSelect select_light"}
                                    value={selectedSites}
                                    onChange={(e) => handleSelectSite(e.target.value)}
                                >

                                    {
                                        sites.map((site, index) => (
                                            <MenuItem
                                                key={index}
                                                value={site.organizationId}>
                                                {site.organizationName}
                                            </MenuItem>
                                        ))
                                    }


                                </Select>
                                {validationErrors && (
                                    <Typography
                                        sx={{ color: '#d32f2f', ml: 2, mt: '2% !important' }}
                                        className={
                                            // isDarkMode
                                            //     ? 'errormargin  errorStyle '
                                            //     : 
                                            ' errormargin errorStyle'
                                        }
                                    >
                                        {validationErrors}
                                    </Typography>
                                )}
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} container className="alignR" sx={{ mt: '3% !important' }}>
                        <StyledCreateSmallButton
                            variant="contained"
                            disableRipple
                            startIcon={<MdOutlineAddToPhotos color="white" />}
                            onClick={handleCreateAll}
                        >

                            Create all
                        </StyledCreateSmallButton>
                    </Grid>
                </Grid>
                {/* {showAlert || showWarning ? */}

                <Grid container sx={{ mt: 2, justifyContent: 'center' }} >

                    <Snackbar
                        open={showAlert}
                        autoHideDuration={6000}
                        onClose={handlealertClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert
                            onClose={handlealertClose}
                            severity={isError ? "error" : "success"}
                            variant="outlined"
                            sx={{ width: '100%', color: isError ? '#C61A1A' : '#11b311', backgroundColor: isError ? '#FCDFDF' : '#5bdb5b73' }}

                        >
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={showWarning}
                        autoHideDuration={6000}
                        onClose={handlealertClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert
                            onClose={handlealertClose}
                            severity="warning"
                            sx={{ width: '100%' }}

                        >
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                    {/* {showWarning ?
                            < Grid item lg={11.7} md={11.7} sm={12} xs={12} >
                                <Alert variant="outlined" severity="warning">{alertMessage}</Alert>
                            </Grid> : <></>
                        } */}
                </Grid>
                {/* } */}
                <Grid container sx={{ overflowY: 'scroll', mt: 2, justifyContent: 'center' }} >

                    {dataFromExcel ?
                        dataFromExcel.map((person, index) => (
                            < Grid item lg={11.7} sx={{ border: '2px solid #D9D7D7', borderRadius: '5px', mt: 3, p: 3 }} container>

                                {/* <Grid item lg={12} md={12} sm={12} xs={12}  > */}
                                <Grid item container lg={12} md={12} sm={12} xs={12} sx={{ mb: 2 }}>
                                    <Avatar
                                        sx={{ bgcolor: '#F8E3C4', color: '#FFAC0B', padding: 1 }}
                                        alt={person.Name}

                                    >            {person.Name.charAt(0)}
                                    </Avatar>
                                    <Grid item sx={{ pl: 1, mt: 1 }} lg={8} md={8} sm={7} xs={7}>
                                        <Typography className="import_list_head">
                                            {person.Name}
                                        </Typography>
                                        <Typography className="import_list_subhead">
                                            {person["Employee Id"]}
                                        </Typography>
                                    </Grid>
                                    <Grid item container className="alignR" lg={3.3} md={3.3} sm={2} xs={2}>
                                        <IconButton
                                            sx={{ justifyContent: 'right' }}
                                            onClick={() => handlePersonDelete(person["Employee Id"])}
                                        >
                                            <RiDeleteBin6Line color="#C61A1A" />
                                        </IconButton>
                                    </Grid >
                                    {/* </Grid> */}

                                </Grid>



                                {/* <Grid Container sx={{flexDirection:'row ',display:'flex'}}> */}

                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml '>
                                        <Typography className=' label_color import_label'>Mail Id</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Mail Id"]}</Typography>
                                    </div>
                                </Grid>

                                {/* <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml'>
                                        <Typography className='label_color import_label'>Sites</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Sites"]}</Typography>
                                        </div>
                                    </Grid> */}

                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml'>
                                        <Typography className='label_color import_label'>Permit Serial Number</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Permit Serial Number"]}</Typography>
                                    </div>
                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml ' >
                                        <Typography className='label_color import_label'>Permit Issuance Date</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Permit Issuance Date"]}</Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml'>
                                        <Typography className='label_color import_label'>Domain/ Unit</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Domain/ Unit"]}</Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml'>
                                        <Typography className='label_color import_label'>Project title</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Project title"]}</Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml'>
                                        <Typography className='label_color import_label'>Start Date</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Start Date & Time"]}</Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml'>
                                        <Typography className='label_color import_label'>End Date </Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["End Date & Time"]}</Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv borderbottom rowdiv_ml'>
                                        <Typography className='label_color import_label'>Project Manager</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Project Manager"]}</Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv  rowdiv_ml'>
                                        <Typography className='label_color import_label'>Name of Awarded Contractor</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Name of Awarded Contractor"]}</Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv  rowdiv_ml'>
                                        <Typography className='label_color import_label'>Supervisor Name</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Supervisor Name"]}</Typography>
                                    </div>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} >
                                    <div className='rowdiv  rowdiv_ml'>
                                        <Typography className='label_color import_label'>Supervisor Contact No</Typography>
                                        <Typography className='import_text_width content_color import_text'>{person["Supervisor Phone No"]}</Typography>
                                    </div>
                                </Grid>

                                {/* </Grid> */}
                            </Grid>
                        )) : <></>}
                </Grid>




            </Box>

        </Modal >
    )
}