import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchTrackingReport } from "../../../redux/actions/AlertActions";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { ReportSquareChart } from "./ReportSqareChart";
import { MonthYearFilter } from "./MonthYearFilter";
import { CircularProgress, Grid } from "@mui/material";
import * as XLSX from "xlsx";

export const TrackingPatrolSentry = ({ handlePdfExport }) => {
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const siteData = useSelector((state) => state.userRole.siteData);
  const trackingReportData = useSelector((state) => state.alert.trakingReport);

  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [trackingDatas, setTrackingDatas] = useState("");
  const [petrolDatas, setPetrolDatas] = useState("");
  const [totalDatas, setTotalDatas] = useState("");
  const [sentryDatas, setSentryDatas] = useState("");
  const [trackingDates, setTrackingDates] = useState("");
  const [exportMenu, setExportMenu] = useState(false);
  const [exportType, setExportType] = useState('')
  const [exportModalAlert, setExportModalAlert] = useState(false);
  const [size, setSize] = useState(550);

  useEffect(() => {
    dispatch(fetchSites());
    const currentDate = new Date();
    setSelectedYear(currentDate.getFullYear());
    setSelectedMonth(String(currentDate.getMonth() + 1).padStart(2, "0")); // Adding 1 since getMonth() returns 0-11
    // fetchTrackingReport
  }, []);
  useEffect(() => {
    if (
      !siteData.isFetching &&
      siteData.payload &&
      siteData.payload.success === true
    ) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp,
        };
      });
      setSites(temp);
      const siteId = sessionStorage.getItem("user_site_id");
      // setSelectedSites(siteId)
      if (siteId != 0) {
        setSelectedSites(siteId);
      }
    }
    if (
      !trackingReportData.isFetching &&
      trackingReportData.payload &&
      trackingReportData.payload.success === true
    ) {
      const trackingReport = trackingReportData.payload.data;
      const length = Object.keys(trackingReport).length;
      const ChartSize = calculateSize(length);

      setSize(ChartSize);

      setTrackingDatas(trackingReport);

      const dates = [];
      const patrolData = [];
      const sentryData = [];
      const totalData = [];

      // Extract data from JSON
      for (const [date, values] of Object.entries(trackingReport)) {
        dates.push(date);
        patrolData.push(values.patrol);
        sentryData.push(values.sentry);
        totalData.push(values.total);
      }
      setPetrolDatas(patrolData);
      setSentryDatas(sentryData);
      setTotalDatas(totalData);
      setTrackingDates(dates)
    }
  }, [siteData, trackingReportData]);
  useEffect(() => {
    let data = {
      site_id: selectedSites,
      month: selectedMonth,
      year: selectedYear,
    };
    dispatch(fetchTrackingReport(data));
  }, [selectedSites, selectedMonth, selectedYear]);
  const handleSites = (value) => {
    setSelectedSites(value);
  };
  const handleSelectedYear = (value) => {
    setSelectedYear(value);
  };
  const handleSelectedMonth = (value) => {
    setSelectedMonth(value);
  };
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => currentYear - index); // Last 10 years
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const handleExportModal = () => {
    setExportMenu(!exportMenu);
    setExportType('');
    setExportModalAlert(false);
  }
  const handleExcelExport = () => {
    const firstEntryDate = Object.values(trackingDatas)[0].date;
    const firstEntryMonth = new Date(firstEntryDate).toLocaleString('default', { month: 'long' });
    const firstEntryYear = new Date(firstEntryDate).getFullYear();

    // Convert data to array of arrays
    const exportData = Object.keys(trackingDatas).map(key => {
      const { date, patrol, sentry, total } = trackingDatas[key];
      return [date, patrol, sentry, total];
    });

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      [`Tracking Violation Alerts - ${firstEntryMonth} ${firstEntryYear}`], // Title row
      [], // Empty row for spacing
      ['Date', 'Patrol', 'Sentry', 'Total'], // Header row
      ...exportData // Data rows
    ]);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Tracking Data');

    // Construct filename based on the first entry's date
    const filename = `trackingviolationalert_${firstEntryMonth.toLowerCase()}${firstEntryYear}.xlsx`;

    // Export the workbook as a .xlsx file with the constructed filename
    XLSX.writeFile(wb, filename);

  };
  const exportChartToImage = (trackingDatas) => {
    if (chartRef.current) {
      const firstEntryDate = Object.values(trackingDatas)[0].date;
      const firstEntryMonth = new Date(firstEntryDate).toLocaleString('default', { month: 'long' });
      const firstEntryYear = new Date(firstEntryDate).getFullYear();

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Worksheet for tracking data
      const exportData = Object.keys(trackingDatas).map(key => {
        const { date, patrol, sentry, total } = trackingDatas[key];
        return [date, patrol, sentry, total];
      });
      const wsData = XLSX.utils.aoa_to_sheet([
        [`Tracking Violation Alerts - ${firstEntryMonth} ${firstEntryYear}`], // Title row
        [], // Empty row for spacing
        ['Date', 'Patrol', 'Sentry', 'Total'], // Header row
        ...exportData // Data rows
      ]);

      // Worksheet for chart image
      if (chartRef && chartRef.current) {
        const chart = chartRef.current.chart; // Assuming chartRef.current contains the chart instance
        chart.exportChart({
          type: "image/png",
          filename: "chart-image",
          callback: function (url) {
            const wsChart = XLSX.utils.aoa_to_sheet([
              [`Chart Image - ${firstEntryMonth} ${firstEntryYear}`], // Title row
              [], // Empty row for spacing
              [ // Chart image
                { t: "s", v: url, l: { Target: url } },
              ]
            ]);


            XLSX.utils.book_append_sheet(wb, wsData, 'Tracking Data');

            XLSX.utils.book_append_sheet(wb, wsChart, 'Chart Image');
            // Construct filename based on the first entry's date
            const filename = `trackingviolationalert_${firstEntryMonth.toLowerCase()}${firstEntryYear}.xlsx`;

            // Export the workbook as a .xlsx file with the constructed filename
            XLSX.writeFile(wb, filename);

            // Automatically open the downloaded image in Excel (if Excel is the default application for PNG/JPEG)
            //window.open(url);
          },

        });
      }
    }
  };
  const handleExporttype = (value) => {
    // setExportMenu(false);

    setExportType(value)
  }
  const confirmExport = () => {
    if (exportType == "") {
      setExportModalAlert(true);
    }
    else {
      setExportModalAlert(false);
      if (exportType == "excel") {
        handleExcelExport();
        // exportChartToImage();
        setExportType('');
        setExportMenu(!exportMenu);
      }
      else {
        handlePdfExport("trackingViolationReport");
        setExportType('');
        setExportMenu(!exportMenu);
      }
    }
  }
  const calculateSize = (length) => {
    if (length <= 5) {
      return 550;
    }
    else
      return 550 + ((length - 5) * 75);
  }

  return (
    <div >
      <MonthYearFilter
        sites={sites}
        selectedSite={selectedSites}
        handleSelectedSite={handleSites}
        handleSelectedYear={handleSelectedYear}
        selectedYear={selectedYear}
        years={years}
        selectedMonth={selectedMonth}
        handleSelectedMonth={handleSelectedMonth}
        months={months}
        //  handleExport={handleExportModal}
        handleExport={handleExportModal}
      />
      {trackingReportData.isFetching ?
        <Grid item lg={12} md={12} sm={12} xs={12} container
          sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress sx={{ color: '#FFAC0B' }} />
        </Grid>
        :
        trackingDates.length != 0 ?
          <ReportSquareChart chartOptions={trackingDatas}
            patrolData={petrolDatas}
            sentryData={sentryDatas}
            totalData={totalDatas}
            trackingDates={trackingDates}
            trackingDatas={trackingDatas}
            // handleExportModal={handleExportModal}
            handleExportMenu={handleExportModal}
            exportMenu={exportMenu}
            confirmExport={confirmExport}
            handleExporttype={handleExporttype}
            exportModalAlert={exportModalAlert}
            size={size}
            chartRef={chartRef}
          />

          :
          <Grid
            item
            lg={11.8}
            sm={11.8}
            xs={11.8}
            sx={{ mt: "6% !important", display: 'flex', justifyContent: 'center' }}
          >
            No Data Found
          </Grid>}
    </div>
  );
};
