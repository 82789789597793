import { API } from './Api';
const device ='/device'
//get listener list
export const fetchListenerListData = async (id) => {
    
    
    const response = await API.get(device+'?site_id='+id+'&type=');
    return response.data;


}
//get assigned listener 
export const fetchAssignedListenerData = async (id) => {
    
    
    const response = await API.get('/checkpoints/assigned_tag?site_id='+id);
    return response.data;


}
// assign listener 
export const assignListenerData = async (data) => {
    
    
    const response = await API.post('/checkpoints/assign',data);
    return response.data;


}
export const unAssignListenerData = async (data) => {
    
    
    const response = await API.post('/checkpoints/unassign',data);
    return response.data;


}