import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { YellowBorderButton } from "../styles/ButtonStyles";
import { TagModalstyle ,CameraTagModalstyle} from "../styles/ModalStyle";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
export const CameraViewModal = ({ openViewOption, handleView ,handleStatusModal,handleStream, camera}) => {
  return (
    <>
      <Modal
        open={openViewOption}
        onClose={handleView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={CameraTagModalstyle}>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton onClick={handleView}>
                <CloseOutlinedIcon
                  sx={{ fontSize: "30px", mr: "5px", color: "#161616" }}
                />
              </IconButton>
            </Grid>
            <Grid>
            <Typography className="map_popup_label">
              {camera?.title}
              </Typography>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              marginTop: "25% !important",
              justifyContent: "space-around",
            }}
          >
            <YellowBorderButton
            
            onClick={handleStream}>
              <img
                src={require("../../assets/icons/cctvIcon.png")}
                alt="cctvIcon"
                className="camIcon"
              />{" "}
              <span className="dark_text"> View Stream</span>
            </YellowBorderButton>
            <YellowBorderButton sx={{ ml: 3 }}
            onClick={handleStatusModal}
            >
              <AutorenewOutlinedIcon
                sx={{ fontSize: "30px", mr: "5px", color: "#939393" }}
              />
              <span className="dark_text">Unassign Camera</span>
            </YellowBorderButton>
          </div>
        </Box>
        {/* YellowBorderButton */}
      </Modal>
    </>
  );
};
