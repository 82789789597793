import { Grid, Typography } from '@mui/material';
import React from 'react';
export const UserDetails =({AlertDetails})=>{
    return(
        <div className='device_view reducepb' >
        <Grid container >
            <Grid item container sx={{ mb: 1 }}>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                        <Typography className='device_view_text label_color rowdiv_label'>Name</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {AlertDetails.contractorname}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }}>
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Tag Id
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                            {AlertDetails.duty_type === 0 ?AlertDetails.infantName :AlertDetails.infant_name}
                           
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr' style={{ paddingBottom: "20px" }} >
                        <Typography className='device_view_text label_color rowdiv_label'>Employee Id</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                           {AlertDetails.empid}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' style={{ paddingBottom: "20px" }}>
                        <Typography className='device_view_text label_color rowdiv_label'>
                           Role
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text negativemb3 '>
                        <div className="alert_stage_box alert_medium" >
                                <span className=" medium_text">Personnel</span>
                            </div>

                           
                           
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv rowdiv_mr'>
                        <Typography className='device_view_text label_color rowdiv_label'>Mail Id</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                        {AlertDetails.email}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv rowdiv_ml'>
                        <Typography className='device_view_text label_color rowdiv_label'>Phone </Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                        {AlertDetails.phone}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            </Grid>
            </div>
    )
}