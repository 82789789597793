import { Alert, Grid, InputAdornment, Snackbar, TextField, Typography } from "@mui/material";
import React from "react";
import { PlainActiveButton, PlainButton, StyledMoreOnButton } from "../styles/ButtonStyles";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addGetInTouch, clearAddGetInTouchStatus } from "../../redux/actions/UserRoleActions";
import { useEffect } from "react";
import { validateEmail } from "../helpers/ValidationHelper";
import { Padding } from "@mui/icons-material";
export const MoreOn = ({ }) => {
  const dispatch = useDispatch();
  const getInTouchStatus = useSelector(state => state.userRole.getInTouch);
  const [getInTouchMailHeader, setGetInTouchMailHeader] = useState("");
  const [getInTouchMailFooter, setGetInTouchMailFooter] = useState("");
  const [headerValidation, setHeaderValidation] = useState('');
  const [footerValidation, setFooterValidation] = useState('');

  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertmessage] = useState(false);

  useEffect(() => {
    if (!getInTouchStatus.isFetching && getInTouchStatus.payload && getInTouchStatus.payload.success === true) {
      setShowAlert(true);
      const msg = getInTouchStatus.payload.message;
      setGetInTouchMailHeader("");
      setGetInTouchMailFooter("");
      setAlertmessage(msg);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearAddGetInTouchStatus());

        // sessionStorage.setItem('trackingTab', 'six')
        //     window.location.href = '/tracking';
      }, 2000);

    }
    if (!getInTouchStatus.isFetching && getInTouchStatus.error === true) {
      const message = getInTouchStatus.errorMessage.message;
      setAlertmessage(message)

      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearAddGetInTouchStatus());
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [getInTouchStatus])
  const handleHeaderChange = (value) => {
    setGetInTouchMailHeader(value);
    const validEmail = validateEmail(value);
    if (validEmail) {
      setHeaderValidation('')
    }
    else
      setHeaderValidation('Invalid Email')
  }
  const handleFooterChange = (value) => {
    setGetInTouchMailFooter(value);
    const validEmail = validateEmail(value);
    if (validEmail) {
      setFooterValidation('')
    }
    else
      setFooterValidation('Invalid Email')
  }
  const handleGetInTouchHeader = () => {
    let data = {
      email: getInTouchMailHeader
    }
    if (getInTouchMailHeader.trim() === '') {
      setHeaderValidation('This field is mandatory');
    }
    else if (headerValidation != '') {
      let temp = headerValidation;
      setHeaderValidation(temp)
    }
    else if (getInTouchMailHeader.trim() !== '' && headerValidation == '') {
      setHeaderValidation('');
      dispatch(addGetInTouch(data));

    }
  }
  const handleGetInTouchfooter = () => {
    let data = {
      email: getInTouchMailFooter
    }
    if (getInTouchMailFooter.trim() === '') {
      setFooterValidation('This field is mandatory');
    }
    else if (footerValidation != '') {
      let temp = footerValidation;
      setFooterValidation(temp)
    }
    else if (getInTouchMailFooter.trim() !== '' && footerValidation == '') {
      setFooterValidation('');
      dispatch(addGetInTouch(data));
    }

  }
  const handleLogin = () => {
    window.location.href = '/'
  }
  const handleContactUs = () => {
    window.location.href = '/contactus'

  }
  const handlealertClose = () => {
    setShowAlert(false);
  }
  return (
    <div style={{ height: "100%", marginBottom: "0px !important " }}>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} className="moreon_fisrtdiv">
          <Grid container>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <img
                src={require("../../assets/img/loginlogo.png")}
                alt="login logo"
                className="moreonlogo"
              />

            </Grid>

            <Grid
              item
              lg={8}
              md={8}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <PlainActiveButton>More on VIDSafe</PlainActiveButton>
              <PlainButton onClick={handleContactUs}>Contact US</PlainButton>
              <PlainButton onClick={handleLogin}>Login </PlainButton>
            </Grid>
            <Grid item lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}>
              <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert
                  onClose={handlealertClose}
                  severity={isError ? "error" : "success"}
                  variant="outlined"
                  sx={{ width: '100%', color: isError ? '#C61A1A' : '#11b311', backgroundColor: isError ? '#FCDFDF' : '#5bdb5b73' }}

                >
                  {alertMessage}
                </Alert>
              </Snackbar>
              <div className="moremaindiv">
                <Typography className="moreonheaderhead">
                  Transforming Cities with Green IoT Technology.
                </Typography>
                <Typography className="moreonheadersub">
                  Our innovative green IoT technology simplifies operation and enhances safety in smart buildings, cities, and beyond.
                </Typography>

              </div>
            </Grid>
            <Grid item lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "center", marginTop: "3%", color: "white !important" }}>
              <TextField className="mergedinput textPlaceColor" sx={{ color: "white !important" }}
                placeholder="Enter Your Email"
                onChange={(e) => handleHeaderChange(e.target.value)}
                value={getInTouchMailHeader}
                error={!!headerValidation}
                helperText={headerValidation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlinedIcon sx={{ color: "#D5D5D5" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <StyledMoreOnButton className="mergedbutton"
                sx={{ pl: "20px !important", pr: "20px !important" }}
                onClick={handleGetInTouchHeader}
              >Submit</StyledMoreOnButton>
            </Grid>
            {/* <Grid item lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}>
              
                <Typography className="moreonsubhead">
                Simplified tracking and monitoring with VIDSafe.
                </Typography>
                
              </Grid> */}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="" sx={{
          mt: 7,
          //  ml: "3%" 

        }}>
          <Grid container>
            <Grid item lg={7.5} md={7.5} sm={12} xs={12} style={{ display: "flex", justifyContent: "center" }} sx={{ mt: 9 }}>
              <div className="" style={{ Padding: 2 }}>
                <Typography className="moreonhead plm">
                  Environmentally friendly iot solutions.
                </Typography>
                <Typography className="moreonsubhead moreonpadd" >
                  Our VIDSafe technology minimizes energy consumption and reduces carbon footprint, offering eco-friendly solutions to smart cities and buildings.
                </Typography>

              </div>

            </Grid>
            <Grid item lg={4} md={4} xs={12} sm={12}>
              <div style={{ alignContent: "center" }}>
                <img
                  src={require("../../assets/img/greenIot.png")}
                  alt="greenIOT"
                  className="grrenIotPic"
                />
              </div>

            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="" sx={{ mt: 7, }}>
          <Grid container>
            <Grid item lg={4} md={4} xs={12} sm={12} style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ alignContent: "center" }}>

                <img
                  src={require("../../assets/img/trackingcam.png")}
                  alt="Tracking"
                  className="grrenIotPic"
                />
              </div>
            </Grid>
            <Grid item lg={7.5} md={7.5} sm={12} xs={12} style={{ display: "flex", justifyContent: "center" }} sx={{ mt: 9 }}>
              <div className="">
                <Typography className="moreonhead">
                  Simplified monitoring with VIDSafe.
                </Typography>
                <Typography className="moreonsubhead">
                  VIDSafe is an ultra-low bandwidth video monitoring with real time geo-tracking that simplifies the operation and enhancing safety and security.
                </Typography>

              </div>

            </Grid>

          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="" sx={{ mt: 7, mb: "5%" }}>
          <Grid container>
            <Grid item lg={7.5} md={7.5} sm={12} xs={12} style={{ display: "flex", justifyContent: "center" }} sx={{ mt: 9 }}>
              <div className="">
                <Typography className="moreonhead plm">
                  Connect your city with citywire.
                </Typography>
                <Typography className="moreonsubhead moreonpadd">
                  Our CityWire platform offers a reliable wireless connection for all your IoT sensors, providing seamless connectivity and real-time data analysis.
                </Typography>

              </div>

            </Grid>
            <Grid item lg={4} md={4} xs={12} sm={12}>
              <div style={{ alignContent: "center" }}>
                <img
                  src={require("../../assets/img/city.png")}
                  alt="city"
                  className="grrenIotPic"
                />
              </div>

            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="moreon_footer">
          <Grid container>
            <Grid item lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}>
              <div className="morefooterdiv">
                <Typography className="moreonfooter">
                  Join us in building sustainable smart cities with Green IoT technology.
                </Typography>
              </div>
              {/* <div class="buttonIn">
        <TextField className="mergedinput" type="text" id="enter" />
        <Button className="mergedbutton" id="clear">clear</Button>
    </div> */}
            </Grid>
            <Grid item lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
              <TextField className="mergedinput textPlaceColor" sx={{ color: "green !important" }}
                placeholder="Enter Your Email"
                onChange={(e) => handleFooterChange(e.target.value)}
                value={getInTouchMailFooter}
                error={!!footerValidation}
                helperText={footerValidation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlinedIcon sx={{ color: "#D5D5D5" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <StyledMoreOnButton
                sx={{ pl: "20px !important", pr: "20px !important" }}

                onClick={handleGetInTouchfooter}
                className="mergedbutton">Submit</StyledMoreOnButton>
            </Grid>
            <Grid item lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}>
              <div className="morefooterdiv">
                <Typography className="apx_watermark">
                  APX Technologies Pte Ltd All rights reserved. 2024
                </Typography>
              </div>

            </Grid>
            <Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
