import { Alert, Grid, IconButton, InputLabel, Snackbar, TextField, Typography } from "@mui/material";
import React from "react";
import { StyledGreyButton } from "../Styles";
import './Athentication.css';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { clearForgotPassword, forgotPassword } from "../../redux/actions/AuthenticationActions";
import { useEffect } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { validateEmail } from "../helpers/ValidationHelper";

export const ForgotPassword = ({ handleOtp }) => {
    const dispatch = useDispatch();
    const forgotStatus = useSelector(state => state.login.forgotData);
    const [email, setEmail] = useState('');
    const [showAlert, setAlert] = useState(false);
    const [alerText, setAlertText] = useState("");
    const [validationErrors, setValidationErrors] = useState('');
    useEffect(() => {
        if (!forgotStatus.isFetching && forgotStatus.payload && forgotStatus.payload.success === true) {
            window.location.href = '/otp-verification';

        }
        else if (!forgotStatus.isFetching && forgotStatus.error) {
            if (forgotStatus.errorMessage.success === false) {
                const message = forgotStatus.errorMessage.message;
                setAlertText(message)
            }
            setAlert(true);
            const timeoutId = setTimeout(() => {
                setAlert(false);
                dispatch(clearForgotPassword());
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [forgotStatus])
    const handleChange = (val) => {
        setEmail(val);
        const error = validateEmail(val);
        if (!error) {
            setValidationErrors('Invalid Email')
        }
        else {
            setValidationErrors('')
        }
    }
    const handleEmail = () => {
        let body = { email: email }

        if (body.email === '') {
            setValidationErrors('This field is mandatory')

        }
        else {

            setValidationErrors('');
            if (validationErrors == '') {
                 dispatch(forgotPassword(body));
                sessionStorage.setItem("forgotEmail", email);
            }
            else {
                let temp = validationErrors;
                setValidationErrors(temp)
            }
        }

    }
    const handleBack = () => {
        window.location.href = '/';
    }
    const handlealertClose = () => {
        setAlert(false)
    }
    return (
        <div style={{ height: '100%', }}>
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    {alerText}
                </Alert>
            </Snackbar>
            <Grid container style={{ height: '100%',padding:"5% !important" }} className="light_grey_bg pd5">
                <Grid item container lg={12} md={12} sm={12} xs={12} style={{ justifyContent: 'left' }}>
                    <IconButton
                        sx={{ backgroundColor: '#E7E7E7', opacity: '70%', borderRadius: 0, mt: 1 }}
                        onClick={handleBack}
                    >
                        <ArrowBackIcon sx={{ color: "#161616", opacity: '100%' }} fontSize="mediuam" />
                    </IconButton>
                </Grid>
                <Grid item container lg={12} md={12} sm={12} style={{ justifyContent: 'center' }}>
                    <Grid item container lg={6} md={6} sm={12} className="light_grey_bg login_div_container"   >
                        <div className="login_div1 loginpt1">


                            <Grid item container style={{ justifyContent: 'center' }}>
                                <img className="login_logo1" src={require("../../assets/img/logo.png")} alt="logo" />

                            </Grid>

                            {/* {showAlert ?
                                <Alert variant="outlined" severity="error">{alerText}</Alert> : <></>
                            } */}
                            <Typography
                                sx={{
                                    color: "black", fontSize: '28px !important', mb: 2,
                                    fontFamily: 'Nunito, sans-serif !important', fontWeight: 600, textAlign: 'center'
                                }}>
                                Enter Your Mail Id</Typography>
                            <InputLabel
                                className="forgottextlabel"
                                sx={{ color: "black", mb: 2 }}>
                                Mail Id
                            </InputLabel>
                            <TextField
                                value={email}
                                onChange={(e) => handleChange(e.target.value)}
                                className={"text"}
                                sx={{ mb: 5 }}
                                error={!!validationErrors}
                                helperText={validationErrors}
                                type="email"
                                inputProps={{ autoComplete: 'off' }}
                                id="forgotmail"
                                name={`forgotmail_${Math.random()}`}
                            />
                            <StyledGreyButton
                                className="full_width"
                                variant="contained"
                                disableRipple
                                onClick={handleEmail}
                            >
                                SUBMIT
                            </StyledGreyButton>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}