import { API } from './Api';
const personnel ='/contractor'
//get route list
export const fetchPersonnelListData = async (data) => {
     const start = data && data.start !== undefined ? data.start : 0;
     const limit = data && data.limit !== undefined ? data.limit : 15;
    const site_id = data && data.site_id !== undefined ? data.site_id :'';
     const type = data && data.type !== undefined ? data.type : '';
     const searchWord=data && data.searchWord !== undefined ? data.searchWord : '';
     const org_id =data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));
    const response = await API.get(`${personnel}?start=${start}&limit=${limit}&site_id=${site_id}&search=${searchWord}&type=${type}&org_id=${org_id}`);
    return response.data;
}
export const addNewPersonnelDetails = async (body) => {
    
    const response = await API.post(`${personnel}`,body);
    return response.data;
}
export const deletePersonnelDetails = async (body) => {
    
    const response = await API.put(`${personnel}`,body);
    return response.data;
}
export const deleteBulkPersonnelDetails = async (data) => {
    const response = await API.post(`${personnel}/bulk_delete`,data);
    return response.data;
}
export const viewPersonnelDetails = async (id) => {
    
    const response = await API.get(`${personnel}/${id}`);
    return response.data;
}
export const editPersonnelDetails = async (id,data) => {
    
    const response = await API.put(`${personnel}/${id}`,data);
    return response.data;
}
export const assignPersonnelDetails = async (data) => {
    
    const response = await API.post(`${personnel}/assign/`,data);
    return response.data;
}
export const unassignPersonnelDetails = async (data) => {
    
    const response = await API.post(`${personnel}/unassign/`,data);
    return response.data;
}

export const getPerssonnelTag = async (id) => {
    
    const response = await API.get(`${personnel}/tag_details/${id}`);
    return response.data;
}

export const importPersonnelsDetails = async (data) => {
    const response = await API.post(`${personnel}/import`,data);
    return response.data;
}