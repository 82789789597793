import { deviceActionType } from "../actions/DeviceActions"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    deviceList: dataTemplate,
    singleDevice: dataTemplate,
    tagList: dataTemplate,
    singleTag: dataTemplate,
    deviceListBridge: dataTemplate,
    rssiPrev:dataTemplate,
    rssi : dataTemplate
}
const DeviceReducer = (state = initialData, action) => {
    switch (action.type) {
        //fetch listener list
        case deviceActionType.FETCH_DEVICE:
            return {
                ...state,
                deviceList: {
                    ...state.deviceList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case deviceActionType.FETCH_DEVICE_BRIDGE:
            return {
                ...state,
                deviceListBridge: {
                    ...state.deviceListBridge,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case deviceActionType.FETCH_DEVICE_SUCCESS:
            return {

                ...state,
                deviceList: {
                    ...state.deviceList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case deviceActionType.FETCH_DEVICE_ERROR:
            return {
                ...state,
                deviceList: {
                    ...state.deviceList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch single device
        case deviceActionType.FETCH_SINGLE_DEVICE:
            return {
                ...state,
                singleDevice: {
                    ...state.singleDevice,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case deviceActionType.FETCH_SINGLE_DEVICE_SUCCESS:
            return {

                ...state,
                singleDevice: {
                    ...state.singleDevice,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case deviceActionType.FETCH_SINGLE_DEVICE_ERROR:
            return {
                ...state,
                singleDevice: {
                    ...state.singleDevice,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch tag
        case deviceActionType.FETCH_TAG:
            return {
                ...state,
                tagList: {
                    ...state.tagList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case deviceActionType.FETCH_TAG_SUCCESS:
            return {

                ...state,
                tagList: {
                    ...state.tagList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case deviceActionType.FETCH_TAG_ERROR:
            return {
                ...state,
                tagList: {
                    ...state.tagList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch  sinlge tag
        case deviceActionType.FETCH_SINGLE_TAG:
            return {
                ...state,
                singleTag: {
                    ...state.singleTag,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case deviceActionType.FETCH_SINGLE_TAG_SUCCESS:
            return {

                ...state,
                singleTag: {
                    ...state.singleTag,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case deviceActionType.FETCH_SINGLE_TAG_ERROR:
            return {
                ...state,
                singleTag: {
                    ...state.singleTag,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
             //Rssi get
        case deviceActionType.GET_RSSI:
            return {
                ...state,
                rssiPrev: {
                    ...state.rssiPrev,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case deviceActionType.GET_RSSI_SUCCESS:
            return {

                ...state,
                rssiPrev: {
                    ...state.rssiPrev,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case deviceActionType.GET_RSSI_ERROR:
            return {
                ...state,
                rssiPrev: {
                    ...state.rssiPrev,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
                //Rssi update
        case deviceActionType.UPDATE_RSSI:
            return {
                ...state,
                rssi: {
                    ...state.rssi,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case deviceActionType.UPDATE_RSSI_SUCCESS:
            return {

                ...state,
                rssi: {
                    ...state.rssi,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case deviceActionType.UPDATE_RSSI_ERROR:
            return {
                ...state,
                rssi: {
                    ...state.rssi,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case deviceActionType.CLEAR_RSSI_UPDATE_STATUS:
                return {
                    ...state,
                    rssi: {
                        ...state.rssi,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ""
                    }
                }
        default:
            return state
    }

}
export default DeviceReducer;