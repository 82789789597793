import { Grid, IconButton } from '@mui/material';
import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const LocationDetails =({personnelData})=>{
    return(
        <div className='location_view'>
                        <Grid item lg={4} className='text_alignment'>
                            <span className="route_text_div locationWid">Site:
                                <span>{personnelData.organization_name}</span>
                            </span>
                            <IconButton
                                style={{
                                    width: '5px', height: '5px', position: 'relative',
                                    marginTop: '-4px', marginLeft: '-13px'
                                }}
                                aria-label="arrow-down"
                            >
                                <ArrowRightIcon style={{ color: '#FFAC0B', fontSize: '25px' }} />
                            </IconButton>
                            <div className="dashed-line-left"></div>
                        </Grid>
                        <Grid item lg={4} className='text_alignment'>
                            <IconButton
                                style={{
                                    width: '5px', height: '5px', position: 'relative',
                                    marginTop: '-4px', marginLeft: '-13px'
                                }}
                                aria-label="arrow-down"
                            >
                                <ArrowRightIcon style={{ color: '#FFAC0B', fontSize: '25px' }} />
                            </IconButton>
                            <span className="route_text_div">Level:
                                <span>{personnelData.level_name}</span>
                            </span>
                            <IconButton
                                style={{
                                    width: '5px', height: '5px', position: 'relative',
                                    marginTop: '-4px', marginLeft: '-13px'
                                }}
                                aria-label="arrow-down"
                            >
                                <ArrowRightIcon style={{ color: '#FFAC0B', fontSize: '25px' }} />
                            </IconButton>
                            <div className="dashed-line"></div>
                        </Grid>
                        <Grid item lg={4} className='text_alignment'>
                            <IconButton
                                style={{
                                    width: '5px', height: '5px', position: 'relative',
                                    marginTop: '-4px', marginLeft: '-13px'
                                }}
                                aria-label="arrow-down"
                            >
                                <ArrowRightIcon style={{ color: '#FFAC0B', fontSize: '25px' }} />
                            </IconButton>
                            <span className="route_text_div">Block:
                                <span>{personnelData.block_name}</span>
                            </span>

                        </Grid>
                    </div>
    )
}