import React, { useEffect, useState } from "react";
import { StyledGreyButton, darkTheme, lightTheme, MenuProps } from "../Styles";
import { Alert, CircularProgress, Grid, MenuItem, Pagination, Select, Snackbar, ThemeProvider, Typography, useTheme } from "@mui/material";
import '../dashboard/Dashboard.css'
import { organisationTableData, organisationTableHead } from "../../assets/data/data";
import { Tables } from "../sharable/tables/Table";
import { useDispatch, useSelector } from "react-redux";
import { clearDeleteOrganisationStatus, deleteOrganisation, fetchOrganisationList } from "../../redux/actions/OrganisationActions";
import { Layout } from "../layout/Layout";
import { subDays } from "date-fns";
import { fetchUserRole } from "../../redux/actions/UserRoleActions";
import { fetchUser } from "../../redux/actions/UserActions";
import { StyledCreateButton } from "../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { Paginations } from "../sharable/pagination/Pagination";
import { DeleteModal } from "../Modals/DeleteModal";
import { dateOnly, dateSearchConverter } from "../convertors/TimeConvertor";
import dayjs from "dayjs";

export const OrganisationList = ({ }) => {
  const [organisationListData, setOrganisationListData] = useState([])
  const [org_type, setOrg_Type] = useState([]);

  //const orgTypeData = useSelector(state => state.organisation.organisationType);
  const dispatch = useDispatch();
  const orgList = useSelector(state => state.organisation.organasationList);
  const deleteOrgStatus = useSelector(state => state.organisation.deleteOrg);

  const theme = useTheme();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [isDarkMode, setDarkMode] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("organisation");
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [deleteOrgId, setDeleteOrgId] = useState(0);
  const [deleteOrgName, setDeleteOrgName] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [is_wisilica, setIs_wisilica] = useState(false);
  const [typeFilter, setTypeFilter] = useState("")
  // useEffect(() => {
  //   const start = (currentPage - 1) * itemsPerPage;
  //   const limit = itemsPerPage;
  //   let data = {
  //     to_date: dateSearchConverter(selectedEndDate),
  //     from_date: dateSearchConverter(selectedStartDate),
  //     limit: limit,
  //     start: start,
  //     is_wisilica:typeFilter
  //   }
  //   dispatch(fetchOrganisationList(data));
  // }, [])
  const dateFormat = (date) => {
    const tempdate = dateOnly(date)
    let formattedDate = dayjs(tempdate);

    return formattedDate.format("MM/DD/YYYY")
  }
  useEffect(() => {
    if (!orgList.isFetching && orgList.payload && orgList.payload.success === true) {
      const tempList = orgList.payload.org_list;
      const tempPaginationData = orgList.payload.pagination;
      const tempOrgList = tempList.map((item) => {
        return {

          created_at: dateFormat(item.created_at),
          organization_name: item.organization_name,
          org_name: item.org_name,
          email: item.email,
          phone: item.phone,
          id: item.id,
          org_type_id: item.org_type_id,
          is_wisilica: item.is_wisilica

        }
      });

      setOrganisationListData(tempOrgList)
      setPageCount(tempPaginationData.total_pages)
    }


    if (!deleteOrgStatus.isFetching && deleteOrgStatus.payload && deleteOrgStatus.payload.success === true) {
      setOpenDeleteModal(false);

      window.location.href = '/organisation';

    }
    if (!deleteOrgStatus.isFetching && deleteOrgStatus.error) {
      const error = deleteOrgStatus.errorMessage.message;
      setAlertMessage(error);
      setOpenDeleteModal(false);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearDeleteOrganisationStatus())

      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [orgList, deleteOrgStatus]);
  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;
    let data = {
      to_date: selectedEndDate != undefined ? dateSearchConverter(selectedEndDate) : "",
      from_date: selectedStartDate != undefined ? dateSearchConverter(selectedStartDate) : "",
      start: start,
      limit: limit,
      // type: selectedType,
      // stage: selectedStage,
      // site_id: selectedSite,
      searchword: searchWord,
      is_wisilica: typeFilter
    }
    dispatch(fetchOrganisationList(data));
  }, [searchWord, selectedEndDate, selectedStartDate, typeFilter, currentPage])
  // useEffect(() => {
  //   const start = (currentPage - 1) * itemsPerPage;
  //   const limit = itemsPerPage;
  //   let data = {
  //     start: start,
  //     limit: limit
  //   }
  //   dispatch(fetchOrganisationList(data));
  // }, [currentPage]);
  const handleViewMore = (data) => {
    sessionStorage.setItem("organasition_view_id", data.id);
    window.location.href = '/organisation/view';
    sessionStorage.setItem('organisationTab', 'org_details');

    // handleView();
  }
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  }
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleDarkmode = () => {
    setDarkMode(!isDarkMode);

  }
  const handleCreate = () => {
    window.location.href = "/organisation/create"
  }
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  }
  const handleDelete = (data) => {

    setDeleteOrgId(data.id);
    setDeleteOrgName(data.org_name);
    setOpenDeleteModal(true);
    setIs_wisilica(data.is_wisilica)

  }
  const handleDeleteModal = () => {
    setOpenDeleteModal(false);
  }
  const onDeleteConfirm = () => {
    const data = {
      id: deleteOrgId,
      created_user: parseInt(sessionStorage.getItem('id')),
      is_wisilica: is_wisilica
    }
    dispatch(deleteOrganisation(data))
  }
  const handleEdit = (data) => {
    sessionStorage.setItem("organasition_edit_id", data.id);
    window.location.href = '/organisation/edit';
  }
  const onSearchWordChange = (data) => {
    setSearchWord(data);
  }
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <Layout
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      handleEndDateChange={handleEndDateChange}
      handleStartDateChange={handleStartDateChange}
      isDarkMode={isDarkMode}
      handleDarkmode={handleDarkmode}
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"organisation"}
      activeMenuKey={"Organisation"}
      placeHolder={"Search Organisation name"}
      searchWord={searchWord}
      onSearchWordChange={onSearchWordChange}
    >
      <DeleteModal
        handleDeleteModal={handleDeleteModal}
        openDeleteModal={openDeleteModal}
        ModalHead={'Delete Organisation'}
        ModalBody={deleteOrgName}
        onDeleteConfirm={onDeleteConfirm}
      />
      <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
        <Grid container spacing={1} sx={{ pt: 3, mb: 5, ml: 2 }}
        //className={isDarkMode ? "chartbg_dark " : "chartbg_light "} 
        >
          <Grid item lg={2.5} md={2.5} sm={12} xs={12} sx={{ mt: 1 }}>
            <Typography
              className={isDarkMode ? "contentHead pdl chartheading_color" : "pdl chartheading_color_light contentHead"}>
              List Of Organisation
            </Typography>
          </Grid>
          <Grid item lg={3} sm={12} xs={12}>
            <Grid
              item
              lg={12}
              sm={12}
              xs={12}
              md={12}
              className="mt2"
              style={{ display: "flex", justifyContent: "flex-end" }}
              sx={{ '@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important' } }}
            >
              <Grid item sx={{ mt: 1 }}>
                <Typography
                  sx={{ textAlign: "center" }}
                  className={
                    isDarkMode
                      ? "headersubhead chartheading_color"
                      : "headersubhead light_text"
                  }
                >
                  Sites :
                </Typography>
              </Grid>
              <Grid item lg={8} sm={8} xs={8} md={8}>
                <Select
                  //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                  sx={{
                    color: isDarkMode ? "white" : "#161616",
                    ml: 1,
                    minWidth: 100,
                    textAlign: "left",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(233, 233, 233, 1)",
                      border: "",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(233, 233, 233, 1)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(233, 233, 233, 1)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: isDarkMode ? "white !important" : "#161616",
                    },
                  }}
                  displayEmpty
                  MenuProps={MenuProps}
                  className={
                    isDarkMode
                      ? "headerSelect select_dark headerhead_dark"
                      : "headerSelect select_light"
                  }
                  value={typeFilter}
                  onChange={(e) => setTypeFilter(e.target.value)}
                >

                  <MenuItem value="">All</MenuItem>
                  <MenuItem value={true}>Wislica Organisation</MenuItem>

                  <MenuItem value={false}>New Creation</MenuItem>

                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5.9} sm={10} xs={10} container className="alignR"
            sx={{ '@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important', mt: 3 } }}>
            <StyledCreateButton
              variant="contained"

              disableRipple
              startIcon={<MdOutlineAddToPhotos color="white" />}
              onClick={handleCreate}>Create Organisation</StyledCreateButton>
          </Grid>

          <div className="hidden-mobile chartmr15 ">
          </div>
        </Grid>
        {/* <Grid container sx={{ pt: 2, mb: 2 }}
          className={isDarkMode ? "table_dark " : "table_light "}
        > */}
        <Grid container spacing={1} sx={{ pt: 3, ml: 1 }}  >

          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={handlealertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handlealertClose}
              severity="error"
              variant="outlined"
              sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

            >
              {alertMessage}
            </Alert>
          </Snackbar>
          {orgList.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
          <Grid item lg={11.8} sm={11.8} xs={11.8}
            className={isDarkMode ? "chartbg_dark tableheight" : "tableheight chartbg_light "}>
            <Tables
              isDarkMode={isDarkMode}
              column={organisationTableHead}
              row={organisationListData}
              handleView={handleViewMore}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              view={true}
              // buttonName={"View More"}
              actionName={"Action"} />
            {organisationListData.length ?
              <div>
                <Paginations
                  isDarkMode={isDarkMode}
                  pageCount={pageCount}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div> : <></>
            }
          </Grid>
}
        </Grid>

      </div>
    </Layout>
  )
}