import React, { useEffect, useState } from 'react';
import { Layout } from '../layout/Layout';
import { subDays } from 'date-fns';
import { Grid } from '@mui/material';
import { SurveillanceTab } from './SurveillanceTab';
import { TabContext, TabPanel } from '@mui/lab';
import { CameraList } from './camera/CameraList';
import { Stream } from './camera/stream/Stream';
import { SensorList } from './sensor/SensorList';
import { PowerList } from './power/PowerList';
import { MapHome } from './map/MapHome';
export const SurveillanceHome = () => {
    const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [tabValue, setTabValue] = useState('s2');

    useEffect(() => {
        const tabId = sessionStorage.getItem("surveillanceTab")
        if (tabId !== null && tabId !== undefined) {
            setTabValue(tabId)
        }
    }, [])
    const handleActiveMenuItems = (menuItems) => {

        setActiveMenuItem(menuItems);
    }
    const handleEndDateChange = (date) => {

        setSelectedEndDate(date);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
        sessionStorage.setItem('surveillanceTab', newValue)

    }
    return (
        <Layout
            handleEndDateChange={handleEndDateChange}
            handleStartDateChange={handleStartDateChange}
            //  handleSubTabChange={handleSubTabChange}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"videosurveillance"}
            activeMenuKey={"videosurveillance"}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}

        >
            <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
                <Grid container spacing={1} sx={{ pt: 3, mb: 5, ml: 2 }}>

                    <TabContext value={tabValue}>
                        <SurveillanceTab
                            handleTabChange={handleTabChange}
                            tabValue={tabValue}
                        />
                        <TabPanel value="s2" index="s2" sx={{ width: "100% !important" }}>
                            <SensorList
                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate}
                            />
                        </TabPanel>
                        <TabPanel value="s3" index="s3" sx={{ width: "100% !important" }}>
                            <PowerList
                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate} />
                        </TabPanel>
                        <TabPanel value="s1" index="s1" sx={{ width: "100% !important" }}>
                            <CameraList
                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate} />
                        </TabPanel>
                        <TabPanel value="s4" index="s4" sx={{ width: "100% !important" }}>
                            <MapHome />
                        </TabPanel>

                    </TabContext>
                </Grid>
            </div>
        </Layout>
    )
}