import { all, put, takeLatest } from "redux-saga/effects";
import { fetchBlockSuccess, fetchLevelError, fetchLevelSuccess, sharableActionType } from "../actions/SharableActions";
import { fetchBlockListData, fetchLevelListData } from "../../services/SharableServices";
//fetch block list
function* _getBlockList(action) {

    try {
        const BlockInfo = yield fetchBlockListData(action.data);
        yield put(fetchBlockSuccess(BlockInfo));
    } catch (e) {
        yield put(fetchLevelError("error"));
    }
}
function* getBlockList() {
    yield takeLatest(sharableActionType.FETCH_BLOCK, _getBlockList)
}
//fetch level list
function* _getLayerList(action) {

    try {
        const LayerInfo = yield fetchLevelListData(action.id);
        yield put(fetchLevelSuccess(LayerInfo));
    } catch (e) {
        yield put(fetchLevelError("error"));
    }
}
function* getLayerList() {
    yield takeLatest(sharableActionType.FETCH_LEVEL, _getLayerList)
}
export function* SharableSaga(){
    yield all([getLayerList(),getBlockList()])
}