import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import { StyledTab, StyledTabs } from '../../Styles';
export const OrgViewTabs =({tabValue,handleTabChange}) =>{
    const isMobile = useMediaQuery('(max-width: 600px)');

    return (
      <Box sx={{ width: '100%' }}>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          variant={isMobile ? 'scrollable' : 'standard'}
        // scrollButtons={isMobile ? 'auto' : 'off'}
        //   textColor="secondary"
        //   indicatorColor="#E28909"z
        //   aria-label="secondary tabs example"
        >
          <StyledTab value="org_details" label="Organisation Details"
        //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
            iconPosition="start" />
             <StyledTab value="org_sites" label="Organisation Sites"
        //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
            iconPosition="start" />
             <StyledTab value="org_user" label="Organisation Users"
        //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
            iconPosition="start" />
             <StyledTab value="org_telegram" label="Telegram"
        //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
            iconPosition="start" />
            </StyledTabs>
            </Box> 
    )
}