import { Alert, CircularProgress, Grid, InputLabel, Snackbar, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addPower, clearaddPowerStatus } from "../../../redux/actions/SurveillanceAction";
import { Layout } from "../../layout/Layout";
import { StyledCreateSmallButton } from "../../styles/ButtonStyles";
export const AddPower = () => {
  const dispatch = useDispatch();
  const addPowerStatus = useSelector((state) => state.surveillance.addPower);

  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("")
  const [batteryDetails, setBatteryDetails] = useState({
    battery_name: "",
    battery_id: ""
  });
  const [validationErrors, setValidationErrors] = useState({
    battery_name: "",
    battery_id: ""
  });
  useEffect(() => {
    if (
      !addPowerStatus.isFetching &&
      addPowerStatus.payload &&
      addPowerStatus.payload.success === true
    ) {
      sessionStorage.setItem('surveillanceTab', "s3")

      window.location.href = "/surveillance";

      //setBatteryDrop(tempBatteryList);
    }
    if (
      !addPowerStatus.isFetching &&
      addPowerStatus.error
    ) {
      let tempalert = addPowerStatus.errorMessage.message;
      setAlertMessage(tempalert);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearaddPowerStatus());

      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [addPowerStatus])
  const onChange = (value, key) => {
    let temp = { ...batteryDetails };
    temp[key] = value;
    setBatteryDetails(temp);
    const strln = value.toString().trim();
    const valueLength = strln.length;
    let error = "";
    switch (key) {
      case "battery_id":
      case "battery_name":

        // case 'license_no':

        error =
          valueLength < 3 || valueLength >= 30
            ? "Field must be between 2 and 30 characters long"
            : "";
        break;
      default:
        break;
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  }
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  const handleCreate = (e) => {
    let data = {
      battery_name: batteryDetails.battery_name,
      battery_id: batteryDetails.battery_id,
      created_user: parseInt(sessionStorage.getItem("id")),
      org_id: parseInt(sessionStorage.getItem("org_Id")),
    }
    e.preventDefault();
    const isEmptyField = Object.values(data).some((value) => {
      return (
        (typeof value === "string" && value.trim() === "") ||
        value === undefined ||
        value === null
      );
    });
    const allClear = Object.values(validationErrors).every((value) => {
      return (
        (typeof value === "string" && value.trim() == "") ||
        value === undefined ||
        value === null
      );
    });
    if (isEmptyField) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        battery_name:
          data.battery_name.trim() === ""
            ? "This field is mandatory"
            : prevErrors.battery_name,
        battery_id: data.battery_id ? "" : "This field is mandatory",
      }));
    }

    if (!isEmptyField && allClear) {
      setValidationErrors({
        battery_name: "",
        battery_id: "",

      });
      dispatch(addPower(data))
    }
  }
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"videosurveillance"}
      activeMenuKey={"videosurveillance"}
      isCreate={true}
    >
      <div
        style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%" }}
        className=""
      >
        <Grid container>
          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={handlealertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handlealertClose}
              severity="error"
              variant="outlined"
              sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

            >
              {alertMessage}
            </Alert>
          </Snackbar>
          {addPowerStatus.isFetching ?
            <Grid item lg={12} md={12} sm={12} xs={12} container
              sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
              <CircularProgress sx={{ color: '#FFAC0B' }} />
            </Grid>
            :
            <></>
          }
          <Grid item lg={12} sm={12} xs={12} md={12} sx={{ ml: 2 }}>
            <Typography
              sx={{ textAlign: "left" }}
              className={"headersubhead light_text"}
            >
              Add Battery
            </Typography>
          </Grid>
          <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3, ml: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Battery Name
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="Battery Name"
              value={batteryDetails.battery_name}
              onChange={(e) => onChange(e.target.value, "battery_name")}
              error={!!validationErrors.battery_name}
              helperText={validationErrors.battery_name}
            />
          </Grid>
          <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3, ml: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Battery Id
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="Battery Id"
              value={batteryDetails.battery_id}
              onChange={(e) => onChange(e.target.value, "battery_id")}
              error={!!validationErrors.battery_id}
              helperText={validationErrors.battery_id}
            />
          </Grid>
          <Grid item tem xs={10} sm={10} lg={10} md={10} sx={{ ml: 3 }}>
            <StyledCreateSmallButton
              onClick={handleCreate}
              sx={{ mt: 5, width: 150 }}
              variant="contained"
              disableRipple
            >
              Create
            </StyledCreateSmallButton>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};
