import {
  Alert,
  Box,
  Grid,
  IconButton,
  Modal,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ImportModalstyle } from "../styles/ModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import { MenuProps } from "../Styles";
import { StyledCreateSmallButton } from "../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSites } from "../../redux/actions/UserRoleActions";
import { clearDeviceOnboardingStatus, deviceOnboarding } from "../../redux/actions/SurveillanceAction";

export const DeviceImportModal = ({
  importModalOpen,
  CloseImportModal,
  dataFromExcel,
  CloseSuccessImportModal,
  handleDeviceDelete
}) => {
  const dispatch = useDispatch();
  const importStatus = useSelector(state => state.surveillance.deviceImport);
  const [isError, setIsError] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [alertMessage, setAlertmessage] = useState('')
  useEffect(() => {
    if (!importStatus.isFetching && importStatus.payload && importStatus.payload.success === true && importStatus.payload.error === false) {
      CloseImportModal();


      sessionStorage.setItem('surveillanceTab', 's1')
      window.location.href = '/surveillance';
    }
    if (!importStatus.isFetching && importStatus.payload && importStatus.payload.success === false && importStatus.payload.error === true) {
      const msg = importStatus.payload.message;
      setShowAlert(true);
      setIsError(true);
      setAlertmessage(msg)
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearDeviceOnboardingStatus());
        CloseImportModal();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
    if (!importStatus.isFetching && importStatus.payload && importStatus.payload.success === true && importStatus.payload.error === true) {
      const msg = importStatus.payload.message;
      setShowWarning(true);
      setIsError(true);
      setAlertmessage(msg)
      const timeoutId = setTimeout(() => {
        setShowWarning(false);
        dispatch(clearDeviceOnboardingStatus());

        CloseSuccessImportModal();
        // sessionStorage.setItem('trackingTab', 'three')
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [importStatus])
  const handleCreateAll = () => {
    let data = {
      title: dataFromExcel.map((item) => {
        return item["Title"];
      }),
      machine_id: dataFromExcel.map((item) => {
        return item["Stream Id"];
      }),
      battery_id: dataFromExcel.map((item) => {
        return item["Battery Id"];
      }),
      battery_name: dataFromExcel.map((item) => {
        return item["Battery Name"];
      }),
      sensor_name: dataFromExcel.map((item) => {
        return item["Sensor Name"];
      }),
      d1: dataFromExcel.map((item) => {
        return String(item["Distance 1"]);
      }),
      d2: dataFromExcel.map((item) => {
        return String(item["Distance 2"]);
      }),
      count: dataFromExcel.length,
      org_id: parseInt(sessionStorage.getItem("org_Id")),
      created_user: parseInt(sessionStorage.getItem("id")),
    };

    dispatch(deviceOnboarding(data));
  };
  const handlealertClose = () => {
    setShowAlert(false)
    setShowWarning(false)
  }
  return (
    <Modal
      open={importModalOpen}
      onClose={CloseImportModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ImportModalstyle}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={5.5} xs={5.5}>
            <Typography className="import_main_head">Imported</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6} container className="alignR">
            <IconButton onClick={CloseImportModal}>
              <CloseIcon sx={{ color: "#161616F" }} />
            </IconButton>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="card_divider"></div>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={12}
            xs={12}
            sx={{ mt: "4% !important", pl: "1% !important" }}
          >
            <Typography className="import_main_sub">Device List</Typography>
          </Grid>


          <Grid container sx={{ mt: 2, justifyContent: 'center' }} >
            <Snackbar
              open={showAlert}
              autoHideDuration={6000}
              onClose={handlealertClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handlealertClose}
                severity={isError ? "error" : "success"}
                variant="outlined"
                sx={{ width: '100%', color: isError ? '#C61A1A' : '#11b311', backgroundColor: isError ? '#FCDFDF' : '#5bdb5b73' }}

              >
                {alertMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={showWarning}
              autoHideDuration={6000}
              onClose={handlealertClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handlealertClose}
                severity="warning"
                sx={{ width: '100%' }}

              >
                {alertMessage}
              </Alert>
            </Snackbar>

          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ mt: "4% !important", pl: "1% !important" }}
          ></Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            container
            className="alignR"
            sx={{ mt: "3% !important" }}
          >
            <StyledCreateSmallButton
              variant="contained"
              disableRipple
              startIcon={<MdOutlineAddToPhotos color="white" />}
              onClick={handleCreateAll}
            >
              Create all
            </StyledCreateSmallButton>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ overflowY: "scroll", mt: 2, justifyContent: "center" }}
        >
          {dataFromExcel ? (
            dataFromExcel.map((device, index) => (
              <Grid
                item
                lg={11.7}
                sx={{
                  border: "2px solid #D9D7D7",
                  borderRadius: "5px",
                  mt: 3,
                  p: 3,
                }}
                container
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ mb: 2 }}
                >
                  <Grid
                    item
                    container
                    className="alignR"
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <IconButton
                      sx={{ justifyContent: "right" }}
                      onClick={() => handleDeviceDelete(device["Title"])}
                    >
                      <RiDeleteBin6Line color="#C61A1A" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml ">
                    <Typography className=" label_color import_label">
                      Title
                    </Typography>
                    <Typography className="import_text_width content_color import_text">
                      {device["Title"]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml">
                    <Typography className="label_color import_label">
                      Stream Id
                    </Typography>
                    <Typography className="import_text_width content_color import_text">
                      {device["Stream Id"]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml ">
                    <Typography className=" label_color import_label">
                      Battery Id
                    </Typography>
                    <Typography className="import_text_width content_color import_text">
                      {device["Battery Id"]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml">
                    <Typography className="label_color import_label">
                      Battery Name
                    </Typography>
                    <Typography className="import_text_width content_color import_text">
                      {device["Battery Name"]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml ">
                    <Typography className=" label_color import_label">
                      Sensor Name
                    </Typography>
                    <Typography className="import_text_width content_color import_text">
                      {device["Sensor Name"]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv borderbottom rowdiv_ml">
                    <Typography className="label_color import_label">
                      Distance -1
                    </Typography>
                    <Typography className="import_text_width content_color import_text">
                      {device["Distance 1"]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="rowdiv  rowdiv_ml ">
                    <Typography className=" label_color import_label">
                      Distance -2
                    </Typography>
                    <Typography className="import_text_width content_color import_text">
                      {device["Distance 2"]}
                    </Typography>
                  </div>
                </Grid>
                {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="rowdiv borderbottom rowdiv_ml">
                <Typography className="label_color import_label">
                  Permit Serial Number
                </Typography>
                <Typography className="import_text_width content_color import_text">
                </Typography>
              </div>
            </Grid> */}
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};
