import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchTimeBasedReport } from "../../../redux/actions/AlertActions";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { MonthYearFilter } from "./MonthYearFilter";
import { TimeBasedReportChart } from "./TimeBasedReportChart";
import { TopAlertChart } from "./TopAlertChart";
import { CircularProgress, Grid } from "@mui/material";
import * as XLSX from "xlsx";

export const TimeBasedReport = ({ handlePdfExport }) => {
  const dispatch = useDispatch();
  const siteData = useSelector((state) => state.userRole.siteData);
  const trackingReportData = useSelector((state) => state.alert.timeBasedReport);

  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [trackingDatas, setTrackingDatas] = useState("");
  const [petrolDatas, setPetrolDatas] = useState("");
  const [totalDatas, setTotalDatas] = useState("");
  const [sentryDatas, setSentryDatas] = useState("");
  const [trackingnames, setTrackingNames] = useState("");
  const [exportMenu, setExportMenu] = useState(false);
  const [exportType, setExportType] = useState('')
  const [exportModalAlert, setExportModalAlert] = useState(false);
  const [size, setSize] = useState(550);

  useEffect(() => {
    dispatch(fetchSites());
    const currentDate = new Date();
    setSelectedYear(currentDate.getFullYear());
    setSelectedMonth(String(currentDate.getMonth() + 1).padStart(2, "0")); // Adding 1 since getMonth() returns 0-11
    // fetchTrackingReport
  }, []);
  useEffect(() => {
    if (
      !siteData.isFetching &&
      siteData.payload &&
      siteData.payload.success === true
    ) {
      const siteList = siteData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          timestamp: sites.timestamp,
        };
      });
      setSites(temp);
      const siteId = sessionStorage.getItem("user_site_id");
      // setSelectedSites(siteId)
      if (siteId != 0) {
        setSelectedSites(siteId);
      }
    }
    if (
      !trackingReportData.isFetching &&
      trackingReportData.payload &&
      trackingReportData.payload.success === true
    ) {
      const trackingReport = trackingReportData.payload.data;
      const length = trackingReport.length;
      const ChartSize = calculateSize(length);

      setSize(ChartSize);
      setTrackingDatas(trackingReport);
      const hours = [];
      const patrolData = [];
      const sentryData = [];
      const totalData = [];
      trackingReport.map((item) => {
        if (item.hour) {
          hours.push(item.hour);
          patrolData.push(item.patrol);
          sentryData.push(item.sentry)

        }

      })

      setPetrolDatas(patrolData);
      setSentryDatas(sentryData);
      setTotalDatas(totalData);
      setTrackingNames(hours)
    }
  }, [siteData, trackingReportData]);
  useEffect(() => {
    let data = {
      site_id: selectedSites,
      month: selectedMonth,
      year: selectedYear,
    };
    dispatch(fetchTimeBasedReport(data));
  }, [selectedSites, selectedMonth, selectedYear]);
  const handleSites = (value) => {
    setSelectedSites(value);
  };
  const handleSelectedYear = (value) => {
    setSelectedYear(value);
  };
  const handleSelectedMonth = (value) => {
    setSelectedMonth(value);
  };
  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => currentYear - index); // Last 10 years
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const handleExportModal = () => {
    setExportMenu(!exportMenu);
    setExportType('');
    setExportModalAlert(false);
  }
  const handleExporttype = (value) => {
    // setExportMenu(false);

    setExportType(value)
  }
  const handleExcelExport = () => {
    const firstEntryDate = Object.values(trackingDatas)[0].date;
    const firstEntryMonth = new Date(firstEntryDate).toLocaleString('default', { month: 'long' });
    const firstEntryYear = new Date(firstEntryDate).getFullYear();

    // Convert data to array of arrays
    const exportData = Object.keys(trackingDatas).map(key => {
      const { hour, patrol, sentry, total } = trackingDatas[key];
      return [hour, patrol, sentry, total];
    });

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      [`Time Based Tracking Alerts - ${selectedMonth} ${selectedYear}`], // Title row
      [], // Empty row for spacing
      ['Hour', 'Patrol', 'Sentry', 'Total'], // Header row
      ...exportData // Data rows
    ]);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Time Based Tracking Data');

    // Construct filename based on the first entry's date
    const filename = `Time Based Tracking Alerts_${selectedMonth.toLowerCase()}/${selectedYear}.xlsx`;

    // Export the workbook as a .xlsx file with the constructed filename
    XLSX.writeFile(wb, filename);


  }
  const confirmExport = () => {
    if (exportType == "") {
      setExportModalAlert(true);
    }
    else {
      setExportModalAlert(false);
      if (exportType == "excel") {
        handleExcelExport();
        setExportType('');
        setExportMenu(!exportMenu);
      }
      else {
        handlePdfExport("StatisticViolationReport");
        setExportType('');
        setExportMenu(!exportMenu);
      }
    }
  }
  const calculateSize = (length) => {
    if (length <= 5) {
      return 550;
    }
    else
      return 550 + ((length - 5) * 75);
  }
  return (
    <div>
      <MonthYearFilter
        sites={sites}
        selectedSite={selectedSites}
        handleSelectedSite={handleSites}
        handleSelectedYear={handleSelectedYear}
        selectedYear={selectedYear}
        years={years}
        selectedMonth={selectedMonth}
        handleSelectedMonth={handleSelectedMonth}
        months={months}
        handleExport={handleExportModal}

      />
      {trackingReportData.isFetching ?
        <Grid item lg={12} md={12} sm={12} xs={12} container
          sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <CircularProgress sx={{ color: '#FFAC0B' }} />
        </Grid>
        :
        trackingnames.length != 0 ?
          <TimeBasedReportChart
            patrolData={petrolDatas}
            sentryData={sentryDatas}
            trackingnames={trackingnames}
            handleExportMenu={handleExportModal}
            exportMenu={exportMenu}
            confirmExport={confirmExport}
            handleExporttype={handleExporttype}
            exportModalAlert={exportModalAlert}
            size={size}

          /> :
          <Grid
            item
            lg={11.8}
            sm={11.8}
            xs={11.8}
            sx={{ mt: "6% !important", display: 'flex', justifyContent: 'center' }}
          >
            No Data Found
          </Grid>}

    </div>
  )
}