import { authenticationActionType } from "../actions/AuthenticationActions";

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    loginData: dataTemplate,
    forgotData: dataTemplate,
    otpData: dataTemplate,
    resetData: dataTemplate,
    loggedUser:dataTemplate,
    refreshToken:dataTemplate,
    logout:dataTemplate
}
const AuthenticationReducer = (state = initialData, action) => {
    switch (action.type) {
        case authenticationActionType.FETCH_AUTHENTICATION_INFO:

            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case authenticationActionType.FETCH_AUTHENTICATION_INFO_SUCCESS:
            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case authenticationActionType.FETCH_AUTHENTICATION_INFO_ERROR:
            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case authenticationActionType.CLEAR_FETCH_AUTHENTICATION_INFO:
                return {
                    ...state,
                    loginData: {
                        ...state.loginData,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
        case authenticationActionType.FORGOT_PASSWORD:
            return {
                ...state,
                forgotData: {
                    ...state.forgotData,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case authenticationActionType.FORGOT_PASSWORD_SUCCESS:
            return {

                ...state,
                forgotData: {
                    ...state.forgotData,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case authenticationActionType.FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotData: {
                    ...state.forgotData,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case authenticationActionType.CLEAR_FORGOT_PASSWORD:
                return {
                    ...state,
                    forgotData: {
                        ...state.forgotData,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
        case authenticationActionType.OTP_VERIFICATION:
            return {
                ...state,
                otpData: {
                    ...state.otpData,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case authenticationActionType.OTP_VERIFICATION_SUCCESS:
            return {

                ...state,
                otpData: {
                    ...state.otpData,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case authenticationActionType.OTP_VERIFICATION_ERROR:
            return {
                ...state,
                otpData: {
                    ...state.otpData,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
            case authenticationActionType.CLEAR_OTP_VERIFICATION:
                return {
                    ...state,
                    otpData: {
                        ...state.otpData,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
            case authenticationActionType.RESET_PASSWORD:
                return {
                    ...state,
                    resetData: {
                        ...state.resetData,
                        isFetching: true,
                        payload: [],
                        error: false
                    }
                }
            case authenticationActionType.RESET_PASSWORD_SUCCESS:
    
                return {
    
                    ...state,
                    resetData: {
                        ...state.resetData,
                        isFetching: false,
                        error: false,
                        payload: action.payload
                    }
                }
            case authenticationActionType.RESET_PASSWORD_ERROR:
                return {
                    ...state,
                    resetData: {
                        ...state.resetData,
                        isFetching: false,
                        payload: [],
                        error: true,
                        errorMessage: action.error
                    }
                }
                 case authenticationActionType.CLEAR_RESET_PASSWORD:
                return {
                    ...state,
                    resetData: {
                        ...state.resetData,
                        isFetching: false,
                        payload: [],
                        error: false,
                        errorMessage: ''
                    }
                }
                case authenticationActionType.FETCH_LOGGED_USER_INFO:
                    return {
                        ...state,
                        loggedUser: {
                            ...state.loggedUser,
                            isFetching: true,
                            payload: [],
                            error: false
                        }
                    }
                case authenticationActionType.FETCH_LOGGED_USER_INFO_SUCCESS:
        
                    return {
        
                        ...state,
                        loggedUser: {
                            ...state.loggedUser,
                            isFetching: false,
                            error: false,
                            payload: action.payload
                        }
                    }
                case authenticationActionType.FETCH_LOGGED_USER_INFO_ERROR:
                    return {
                        ...state,
                        loggedUser: {
                            ...state.loggedUser,
                            isFetching: false,
                            payload: [],
                            error: true,
                            errorMessage: action.error
                        }
                    }












                    case authenticationActionType.FETCH_REFRESH_TOKEN:
                        return {
                            ...state,
                            refreshToken: {
                                ...state.refreshToken,
                                isFetching: true,
                                payload: [],
                                error: false
                            }
                        }
                    case authenticationActionType.FETCH_REFRESH_TOKEN_SUCCESS:
            
                        return {
            
                            ...state,
                            refreshToken: {
                                ...state.refreshToken,
                                isFetching: false,
                                error: false,
                                payload: action.payload
                            }
                        }
                    case authenticationActionType.FETCH_REFRESH_TOKEN_ERROR:
                        return {
                            ...state,
                            refreshToken: {
                                ...state.refreshToken,
                                isFetching: false,
                                payload: [],
                                error: true,
                                errorMessage: action.error
                            }
                        }
                         case authenticationActionType.CLEAR_REFRESH_TOKEN:
                        return {
                            ...state,
                            refreshToken: {
                                ...state.refreshToken,
                                isFetching: false,
                                payload: [],
                                error: false,
                                errorMessage: ''
                            }
                        }
                        case authenticationActionType.USER_LOGOUT:
                            return {
                                ...state,
                                logout: {
                                    ...state.logout,
                                    isFetching: true,
                                    payload: [],
                                    error: false
                                }
                            }
                        case authenticationActionType.USER_LOGOUT_SUCCESS:
                
                            return {
                
                                ...state,
                                logout: {
                                    ...state.logout,
                                    isFetching: false,
                                    error: false,
                                    payload: action.payload
                                }
                            }
                        case authenticationActionType.USER_LOGOUT_ERROR:
                            return {
                                ...state,
                                logout: {
                                    ...state.logout,
                                    isFetching: false,
                                    payload: [],
                                    error: true,
                                    errorMessage: action.error
                                }
                            }
                             case authenticationActionType.CLEAR_LOGOUT:
                            return {
                                ...state,
                                logout: {
                                    ...state.logout,
                                    isFetching: false,
                                    payload: [],
                                    error: false,
                                    errorMessage: ''
                                }
                            }
        default:
            return state
    }

}
export default AuthenticationReducer;