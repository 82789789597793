import React from "react";
import { CustomizedTableCell, CustomizedTableRow } from "../styles/TablebButtonStyles";
import { Grid, IconButton, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { IoEyeOutline } from "react-icons/io5";
import { Paginations } from "../sharable/pagination/Pagination";

export const AuditTrailTable = ({ AuditTrailList,pageCount,page,onChange }) => {
    return (
        <Grid Container>
            <Grid item lg={11.5} md={11.5} sm={12} xs={12}>
                <div className='table_Scroll'>

                    <Table className="table_mob" sx={{ m: 2, width: "100%" }}>

                        <TableHead>
                            <TableRow>
                                <CustomizedTableCell>Date & Time</CustomizedTableCell>
                                <CustomizedTableCell>Creator</CustomizedTableCell>
                                <CustomizedTableCell>Description</CustomizedTableCell>
                                {/* <CustomizedTableCell>View</CustomizedTableCell> */}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {AuditTrailList.map((item, index) => (
                                <CustomizedTableRow key={index}>
                                    <CustomizedTableCell>{item.date} - {item.createdTime}</CustomizedTableCell>
                                    <CustomizedTableCell>{item.creator == 0 ? "NA" : item.creatorData.username} {item.creator == 0 ? "" : "(" + item.creatorData.user_role + ")"}</CustomizedTableCell>
                                    <CustomizedTableCell>{item.message}</CustomizedTableCell>
                                    {/* <CustomizedTableCell>
                                        <IconButton
                                        // onClick={() => handleView(alert.id, alert.duty_type)}
                                        >
                                            <IoEyeOutline color="#161616" />
                                        </IconButton>
                                    </CustomizedTableCell> */}

                                </CustomizedTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div>
                        <Paginations
                            // isDarkMode={isDarkMode}
                            pageCount={pageCount}
                            page={page}
                            onChange={onChange}
                        />
                    </div>
                </div>

            </Grid>
        </Grid>
    )
}