import { all } from "redux-saga/effects";
import { AuthenticationSaga } from "./AutheticationSaga";
import { UserSaga } from "./UserSaga";
import { UserRoleSaga } from "./UserRoleSaga";
import { RoleFeatureSaga } from "./RoleFeatureSaga";
import { OrgaisationSaga } from "./OrganisationSaga";
import { CheckPointSaga } from "./CheckPointSaga";
import { SharableSaga } from "./SharableSaga";
import { ListenerSaga } from "./ListenerSaga";
import { RouteSaga } from "./RouteSaga";
import { DeviceSaga } from "./DeviceSaga";
import { PersonnelSaga } from "./PersonnelSaga";
import { AlertSaga } from "./AlertSaga";
import { SurveillanceSaga } from "./SurveillanceSaga";

export default function* rootSaga() {
    yield all([AuthenticationSaga(),UserSaga(),UserRoleSaga(),RoleFeatureSaga()
        ,OrgaisationSaga(),CheckPointSaga(),SharableSaga(),ListenerSaga(),RouteSaga(),DeviceSaga(),PersonnelSaga(),
    AlertSaga(),SurveillanceSaga()])
}
