import { alertActionType } from "../actions/AlertActions";

const dataTemplate = {
  isFetching: false,
  payload: [],
  error: false,
  errorMessage: "",
};
const initialData = {
  alertList: dataTemplate,
  singlePatrol: dataTemplate,
  singleSentry: dataTemplate,
  singleDevice: dataTemplate,
  assignAlert: dataTemplate,
  gaugeValues: dataTemplate,
  alertTrend: dataTemplate,
  auditList: dataTemplate,
  singleSensor: dataTemplate,
  trakingReport:dataTemplate,
  topPersonelAlerts : dataTemplate,
  timeBasedReport : dataTemplate,
  tagBattery: dataTemplate,
  sensorReports:dataTemplate,
  vaAlert:dataTemplate,
  surveillancePerformance:dataTemplate,
  encoderDropdown:dataTemplate,
  batteryPerformance:dataTemplate,
  singleCamera : dataTemplate,
};
const AlertReducer = (state = initialData, action) => {
  switch (action.type) {
    //fetch org list
    case alertActionType.FETCH_ALERT_LIST:
      return {
        ...state,
        alertList: {
          ...state.alertList,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_ALERT_LIST_SUCCESS:
      return {
        ...state,
        alertList: {
          ...state.alertList,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_ALERT_LIST_ERROR:
      return {
        ...state,
        alertList: {
          ...state.alertList,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };

    case alertActionType.FETCH_SINGLE_PATROL:
      return {
        ...state,
        singlePatrol: {
          ...state.singlePatrol,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_SINGLE_PATROL_SUCCESS:
      return {
        ...state,
        singlePatrol: {
          ...state.singlePatrol,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_SINGLE_PATROL_ERROR:
      return {
        ...state,
        singlePatrol: {
          ...state.singlePatrol,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };

    case alertActionType.FETCH_SINGLE_SENTRY:
      return {
        ...state,
        singleSentry: {
          ...state.singleSentry,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_SINGLE_SENTRY_SUCCESS:
      return {
        ...state,
        singleSentry: {
          ...state.singleSentry,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_SINGLE_SENTRY_ERROR:
      return {
        ...state,
        singleSentry: {
          ...state.singleSentry,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };

    //assign alert
    case alertActionType.ASSIGN_ALERT:
      return {
        ...state,
        assignAlert: {
          ...state.assignAlert,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.ASSIGN_ALERT_SUCCESS:
      return {
        ...state,
        assignAlert: {
          ...state.assignAlert,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.ASSIGN_ALERT_ERROR:
      return {
        ...state,
        assignAlert: {
          ...state.assignAlert,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };
    case alertActionType.CLEAR_ASSIGN_ALERT_STATUS:
      return {
        ...state,
        assignAlert: {
          ...state.assignAlert,
          isFetching: false,
          payload: [],
          error: false,
          errorMessage: "",
        },
      };

    //device alert
    case alertActionType.FETCH_SINGLE_DEVICE_ALERT:
      return {
        ...state,
        singleDevice: {
          ...state.singleDevice,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_SINGLE_DEVICE_ALERT_SUCCESS:
      return {
        ...state,
        singleDevice: {
          ...state.singleDevice,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_SINGLE_DEVICE_ALERT_ERROR:
      return {
        ...state,
        singleDevice: {
          ...state.singleDevice,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };

    //dashboard guages
    case alertActionType.FETCH_DASHBOARD_GUAGES:
      return {
        ...state,
        gaugeValues: {
          ...state.gaugeValues,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_DASHBOARD_GUAGES_SUCCESS:
      return {
        ...state,
        gaugeValues: {
          ...state.gaugeValues,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_DASHBOARD_GUAGES_ERROR:
      return {
        ...state,
        gaugeValues: {
          ...state.gaugeValues,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };
    //dashboard alert trend
    case alertActionType.FETCH_ALERT_TREND:
      return {
        ...state,
        alertTrend: {
          ...state.alertTrend,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_ALERT_TREND_SUCCESS:
      return {
        ...state,
        alertTrend: {
          ...state.alertTrend,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_ALERT_TREND_ERROR:
      return {
        ...state,
        alertTrend: {
          ...state.alertTrend,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };
    //audit list
    case alertActionType.FETCH_AUDITTRAIL_LIST:
      return {
        ...state,
        auditList: {
          ...state.auditList,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_AUDITTRAIL_LIST_SUCCESS:
      return {
        ...state,
        auditList: {
          ...state.auditList,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_AUDITTRAIL_LIST_ERROR:
      return {
        ...state,
        auditList: {
          ...state.auditList,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };
    //single sensor  alert
    case alertActionType.FETCH_SINGLE_SENSOR_ALERT:
      return {
        ...state,
        singleSensor: {
          ...state.singleSensor,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_SINGLE_SENSOR_ALERT_SUCCESS:
      return {
        ...state,
        singleSensor: {
          ...state.singleSensor,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_SINGLE_SENSOR_ALRET_ERROR:
      return {
        ...state,
        singleSensor: {
          ...state.singleSensor,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };
      //tracking report fetching
    case alertActionType.FETCH_TRACKING_REPORT:
      return {
        ...state,
        trakingReport: {
          ...state.trakingReport,
          isFetching: true,
          payload: [],
          error: false,
        },
      };
    case alertActionType.FETCH_TRACKING_REPORT_SUCCESS:
      return {
        ...state,
        trakingReport: {
          ...state.trakingReport,
          isFetching: false,
          error: false,
          payload: action.payload,
        },
      };
    case alertActionType.FETCH_TRACKING_REPORT_ERROR:
      return {
        ...state,
        trakingReport: {
          ...state.trakingReport,
          isFetching: false,
          payload: [],
          error: true,
          errorMessage: action.error,
        },
      };
 //top personnel alert fetching
 case alertActionType.FETCH_TOP_PERSONNEL_ALERTS:
  return {
    ...state,
    topPersonelAlerts: {
      ...state.topPersonelAlerts,
      isFetching: true,
      payload: [],
      error: false,
    },
  };
case alertActionType.FETCH_TOP_PERSONNEL_ALERTS_SUCCESS:
  return {
    ...state,
    topPersonelAlerts: {
      ...state.topPersonelAlerts,
      isFetching: false,
      error: false,
      payload: action.payload,
    },
  };
case alertActionType.FETCH_TOP_PERSONNEL_ALERTS_ERROR:
  return {
    ...state,
    topPersonelAlerts: {
      ...state.topPersonelAlerts,
      isFetching: false,
      payload: [],
      error: true,
      errorMessage: action.error,
    },
  };
   //time based report fetching
 case alertActionType.FETCH_TIME_BASED_REPORT:
  return {
    ...state,
    timeBasedReport: {
      ...state.timeBasedReport,
      isFetching: true,
      payload: [],
      error: false,
    },
  };
case alertActionType.FETCH_TIME_BASED_REPORT_SUCCESS:
  return {
    ...state,
    timeBasedReport: {
      ...state.timeBasedReport,
      isFetching: false,
      error: false,
      payload: action.payload,
    },
  };
case alertActionType.FETCH_TIME_BASED_REPORT_ERROR:
  return {
    ...state,
    timeBasedReport: {
      ...state.timeBasedReport,
      isFetching: false,
      payload: [],
      error: true,
      errorMessage: action.error,
    },
  };
 //fetching sensor alert
 case alertActionType.FETCH_SENSOR_REPORT:
  return {
    ...state,
    sensorReports: {
      ...state.sensorReports,
      isFetching: true,
      payload: [],
      error: false,
    },
  };
case alertActionType.FETCH_SENSOR_REPORT_SUCCESS:
  return {
    ...state,
    sensorReports: {
      ...state.sensorReports,
      isFetching: false,
      error: false,
      payload: action.payload,
    },
  };
case alertActionType.FETCH_SENSOR_REPORT_ERROR:
  return {
    ...state,
    sensorReports: {
      ...state.sensorReports,
      isFetching: false,
      payload: [],
      error: true,
      errorMessage: action.error,
    },
  };
  //fetching tag battery alert
 case alertActionType.FETCH_TAG_BATTERY_ALERT:
  return {
    ...state,
    tagBattery: {
      ...state.tagBattery,
      isFetching: true,
      payload: [],
      error: false,
    },
  };
case alertActionType.FETCH_TAG_BATTERY_ALERT_SUCCESS:
  return {
    ...state,
    tagBattery: {
      ...state.tagBattery,
      isFetching: false,
      error: false,
      payload: action.payload,
    },
  };
case alertActionType.FETCH_TAG_BATTERY_ALERT_ERROR:
  return {
    ...state,
    tagBattery: {
      ...state.tagBattery,
      isFetching: false,
      payload: [],
      error: true,
      errorMessage: action.error,
    },
  };
    //fetching va alert
 case alertActionType.FETCH_VA_ALERT:
  return {
    ...state,
    vaAlert: {
      ...state.vaAlert,
      isFetching: true,
      payload: [],
      error: false,
    },
  };
case alertActionType.FETCH_VA_ALERT_SUCCESS:
  return {
    ...state,
    vaAlert: {
      ...state.vaAlert,
      isFetching: false,
      error: false,
      payload: action.payload,
    },
  };
case alertActionType.FETCH_VA_ALERT_ERROR:
  return {
    ...state,
    vaAlert: {
      ...state.vaAlert,
      isFetching: false,
      payload: [],
      error: true,
      errorMessage: action.error,
    },
  };
//fetching surveillace performance
case alertActionType.FETCH_SURVEILLANCE_PERFORMANCE:
  return {
    ...state,
    surveillancePerformance: {
      ...state.surveillancePerformance,
      isFetching: true,
      payload: [],
      error: false,
    },
  };
case alertActionType.FETCH_SURVEILLANCE_PERFORMANCE_SUCCESS:
  return {
    ...state,
    surveillancePerformance: {
      ...state.surveillancePerformance,
      isFetching: false,
      error: false,
      payload: action.payload,
    },
  };
case alertActionType.FETCH_SURVEILLANCE_PERFORMANCE_ERROR:
  return {
    ...state,
    surveillancePerformance: {
      ...state.surveillancePerformance,
      isFetching: false,
      payload: [],
      error: true,
      errorMessage: action.error,
    },
  };
  //fetching encoder dropdown
case alertActionType.FETCH_ENCODER_DROPDOWN:
  return {
    ...state,
    encoderDropdown: {
      ...state.encoderDropdown,
      isFetching: true,
      payload: [],
      error: false,
    },
  };
case alertActionType.FETCH_ENCODER_DROPDOWN_SUCCESS:
  return {
    ...state,
    encoderDropdown: {
      ...state.encoderDropdown,
      isFetching: false,
      error: false,
      payload: action.payload,
    },
  };
case alertActionType.FETCH_ENCODER_DROPDOWN_ERROR:
  return {
    ...state,
    encoderDropdown: {
      ...state.encoderDropdown,
      isFetching: false,
      payload: [],
      error: true,
      errorMessage: action.error,
    },
  };
  //fetching battery performance
  case alertActionType.FETCH_BATTERY_PERFORMANCE:
    return {
      ...state,
      batteryPerformance: {
        ...state.batteryPerformance,
        isFetching: true,
        payload: [],
        error: false,
      },
    };
  case alertActionType.FETCH_BATTERY_PERFORMANCE_SUCCESS:
    return {
      ...state,
      batteryPerformance: {
        ...state.batteryPerformance,
        isFetching: false,
        error: false,
        payload: action.payload,
      },
    };
  case alertActionType.FETCH_BATTERY_PERFORMANCE_ERROR:
    return {
      ...state,
      batteryPerformance: {
        ...state.batteryPerformance,
        isFetching: false,
        payload: [],
        error: true,
        errorMessage: action.error,
      },
    };
  //fetching single camera alert
  case alertActionType.FETCH_SINGLE_CAMERA_ALERT:
    return {
      ...state,
      singleCamera: {
        ...state.singleCamera,
        isFetching: true,
        payload: [],
        error: false,
      },
    };
  case alertActionType.FETCH_SINGLE_CAMERA_ALERT_SUCCESS:
    return {
      ...state,
      singleCamera: {
        ...state.singleCamera,
        isFetching: false,
        error: false,
        payload: action.payload,
      },
    };
  case alertActionType.FETCH_SINGLE_CAMERA_ALERT_ERROR:
    return {
      ...state,
      singleCamera: {
        ...state.singleCamera,
        isFetching: false,
        payload: [],
        error: true,
        errorMessage: action.error,
      },
    };
  
    default:
      return state;
  }
};
export default AlertReducer;
