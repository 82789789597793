import { all, put, takeLatest } from "redux-saga/effects";
import { fetchRoleFeatureError, fetchRoleFeatureSuccess, roleFeatureActionType } from "../actions/RoleFeatures";
import { fetchFeatureList } from "../../services/RoleFeatureServices";

//fetch feature list
function* _fetchRoleFeatureList(action) {

    try {
        const featureInfo = yield fetchFeatureList(action.data);
        yield put(fetchRoleFeatureSuccess(featureInfo));
    } catch (e) {
        yield put(fetchRoleFeatureError("error"));
    }
}
function* fetchRoleFeatureList (){
    yield takeLatest(roleFeatureActionType.FETCH_ROLE_FEATURE,_fetchRoleFeatureList)
}
export function* RoleFeatureSaga(){
    yield all([fetchRoleFeatureList()])
}