export const listnerActionType ={
    FETCH_LISTENER: "FETCH_LISTENER",
    FETCH_LISTENER_SUCCESS : "FETCH_LISTENER_SUCCESS",
    FETCH_LISTENER_ERROR : "FETCH_LISTENER_ERROR",

    FETCH_ASSINED_LISTENER: "FETCH_ASSINED_LISTENER",
    FETCH_ASSINED_LISTENER_SUCCESS : "FETCH_ASSINED_LISTENER_SUCCESS",
    FETCH_ASSINED_LISTENER_ERROR : "FETCH_ASSINED_LISTENER_ERROR",

    ASSIGN_LISTENER : "ASSIGN_LISTENER" ,
    ASSIGN_LISTENER_SUCCESS : "ASSIGN_LISTENER_SUCCESS" ,
    ASSIGN_LISTENER_ERROR : "ASSIGN_LISTENER_ERROR" ,

    UNASSIGN_LISTENER : "UNASSIGN_LISTENER" ,
    UNASSIGN_LISTENER_SUCCESS : "UNASSIGN_LISTENER_SUCCESS" ,
    UNASSIGN_LISTENER_ERROR : "UNASSIGN_LISTENER_ERROR" ,

  


}
//fetch listener list
export const fetchListener = (id) => {


    return {
        type: listnerActionType.FETCH_LISTENER,
        id:id
      
    }
}
export const fetchListenerSuccess = (data) => {
    return {
        type: listnerActionType.FETCH_LISTENER_SUCCESS,
        payload: data
    }
}
export const fetchListenerError = (error) => {
    return {
        type: listnerActionType.FETCH_LISTENER_ERROR,
        error
    }
}
//fetch assigned listener list
export const fetchAssignedListener = (id) => {


    return {
        type: listnerActionType.FETCH_ASSINED_LISTENER,
        id:id
      
    }
}
export const fetchAssignedListenerSuccess = (data) => {
    return {
        type: listnerActionType.FETCH_ASSINED_LISTENER_SUCCESS,
        payload: data
    }
}
export const fetchAssignedListenerError = (error) => {
    return {
        type: listnerActionType.FETCH_ASSINED_LISTENER_ERROR,
        error
    }
}
// assign listener 
export const assignListener = (data) => {


    return {
        type: listnerActionType.ASSIGN_LISTENER,
        data:data
      
    }
}
export const assignListenerSuccess = (data) => {
    return {
        type: listnerActionType.ASSIGN_LISTENER_SUCCESS,
        payload: data
    }
}
export const assignListenerError = (error) => {
    return {
        type: listnerActionType.ASSIGN_LISTENER_ERROR,
        error
    }
}
// unassign listener 
export const unAssignListener = (data) => {


    return {
        type: listnerActionType.UNASSIGN_LISTENER,
        data:data
      
    }
}
export const unAssignListenerSuccess = (data) => {
    return {
        type: listnerActionType.UNASSIGN_LISTENER_SUCCESS,
        payload: data
    }
}
export const unAssignListenerError = (error) => {
    return {
        type: listnerActionType.UNASSIGN_LISTENER_ERROR,
        error
    }
}