import React, { useEffect, useState } from "react";
import { Layout } from "../layout/Layout";
import { Grid } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { ReportTab } from "./ReportTabs";
import { subDays } from "date-fns";
import { AlertReportHome } from "./reportAlerts/AlertReportHome";
import { TrackingReportHome } from "./trackingAlerts/TrackingReportsHome";
import { SurveillanceReportHome } from "./surveillanceReports/SurveillanceReportHome";
export const ReportHome = ({}) => {
  const [activeMenuItem, setActiveMenuItem] = useState("report");
  const [selectedStartDate, setSelectedStartDate] = useState(
    subDays(new Date(), 7)
  );
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [searchWord, setSearchWord] = useState("");
  const [tabValue, setTabValue] = useState("r1");
  const [istracking, setIsTracking] = useState(false);
  const [isSurveillance, setIsSurveillance] = useState(false);
  const [orgType, setOrgType] = useState(0);
  useEffect(() => {
    //     const features = sessionStorage.getItem("per_feature");
    //     if(features){
    //         features.map(item=>{
    //             if(item == 6){
    //                 setIs
    //             }
    //         })
    //     }\
    const orgType = sessionStorage.getItem("organisation_type_data");
    setOrgType(orgType);
    if(orgType == 0){
      setTabValue("r2")

    }
    else if(orgType == 1){
      setTabValue("r1")
    }
    else{
      setTabValue("r1")

    }
  }, []);
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const onSearchWordChange = (data) => {
    setSearchWord(data);
  };
  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
    // setAnchorEl(null);
    sessionStorage.setItem("reportTab", newValue);
  };
  return (
    <Layout
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      searchWord={searchWord}
      handleEndDateChange={handleEndDateChange}
      handleStartDateChange={handleStartDateChange}
      onSearchWordChange={onSearchWordChange}
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"report"}
      activeMenuKey={"Report"}
      placeHolder={"Search violations..."}
    >
      <div
        style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%" }}
        className=""
      >
        <Grid container spacing={1} sx={{ pt: 3, mb: 5, ml: 2 }}>
          <TabContext value={tabValue}>
            <ReportTab
              handleTabChange={handleTabChange}
              tabValue={tabValue}
              orgType={orgType}
            />
            {orgType == 1 && (
              <TabPanel value="r1" index="r1" sx={{ width: "100% !important" }}>
                <SurveillanceReportHome
                //   selectedStartDate={selectedStartDate}
                //   selectedEndDate={selectedEndDate}
                />
              </TabPanel>
            )}
            {orgType == 0 && (
              <TabPanel value="r2" index="r2" sx={{ width: "100% !important" }}>
                <TrackingReportHome
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                />
              </TabPanel>
            )}
            {orgType == 'null' && (
              <>
                <TabPanel
                  value="r1"
                  index="r1"
                  sx={{ width: "100% !important" }}
                >
                  <SurveillanceReportHome
                  //   selectedStartDate={selectedStartDate}
                  //   selectedEndDate={selectedEndDate}
                  />
                </TabPanel>

                <TabPanel
                  value="r2"
                  index="r2"
                  sx={{ width: "100% !important" }}
                >
                  <TrackingReportHome
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                  />
                </TabPanel>
              </>
            )}
            {orgType == 2 && (
              <>
                <TabPanel
                  value="r1"
                  index="r1"
                  sx={{ width: "100% !important" }}
                >
                  <SurveillanceReportHome
                  //   selectedStartDate={selectedStartDate}
                  //   selectedEndDate={selectedEndDate}
                  />
                </TabPanel>

                <TabPanel
                  value="r2"
                  index="r2"
                  sx={{ width: "100% !important" }}
                >
                  <TrackingReportHome
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                  />
                </TabPanel>
              </>
            )}
          </TabContext>
        </Grid>
      </div>
    </Layout>
  );
};
