import {surveillanceActionType } from "../actions/SurveillanceAction"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    mapData: dataTemplate,
    vmsDeviceList: dataTemplate,
    sensorList: dataTemplate,
    addSensor: dataTemplate,
    singleSensor: dataTemplate,
    updateSensor: dataTemplate,
    deleteSensor: dataTemplate,
    cameraDropdown: dataTemplate,
    sensorDropdown: dataTemplate,
    batteryDropdown: dataTemplate,
    addEncoder: dataTemplate,
    SingleEncoder: dataTemplate,
    deleteEncoder: dataTemplate,
    editEncoder: dataTemplate,
    addPower: dataTemplate,
    powerList: dataTemplate,
    deletePower: dataTemplate,
    editPower: dataTemplate,
    singlePower: dataTemplate,
    cameraStatus:dataTemplate,
    cameraHistory:dataTemplate,
    deviceImport:dataTemplate,
    
    mapInfo: null,
    mapPositionMarkers: []
}
const SurveillanceReducer = (state = initialData, action) => {
    switch (action.type) {
        //map data
        case surveillanceActionType.FETCH_SURVEILLANCE_MAP:
            return {
                ...state,
                mapData: {
                    ...state.mapData,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_SURVEILLANCE_MAP_SUCCESS:
            return {

                ...state,
                mapData: {
                    ...state.mapData,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_SURVEILLANCE_MAP_ERROR:
            return {
                ...state,
                mapData: {
                    ...state.mapData,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch vms device list
        case surveillanceActionType.FETCH_VMS_DEVICE_LIST:
            return {
                ...state,
                vmsDeviceList: {
                    ...state.vmsDeviceList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_VMS_DEVICE_LIST_SUCCESS:
            return {

                ...state,
                vmsDeviceList: {
                    ...state.vmsDeviceList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_VMS_DEVICE_LIST_ERROR:
            return {
                ...state,
                vmsDeviceList: {
                    ...state.vmsDeviceList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch sensor list
        case surveillanceActionType.FETCH_SENSOR_LIST:
            return {
                ...state,
                sensorList: {
                    ...state.sensorList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_SENSOR_LIST_SUCCESS:
            return {

                ...state,
                sensorList: {
                    ...state.sensorList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_SENSOR_LIST_ERROR:
            return {
                ...state,
                sensorList: {
                    ...state.sensorList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //add sensor 
        case surveillanceActionType.ADD_SENSOR:
            return {
                ...state,
                addSensor: {
                    ...state.addSensor,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.ADD_SENSOR_SUCCESS:
            return {

                ...state,
                addSensor: {
                    ...state.addSensor,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.ADD_SENSOR_ERROR:
            return {
                ...state,
                addSensor: {
                    ...state.addSensor,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_ADD_SENSOR_STATUS:
            return {
                ...state,
                addSensor: {
                    ...state.addSensor,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
        //get sensor
        case surveillanceActionType.FETCH_SINGLE_SENSOR:
            return {

                ...state,
                singleSensor: {
                    ...state.singleSensor,
                    isFetching: true,
                    error: false,
                    payload: []
                }
            }
        case surveillanceActionType.FETCH_SINGLE_SENSOR_SUCCESS:
            return {
                ...state,
                singleSensor: {
                    ...state.singleSensor,
                    isFetching: false,
                    payload: action.payload,
                    error: false,
                }
            }
        case surveillanceActionType.FETCH_SINGLE_SENSOR_ERROR:
            return {
                ...state,
                singleSensor: {
                    ...state.singleSensor,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error

                }
            }
        //update sensor 
        case surveillanceActionType.UPDATE_SENSOR:
            return {
                ...state,
                updateSensor: {
                    ...state.updateSensor,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.UPDATE_SENSOR_SUCCESS:
            return {

                ...state,
                updateSensor: {
                    ...state.updateSensor,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.UPDATE_SENSOR_ERROR:
            return {
                ...state,
                updateSensor: {
                    ...state.updateSensor,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_UPDATE_SENSOR_STATUS:
            return {
                ...state,
                updateSensor: {
                    ...state.updateSensor,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
        //delete sensor 
        case surveillanceActionType.DELETE_SENSOR:
            return {
                ...state,
                deleteSensor: {
                    ...state.deleteSensor,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.DELETE_SENSOR_SUCCESS:
            return {

                ...state,
                deleteSensor: {
                    ...state.deleteSensor,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.DELETE_SENSOR_ERROR:
            return {
                ...state,
                deleteSensor: {
                    ...state.deleteSensor,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_DELETE_SENSOR_STATUS:
            return {
                ...state,
                deleteSensor: {
                    ...state.deleteSensor,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
        //camera dropdown
        case surveillanceActionType.FETCH_CAMERA_DROPDOWN:
            return {
                ...state,
                cameraDropdown: {
                    ...state.cameraDropdown,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_CAMERA_DROPDOWN_SUCCESS:
            return {

                ...state,
                cameraDropdown: {
                    ...state.cameraDropdown,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_CAMERA_DROPDOWN_ERROR:
            return {
                ...state,
                cameraDropdown: {
                    ...state.cameraDropdown,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //sensor dropdown
        case surveillanceActionType.FETCH_SENSOR_DROPDOWN:
            return {
                ...state,
                sensorDropdown: {
                    ...state.sensorDropdown,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_SENSOR_DROPDOWN_SUCCESS:
            return {

                ...state,
                sensorDropdown: {
                    ...state.sensorDropdown,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_SENSOR_DROPDOWN_ERROR:
            return {
                ...state,
                sensorDropdown: {
                    ...state.sensorDropdown,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //battery dropdown
        case surveillanceActionType.FETCH_BATTERY_DROPDOWN:
            return {
                ...state,
                batteryDropdown: {
                    ...state.batteryDropdown,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_BATTERY_DROPDOWN_SUCCESS:
            return {

                ...state,
                batteryDropdown: {
                    ...state.batteryDropdown,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_BATTERY_DROPDOWN_ERROR:
            return {
                ...state,
                batteryDropdown: {
                    ...state.batteryDropdown,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //add encoder
        case surveillanceActionType.ADD_ENCODER:
            return {
                ...state,
                addEncoder: {
                    ...state.addEncoder,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.ADD_ENCODER_SUCCESS:
            return {

                ...state,
                addEncoder: {
                    ...state.addEncoder,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.ADD_ENCODER_ERROR:
            return {
                ...state,
                addEncoder: {
                    ...state.addEncoder,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_ADD_ENCODER_STATUS:
            return {
                ...state,
                addEncoder: {
                    ...state.addEncoder,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
        //get encoder
        case surveillanceActionType.FETCH_SINGLE_ENCODER:
            return {
                ...state,
                SingleEncoder: {
                    ...state.SingleEncoder,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_SINGLE_ENCODER_SUCCESS:
            return {

                ...state,
                SingleEncoder: {
                    ...state.SingleEncoder,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_SINGLE_ENCODER_ERROR:
            return {
                ...state,
                SingleEncoder: {
                    ...state.SingleEncoder,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //delete encoder
        case surveillanceActionType.DELETE_ENCODER:
            return {
                ...state,
                deleteEncoder: {
                    ...state.deleteEncoder,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.DELETE_ENCODER_SUCCESS:
            return {

                ...state,
                deleteEncoder: {
                    ...state.deleteEncoder,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.DELETE_ENCODER_ERROR:
            return {
                ...state,
                deleteEncoder: {
                    ...state.deleteEncoder,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_DELETE_ENCODER_STATUS:
            return {
                ...state,
                deleteEncoder: {
                    ...state.deleteEncoder,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
             //edit encoder
        case surveillanceActionType.EDIT_ENCODER:
            return {
                ...state,
                editEncoder: {
                    ...state.editEncoder,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.EDIT_ENCODER_SUCCESS:
            return {

                ...state,
                editEncoder: {
                    ...state.editEncoder,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.EDIT_ENCODER_ERROR:
            return {
                ...state,
                editEncoder: {
                    ...state.editEncoder,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_EDIT_ENCODER_STATUS:
            return {
                ...state,
                editEncoder: {
                    ...state.editEncoder,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
        //add power
        case surveillanceActionType.ADD_POWER:
            return {
                ...state,
                addPower: {
                    ...state.addPower,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.ADD_POWER_SUCCESS:
            return {

                ...state,
                addPower: {
                    ...state.addPower,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.ADD_POWER_ERROR:
            return {
                ...state,
                addPower: {
                    ...state.addPower,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_ADD_POWER_STATUS:
            return {
                ...state,
                addPower: {
                    ...state.addPower,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
        //get powerlist
        case surveillanceActionType.FETCH_POWERLIST:
            return {
                ...state,
                powerList: {
                    ...state.powerList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_POWERLIST_SUCCESS:
            return {

                ...state,
                powerList: {
                    ...state.powerList,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_POWERLIST_ERROR:
            return {
                ...state,
                powerList: {
                    ...state.powerList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //delete power 
        case surveillanceActionType.DELETE_POWER:
            return {
                ...state,
                deletePower: {
                    ...state.deletePower,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.DELETE_POWER_SUCCESS:
            return {

                ...state,
                deletePower: {
                    ...state.deletePower,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.DELETE_POWER_ERROR:
            return {
                ...state,
                deletePower: {
                    ...state.deletePower,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_DELETE_POWER_STATUS:
            return {
                ...state,
                deletePower: {
                    ...state.deletePower,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
        //edit power 
        case surveillanceActionType.EDIT_POWER:
            return {
                ...state,
                editPower: {
                    ...state.editPower,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.EDIT_POWER_SUCCESS:
            return {

                ...state,
                editPower: {
                    ...state.editPower,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.EDIT_POWER_ERROR:
            return {
                ...state,
                editPower: {
                    ...state.editPower,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case surveillanceActionType.CLEAR_EDIT_POWER_STATUS:
            return {
                ...state,
                editPower: {
                    ...state.editPower,
                    isFetching: false,
                    payload: [],
                    error: false,
                }
            }
        //sigle power 
        case surveillanceActionType.FETCH_SINGLE_POWER:
            return {
                ...state,
                singlePower: {
                    ...state.singlePower,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case surveillanceActionType.FETCH_SINGLE_POWER_SUCCESS:
            return {

                ...state,
                singlePower: {
                    ...state.singlePower,
                    isFetching: false,
                    error: false,
                    payload: action.payload
                }
            }
        case surveillanceActionType.FETCH_SINGLE_POWER_ERROR:
            return {
                ...state,
                singlePower: {
                    ...state.singlePower,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }

       //change camera status
       case surveillanceActionType.UPDATE_CAMERA_STATUS:
        return {
            ...state,
            cameraStatus: {
                ...state.cameraStatus,
                isFetching: true,
                payload: [],
                error: false
            }
        }
    case surveillanceActionType.UPDATE_CAMERA_STATUS_SUCCESS:
        return {

            ...state,
            cameraStatus: {
                ...state.cameraStatus,
                isFetching: false,
                error: false,
                payload: action.payload
            }
        }
    case surveillanceActionType.UPDATE_CAMERA_STATUS_ERROR:
        return {
            ...state,
            cameraStatus: {
                ...state.cameraStatus,
                isFetching: false,
                payload: [],
                error: true,
                errorMessage: action.error
            }
        }
    case surveillanceActionType.CLEAR_UPDATE_CAMERA_STATUS:
        return {
            ...state,
            cameraStatus: {
                ...state.cameraStatus,
                isFetching: false,
                payload: [],
                error: false,
            }
        }

  //get camera history
  case surveillanceActionType.FETCH_CAMERA_HISTORY:
    return {
        ...state,
        cameraHistory: {
            ...state.cameraHistory,
            isFetching: true,
            payload: [],
            error: false
        }
    }
case surveillanceActionType.FETCH_CAMERA_HISTORY_SUCCESS:
    return {

        ...state,
        cameraHistory: {
            ...state.cameraHistory,
            isFetching: false,
            error: false,
            payload: action.payload
        }
    }
case surveillanceActionType.FETCH_CAMERA_HISTORY_ERROR:
    return {
        ...state,
        cameraHistory: {
            ...state.cameraHistory,
            isFetching: false,
            payload: [],
            error: true,
            errorMessage: action.error
        }
    }
  //Device Onboading
  case surveillanceActionType.DEVICE_ONBOARD:
    return {
        ...state,
        deviceImport: {
            ...state.deviceImport,
            isFetching: true,
            payload: [],
            error: false
        }
    }
case surveillanceActionType.DEVICE_ONBOARD_SUCCESS:

    return {

        ...state,
        deviceImport: {
            ...state.deviceImport,
            isFetching: false,
            error: false,
            payload: action.payload
        }
    }
case surveillanceActionType.DEVICE_ONBOARD_ERROR:
    return {
        ...state,
        deviceImport: {
            ...state.deviceImport,
            isFetching: false,
            payload: [],
            error: true,
            errorMessage: action.error
        }
    }
    case surveillanceActionType.CLEAR_DEVICE_ONBOARD_STATUS:
        return {
            ...state,
            deviceImport: {
                ...state.deviceImport,
                isFetching: false,
                payload: [],
                error: false,
                errorMessage: ""
            }
        }
    



            //survilance map
        case surveillanceActionType.SET_SURVEILLANCE_MAP:
            return {
                ...state,
                mapInfo: action.payload
            }
        case surveillanceActionType.SET_SURVEILLANCE_MAP_MARKERS:
            return {
                ...state,
                mapPositionMarkers: action.payload
            }
        default:
            return state
    }

}
export default SurveillanceReducer;