import { all, put, takeLatest } from "redux-saga/effects";
import { addUserError, addUserSuccess, deleteUserError, deleteUserSuccess, fetchUserError, 
    fetchUserListError, fetchUserListSuccess, fetchUserSuccess, updateUserError, 
    updateUserSuccess, userActionType } from "../actions/UserActions";
import { addUserData, deleteUserData, editUserData, fetchUserData, 
    fetchUserListData } from "../../services/UserServices";

//upload pic of user
// function* _postUserPic(action) {

//     try {
//         const userPicInfo = yield uploadUserProfilePic(action.data);
//         yield put(uploadUserPicSuccess(userPicInfo));
//     } catch (e) {
//         yield put(uploadUserPicError("error"));
//     }
// }
// function* postUserPic() {
//     yield takeLatest(userActionType.UPLOAD_USER_PIC, _postUserPic)
// }
//edit user
function* _editUser(action) {

    try {
        const updateUserInfo = yield editUserData(action.id,action.data);
        yield put(updateUserSuccess(updateUserInfo));
    } 
    catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(updateUserError( error.response.data));
        }else{
            yield put(updateUserError( error));
        }
    }
}
function* editUser() {
    yield takeLatest(userActionType.UPDATE_USER, _editUser)
}
//delete user
function* _removeUser(action) {

    try {
        const deleteUserInfo = yield deleteUserData(action.data);
        yield put(deleteUserSuccess(deleteUserInfo));
    } catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(deleteUserError( error.response.data));
        }else{
            yield put(deleteUserError( error));
        }
    }
}
function* removeUser() {
    yield takeLatest(userActionType.DELETE_USER, _removeUser)
}
//fetch userlist
function* _getUserList(action) {

    try {
        const userlistInfo = yield fetchUserListData(action.data);
        yield put(fetchUserListSuccess(userlistInfo));
    } catch (e) {
        yield put(fetchUserListError("error"));
    }
}
function* getUserList() {
    yield takeLatest(userActionType.FETCH_USER_LIST, _getUserList)
} 
//add user
function* _addUserDetails(action) {

    try {
        const userInfo = yield addUserData(action.data);
        yield put(addUserSuccess(userInfo));
    } 
    catch  (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(addUserError( error.response.data));
        }else{
            yield put(addUserError( error));
        }
    }
}
function* addUserDetails() {
    yield takeLatest(userActionType.ADD_USER, _addUserDetails)
} 
//fetch single user
function* _fetchUserDetails(action) {

    try {
        const userInfo = yield fetchUserData(action.id);
        yield put(fetchUserSuccess(userInfo));
    } catch (e) {
        yield put(fetchUserError("error"));
    }
}
function* fetchUserDetails() {
    yield takeLatest(userActionType.FETCH_USER, _fetchUserDetails)
} 
export function* UserSaga(){
    yield all([fetchUserDetails(),addUserDetails(),getUserList(),removeUser(),editUser()])
}