import { Alert, CircularProgress, Grid, LinearProgress, Snackbar } from '@mui/material';
import React,{useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchUserRole } from '../../redux/actions/UserRoleActions';
import { Layout } from '../layout/Layout';
export const InitialLoading =()=>{
    const roleDetails = useSelector(state => state.userRole.roleData);
    const[location,setLocation] = useState("");
    const [isShowAlert, setShowAlert] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        const role_id = sessionStorage.getItem('role_id');
        dispatch(fetchUserRole(role_id));

       
        const temp_id = { id: parseInt(role_id) };
    },[])
    useEffect(() => {
        if (!roleDetails.isFetching && roleDetails.payload && roleDetails.payload.success === true) {
            const roleData = roleDetails.payload.Role_details.role_feature[0].features;
            const roleArray = JSON.parse(roleData);

            const tempactivebar=roleArray[0];
            let path=''
            switch(tempactivebar)
            {
                case 1:
                    path='/dashboard'
                break;
                case 2:
                    path='/organisation'
                break;

                case 3:
                    path='/usermanagement'
                break;
                case 4:
                    path='/roleManagement'
                break;
                case 5:
                    path='/tracking'
                break;
                case 6:
                    path='/report'
                break;
                case 7:
                    path='/surveillance'
                break;
                case 8:
                    path='/alert'
                break;
                case 9:
                    path='/devicemanagement'
                break;
                case 10:
                    path='/support'
                break;
                case 11:
                    path='/audittrail'
                break;
                default:
                    break;
            }
            setLocation(path)
           window.location.href=path;
        }
        if (!roleDetails.isFetching && roleDetails.error){
            setShowAlert(true)

            const timeoutId = setTimeout(() => {
                setShowAlert(false);
              //  dispatch(clearFetchAuthenticationInfo());
              window.location.href='/';

            }, 3000);
            return () => clearTimeout(timeoutId);

        }
    }, [roleDetails])
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return(
    
        <>
         <Snackbar
                open={isShowAlert}
                autoHideDuration={3000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                     User Permissions Denied
                </Alert>
            </Snackbar>
            <Grid container >
                <Grid item container lg={12} md={12} sm={12} sx={{pt:'20%'}} style={{justifyContent:'center'}}>
                <CircularProgress className="progress" color="warning"  />

                </Grid>

            </Grid>
        </>
       
    )
}