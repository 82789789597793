import { Card, CardActionArea, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import { cardHeaderText } from "../../../styles/CardStyles";
export const BridgeListItem = ({ DeviceDetails,handleView }) => {
    return (
        <>
            <Card className="device_card_bridge">
                <CardHeader
                    avatar={
                        <IconButton sx={{ backgroundColor: '#F8E3C4', marginRight: -1 }}>
                            <img src={require("../../../../assets/img/bridge.png")} alt="listener" />
                        </IconButton>
                    }
                    title={DeviceDetails.name}
                    titleTypographyProps={cardHeaderText.title}
                    subheaderTypographyProps={cardHeaderText.subHeader}
                />
                <div className="card_divider"></div>
                <CardContent>
                    <Grid container sx={{mb:2}}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography className="card_text_label">
                                Last reported Time 
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography className="card_text">
                                {DeviceDetails.lastSeen}
                            </Typography>
                        </Grid>
                        </Grid>
                        <div className="card_divider"></div>
                        <Grid container sx={{mt:2}}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography className="card_text_label">
                                IP Address 
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography className="card_text">
                                {DeviceDetails.ip_address}
                            </Typography>
                        </Grid>
                    </Grid>

                </CardContent>
                <div className="card_divider"></div>
                <CardActionArea sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2 }}>
                    <IconButton  
                        onClick={()=>handleView(DeviceDetails.id)}
                    >
                        <IoEyeOutline color="#161616" />
                    </IconButton>
                </CardActionArea>
            </Card>
        </>
    )
}