import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { TagModalstyle } from "../styles/ModalStyle";
import { RiBuildingLine } from "react-icons/ri";
import { BorderButton, StyledCreateSmallButton } from "../styles/ButtonStyles";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { GoTag } from "react-icons/go";

export const BuildSiteModal = ({ handleBuildSiteModalOpen, openBuildSite,handleBuild ,handleClose}) => {
  return (
    <>
      <Modal
        open={openBuildSite}
        onClose={handleBuildSiteModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={TagModalstyle}>
          <IconButton>
            <RiBuildingLine color="#FFAC0B" size={50} />
          </IconButton>
          <Typography className="modalhead">Build Sites</Typography>
          <Typography className="modalBody modallight" sx={{ mt: 2 }}>
            Are you want add Sites, Level, Block and Map Under Your Organisation
          </Typography>
          <div style={{ display: 'flex' ,flexWrap:'wrap'}} >
                        <BorderButton
                            sx={{ mr: 2, mt: 3 }} variant="outlined"
                            onClick={handleClose}
                        >
                            <HighlightOffRoundedIcon
                                sx={{ fontSize: '20px', mr: '5px', color: "#161616" }} />
                            Cancel
                        </BorderButton>
                        <StyledCreateSmallButton
                            startIcon={<RiBuildingLine  color="white" />}
                            sx={{ mt: 3 }}
                            variant="contained"
                           onClick={handleBuild}
                        >
                            {/* <ThumbUpOffAltIcon sx={{ fontSize: '20px', mr: '5px', color: "#ffffff" }} /> */}
                            Build Sites 
                        </StyledCreateSmallButton>

                    </div>
        </Box>
      </Modal>
    </>
  );
};
