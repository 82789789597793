import { all, put, takeLatest } from 'redux-saga/effects'
import {
    authenticationActionType, fetchAuthenticationInfoError, fetchAuthenticationInfoSuccess,
    fetchLoggedUserInfoError,
    fetchLoggedUserInfoSuccess,
    fetchRefreshTokenError,
    fetchRefreshTokenSuccess,
    forgotPasswordError, forgotPasswordSuccess, otpVerificationError, otpVerificationSuccess,
    resetPasswordError, resetPasswordSuccess,
    userLogoutSuccess
} from '../actions/AuthenticationActions'
import {
    createNewPassword, forgottedData, getLoggedUserData,
    getRefreshToken,
    loginData, MakeUserLogout, otpData
} from '../../services/AuthenticationServices';
//reset password
function* _userLogout(action) {

    try {
        const logout = yield MakeUserLogout(action.data);
        yield put(userLogoutSuccess(logout));
    } catch (error) {

        if (error.response && error.response.data && error.response.data) {
            yield put(userLogoutSuccess(error.response.data));
        } else {
            yield put(userLogoutSuccess(error));
        }
    }
}
function* userLogout() {
    yield takeLatest(authenticationActionType.USER_LOGOUT, _userLogout)
}
//refresh token
function* _refreshToken(action) {

    try {
        const resetInfo = yield getRefreshToken(action.data);
        yield put(fetchRefreshTokenSuccess(resetInfo));
    } catch (error) {

        if (error.response && error.response.data && error.response.data) {
            yield put(fetchRefreshTokenError(error.response.data));
        } else {
            yield put(fetchRefreshTokenError(error));
        }
    }
}
function* refreshToken() {
    yield takeLatest(authenticationActionType.FETCH_REFRESH_TOKEN, _refreshToken)
}
//logged user daata
function* _getLoggedUser(action) {

    try {
        const resetInfo = yield getLoggedUserData(action.id);
        yield put(fetchLoggedUserInfoSuccess(resetInfo));
    } catch (e) {
        yield put(fetchLoggedUserInfoError("error"));
    }
}
function* getLoggedUser() {
    yield takeLatest(authenticationActionType.FETCH_LOGGED_USER_INFO, _getLoggedUser)
}
//reset password
function* _passwordReset(action) {

    try {
        const resetInfo = yield createNewPassword(action.data);
        yield put(resetPasswordSuccess(resetInfo));
    } catch (error) {

        if (error.response && error.response.data && error.response.data) {
            yield put(resetPasswordError(error.response.data));
        } else {
            yield put(resetPasswordError(error));
        }
    }
}
function* passwordReset() {
    yield takeLatest(authenticationActionType.RESET_PASSWORD, _passwordReset)
}
//otp verify
function* _verifyOtp(action) {

    try {

        const otpInfo = yield otpData(action.data);
        yield put(otpVerificationSuccess(otpInfo));
    } catch (error) {
        if (error.response && error.response.data && error.response.data) {
            yield put(otpVerificationError(error.response.data));
        } else {
            yield put(otpVerificationError(error));
        }

    }
}
function* verifyOtp() {
    yield takeLatest(authenticationActionType.OTP_VERIFICATION, _verifyOtp)
}
//forgot password
function* _forgotUserPassword(action) {

    try {
        const forgotInfo = yield forgottedData(action.data);
        yield put(forgotPasswordSuccess(forgotInfo));
    } catch (error) {

        if (error.response && error.response.data && error.response.data) {
            yield put(forgotPasswordError(error.response.data));
        } else {
            yield put(forgotPasswordError(error));
        }
    }
}
function* forgotUserPassword() {
    yield takeLatest(authenticationActionType.FORGOT_PASSWORD, _forgotUserPassword)
}

//Login
function* _loginUser(action) {

    try {
        const loginInfo = yield loginData(action.data);
        yield put(fetchAuthenticationInfoSuccess(loginInfo));
    } catch (error) {
        if (error.response && error.response.data && error.response.data) {
            yield put(fetchAuthenticationInfoError(error.response.data));
        } else {
            yield put(fetchAuthenticationInfoError(error));
        }
    }
}
function* loginUser() {
    yield takeLatest(authenticationActionType.FETCH_AUTHENTICATION_INFO, _loginUser)
}

export function* AuthenticationSaga() {
    yield all([loginUser(), forgotUserPassword(), verifyOtp(), passwordReset(), getLoggedUser(),refreshToken(),userLogout()])
}