import React, { useEffect, useState } from "react";
import '../dashboard/Dashboard.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StyledGreyButton, StyledGreySmallButton } from "../Styles";
import { Alert, Checkbox, CircularProgress, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addUserRole, fetchSites, fetchUserRole } from "../../redux/actions/UserRoleActions";
import { fetchRoleFeature } from "../../redux/actions/RoleFeatures";
import { Layout } from "../layout/Layout";
import { fetchUser } from "../../redux/actions/UserActions";
import { subDays } from "date-fns";
import { StyledCreateSmallButton } from "../styles/ButtonStyles";

export const RoleCreate = ({ }) => {
    const [isDarkMode, setDarkMode] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(subDays(new Date(), 5));
    const [validationErrors, setValidationErrors] = useState({
        role: '',
        features: '',
    });
    const dispatch = useDispatch();
    //const siteData = useSelector(state => state.userRole.siteData);
    const featureData = useSelector(state => state.feature.featureData);
    const newRoleData = useSelector(state => state.userRole.addUserRole);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('')
    const [feature, setFeature] = useState([]);
    const [orgType, setOrgType] = useState(0);
    const [roleDetails, setRoleDetails] = useState({
        role: "",
        status: 1,
        //  site_id: "",
    })
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    useEffect(() => {
        dispatch(fetchRoleFeature());
        const orgType = sessionStorage.getItem('organisation_type_data');
        setOrgType(orgType);
    }, [])


    useEffect(() => {
        if (!featureData.isFetching && featureData.payload && featureData.payload.success === true) {
            const featureList = featureData.payload.feature_list;
            let features = featureList.slice();
            function removeFeatureByName(featureName) {
                features = features.filter(feature => feature.id !== featureName);
            }

            if (orgType == 1) {
                removeFeatureByName(5);
            } else if (orgType == 0) {
                removeFeatureByName(7);
            }
            setFeature(features);
        }
    }, [featureData])
    useEffect(() => {
        if (!newRoleData.isFetching && newRoleData.payload && newRoleData.payload.success === true) {
            const alert = newRoleData.payload.message;
            setAlertSuccess(true);
            setAlertMessage(alert)
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            window.location.href = '/roleManagement'
            return () => clearTimeout(timeoutId);
        }
        if (!newRoleData.isFetching && newRoleData.error && newRoleData.errorMessage.messages[0].success === false) {
            const alert = newRoleData.errorMessage.messages[0].message;
            setAlertMessage(alert)
            setAlertSuccess(false);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 2000);

            return () => clearTimeout(timeoutId);

        }
    }, [newRoleData])
    const handleChange = (value, key) => {
        let temp = { ...roleDetails };
        temp[key] = value;
        setRoleDetails(temp);
        // Validate the role name
        if (key === 'role') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                role: value.trim() === '' ? 'Role name is required' : '',
            }));
        }
        // Validate the role name
        if (key === 'role') {
            const roleLengthError =
                value.trim().length < 3 ? 'Role name must be at least 3 characters long' : '';

            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                role: roleLengthError,
            }));
        }
    }
    const handleCheckboxChange = (id) => {
        const isSelected = selectedFeatures.includes(id);

        // Update the selected features based on the current state
        if (isSelected) {
            setSelectedFeatures(selectedFeatures.filter(item => item !== id));
        } else {
            setSelectedFeatures([...selectedFeatures, id]);
        }
        const arrayString = JSON.stringify(selectedFeatures);
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            features: '',
        }));

    };
    const handleCreateRole = () => {

        let temp = {
            role: roleDetails.role,
            status: 1,
            // site_id: parseInt(roleDetails.site_id.organizationId),
            org_id: parseInt(sessionStorage.getItem('org_Id')),
            user_id: parseInt(sessionStorage.getItem('id')),
            features: JSON.stringify(selectedFeatures),
        }
        if (roleDetails.role.trim() === '') {
            setValidationErrors({
                role: 'Role name is required',
            });
            return;
        }
        // Validate the role name
        if (roleDetails.role.trim().length < 3) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                role: 'Role name must be at least 3 characters long',
            }));
            return;
        }

        if (selectedFeatures.length === 0) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                features: 'Please select at least one feature',
            }));
            return;
        }
        dispatch(addUserRole(temp))
    }

    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };
    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);

    }
    const handleBack = () => {
        window.location.href = '/roleManagement'

    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (<Layout
        isDarkMode={isDarkMode}
        handleDarkmode={handleDarkmode}
        isCreate={true}
        handleActiveMenuItems={handleActiveMenuItems}
        activeMenuItem={"rolemanagement"}
        activeMenuKey={"rolemanagement"}

    >
        <>
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: '100%', color: !alertSuccess ? '#C61A1A' : '#11b311', backgroundColor: !alertSuccess ? '#FCDFDF' : '#5bdb5b73' }}

                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            {newRoleData.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
            <Grid container spacing={2} sx={{ mt: 3, pb: 5 }} className={isDarkMode ? "table_dark ml" : "table_light ml"}>


                <Grid item lg={12}>
                    <Typography className='device_view_head'>
                        Create Role & Feature                    </Typography>

                </Grid>
                <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                    <InputLabel
                        className={isDarkMode ? "form_text" : "form_text_light"}
                        sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                        Name Of Role</InputLabel>
                    <TextField
                        className={isDarkMode ? "form_field form_div" : "form_field form_field_light"}
                        placeholder="Role name"

                        type="text"
                        inputProps={{ autoComplete: 'off' }}
                        id="userrolename1"
                        name={`userrolename1_${Math.random()}`}
                        value={roleDetails.role}
                        onChange={(e) => handleChange(e.target.value, "role")}
                        error={!!validationErrors.role}
                        helperText={validationErrors.role}
                    />
                </Grid>
                <Grid item xs={11} sm={11} lg={12} md={12} sx={{ mt: 3 }}>
                    <InputLabel
                        className={isDarkMode ? "form_text" : "form_text_light"}
                        sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                        Features</InputLabel>
                </Grid>
                <Grid item xs={11} sm={11} lg={11} md={11} sx={{ mt: 3 }} container columnSpacing={7}>

                    {feature.map((item, index) => (
                        <Grid item key={index} >
                            <Grid container spacing={1}>
                                <div className="role_box">

                                    <Grid item>
                                        <InputLabel
                                            className={isDarkMode ? "checkbox_text role_feature_text" :
                                                "role_feature_text checkbox_text"} >
                                            {item.feature}</InputLabel>
                                    </Grid>
                                    <Grid item >
                                        <Checkbox
                                            checked={selectedFeatures.includes(item.id)}
                                            onChange={() => handleCheckboxChange(item.id)}
                                            sx={{
                                                color: isDarkMode ? "white" : "#999999",
                                                // backgroundColor: isDarkMode?"#1E1D2C":"#E9E9E9",
                                                '&.Mui-checked': {
                                                    color: isDarkMode ? "white" : "#999999",
                                                    //   backgroundColor:  isDarkMode?"#1E1D2C":"#E9E9E9"
                                                },
                                            }} size="medium"
                                        />
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    ))}
                    {validationErrors.features && (
                        <Typography
                            sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                            className={
                                isDarkMode
                                    ? 'validation_error  errorStyle'
                                    : ' validation_error errorStyle'
                            }
                        >
                            {validationErrors.features}
                        </Typography>
                    )}
                </Grid>

                <Grid item container>
                    <StyledCreateSmallButton
                        sx={{ mt: 5, width: 150 }}
                        variant="contained"
                        disableRipple
                        onClick={handleCreateRole}
                    >
                        Create
                    </StyledCreateSmallButton>
                </Grid>
            </Grid>
}


        </>

    </Layout>
    )
}