import {
  Alert,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Layout } from "../../layout/Layout";
import {
  StyledCreateBorderButton,
  StyledCreateBorderLessButton,
  StyledCreateButton,
  StyledCreateSmallButton,
} from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { orgBuildLevel, orgBuildSite } from "../../../services/OrganisationServices";
import Card from "@mui/material/Card";
import L from "leaflet";
import { MapContainer, TileLayer, ImageOverlay } from "react-leaflet";
import { useParams } from "react-router-dom";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const AddLevel = () => {
  const dispatch = useDispatch();
  const siteListData = useSelector((state) => state.userRole.siteData);
  const { id } = useParams();
  const mapRef = useRef(null);
  const [mapDetails, setMap] = useState({
    minZoom: 17,
    maxZoom: 21,
  });
  const [mapUpload, setMapUpload] = useState({});
  const [mapBounds, setMapBounds] = useState(null);
  const [mapUrl, setMapUrl] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [activeMenuItem, setActiveMenuItem] = useState("organisation");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [blockCount, setBlockCount] = useState(1); // State to track the number of blocks added
  const [sites, setSites] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [siteData, setSiteData] = useState({
    org_id: id !== null ? id : parseInt(sessionStorage.getItem('created_org_id')),
    site: "",
    layer: "",
    block: [],
    map_version: "",
    map_name: "",
    scalling_factor: "",
    minzoom: "",
    maxzoom: "",
    imgheight: "",
    imgwidth: "",
    map_status: "",
    scalling_factors: [
      {
        "17": 0.063745019920319,
        "18": 0.031872509960159,
        "19": 0.01593625498008,
        "20": 0.0079681274900398,
        "21": 0.0039840637450199
      }
    ]
  });
  const [validationErrors, setValidationErrors] = useState({
    site: "",
    layer: "",
  })

  useEffect(() => {
    // Use the id value in any way you need
    let tempOg = { ...siteData };
    tempOg["org_id"] = id;
    setSiteData(tempOg);
    const is_wisilica = sessionStorage.getItem("orgsanisation_is_wisilica");
    if (id !== null) {
      // alert(orgId);

      let data = {
        org_id: id,
        start: 0,
        limit: 1000,
        is_wisilica: is_wisilica,
      };
      // dispatch(fetchUserList(data))
      dispatch(fetchSites(data));
    }
  }, [id]);
  useEffect(() => {
    if (
      !siteListData.isFetching &&
      siteListData.payload &&
      siteListData.payload.success === true
    ) {
      const siteList = siteListData.payload.data.SiteList;
      const temp = siteList.map((sites) => {
        return {
          //   networkId: sites.networkId,
          organizationId: sites.organizationId,
          organizationName: sites.organizationName,
          //   timestamp: sites.timestamp,
        };
      });

      setSelectedSiteId(temp[0].organizationId);
      // setSelectedUserSiteId(temp[0].organizationId)
      let tempSite = { ...siteData };
      tempSite["site"] = temp[0].organizationId;
      setSiteData(tempSite);
      setSites(temp);

    }
  }, [siteListData]);
  const handleAddBlock = () => {
    setBlockCount((prevCount) => prevCount + 1); // Increment the block count
  };

  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  const handleChange = (value, key, index) => {

    if (key === "block") {
      // If the key is "block", update the block array in siteData state
      setSiteData((prevData) => {
        const updatedBlock = [...prevData.block]; // Create a copy of the block array
        updatedBlock[index] = value; // Update the value at the specified index
        return {
          ...prevData,
          block: updatedBlock, // Update the block array in state
        };
      });
    } else {
      // For other keys, update directly
      setSiteData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
    const tempValue = String(value)
    const valueLength = tempValue.trim().length;
    let error = "";
    switch (key) {
      case "site":
        error = valueLength < 1 ? "This field is mandatory" : "";
        break;
      case "layer":
        error =
          valueLength < 2 || valueLength >= 30
            ? "Field must be between 2 and 30 characters long"
            : "";
        break;
      default:
        break;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  };
  const handleSiteCreate = async () => {
    let data = {
      site: String(siteData.site),
      layer: siteData.layer
    }


    const params = new FormData();
    params.append("org_id", siteData.org_id);
    params.append("site_id", siteData.site);
    params.append("layer", siteData.layer);
    params.append("block", siteData.block);
    params.append("map_version", siteData.map_version);
    params.append("map_name", siteData.map_name);
    params.append("scalling_factor", siteData.scalling_factor);
    params.append("scalling_factors", siteData.scalling_factors);

    params.append("minzoom", siteData.minzoom);
    params.append("maxzoom", siteData.maxzoom);
    params.append("imgheight", siteData.imgheight);
    params.append("imgwidth", siteData.imgwidth);
    params.append("map_status", 1);
    params.append("created_user", parseInt(sessionStorage.getItem("id")));
    params.append("file", mapUpload.currentFile);
    const isEmptyField = Object.values(data).some((value) => {
      return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
    });

    const allClear = Object.values(validationErrors).every((value) => {
      return (
        (typeof value === "string" && value.trim() == "") ||
        value === undefined ||
        value === null
      );
    });
    if (isEmptyField) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        site:
          String(siteData.site).trim() === ""
            ? "This field is mandatory"
            : prevErrors.site,
        layer: siteData.layer.trim() === ""
          ? "This field is mandatory"
          : prevErrors.layer,

      }));
    }

    if (!isEmptyField && allClear) {
      setValidationErrors({
        site: "",
        layer: "",

      });

      try {
        const resp = await orgBuildLevel(params);
        if (resp.success) {
          //  window.location.href = '/organisation';
          sessionStorage.setItem("organasition_view_id", siteData.org_id);
          sessionStorage.setItem('organisationTab', 'org_sites')
          window.location.href = '/organisation/view';
        }
      } catch (error) {
        const alert = "Level Creation Failed";
        setAlertMessage(alert);
        setAlertSuccess(false);
        setShowAlert(true);
        const timeoutId = setTimeout(() => {
          setShowAlert(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
      }
    };
  }
  const mapSelected = (item) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const data = { ...siteData };
        data.imgheight = height;
        data.imgwidth = width;
        setSiteData(data);
      };
    };

    reader.readAsDataURL(item.target.files[0]);

    setMapUpload({
      currentFile: item.target.files[0],
      previewImage: URL.createObjectURL(item.target.files[0]),
    });
    setMapUrl(URL.createObjectURL(item.target.files[0]));
  };
  const onMapReady = (map) => {
    map.target.setMaxBounds(map.target.getBounds());
    const dimesion = {
      height: mapDetails?.imgHeightMeters,
      width: mapDetails?.imgWidthMeters,
      mapName: mapDetails?.mapName,
    };
    map.target.addControl(
      new customLegends(dimesion, { position: "topright" })
    );
  };

  const customLegends = L.Control.extend({
    container: null,
    maxZoom: undefined,
    maxScalingFactor: undefined,
    initialize: function (dimesion, options) {
      L.Util.setOptions(this, options);
      this.height = Math.round(dimesion.height);
      this.width = Math.round(dimesion.width);
      this.mapName = dimesion.mapName;
    },
    onAdd: function () {
      const div = L.DomUtil.create(
        "div",
        "leaflet-trak-custom-legend leaflet-bar"
      );
      div.innerHTML = `<div></div><p>Map Name: ${this.mapName} </p><p style='float:left;'> Height: ${this.height} meters</p><br><p style='float:left;'>Width: ${this.width} meters</p></div>`;
      return div;
    },
  });
  const handlealertClose = () => {
    setShowAlert(false);
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"organisation"}
      activeMenuKey={"Organisation"}
      isCreate={true}
    >
      <div style={{ marginRight: "2%", marginTop: "3%" }} className="">

        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={handlealertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handlealertClose}
            severity={!alertSuccess ? "error" : "success"}
            variant="outlined"
            sx={{ width: '100%', color: !alertSuccess ? '#C61A1A' : '#11b311', backgroundColor: !alertSuccess ? '#FCDFDF' : '#5bdb5b73' }}

          >
            {alertMessage}
          </Alert>
        </Snackbar>

        <Grid
          container
          spacing={2}
          sx={{ mt: 3, pb: 5 }}
          className={"table_light ml"}
        >
          <Grid item lg={12}>
            <Typography className="device_view_head">Add Level</Typography>
          </Grid>
          <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light form_text_mob"}
              sx={{ color: "black", mb: 2 }}
            >
              Site Name
            </InputLabel>
            <Select
              className={"form_field form_field_light noml"}
              value={siteData.site}
              onChange={(e) => handleChange(e.target.value, "site")}
              displayEmpty
              renderValue={(selected) => {
                if (selected == '') {
                  return <span style={{ color: '#a2a2a2' }}>Site</span>;
                }

                const selectedSite = sites.find(site => site.organizationId === selected);

                // Return the organizationName of the selected site
                return selectedSite ? selectedSite.organizationName : '';
              }}
            //  error={!!validationErrors.org_type_id}
            >
              {sites.length ? (
                sites.map((org, index) => (
                  <MenuItem key={index} value={org.organizationId}>
                    {org.organizationName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No Sites Found</MenuItem>
              )}
            </Select>
            {validationErrors.site && (
              <Typography
                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                className={' errormargin errorStyle'}
              >
                {validationErrors.site}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} lg={12} md={12} sm={12} sx={{ mt: 3 }}>
            <Typography className="device_view_head">Add Level</Typography>
          </Grid>

          <Grid item xs={11} lg={11} md={11} sm={11} sx={{ mt: 3 }}>
            <div className="device_view">
              <Grid container spacing={2} sx={{ pb: 5 }}>
                <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                  <InputLabel
                    className={"form_text_light form_text_mob"}
                    sx={{ color: "black", mb: 2 }}
                  >
                    Level Name
                  </InputLabel>
                  <TextField
                    className={"form_field_right form_field_light"}
                    placeholder="Level Name"
                    type="text"
                    onChange={(e) => handleChange(e.target.value, "layer")}
                    value={siteData.layer}
                    error={!!validationErrors.layer}
                    helperText={validationErrors.layer}
                  />
                </Grid>
                <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                  <InputLabel
                    className={"form_text_light"}
                    sx={{ color: "black", ml: 0, mb: 2 }}
                  >
                    Map Name
                  </InputLabel>
                  <TextField
                    className={"form_field form_field_light"}
                    placeholder="Map Name"
                    onChange={(e) => handleChange(e.target.value, "map_name")}
                    value={siteData.map_name}
                    //   error={!!validationErrors.map_name}
                    //   helperText={validationErrors.map_name}
                    type="text"
                    inputProps={{ autoComplete: "off" }}
                    id="orgmail"
                    name={`orgmail_${Math.random()}`}
                  />
                </Grid>
                <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                  {[...Array(blockCount)].map((_, index) => (
                    <Grid key={index} item xs={10} lg={12} md={10} >
                      <Grid container sx={{ pb: 2 }}>
                        <Grid
                          item
                          lg={6}
                          sm={12}
                          xs={12}>
                          <InputLabel
                            className={"form_text_light form_text_mob"}
                            sx={{ color: "black", mb: 2 }}
                          >
                            Block Name
                          </InputLabel>
                        </Grid>
                        {index == 0 && (
                          <Grid
                            item
                            lg={6}
                            sm={12}
                            xs={12}
                            container
                            className="alignR"
                          >
                            <StyledCreateBorderLessButton
                              variant="contained"
                              disableRipple
                              startIcon={
                                <MdOutlineAddToPhotos color="#FFAC0B" />
                              }
                              onClick={handleAddBlock}
                            >
                              Add Block
                            </StyledCreateBorderLessButton>
                          </Grid>
                        )}
                      </Grid>

                      <TextField
                        className={"form_field_right form_field_light"}
                        placeholder="Block Name"
                        type="text"
                        onChange={(e) =>
                          handleChange(e.target.value, "block", index)
                        }
                        value={siteData.block[index] || ""} // Ensure a default value for empty blocks
                      //   error={!!validationErrors.block}
                      //   helperText={validationErrors.block}
                      />
                    </Grid>
                  ))}
                </Grid>

                <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                  <InputLabel
                    className={"form_text_light"}
                    sx={{ color: "black", ml: 0, mb: 4 }}
                  >
                    Map Version
                  </InputLabel>
                  <TextField
                    className={"form_field form_field_light"}
                    placeholder="Map Version"
                    onChange={(e) =>
                      handleChange(e.target.value, "map_version")
                    }
                    value={siteData.map_version}
                    //   error={!!validationErrors.map_version}
                    //   helperText={validationErrors.map_version}
                    type="text"
                    inputProps={{ autoComplete: "off" }}
                    id="orgmail"
                    name={`orgmail_${Math.random()}`}
                  />
                </Grid>

                <Grid
                  item
                  xs={5}
                  sm={5}
                  lg={2}
                  md={2}
                  sx={{ mt: 2 }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <InputLabel
                    className={"form_text_light"}
                    sx={{ color: "black", ml: 0 }}
                  >
                    Map Upload
                  </InputLabel>
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={3}
                  lg={3}
                  md={3}
                  sx={{ mt: 2 }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div class="file-input-container">
                    <label for="image-upload" className=" pic_upload">
                      <span style={{ marginRight: "15px" }}></span>
                      Upload Map
                      <span style={{ marginLeft: "15px" }}></span>
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="image-upload"
                      onChange={(event) => {
                        mapSelected(event);
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                </Grid>
                {/* </Grid> */}

                <Grid item xs={12}>
                  <Card>
                    {
                      mapUrl && (
                        <MapContainer
                          maxZoom={21}
                          scrollWheelZoom={false}
                          minZoom={1}
                          dragging={false}
                          bounds={[
                            [-326.5, -35],
                            [90.99, -540.85],
                          ]}
                        >
                          <ImageOverlay
                            crossOrigin="anonymous"
                            url={mapUrl}
                            bounds={[
                              [-326.5, -35],
                              [50.99, -640.85],
                            ]}
                          />
                        </MapContainer>
                      )

                      // <MapContainer zoom={19}
                      //   minZoom={mapDetails.minZoom}
                      //   maxZoom={mapDetails.maxZoom}
                      //   scrollWheelZoom={false}
                      //   bounds={[[-326.5, -35], [90.99, -540.85]]}
                      //   ref={mapRef}
                      //   whenReady={(map) => {
                      //     setMap(map)
                      //     onMapReady(map)
                      //   }}>
                      //   <ImageOverlay crossOrigin="anonymous" url={mapUrl} bounds={[[-326.5, -35], [50.99, -640.85]]} />
                      //   {/* <TileLayer
                      //     tms={true}
                      //     noWrap={true}
                      //     url={mapUrl}
                      //     zoom={19}
                      //     minZoom={mapDetails.minZoom}
                      //     maxZoom={mapDetails.maxZoom}
                      //   /> */}
                      // </MapContainer>
                    }
                  </Card>
                </Grid>

                <Grid item lg={12} md={12}>
                  <StyledCreateSmallButton
                    onClick={handleSiteCreate}
                    sx={{ mt: 5, width: 150 }}
                    variant="contained"
                    disableRipple
                  >
                    Create
                  </StyledCreateSmallButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};
