import { API } from './Api';
const device = '/device';
const tag = '/contractor/tag';
//get device list
export const fetchDeviceListData = async (data) => {
  const start = data && data.start !== undefined ? data.start : 0;
  const limit = data && data.limit !== undefined ? data.limit : 15;
  const searchword = data && data.search !== undefined ? data.search : '';
  // const start=data.start;
  // const limit=data.limit;
  const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));

  const site_id = data && data.site_id !== undefined ? data.site_id : '';
  const type = data && data.type !== undefined ? data.type : '';
    const sort = data && data.sort !== undefined ? data.sort : '';

  //  const searchword= data && data.searchword !== undefined ? data.searchword : '';
  const response = await API.get(`${device}?start=${start}&limit=${limit}&site_id=${site_id}&type=${type}&search=${searchword}&org_id=${org_id}&sort=${sort}`);
  return response.data;


}
//get singleDevice
export const fetchSingleDeviceData = async (id) => {
  const response = await API.get(`${device}/${id}`);
  return response.data;


}
export const fetchTagListData = async (data) => {
  const start = data && data.start !== undefined ? data.start : 0;
  const limit = data && data.limit !== undefined ? data.limit : 15;
  const checkout = data && data.checkout !== undefined ? data.checkout : '';
  const transfer = data && data.transfer !== undefined ? data.transfer : '';
  const searchword = data && data.search !== undefined ? data.search : '';
  const is_assign = data && data.is_assign !== undefined ? data.is_assign : 0;
  const org_id = data && data.org_id !== undefined ? data.org_id : parseInt(sessionStorage.getItem('org_Id'));

  const site_id = data && data.site_id !== undefined ? data.site_id : '';
  const response = await API.get(`${tag}?start=${start}&limit=${limit}&site_id=${site_id}&search=${searchword}&checkout=${checkout}&transfer=${transfer}&is_assign=${is_assign}&org_id=${org_id}`);
  return response.data;


}
export const fetchSingleTagData = async (id) => {
  const response = await API.get(`${tag}/${id}`);
  return response.data;
}
//get rssi
export const getRssiLevelDetails = async (data) => {
  const response = await API.get(`/organisation/getrssi/${data}`);
  return response.data;
  

}
//update rssi
export const updateRssiLevelDetails = async (data) => {
  const response = await API.post(`/organisation/updaterssi`,data);
  return response.data;
  

}
/**
 * getDevices 
 *
 * @param
 */
export function getDevices(data) {
  return new Promise((resolve, reject) => {
    API.get("/device?" + new URLSearchParams(Object.assign({}, data))).then(res => {
      resolve(res.data);
    }).catch(err => {
      // Check for error response from the server
      if (err.response) {
        // Client received an error response (4xx, 5xx)
        console.error('Error response:', err.response);
        reject(err.response.data || 'An error occurred while saving persona.');
      } else if (err.request) {
        // Client never received a response or request never left
        console.error('Error request:', err.request);
        reject('No response received from the server.');
      } else {
        // Anything else
        console.error('Error message:', err.message);
        reject('An error occurred: ' + err.message);
      }
    });
  })
}