import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { StyledTab, StyledTabs } from "../Styles";
export const ReportTab = ({ tabValue, handleTabChange, orgType }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={{ width: "100%" }}>
     
        {orgType == 1 && (
             <StyledTabs
             value={tabValue}
             onChange={handleTabChange}
             variant={isMobile ? "scrollable" : "standard"}
             // scrollButtons={isMobile ? 'auto' : 'off'}
             //   textColor="secondary"
             //   indicatorColor="#E28909"z
             //   aria-label="secondary tabs example"
           >
          <StyledTab
            value="r1"
            label="Surveillance"
            //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
            iconPosition="start"
          />
          </StyledTabs>
        )}
        {orgType == 0 && (
             <StyledTabs
             value={tabValue}
             onChange={handleTabChange}
             variant={isMobile ? "scrollable" : "standard"}
             // scrollButtons={isMobile ? 'auto' : 'off'}
             //   textColor="secondary"
             //   indicatorColor="#E28909"z
             //   aria-label="secondary tabs example"
           >
          <StyledTab
            value="r2"
            label="Tracking"
            //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
            iconPosition="start"
          />
          </StyledTabs>
        )}
        {
          orgType == 2 && 
          <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          variant={isMobile ? "scrollable" : "standard"}
          // scrollButtons={isMobile ? 'auto' : 'off'}
          //   textColor="secondary"
          //   indicatorColor="#E28909"z
          //   aria-label="secondary tabs example"
        >
              <StyledTab
                value="r1"
                label="Surveillance"
                //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
                iconPosition="start"
              />

              <StyledTab
                value="r2"
                label="Tracking"
                //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
                iconPosition="start"
              />
      </StyledTabs>
          }
           {
          orgType == "null" && 
          <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          variant={isMobile ? "scrollable" : "standard"}
          // scrollButtons={isMobile ? 'auto' : 'off'}
          //   textColor="secondary"
          //   indicatorColor="#E28909"z
          //   aria-label="secondary tabs example"
        >
              <StyledTab
                value="r1"
                label="Surveillance"
                //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
                iconPosition="start"
              />

              <StyledTab
                value="r2"
                label="Tracking"
                //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
                iconPosition="start"
              />
      </StyledTabs>
          }
    </Box>
  );
};
