const io = require("socket.io-client");

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_SOCKET_URL;

export const socket = io(URL, {
    autoConnect: false,
    transports: ['websocket'],
    upgrade: false,
    reconnectionAttempts: 10,
    reconnectionDelayMax: 10000,
    reconnectionDelay: 5000,
    closeOnBeforeunload: false,
});

export const parseMessage = (message) => {
    let createdMessage = '';
    let createdTitle = '';
    if (message.feature) {
        if (message.feature === 1) {
            switch (message.alertType) {
                case 12:
                    createdMessage = message.alertTypeMsg + ' for Bridge:'
                    createdTitle = message.listenerName;
                    break;
                case 13:
                    createdMessage = message.alertTypeMsg + ' for Listener: ';
                    createdTitle = message.listenerName;
                    break;
                case 8:
                    createdMessage = message.alertTypeMsg
                    createdTitle = 'Located Area : ' + message.listenerName;
                    break;
                case 9:
                    createdMessage = message.alertTypeMsg
                    createdTitle = 'Located Area : ' + message.listenerName;
                    break;
                case 32:
                    createdMessage = message.alertTypeMsg
                    createdTitle = '';
                    break;
                case 6:
                    createdMessage = message.alertTypeMsg + ' for ' + message.infantName
                    createdTitle = '';
                    break;
                default:
                    createdMessage = message.alertTypeMsg + ' for ' + message.infantName
                    createdTitle = ' Nearest listener : ' + message.listenerName;
                    break;
            }
        }
    }
    return {
        createdMessage, createdTitle
    }
}
