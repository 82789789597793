import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { StyledTab, StyledTabs } from "../Styles";
export const SurveillanceTab = ({ tabValue, handleTabChange }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');

    return (
        <Box sx={{ width: '100%' }}>
            <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                variant={isMobile ? 'scrollable' : 'standard'}
            // scrollButtons={isMobile ? 'auto' : 'off'}
            //   textColor="secondary"
            //   indicatorColor="#E28909"z
            //   aria-label="secondary tabs example"
            >  <StyledTab value="s2" label="Sensor"
            //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
            iconPosition="start" />
             <StyledTab value="s3" label="Power"
            //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
            iconPosition="start" />
                <StyledTab value="s1" label="Camera"
                    //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
                    iconPosition="start" />
                   
                     <StyledTab value="s4" label="Map"
                    //    icon={<RiRoadMapLine color={tabValue === 'one' ? '#000000' : '#666666'} />}
                    iconPosition="start" />
            </StyledTabs>
        </Box>
    )
}