import { all, put, takeLatest } from "redux-saga/effects";
import { addRouteError, addRouteSuccess, deleteRouteError, deleteRouteSuccess, fetchRouteError, fetchRouteSuccess, fetchSingleRouteError, fetchSingleRouteSuccess, routeActionType, updateRouteError, updateRouteSuccess } from "../actions/RouteActions";
import { addNewRouteData, editRouteData, fetchRouteListData, removeRouteData, singleRouteData } from "../../services/RouteServices";
//delete route 
function* _deleteRoute(action) {

    try {
        const routeInfo = yield removeRouteData(action.data);
        yield put(deleteRouteSuccess(routeInfo));
    } catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(deleteRouteError( error.response.data));
        }else{
            yield put(deleteRouteError( error));
        }
    }
}
function* deleteRoute() {
    yield takeLatest(routeActionType.DELETE_ROUTE, _deleteRoute)
}
//update route 
function* _editRoute(action) {

    try {
        const routeInfo = yield editRouteData(action.data,action.id);
        yield put(updateRouteSuccess(routeInfo));
    } catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(updateRouteError( error.response.data));
        }else{
            yield put(updateRouteError( error));
        }
       
    }
}
function* editRoute() {
    yield takeLatest(routeActionType.UPDATE_ROUTE, _editRoute)
}
//add route 
function* _addNewRoute(action) {

    try {
        const routeInfo = yield addNewRouteData(action.data);
        yield put(addRouteSuccess(routeInfo));
    } catch (error) {
        if(error.response && error.response.data && error.response.data){
            yield put(addRouteError( error.response.data));
        }else{
            yield put(addRouteError( error));
        }
      //  yield put(addRouteError("error"));
    }
}
function* addNewRoute() {
    yield takeLatest(routeActionType.ADD_ROUTE, _addNewRoute)
}
//fetch single route 
function* _getSingleRoute(action) {

    try {
        const singleRoute = yield singleRouteData(action.id);
        yield put(fetchSingleRouteSuccess(singleRoute));
    } catch (e) {
        yield put(fetchSingleRouteError("error"));
    }
}
function* getSingleRoute() {
    yield takeLatest(routeActionType.FETCH_SINGLE_ROUTE, _getSingleRoute)
}
//fetch route list
function* _getRouteList(action) {

    try {
        const routeListInfo = yield fetchRouteListData(action.data);
        yield put(fetchRouteSuccess(routeListInfo));
    } catch (e) {
        yield put(fetchRouteError("error"));
    }
}
function* getRouteList() {
    yield takeLatest(routeActionType.FETCH_ROUTE, _getRouteList)
}
export function* RouteSaga(){
    yield all([getRouteList(),getSingleRoute(),addNewRoute(),editRoute(),deleteRoute()])
}