export const deviceActionType = {
    FETCH_DEVICE_BRIDGE: "FETCH_DEVICE_BRIDGE",
    FETCH_DEVICE: "FETCH_DEVICE",
    FETCH_DEVICE_SUCCESS: "FETCH_DEVICE_SUCCESS",
    FETCH_DEVICE_ERROR: "FETCH_DEVICE_ERROR",

    FETCH_SINGLE_DEVICE: "FETCH_SINGLE_DEVICE",
    FETCH_SINGLE_DEVICE_SUCCESS: "FETCH_SINGLE_DEVICE_SUCCESS",
    FETCH_SINGLE_DEVICE_ERROR: "FETCH_SINGLE_DEVICE_ERROR",

    FETCH_TAG: "FETCH_TAG",
    FETCH_TAG_SUCCESS: "FETCH_TAG_SUCCESS",
    FETCH_TAG_ERROR: "FETCH_TAG_ERROR",

    FETCH_SINGLE_TAG: "FETCH_SINGLE_TAG",
    FETCH_SINGLE_TAG_SUCCESS: "FETCH_SINGLE_TAG_SUCCESS",
    FETCH_SINGLE_TAG_ERROR: "FETCH_SINGLE_TAG_ERROR",
    
    GET_RSSI : "GET_RSSI",
    GET_RSSI_SUCCESS : "GET_RSSI_SUCCESS",
    GET_RSSI_ERROR : "GET_RSSI_ERROR",

    UPDATE_RSSI : "UPDATE_RSSI",
    UPDATE_RSSI_SUCCESS : "UPDATE_RSSI_SUCCESS",
    UPDATE_RSSI_ERROR : "UPDATE_RSSI_ERROR",
    CLEAR_RSSI_UPDATE_STATUS : "CLEAR_RSSI_UPDATE_STATUS"

}
//fetch listener/bridge list
export const fetchDevice = (id) => {
    return {
        type: deviceActionType.FETCH_DEVICE,
        id: id

    }
}
export const fetchBridgeDevice = (id) => {
    return {
        type: deviceActionType.FETCH_DEVICE_BRIDGE,
        id: id

    }
}
export const fetchDeviceSuccess = (data) => {
    return {
        type: deviceActionType.FETCH_DEVICE_SUCCESS,
        payload: data
    }
}
export const fetchDeviceError = (error) => {
    return {
        type: deviceActionType.FETCH_DEVICE_ERROR,
        error
    }
}
//fetch single listener/bridge 
export const fetchSingleDevice = (id) => {


    return {
        type: deviceActionType.FETCH_SINGLE_DEVICE,
        id: id

    }
}
export const fetchSingleDeviceSuccess = (data) => {
    return {
        type: deviceActionType.FETCH_SINGLE_DEVICE_SUCCESS,
        payload: data
    }
}
export const fetchSingleDeviceError = (error) => {
    return {
        type: deviceActionType.FETCH_SINGLE_DEVICE_ERROR,
        error
    }
}
//fetch tag list 
export const fetchTag = (id) => {


    return {
        type: deviceActionType.FETCH_TAG,
        id: id

    }
}
export const fetchTagSuccess = (data) => {
    return {
        type: deviceActionType.FETCH_TAG_SUCCESS,
        payload: data
    }
}
export const fetchTagError = (error) => {
    return {
        type: deviceActionType.FETCH_TAG_ERROR,
        error
    }
}
//fetch tag 
export const fetchSingleTag = (id) => {


    return {
        type: deviceActionType.FETCH_SINGLE_TAG,
        id: id

    }
}
export const fetchSingleTagSuccess = (data) => {
    return {
        type: deviceActionType.FETCH_SINGLE_TAG_SUCCESS,
        payload: data
    }
}
export const fetchSingleTagError = (error) => {
    return {
        type: deviceActionType.FETCH_SINGLE_TAG_ERROR,
        error
    }
}

//get rssi 
export const getRssiLevel = (data) => {


    return {
        type: deviceActionType.GET_RSSI,
        data: data

    }
}
export const getRssiLevelSuccess = (data) => {
    return {
        type: deviceActionType.GET_RSSI_SUCCESS,
        payload: data
    }
}
export const getRssiLevelError = (error) => {
    return {
        type: deviceActionType.GET_RSSI_ERROR,
        error
    }
}


//update rssi 
export const updateRssiLevel = (data) => {


    return {
        type: deviceActionType.UPDATE_RSSI,
        data: data

    }
}
export const updateRssiLevelSuccess = (data) => {
    return {
        type: deviceActionType.UPDATE_RSSI_SUCCESS,
        payload: data
    }
}
export const updateRssiLevelError = (error) => {
    return {
        type: deviceActionType.UPDATE_RSSI_ERROR,
        error
    }
}
export const clearRssiLevelUpdatestatus = () => {
    return {
        type: deviceActionType.CLEAR_RSSI_UPDATE_STATUS,
        
    }
}