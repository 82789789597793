import { Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DatePick } from '../datePicker/DatePicker';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

export const SurveillanceHeader = ({ activeSubtab, handleEndDateChange, handleStartDateChange,
    selectedEndDate, selectedStartDate, isCreate, isSurveillacePrev, activeMenuItem }) => {

    const navigate = useNavigate();
    const handleBack = () => {
        //  sessionStorage.setItem('trackingTab',activeSubtab)
        navigate(-1);
    }
    return (
        <>
            {
                isCreate === true ?
                    isSurveillacePrev === true ? <>
                        <Grid item lg={5} sm={12} xs={12} md={5} className="" sx={{ textAlign: 'center' }}>
                            <Grid container>
                                <Grid item>
                                    <IconButton sx={{ mt: 0, }}
                                        onClick={handleBack}
                                    >
                                        <ArrowBackIosNewOutlinedIcon sx={{ color: "#000000" }} fontSize="medium" />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography className={"headerhead headerhead_light"}>{activeMenuItem == "videosurveillance" ? "Surveillance" : "Dashboard"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={1.5} sm={12} xs={12} md={1.5} className="mt2" >
                            <DatePick
                                handleDateChange={handleStartDateChange}
                                //  isDarkMode={isDarkMode}
                                selectedDate={selectedStartDate}
                            />
                        </Grid>
                        <Grid item lg={.5} md={.5} sm={.5} xs={.5} className="to_arrow">
                            <IconButton>
                                <SyncAltRoundedIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Grid>
                        <Grid item lg={1.5} sm={12} xs={12} md={1.5} className="mlback" sx={{ mr: 3 }}>
                            <DatePick
                                handleDateChange={handleEndDateChange}
                                //isDarkMode={isDarkMode}
                                selectedDate={selectedEndDate} />
                        </Grid>
                    </> :
                        <>
                            <Grid item lg={9.5} sm={12} xs={12} md={9.5} className="" sx={{ textAlign: 'center' }}>
                                <Grid container>
                                    <Grid item>
                                        <IconButton sx={{ mt: 0, }}
                                            onClick={handleBack}
                                        >
                                            <ArrowBackIosNewOutlinedIcon sx={{ color: "#000000" }} fontSize="medium" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={"headerhead headerhead_light"}>  {activeMenuItem == "videosurveillance" ? "Surveillance" : "Dashboard"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    :


                    <>
                        <Grid item
                            lg={5} sm={12} xs={12} md={5}
                            className="ml" sx={{ textAlign: 'center' }}>
                            <Typography
                                sx={{ textAlign: 'center', mt: 1 }} className={"headerhead headerhead_light"}>
                                {activeMenuItem == "videosurveillance" ? "Surveillance" : "Dashboard"}
                            </Typography>
                        </Grid>
                        <Grid item lg={1.5} sm={12} xs={12} md={1.5} className="mt2" >
                            <DatePick
                                handleDateChange={handleStartDateChange}
                                //  isDarkMode={isDarkMode}
                                selectedDate={selectedStartDate}
                            />
                        </Grid>
                        <Grid item lg={.5} md={.5} sm={.5} xs={.5} className="to_arrow">
                            <IconButton>
                                <SyncAltRoundedIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Grid>
                        <Grid item lg={1.5} sm={12} xs={12} md={1.5} className="mlback" sx={{ mr: 3 }}>
                            <DatePick
                                handleDateChange={handleEndDateChange}
                                //isDarkMode={isDarkMode}
                                selectedDate={selectedEndDate} />
                        </Grid>
                    </>
            }
            {

            }
        </>
    )
} 