import { Card } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";

import { TileLayer, MapContainer } from "react-leaflet";
export const ViewWisilicaMap = ({ map }) => {
    const [mapDetails, setMap] = useState(null);
    const [mapBounds, setMapBounds] = useState(null);
    const [mapUrl, setMapUrl] = useState();
    const mapRef = useRef(null)

    useEffect(() => {
        if (map) {
            const array0 = map.mapBoundaries[0].replace(/\(|\)/g, '').split(','); // splitting the map boundary coordinates
            const array2 = map.mapBoundaries[2].replace(/\(|\)/g, '').split(','); // splitting the map boundary coordinates
            setMapUrl(`${decodeURIComponent(map.filePath).replace('map.php', 'img.php')}&zxy=/{z}/{x}/{y}.png`);
            const mapBounds = L.latLngBounds(L.latLng(Number(array0[1]), Number(array0[0])), L.latLng(Number(array2[1]), Number(array2[0])));
            setMapBounds(mapBounds);
            setMap(map);
        }
    }, [map])
    const onMapReady = (map) => {
        map.target.setMaxBounds(map.target.getBounds())
        const dimesion = { 'height': mapDetails?.imgHeightMeters, 'width': mapDetails?.imgWidthMeters, 'mapName': mapDetails?.mapName };
        map.target.addControl(new customLegends(dimesion, { position: 'topright' }));
    }

    const customLegends = L.Control.extend({
        container: null,
        maxZoom: undefined,
        maxScalingFactor: undefined,
        initialize: function (dimesion, options) {
            L.Util.setOptions(this, options);
            this.height = Math.round(dimesion.height);
            this.width = Math.round(dimesion.width);
            this.mapName = dimesion.mapName;
        },
        onAdd: function () {
            const div = L.DomUtil.create('div', 'leaflet-trak-custom-legend leaflet-bar');
            div.innerHTML = `<div></div><p>Map Name: ${this.mapName} </p><p style='float:left;'> Height: ${this.height} meters</p><br><p style='float:left;'>Width: ${this.width} meters</p></div>`;
            return div;
        }
    })

    return (
        <Card>
            {mapUrl && mapDetails &&
                <MapContainer zoom={19}
                    minZoom={mapDetails.minZoom}
                    maxZoom={mapDetails.maxZoom}
                    scrollWheelZoom={false}
                    bounds={mapBounds}
                    ref={mapRef}
                    dragging={false}
                    whenReady={(map) => {
                        setMap(map)
                        onMapReady(map)
                    }}
                >
                    <TileLayer
                        tms={true}
                        noWrap={true}
                        url={mapUrl}
                        zoom={19}
                        minZoom={mapDetails.minZoom}
                        maxZoom={mapDetails.maxZoom}
                    />
                </MapContainer>
            }
        </Card>
    )
}
