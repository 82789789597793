import { Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import './Dashboard.css'
import { MenuProps } from "../Styles";
import { Barchart } from "../sharable/charts/Barchart";
import IconButton from '@mui/material/IconButton';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { useDispatch } from "react-redux";
import { fetchAlertTrend } from "../../redux/actions/AlertActions";
import { useSelector } from "react-redux";

export const DashboardBarchart = ({ isDarkMode, removeWidget, id,handleAlertDutyType,alertDutyType }) => {
  // const [alertDutyType, setAlertDutyType] = useState("");
  const [alertData, setAlertData] = useState([]);
  const [perFeature, setPerFeature] = useState([]);
  const [orgId,setOrgId] = useState("")
  const alertTreandData = useSelector(state => state.alert.alertTrend);
  const dispatch = useDispatch();
  useEffect(() => {
    // if(aler)
    let data = { duty_type: alertDutyType }
  //  dispatch(fetchAlertTrend(data))
    let orgId = sessionStorage.getItem('org_Id');
    setOrgId(orgId);
    const feature = sessionStorage.getItem('per_feature');
    if (feature) {
        setPerFeature(JSON.parse(feature)); // Assuming per_feature is stored as JSON in sessionStorage
    }
  }, [])
  useEffect(() => {
    if(alertDutyType != -1){
    let data = { duty_type: alertDutyType }
    dispatch(fetchAlertTrend(data))
}
  }, [alertDutyType])
  useEffect(() => {
    if (!alertTreandData.isFetching && alertTreandData.payload && alertTreandData.payload.success === true) {
      const temp = alertTreandData.payload.data;
      setAlertData(temp);
    }
  }, [alertTreandData])
const clickOnChart =()=>{
  
  if(alertDutyType == 1)
  {
    sessionStorage.setItem('alert_stage','sentry')

  }
  if(alertDutyType == 0){
    sessionStorage.setItem('alert_stage','device')

  }
  if(alertDutyType == 2){
    sessionStorage.setItem('alert_stage','patrol')

  }
  if(alertDutyType == 4){
    sessionStorage.setItem('alert_stage','camera')

  }
  if(alertDutyType == 5){
    sessionStorage.setItem('alert_stage','sensor')

  }
 window.location.href='/alert';

}
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} lg={4} md={4}>
          <Typography className={isDarkMode ? "chartheading chartheading_color" : "chartheading_color_light chartheading"}>Alert Trend</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Select
            className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
            sx={{
              color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 110,
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
                border: '',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '.MuiSvgIcon-root ': {
                fill: isDarkMode ? "white !important" : "#161616",
              }
            }}
            onChange={(e) => handleAlertDutyType(e.target.value)}
            value={alertDutyType}
            displayEmpty
            MenuProps={MenuProps}
          >
            {/* <MenuItem value={1} >Sentry Violations</MenuItem>
            <MenuItem value={2} >Patrol Violations</MenuItem>
            <MenuItem value={0} >Device Violations</MenuItem>
            <MenuItem value={4} >Camera Violations</MenuItem>
            <MenuItem value={5} >Sensor Violations</MenuItem> */}
            {perFeature.includes(5) || orgId == 0 ? ( <MenuItem value={2}>Patrol Violations</MenuItem>) : null}
                            {perFeature.includes(5) || orgId == 0 ? ( <MenuItem value={1}>Sentry Violations</MenuItem>) : null}
                            {perFeature.includes(5) || orgId == 0 ? ( <MenuItem value={0}>Device Violations</MenuItem>) : null}
                            {perFeature.includes(7) || orgId == 0 ? ( <MenuItem value={4}>VA Violations</MenuItem>) : null}
                            {perFeature.includes(7) || orgId == 0 ? ( <MenuItem value={5}>Sensor Violations</MenuItem>) : null}
                            {!perFeature.includes(5) || !orgId == 0 && !perFeature.includes(7) && <MenuItem value={''}>No Violation allowed</MenuItem> }


          </Select>
        </Grid>
        <Grid item xs={2} sm={2} lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
            <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
          </IconButton>
        </Grid>
      </Grid>
      {alertData && alertData?.length ?
        <Barchart
          data={alertData}
          clickOnChart={clickOnChart}
        />
        :
        <>No alert  found to show.</>
      }
    </>
  )
}
