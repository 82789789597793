import { Grid, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
export const CreatorDetails = ({userInfo,createdDate}) => {
    return (
        <div className='device_view pdb0'>
            <Grid container >
            <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr'  >
                        <Typography className='device_view_text label_color rowdiv_label'>Created By</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {userInfo.creatorData && Object.keys(userInfo.creatorData).length !== 0? userInfo?.creatorData?.username : "No data available"}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' >
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Role
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                        {userInfo.creatorData && Object.keys(userInfo.creatorData).length !== 0? userInfo?.creatorData?.user_role : "No data available"}

                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr'  >
                        <Typography className='device_view_text label_color rowdiv_label'>Created Date & Time</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                        { createdDate?.date } {' '} {createdDate?.time}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' >
                        <Typography className='device_view_text label_color rowdiv_label'>
                        Phone No
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                        {userInfo.creatorData && Object.keys(userInfo.creatorData).length !== 0 ? userInfo?.creatorData?.phone_number : "No data available"}

                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv  rowdiv_mr'  >
                        <Typography className='device_view_text label_color rowdiv_label'>Mail Id</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                        {userInfo.creatorData && Object.keys(userInfo.creatorData).length !== 0? userInfo?.creatorData?.email : "No data available" }
                        </Typography>
                    </div>
                </Grid>
                {/* <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' >
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Gender
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                            {userInfo.gender}

                        </Typography>
                    </div>
                </Grid> */}
            </Grid>
        </div>
    )
}
CreatorDetails.propTypes = {
    userInfo: PropTypes.shape({
        creatorData: PropTypes.shape({
            username: PropTypes.string,
            user_role: PropTypes.string,
            phone_number: PropTypes.string,
            email: PropTypes.string,
        })
    }).isRequired,
    createdDate: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string
    }).isRequired
};