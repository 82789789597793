import React from 'react'
import { CustomizedTableCell, CustomizedTableRow } from '../styles/TablebButtonStyles'
import { IconButton, Table, TableBody, TableHead, TableRow } from '@mui/material';
import '../../components/sharable/tables/Tables.css';
import { MdOutlineEdit } from "react-icons/md";

export const RoleTables = ({isDarkMode,row,handleEdit}) => {
    return (
        <div className='table_Scroll ml2back'>
            <Table className="table_mob" sx={{ m: 2, width: "100%" }} >
                <TableHead>
                    <TableRow>
                        <CustomizedTableCell>Role</CustomizedTableCell>
                        <CustomizedTableCell>Feature</CustomizedTableCell>
                        <CustomizedTableCell>Actions</CustomizedTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {row.map((item,index)=>(
                    <CustomizedTableRow key={index}>
                        <CustomizedTableCell className='rol_div' >{item.role}</CustomizedTableCell>
                        <CustomizedTableCell >
                                <div className="twocolStyle" style={{width:"auto !important"}}>
                                    {item.features
                                        .map((obj, index) =>
                                            <span className="table_text_div" key={index}>{obj}{'  '}
                                                {/* {index == 1  && index < item.chechpointtype.length - 1 && <br />} */}
                                            </span >
                                        )}
                                </div>
                            </CustomizedTableCell>
                            <CustomizedTableCell isDarkMode={isDarkMode}>
                                       
                                        <IconButton onClick={() => handleEdit(item)}>
                                            <MdOutlineEdit color="black" />
                                        </IconButton>
                                        </CustomizedTableCell>
                    </CustomizedTableRow>
))}
                </TableBody>
            </Table>
        </div>
    )
}