import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import {
  Alert,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { StyledCreateSmallButton } from "../../styles/ButtonStyles";
import { useDispatch } from "react-redux";
import {
  addEncoder,
  clearaddEncoderStatus,
  fetchBatteryDropdown,
  fetchCameraDropdown,
  fetchSensorDropdown,
} from "../../../redux/actions/SurveillanceAction";
import { useSelector } from "react-redux";
export const AddEncoder = ({}) => {
  const dispatch = useDispatch();
  const cameraList = useSelector((state) => state.surveillance.cameraDropdown);
  const sensorList = useSelector((state) => state.surveillance.sensorDropdown);
  const addEncoderStatus = useSelector(
    (state) => state.surveillance.addEncoder
  );

  const batteryList = useSelector(
    (state) => state.surveillance.batteryDropdown
  );
  const [showAlert, setShowAlert] = useState(false);

  const [activeMenuItem, setActiveMenuItem] = useState("videosurveillance");
  const [cameraDrop, setCameraDrop] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(0);
  const [sensorDrop, setSensorDrop] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(0);
  const [alertMessage, setAlertMessage] = useState('')
  const [batteryDrop, setBatteryDrop] = useState([]);
  const [selectedBattery, setSelectedBattery] = useState(0);
  const [encoderData, setEncoderData] = useState({
    title: "",
    machine_id: "",
    sensor_id: 0,
    battery_id: 0,
    gateway_id: 0,
  });
  const [validationErrors, setValidationErrors] = useState({
    title: "",
    machine_id: "",
    sensor_id: "",
    gateway_id: "",
  });
  useEffect(() => {
    let data = {
      is_assigned: 0,
      orgId: parseInt(sessionStorage.getItem("org_Id")),
    };

    dispatch(fetchCameraDropdown(data));
    dispatch(fetchSensorDropdown(data));
    dispatch(fetchBatteryDropdown(data));
  }, []);
  useEffect(() => {
    if (
      !cameraList.isFetching &&
      cameraList.payload &&
      cameraList.payload.success === true
    ) {
      const tempList = cameraList.payload.list;
      setCameraDrop(tempList);
    }
    if (
      !sensorList.isFetching &&
      sensorList.payload &&
      sensorList.payload.success === true
    ) {
      const tempsensorList = sensorList.payload.list;
      setSensorDrop(tempsensorList);
    }
    if (
      !batteryList.isFetching &&
      batteryList.payload &&
      batteryList.payload.success === true
    ) {
      const tempBatteryList = batteryList.payload.list;
      setBatteryDrop(tempBatteryList);
    }
    if (
      !addEncoderStatus.isFetching &&
      addEncoderStatus.payload &&
      addEncoderStatus.payload.success === true
    ) {
      sessionStorage.setItem("surveillanceTab", "s1");

      window.location.href = "/surveillance";

      //setBatteryDrop(tempBatteryList);
    }
    if (!addEncoderStatus.isFetching && addEncoderStatus.error) {
      setShowAlert(true);
      const error = addEncoderStatus.errorMessage.message;
      setAlertMessage(error);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      dispatch(clearaddEncoderStatus());
      return () => clearTimeout(timeoutId);
    }
  }, [cameraList, sensorList, batteryList, addEncoderStatus]);
  useEffect(() => {
    // if (selectedBattery != 0) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     battery_id: "",
    //   }));
    // }
    if (selectedCamera != 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        gateway_id: "",
      }));
    }
    if (selectedSensor != 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        sensor_id: "",
      }));
    }
  }, [ selectedCamera, selectedSensor]);
  const onChange = (value, key) => {
    let temp = { ...encoderData };
    temp[key] = value;
    setEncoderData(temp);
    const strln = value.toString().trim();
    const valueLength = strln.length;
    let error = "";
    switch (key) {
      case "title":
        // case 'license_no':

        error =
          valueLength < 3 || valueLength >= 30
            ? "Field must be between 2 and 30 characters long"
            : "";
        break;
        default:
          break;
    }
 
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  };
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  const handleCreate = (e) => {
    let data = {
      title: encoderData.title,
      machine_id: cameraDrop?.find((obj) => obj.id === selectedCamera)
        ?.machine_id,
      sensor_id: selectedSensor,
      battery_id: selectedBattery,
      gateway_id: selectedCamera,
      created_user: parseInt(sessionStorage.getItem("id")),
      org_id: parseInt(sessionStorage.getItem("org_Id")),
    };
    e.preventDefault();
    const isEmptyField = Object.values(data).some((value) => {
      return (
        (typeof value === "string" && value.trim() === "") ||
        value === undefined ||
        value === null
      );
    });
    const allClear = Object.values(validationErrors).every((value) => {
      return (
        (typeof value === "string" && value.trim() == "") ||
        value === undefined ||
        value === null
      );
    });
    if (isEmptyField) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        title:
          data.title.trim() === ""
            ? "This field is mandatory"
            : prevErrors.title,
        sensor_id: selectedSensor ? "" : "This field is mandatory",
        // battery_id: selectedBattery ? "" : "This field is mandatory",
        gateway_id: selectedCamera ? "" : "This field is mandatory",
      }));
    }

    if (!isEmptyField && allClear) {
      setValidationErrors({
        title: "",
        machine_id: "",
        sensor_id: "",
        // battery_id: "",
        gateway_id: "",
      });
      dispatch(addEncoder(data));
    }
  };
  const handlealertClose = () => {
    setShowAlert(false)
  }
  return (
    <Layout
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"videosurveillance"}
      activeMenuKey={"videosurveillance"}
      isCreate={true}
    >
      <div
        style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%" }}
        className=""
      >
        <Grid container>
          
           <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handlealertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handlealertClose}
          severity="error"
          variant="outlined"
          sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

        >
          {alertMessage}
        </Alert>
      </Snackbar>
{addEncoderStatus.isFetching ?
                <Grid item lg={12} md={12} sm={12} xs={12} container
                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                </Grid>
                :
      <>
  <Grid item lg={12} sm={12} xs={12} md={12} sx={{ ml: 2 }}>
            <Typography
              sx={{ textAlign: "left" }}
              className={"headersubhead light_text"}
            >
              Add Encoder
            </Typography>
          </Grid>
          <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3, ml: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Title
            </InputLabel>
            <TextField
              className={"form_field form_field_light"}
              placeholder="Title"
              value={encoderData.title}
              onChange={(e) => onChange(e.target.value, "title")}
              error={!!validationErrors.title}
              helperText={validationErrors.title}
            />
          </Grid>
          <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light form_text_mob"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Camera Id
            </InputLabel>
            <Select
              className={"form_field_right form_field_light"}
              value={selectedCamera}
              onChange={(e) => setSelectedCamera(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                if (selected == 0) {
                  return <span style={{ color: "#a2a2a2" }}>Camera Id</span>;
                }
                const selectedCamera = cameraDrop.find(
                  (cam) => cam.id === selected
                );
                return selectedCamera ? selectedCamera.machine_id : "";
              }}
            >
              {cameraDrop.length > 0 ? (
                cameraDrop.map((camera, index) => (
                  <MenuItem key={index} value={camera.id}>
                    {camera.machine_id}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Camera Found</MenuItem>
              )}
            </Select>
            {validationErrors?.gateway_id && (
              <Typography
                sx={{ color: "#d32f2f", ml: 2, mt: 1 }}
                className={" errormargin errorStyle"}
              >
                {validationErrors?.gateway_id}
              </Typography>
            )}
          </Grid>
          <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3, ml: 3 }}>
            <InputLabel
              className={"form_text_light"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Sensor Name
            </InputLabel>
            <Select
              className={"form_field form_field_light noml"}
              value={selectedSensor}
              onChange={(e) => setSelectedSensor(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                if (selected == 0) {
                  return <span style={{ color: "#a2a2a2" }}>Sensor Name</span>;
                }
                const selectedSensor = sensorDrop.find(
                  (site) => site.id === selected
                );
                return selectedSensor ? selectedSensor.sensor_name : "";
              }}
            >
              {sensorDrop.length > 0 ? (
                sensorDrop.map((sensor, index) => (
                  <MenuItem key={index} value={sensor.id}>
                    {sensor.sensor_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Data Found</MenuItem>
              )}
            </Select>
            {validationErrors?.sensor_id && (
              <Typography
                sx={{ color: "#d32f2f", ml: 2, mt: 1 }}
                className={" errormargin errorStyle"}
              >
                {validationErrors?.sensor_id}
              </Typography>
            )}
          </Grid>
          <Grid item xs={10} sm={10} lg={5.5} md={5.5} sx={{ mt: 3 }}>
            <InputLabel
              className={"form_text_light form_text_mob"}
              sx={{ color: "black", ml: 0, mb: 2 }}
            >
              Battery Name
            </InputLabel>
            <Select
              className={"form_field_right form_field_light"}
              value={selectedBattery}
              onChange={(e) => setSelectedBattery(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                if (selected == "") {
                  return <span style={{ color: "#a2a2a2" }}>Battery</span>;
                }
                const selectedBattery = batteryDrop.find(
                  (battery) => battery.id === selected
                );
                return selectedBattery ? selectedBattery.battery_name : "";
              }}
            >
              {batteryDrop.length > 0 ? (
                batteryDrop.map((battery, index) => (
                  <MenuItem key={index} value={battery.id}>
                    {battery.battery_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No Data Found</MenuItem>
              )}
            </Select>
            {/* {validationErrors?.battery_id && (
              <Typography
                sx={{ color: "#d32f2f", ml: 2, mt: 1 }}
                className={" errormargin errorStyle"}
              >
                {validationErrors?.battery_id}
              </Typography>
            )} */}
          </Grid>
          <Grid item tem xs={10} sm={10} lg={10} md={10} sx={{ ml: 3 }}>
            <StyledCreateSmallButton
              onClick={handleCreate}
              sx={{ mt: 5, width: 150 }}
              variant="contained"
              disableRipple
            >
              Create
            </StyledCreateSmallButton>
          </Grid>
      </>
     } 
        
        </Grid>
      </div>
    </Layout>
  );
};
