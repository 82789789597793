export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
export const validatePhoneNumber = (phoneNumber) => {
  // var phoneRegex = /^(\+\d{7,12}|\d{7,12})$/;
   var phoneRegex = /^[\d -]{7,20}$/;
 // var phoneRegex = /^(\+\d{1,3}(\s)?\d{7,20}|[\d -]{7,20})$/;
  return phoneRegex.test(phoneNumber);
}
export const validatePassword = (password) => {
  // if (password.length < 6 || password.length > 15) {
  //   return {
  //     code: false,
  //     msg: "Password must be between 6 & 15characters long"
  //   };
  // }

  // if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
  //   return {
  //     code: false,
  //     msg: "Password must contain both uppercase and lowercase letters"
  //   };
  // }

  // if (!/\d/.test(password)) {
  //   return {
  //     code: false,
  //     msg: "Password must contain at least one number"
  //   };
  // }

  // if (!/[^a-zA-Z0-9]/.test(password)) {
  //   return {
  //     code: false,
  //     msg: "Password must contain at least one special character"
  //   };
  // }
if(password.length < 6 || password.length > 15 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/\d/.test(password)
|| !/[^a-zA-Z0-9]/.test(password) )
{
  return {
        code: false,
        msg: "Password must be between 6 & 15characters long,contain at least one special character,both uppercase and lowercase letters,at least one number, at least one special character."
      };
}
  return {
    code: true,
    msg: "Password is valid"
  };
}