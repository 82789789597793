import { Grid, Typography } from '@mui/material';
import React from 'react';
import { BorderButton, RedBorderButton } from '../../styles/ButtonStyles';
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import PropTypes from 'prop-types';

export const UserDetails = ({ handleEdit, userInfo, handleDelete, preview }) => {
    return (
        <div className='device_view pdb0'>
            <Grid container >

                <Grid item sx={{ pb: 7, }} lg={9} md={9} sm={12} xs={12}>
                    <div className='rowdiv  rowdiv_mr'  >
                        {preview && preview !== "" ?
                            <img className="userProfile align_mr" crossOrigin="anonymous"  
                            src={preview} alt="user_pic" /> :
                            <img className="userProfile align_mr" src={require('../../../assets/img/user.png')}

                                alt="user_pic" />
                        }
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={3} md={3} sm={12} xs={12}>
                    <div className='rowdiv  rowdiv_ml' >
                        <RedBorderButton
                            onClick={() => handleDelete(userInfo.id, userInfo.username,userInfo.organisation_id
                                )}
                            startIcon={<RiDeleteBin6Line color="#C61A1A" />}
                            sx={{ mt: 3 }} variant="outlined"
                        >
                            Delete
                        </RedBorderButton>
                        <BorderButton
                            onClick={() => handleEdit(userInfo.id)}
                            startIcon={<MdOutlineEdit color="black" />}
                            sx={{ mt: 3 }} variant="outlined">

                            Edit
                        </BorderButton>
                    </div>
                </Grid>






                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr'  >
                        <Typography className='device_view_text label_color rowdiv_label'>Name</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {userInfo.username}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' >
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Role
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                            {userInfo.role}

                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr'  >
                        <Typography className='device_view_text label_color rowdiv_label'>Mail Id</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {userInfo.email}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' >
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Site
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                            {userInfo.site_name}

                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr'  >
                        <Typography className='device_view_text label_color rowdiv_label'>Phone No</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {userInfo.phone_number}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' >
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Gender
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                            {userInfo.gender}

                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_mr'  >
                        <Typography className='device_view_text label_color rowdiv_label'>Year Of Experience</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {userInfo.year_of_experience}
                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv borderbottom rowdiv_ml' >
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Organization Name
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                            {userInfo.org_name}

                        </Typography>
                    </div>
                </Grid>
                <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv rowdiv_mr' >
                        <Typography className='device_view_text label_color rowdiv_label'>About</Typography>
                        <Typography className='device_view_text content_color rowdiv_text'>
                            {userInfo.description}
                        </Typography>
                    </div>
                </Grid>
                {/* <Grid item sx={{ pb: 7, }} lg={6} md={6} sm={12} xs={12}>
                    <div className='rowdiv  rowdiv_ml' >
                        <Typography className='device_view_text label_color rowdiv_label'>
                            Role
                        </Typography>
                        <Typography className='device_view_text content_color rowdiv_text '>
                        {userInfo.role_id}

                        </Typography>
                    </div>
                </Grid> */}
            </Grid>
        </div>
    )
}
UserDetails.propTypes = {
    handleEdit: PropTypes.func.isRequired, // handleEdit should be a function and is required
    userInfo: PropTypes.object.isRequired, // userInfo should be an object and is required
    handleDelete: PropTypes.func.isRequired, // handleDelete should be a function and is required
    preview: PropTypes.string // preview should be a string, this is optional
};
