import { Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { LightBorderBlackButton } from "../../styles/ButtonStyles";
import { BatteryPerformance } from "./BatteryPerformance";
import { SensorAlertReports } from "./SensorAlertReports";
import { SurveillancePerformance } from "./SurveillancePerformance";
import { VaAlerts } from "./VaAlerts";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { TbFileExport } from "react-icons/tb";

export const SurveillanceReport = () => {
  const survillenceRef = useRef();
  const VaRef = useRef();
  const batteryRef = useRef();
  const sensorRef = useRef();
  const surveillanceButtonRef = useRef();
  const vaButtonRef = useRef();
  const batteryButtonRef = useRef();
  const sensorButtonRef = useRef();
  const [isExporting, setIsExporting] = useState({
    surveillance: false,
    va: false,
    batteryperformance: false,
    sensor: false,
  });
  const handlePdfExport = (key) => {

    if (key == "surveillanceReport") {
      const input = survillenceRef.current;
      exportFunction(input, key, surveillanceButtonRef);
    }
    else if (key == "vaReport") {
      const input = VaRef.current;
      exportFunction(input, key, vaButtonRef);
    }
    else if (key == "batteryperformanceReport") {
      const input = batteryRef.current;
      exportFunction(input, key, batteryButtonRef);
    }
    else {
      const input = sensorRef.current;
      exportFunction(input, key, sensorButtonRef);
    }


  };
  const exportFunction = (input, key, buttonRef) => {
    setIsExporting(true);
   // buttonRef.current.style.visibility = 'hidden';
    html2canvas(input, { scale: 2, useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgProps = pdf.getImageProperties(imgData);
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${key}.pdf`);
      setIsExporting(false);
    //  buttonRef.current.style.visibility = 'visible';
    });







    // setIsExporting(true);
    // buttonRef.current.style.visibility = 'hidden';   
    // const scrollWidth = input.scrollWidth;

    // // Create a canvas element with the width equal to the scroll width
    // const canvas = document.createElement('canvas');
    // canvas.width = scrollWidth * 2; // Adjust scale as needed
    // canvas.height = input.clientHeight; // Keep the canvas height equal to the visible height of the div

    // const context = canvas.getContext('2d');

    // // Save the current scroll position
    // const scrollX = input.scrollLeft;
    // const scrollY = input.scrollTop;


    // // Adjust the scroll position to the beginning for capturing the entire content along the x-axis
    // input.scrollLeft = 0;
    // input.scrollTop = 0;

    // html2canvas(input, { 
    //     canvas: canvas, 
    //     scrollX: 0, // Reset to the beginning along the x-axis
    //     scrollY: 0, // Reset to the beginning along the y-axis
    //     width: scrollWidth * 2, // Adjusted width
    //     height: input.clientHeight * 2, // Adjusted height
    //     useCORS: true
    // }).then(canvas => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('l', 'mm', 'a4'); // Landscape orientation

    //     const imgWidth = pdf.internal.pageSize.getWidth(); // A4 width in mm
    //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

    //     pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

    //     pdf.save(`${key}.pdf`);
    //     setIsExporting(false);
    //     buttonRef.current.style.visibility = 'visible';
    // });

    // // Restore the scroll position after capturing the content
    // input.scrollLeft = scrollX;
    // input.scrollTop = scrollY;
  }
  return (
    <Grid container sx={{ mt: 3 }}>
      {/* <Grid item lg={12} sm={12} xs={12} md={12} sx={{ mb: 3 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
       <LightBorderBlackButton
                    disableRipple
                    startIcon={<TbFileExport />}
                     onClick={handleExport}
                   // onClick={handleExportMenu}
                >
                    Export
                </LightBorderBlackButton>
       </Grid> */}
      <div ref={survillenceRef} className="trackingchart_div" >
        <Grid item lg={12} sm={12} xs={12} md={12} container>

          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              Surveillance Performance
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3,mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
       <LightBorderBlackButton
       ref={surveillanceButtonRef}
                           disableRipple
                    startIcon={<TbFileExport />}
                  //   onClick={()=>handleExport("surveillanceReport")}
                   // onClick={handleExportMenu}
                >
                    Export
                </LightBorderBlackButton>
       </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className=" report_max">
            <SurveillancePerformance
              handlePdfExport={handlePdfExport}
            />
          </div>
        </Grid>
      </div>
      <div ref={VaRef} className="trackingchart_div" >

        <Grid item lg={12} sm={12} xs={12} md={12} container>
          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              VA Safety Alerts
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3,mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
       <LightBorderBlackButton
       ref={vaButtonRef}
                    disableRipple
                    startIcon={<TbFileExport />}
                     onClick={()=>handleExport("vaReport")}
                   // onClick={handleExportMenu}
                >
                    Export
                </LightBorderBlackButton>
       </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className="report_max ">
            <VaAlerts
              handlePdfExport={handlePdfExport}

            />
          </div>
        </Grid>
      </div>
      <div ref={batteryRef} className="trackingchart_div" >

        <Grid item lg={12} sm={12} xs={12} md={12} container>
          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              Battery Performance
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3,mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
       <LightBorderBlackButton
       ref={batteryButtonRef}
                    disableRipple
                    startIcon={<TbFileExport />}
                     onClick={()=>handleExport("batteryperformanceReport")}
                   // onClick={handleExportMenu}
                >
                    Export
                </LightBorderBlackButton>
       </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className="report_max ">
            <BatteryPerformance
              handlePdfExport={handlePdfExport}

            />
          </div>
        </Grid>
      </div>
      <div ref={sensorRef} className="trackingchart_div" >

        <Grid item lg={12} sm={12} xs={12} md={12} container>
          <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3, mt: 5 }}>
            <Typography className="device_view_title content_color">
              Sensor Alert
            </Typography>
          </Grid>
          {/* <Grid item lg={6} sm={12} xs={12} md={6} sx={{ mb: 3,mt: 5 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
       <LightBorderBlackButton
       ref={sensorButtonRef}
                    disableRipple
                    startIcon={<TbFileExport />}
                     onClick={()=>handleExport("sensorReport")}
                   // onClick={handleExportMenu}
                >
                    Export
                </LightBorderBlackButton>
       </Grid> */}
        </Grid>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <div className="report_max ">
            <SensorAlertReports
              handlePdfExport={handlePdfExport}

            />
          </div>
        </Grid>
      </div>

    </Grid>
  );
};
