import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Alert, CircularProgress, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { useSelector } from "react-redux";
import { StyledCreateSmallButton } from "../../styles/ButtonStyles";
import { addOrgTelegram, fetchSingleOrgTelegram } from "../../../redux/actions/OrganisationActions";
import { useParams } from "react-router-dom";
export const OrgTelegramBotEdit = () => {
    const dispatch = useDispatch();
    const { id} = useParams();

    const siteData = useSelector(state => state.userRole.siteData);
    const addOrgTelegramStatus = useSelector(
        (state) => state.organisation.addTelegram
    );
    const singleOrgTelegramStatus = useSelector(
        (state) => state.organisation.singleTeligram
    );
    const [activeMenuItem, setActiveMenuItem] = useState("organisation");
    const [sites, setSites] = useState([]);
    const [selectedSiteId, setSelectedSiteId] = useState("");
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [telegramData, setTelegramData] = useState({
        chat_id: "",
        bot_token: "",
    })
    const [validationErrors, setValidationErrors] = useState({
        chat_id: "",
        bot_token: "",
        selectedSiteId: ""
    })
    useEffect(() => {
        const org_id = sessionStorage.getItem("orgsanisation_org_id");
        const is_wisilica = sessionStorage.getItem("orgsanisation_is_wisilica")
        if (org_id != null) {
            let data = {
                org_id: org_id,
                is_wisilica: is_wisilica
            }
            // dispatch(fetchUserList(data))
            dispatch(fetchSites(data))
        }
        if(id != null){
            dispatch(fetchSingleOrgTelegram(id));
        }
       
    }, [id])

    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            });
            setSites(temp)
        }
        if (!addOrgTelegramStatus.isFetching && addOrgTelegramStatus.payload && addOrgTelegramStatus.payload.success === true) {
            sessionStorage.setItem('organisationTab', 'org_telegram')
            window.location.href = '/organisation/view';
        }
        if (!addOrgTelegramStatus.isFetching && addOrgTelegramStatus.error) {
            const alert = addOrgTelegramStatus.errorMessage.message;
            setAlertMessage(alert);
            setAlertSuccess(false);
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
        if (!singleOrgTelegramStatus.isFetching && singleOrgTelegramStatus.payload && singleOrgTelegramStatus.payload.success === true) {
            const singleTelegram = singleOrgTelegramStatus.payload.data;
           let temp = {...telegramData};
           temp["bot_token"] = singleTelegram.bot_token;
           temp["chat_id"] = singleTelegram.chat_id;
           setSelectedSiteId(singleTelegram.id)
            setTelegramData(temp);
        }
    }, [siteData, addOrgTelegramStatus,singleOrgTelegramStatus]);
    
    const handleChange = (value, key) => {
        let temp = { ...telegramData };
        temp[key] = value;
        setTelegramData(temp);
        const valueLength = value.trim().length;
        let error = "";
        switch (key) {
            case "chat_id":
            case "bot_token":
                error =
                    valueLength < 2 || valueLength >= 50
                        ? "Field must be between 2 and 50 characters long"
                        : "";
                break;
            default:
                break;
        }

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: error,
        }));
    }
    const handleSites = (data) => {
        setSelectedSiteId(data);
        if (data !== '') {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                selectedSiteId: '',
            }));
        }
    }
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    };
    const handleSubmit = (e) => {
        let tempData = {
            chat_id: telegramData.chat_id,
            bot_token: telegramData.bot_token,
            site_id: selectedSiteId,
        }
        let data = {
            chat_id: telegramData.chat_id,
            bot_token: telegramData.bot_token,
            site_id: parseInt(selectedSiteId),
            created_user: parseInt(sessionStorage.getItem("id")),
            org_id: parseInt(sessionStorage.getItem("orgsanisation_org_id"))
        }
        e.preventDefault();
        // const isEmptySiteId = selectedSiteId == 
        const isEmptyField = Object.values(tempData).some((value) => {
            return (
                (typeof value === "string" && value === "") ||
                value === undefined ||
                value === null ||
                value === "" ||
                value === NaN
            );
        });
        const allClear = Object.values(validationErrors).every((value) => {
            return (
                (typeof value === "string" && value.trim() == "") ||
                value === undefined ||
                value === null
            );
        });
        if (isEmptyField) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                chat_id:
                    telegramData.chat_id.trim() === ""
                        ? "This field is mandatory"
                        : prevErrors.chat_id,
                bot_token:
                    telegramData.bot_token.trim() === ""
                        ? "This field is mandatory"
                        : prevErrors.bot_token,
                selectedSiteId: selectedSiteId === ""
                    ? "This field is mandatory"
                    : prevErrors.selectedSiteId,
            }))
        }
        if (!isEmptyField && allClear) {
            setValidationErrors({
                chat_id: "",
                bot_token: "",
                selectedSiteId: ""

            });
        dispatch(addOrgTelegram(data));
        }
    }
   
    const handlealertClose = () => {
        setShowAlert(false);
      }
    return (
        <Layout
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"organisation"}
            activeMenuKey={"Organisation"}
            isCreate={true}
        >
           
            <div style={{ marginRight: "2%", marginTop: "3%" }} className="">
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    {alertMessage}
                </Alert>
            </Snackbar>
                {addOrgTelegramStatus.isFetching ?
                    <Grid item lg={12} md={12} sm={12} xs={12} container
                        sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Grid>
                    :
                    <Grid
                        container
                        spacing={2}
                        sx={{ mt: 3, pb: 5 }}
                        className={"table_light ml"}
                    >

                        <Grid item lg={12}>
                            <Typography className="device_view_head">
                                { "Edit"} Telegram Bot
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={"form_text_light"}
                                sx={{ color: "black", ml: 0, mb: 2 }}
                            >
                                Site Name
                            </InputLabel>

                            <Select
                                className={"form_field form_field_light noml"}
                                // value={organasitaionData.type}
                                //  onChange={(e) => handleChange(e.target.value, "type")}
                                //  disabled={update}
                                error={!!validationErrors.selectedSiteId}
                                value={selectedSiteId}
                                onChange={(e) => handleSites(e.target.value)}
                            >
                                {/* <MenuItem value={''}>All</MenuItem> */}
                                {sites !== 'undefined' && sites.length ?
                                    sites.map((site, index) => (



                                        <MenuItem key={index} value={site.organizationId}>
                                            {site.organizationName}
                                        </MenuItem>



                                    ))
                                    : <MenuItem>No Sites Found</MenuItem>
                                }
                            </Select>
                            {validationErrors.selectedSiteId && (
                            <Typography
                                sx={{ color: '#d32f2f', ml: 2, mt: 1 }}
                                className={' errormargin errorStyle'
                                }
                            >
                                {validationErrors.selectedSiteId}
                            </Typography>
                        )}
                        </Grid>
                        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={"form_text_light form_text_mob"}
                                sx={{ color: "black", mb: 2 }}
                            >
                                Chat Id
                            </InputLabel>
                            <TextField
                                className={"form_field_right form_field_light"}
                                placeholder="Telegram Chat Id"
                                onChange={(e) => handleChange(e.target.value, "chat_id")}
                                value={telegramData.chat_id}
                                  error={!!validationErrors.chat_id}
                                  helperText={validationErrors.chat_id}
                                type="text"
                            //   autoComplete="off" // Disable autocomplete
                            //   id="org_name"
                            //   name={`org_name${Math.random().toString(36).substr(2, 9)}`}
                            />
                        </Grid>
                        <Grid item xs={10} lg={6} md={6} sx={{ mt: 3 }}>
                            <InputLabel
                                className={"form_text_light form_text_mob"}
                                sx={{ color: "black", mb: 2 }}
                            >
                                Bot Token
                            </InputLabel>
                            <TextField
                                className={"form_field_right form_field_light"}
                                placeholder="Telegram bot token"
                                onChange={(e) => handleChange(e.target.value, "bot_token")}
                                value={telegramData.bot_token}
                                  error={!!validationErrors.bot_token}
                                  helperText={validationErrors.bot_token}
                                type="text"
                            //   autoComplete="off" // Disable autocomplete
                            //   id="org_name"
                            //   name={`org_name${Math.random().toString(36).substr(2, 9)}`}
                            />
                        </Grid>
                        <Grid item lg={12} md={12}>
                            <StyledCreateSmallButton
                                onClick={handleSubmit}
                                sx={{ mt: 5, width: 150 }}
                                variant="contained"
                                disableRipple
                            >
                                { "Update" }
                                </StyledCreateSmallButton>
                        </Grid>
                    </Grid>
                }
            </div>
        </Layout>
    )
}