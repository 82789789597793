import axios from 'axios';
import { API } from './Api';
const baseUrl = process.env.REACT_APP_API_BASE_URL + '/' + process.env.REACT_APP_API_VERSION;

const user ='/user';
const adduser ='/auth/register';
export const fetchUserData = async (id) => {

    const response = await API.get(`${user}/${id}`)
    return response.data;


}
export const addUserData = async (data) => {

    // const response = await API.post(adduser,data)
    // return response.data;
    const response = await axios({
        method: "post",
        url: `${baseUrl}${adduser}`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" ,
            'Authorization':'Bearer ' +sessionStorage.getItem('token')

        },
       
      })
    return response.data;

}
export const fetchUserListData = async (data) => {
    const start = data && data.start !== undefined ? data.start : 0;
    const limit = data && data.limit !== undefined ? data.limit : 15;
  // const site_id = data && data.site_id !== undefined ? data.site_id :'';
   const searchWord=data && data.searchWord !== undefined ? data.searchWord : '';
   const from_date = data && data.from_date !== undefined ? data.from_date : '';
   const to_date = data && data.to_date !== undefined ? data.to_date : '';
   const org_id= data && data.org_id !== undefined ? data.org_id :parseInt(sessionStorage.getItem('org_Id'));
   const parent_id =  data && data.parent_id !== undefined ? data.parent_id : '';
   const site_id = data && data.site_id !== undefined ? data.site_id : parseInt(sessionStorage.getItem('user_site_id'));

    const response = await API.get(`${user}?start=${start}&limit=${limit}&search=${searchWord}&parent_id=${parent_id}&org_id=${org_id}&from_date=${from_date}&to_date=${to_date}&site_id=${site_id}`)
    return response.data;


}
export const deleteUserData = async (body) => {
    const response = await API.put(`${user}`,body)
   // const response = await API.delete(`${user}/${id}`)
    return response.data;


}
export const editUserData = async (id,data) => {

    const response = await axios({
        method: "put",
        url: `${baseUrl}${user}/${id}`,
        data: data,
        headers: { "Content-Type": "multipart/form-data",
            'Authorization':'Bearer ' +sessionStorage.getItem('token')

         },
       
      })
    return response.data;


}
// export const editUserData = async (data) => {
//     // let file =data.file;
//     //  const formData = new FormData();
//     //         formData.append('file', file);
//     //         formData.append('user_id', data.id);
//     //const response = await axios.post(`${user}/profilepic`,data)
//     const response = await axios({
//         method: "post",
//         url: `${user}/profilepic`,
//         data: data,
//         headers: { "Content-Type": "multipart/form-data" },
//       })
//     return response.data;


// }