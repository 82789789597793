import dayjs from 'dayjs';
import 'dayjs/locale/en';
export const utcTimeStampToLocaly =(lastseenat)=>{
    //   const timestamp = person.traceData?.contactendtime * 1000;
   //  if(person.lastseenat !== 0)
   //  const timestamp = person.lastseenat * 1000;
    // let lastseendate = "NA";
    // let lastseentime = "NA";

        const timestamp = lastseenat * 1000;
        const dateObject = new Date(timestamp);

        // Get the date part
        let lastseendate = dateObject.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

        // Get the time part
     let lastseentime = dateObject.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
        return  `${lastseendate} ${lastseentime}`;;
    
}
export const sensorDatefieldConvertor =(utcTimeString)=>{
  // const utcDate = new Date(utcTimeString);
  // const localDateTimeString = utcDate.toLocaleString();
//   const utcDate = new Date(utcTimeString);
//   if (isNaN(utcDate.getTime())) {
//     throw new Error('Invalid date format');
//   }
//   // Get local date and time in MM/DD/YYYY hh:mm:ss format
//   const dateFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
// const timeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };

// // const localDateString = utcDate.toLocaleDateString(undefined, dateFormatOptions);
// // const localTimeString = utcDate.toLocaleTimeString(undefined, timeFormatOptions);
// const localDateString = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(utcDate);
// const localTimeString = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(utcDate);
// const localDateTimeString = `${localDateString} ${localTimeString}`;
  
//   return localDateTimeString
const utcDate = new Date(utcTimeString);
try{
// Check if the parsed date is valid
if (isNaN(utcDate.getTime())) {
  throw new Error('Invalid date format');
}

// Define options for date formatting
const dateFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
const timeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };

// Format the date and time
const localDateString = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(utcDate);
const localTimeString = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(utcDate);

// Combine the date and time into the desired format
const localDateTimeString = `${localDateString} ${localTimeString}`;

return localDateTimeString;

} catch (error) {
return 'Invalid date';
}
}
export const sensorDatefieldConvertorDateOnly =(utcTimeString)=>{

//   const utcDate = new Date(utcTimeString);
//   if (isNaN(utcDate.getTime())) {
//     throw new Error('Invalid date format');
//   }
//   const dateFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
// const timeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };

// // const localDateString = utcDate.toLocaleDateString(undefined, dateFormatOptions);
// // const localTimeString = utcDate.toLocaleTimeString(undefined, timeFormatOptions);
// const localDateString = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(utcDate);
// const localTimeString = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(utcDate);
// const localDateTimeString = `${localDateString} `;;
  


const utcDate = new Date(utcTimeString);
try{
// Check if the parsed date is valid
if (isNaN(utcDate.getTime())) {
  throw new Error('Invalid date format');
}

// Define options for date formatting
const dateFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
const timeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };

// Format the date and time
const localDateString = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(utcDate);
const localTimeString = new Intl.DateTimeFormat('en-US', timeFormatOptions).format(utcDate);

// Combine the date and time into the desired format
const localDateTimeString = `${localDateString} `;

return localDateTimeString;

} catch (error) {
console.error('Error parsing date:', error.message);
return 'Invalid date';
}
 // return localDateTimeString
}
export const extractDatefromTimeStamp =(utcTimestamp)=>{

// Create a new Date object using the UTC timestamp (multiply by 1000 to convert seconds to milliseconds)
const date = new Date(utcTimestamp * 1000);

// Get local date components
const year = date.getFullYear();
const month = date.getMonth() + 1; // getMonth() returns 0-indexed month (0 = January)
const day = date.getDate();

// Format the date as needed
const localDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

return localDate;

}
export const dateOnlyConverter=(dateTimeString) =>{
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  return formattedDate;
}
export const utcDateTimeToLocalDateTime =(utcDateTimeString)=>{
//   const utcDate = new Date(utcDateTimeString);

// // Convert UTC date to local date/time
// const localDate = new Date(utcDate.toLocaleString());

// // Format local date/time as a string (optional)
// const localDateTimeString = localDate.toLocaleString();

// Convert UTC date to local date/time
const utcDate = new Date(utcDateTimeString);
const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));

// Format local date/time as a string
const localDateTimeString = localDate.toISOString();

// Extract date only
const selectedDateOnly = dateOnlyConverter(localDateTimeString);
return selectedDateOnly; 
}
export const reDirectedDate =(inputDate)=> {
  // Parse the input date string into a Date object
  const date = new Date(inputDate);

  // Array of weekday names
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  // Array of month names
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Extract components from the Date object
  const dayOfWeek = weekdays[date.getDay()];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const timezoneOffset = date.getTimezoneOffset(); // Offset in minutes

  // Determine the timezone offset in hours and minutes
  const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
  const offsetMinutes = Math.abs(timezoneOffset) % 60;
  const timezone = timezoneOffset <= 0 ? '+' : '-'; // Determine if ahead or behind UTC

  // Get local timezone abbreviation
  const timezoneAbbreviation = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Format the date string
  const formattedDate = `${dayOfWeek} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${timezone}${offsetHours < 10 ? '0' : ''}${offsetHours}${offsetMinutes < 10 ? '0' : ''}${offsetMinutes} (${timezoneAbbreviation})`;

  return formattedDate;
}
export const convertMinutesToHMS =(diffminutes)=> {
  // Calculate total seconds
  let totalSeconds = diffminutes * 60;

  // Calculate hours, minutes, and seconds
  let hours = Math.floor(totalSeconds / 3600);
  let remainingSeconds = totalSeconds % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  let seconds = remainingSeconds % 60;

  // Construct the formatted time string
  let formattedTime = `${hours} hours ${String(minutes).padStart(2, '0')} minutes ${String(seconds).padStart(2, '0')} seconds`;

  return formattedTime;
}
export const utcTimeDiffCalculator =(startTime, endTime) =>{
  // Calculate the difference in milliseconds
  let difference = endTime - startTime;

  // Convert milliseconds difference to hours, minutes, and seconds
  let seconds = Math.floor(difference / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  // Calculate remaining minutes and seconds
  minutes %= 60;
  seconds %= 60;

  // Return an object with hours, minutes, and seconds
  let formattedTime = `${hours} hours ${String(minutes).padStart(2, '0')} minutes ${String(seconds).padStart(2, '0')} seconds`;

    return formattedTime;
}

export const utcDateTimeToLocalTime =(utcTimestamp)=>{
  // Given UTC timestamp
//const utcTimestamp = "2024-03-12T09:42:19.923Z";

const utcDate = new Date(utcTimestamp);

// Convert UTC time to local time using toLocaleTimeString method
const localTime = utcDate.toLocaleTimeString();

return localTime;

}
export const timeDifference=(startTime, endTime)=> {
  // // if(startTime > 0 && endTime>0){
  // const start = new Date(`01/01/2000 ${startTime}`);
  // const end = new Date(`01/01/2000 ${endTime}`);
  
  // // Calculate the difference in milliseconds
  // let difference = end - start;

  // // Convert difference to hours and minutes
  // let hours = Math.floor(difference / 3600000);
  // let minutes = Math.floor((difference % 3600000) / 60000);

  // // Format the result as HH:MM
  // return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  const start = new Date(startTime * 1000); // Convert Unix timestamp to milliseconds
  const end = new Date(endTime * 1000);     // Convert Unix timestamp to milliseconds
 
  // Calculate the difference in milliseconds
  let difference = end - start;

  // Convert difference to hours and minutes
  let hours = Math.floor(difference / (1000 * 60 * 60)); // Convert milliseconds to hours
  let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)); // Convert remaining milliseconds to minutes
  difference %= (1000 * 60); // Remaining milliseconds after subtracting minutes
  let seconds = Math.floor(difference / 1000); // Convert remaining milliseconds to seconds

  // Format the result as HH:MM:SS
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  // Format the result as HH:MM
  //return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

}
//   else 
//   return null;
// }
export const dateToTimeStamp = (data) => {
  var dateObject = new Date(data);

  // Get the timestamp (milliseconds since January 1, 1970, 00:00:00 UTC)
  var timestamp = dateObject.getTime();
  var timestampInSeconds = Math.floor(timestamp / 1000);
  return (timestampInSeconds);
}

export const timeStamptoDate = (data) => {
  if (data != null) {
    const dateend = dayjs.utc(data * 1000);
    const iso8601Stringend = dateend.format('$u: YYYY-MM-DD HH:mm:ss');
    const lunchend = dayjs(iso8601Stringend);
    return lunchend
  }
  else
    return null
}
export const GmttimeStamptoDate = (data) => {
  if (data != null) {
    const gmtDate = new Date(data * 1000); // Convert Unix timestamp to milliseconds
    const utcDateString = gmtDate.toISOString();
    const lunchend = dayjs(utcDateString);
    return lunchend
  }
  else
    return null
}
export const dayjsToGmt = (newTime) => {
  const tempDate = newTime.$d.toISOString();
  const unixTimestamp = Date.parse(tempDate) / 1000;
  return unixTimestamp
}
export const GmtToLocalTime = (value) => {
  var gmtTime = new Date(value);
  var myDate = new Date(gmtTime);
  let localDate = myDate.toLocaleString()
  var hours = myDate.getHours();
  var minutes = myDate.getMinutes();
  const formattedHour = hours < 10 ? `0${hours}` : hours;
  const formattedMinute = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHour}:${formattedMinute}`;
}
export const GmtToLocalClockTime = (value) =>{
var gmtTime = new Date(value);
var myDate = new Date(gmtTime);
var hours = myDate.getHours();
var minutes = myDate.getMinutes();
var ampm = hours >= 12 ? 'PM' : 'AM';
hours = hours % 12;
hours = hours ? hours : 12; // Handle midnight (0 hours)
const formattedHour = hours < 10 ? `0${hours}` : hours;
const formattedMinute = minutes < 10 ? `0${minutes}` : minutes;
return `${formattedHour}:${formattedMinute} ${ampm}`;
}
export const GmtToUtc = (value) => {
  var utcTime = new Date(value)
  var year = utcTime.getFullYear();
  var month = (utcTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  var day = utcTime.getDate().toString().padStart(2, '0');
  var hours = utcTime.getHours().toString().padStart(2, '0');
  var minutes = utcTime.getMinutes().toString().padStart(2, '0');
  var seconds = utcTime.getSeconds().toString().padStart(2, '0');

  // Construct ISO 8601 formatted string in local time
  var isoFormattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000`;
  return isoFormattedTime;
}

export const routeListTimeFetch = (newdateString) => {
  const newdate = new Date(newdateString);

  // Check if the conversion is successful
  if (isNaN(newdate.getTime())) {
    // Handle invalid date
    return null; // or any other appropriate handling
  }
  const hour = newdate.getUTCHours();
  const minute = newdate.getUTCMinutes();
  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMinute = minute < 10 ? `0${minute}` : minute;
  return `${formattedHour}:${formattedMinute}`;
  // const hour = newdate.getHours(); // Use getHours() for local time
  // const minute = newdate.getMinutes(); // Use getMinutes() for local time
  // const formattedHour = hour < 10 ? `0${hour}` : hour;
  // const formattedMinute = minute < 10 ? `0${minute}` : minute;

  // return `${formattedHour}:${formattedMinute}`;
}
//ok
export const timeNeglector = (newDate) => {
  if (newDate != null) {
    const dateTime = new Date(newDate);

    const year = dateTime.getUTCFullYear();
    const month = String(dateTime.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateTime.getUTCDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  else {
    return null
  }
}
///ok
export const dateNeglector = (newDate) => {
  const currntDate = new Date();
  const formattedCurentDate = dayjs(currntDate).format('YYYY-MM-DD');
  const formattedTime = dayjs(newDate).format('HH:mm:ss.SSS[Z]');
  const combinedDateTimeString = `${formattedCurentDate}T${formattedTime}`;
  const combinedDateTime = dayjs(combinedDateTimeString);

  const unixTimestamp = Date.parse(combinedDateTime);
  const unixTimestampInSeconds = parseInt(unixTimestamp / 1000);
  return unixTimestampInSeconds;
}








//checked
export const timeOnly = (value) => {
  // Create a new Date object
  const dateObject = new Date(value);

  // Extract the time components
  let hours = dateObject.getUTCHours();
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');
  const seconds = dateObject.getUTCSeconds().toString().padStart(2, '0');


  //if want local time
  // const dateObject = new Date(value);
  // // Extract the time components
  // let hours = dateObject.getHours(); // Use getHours() instead of getUTCHours()
  // const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  // const seconds = dateObject.getSeconds().toString().padStart(2, '0');

  // Determine AM/PM
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format the time as desired (hh:mm:ss AM/PM)
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${amOrPm}`;

  return formattedTime; // Output the formatted time with AM/PM

}

// insured $$$$###########$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%


export const dateOnly = (newDate) => {
  if (newDate != null) {
    const currntDate = newDate;
    const formattedCurentDate = dayjs(currntDate).format('YYYY-MM-DD');
    return formattedCurentDate;
  }
  else
    return "NA"
}
export const convertMinutesToTime = (minutes) => {
  // Convert minutes to hours and minutes
  var hours = Math.floor(minutes / 60);
  var mins = minutes % 60;

  // Determine AM/PM
  var ampm = hours >= 12 ? 'PM' : 'AM';

  // Adjust hours for 12-hour format
  if (hours === 0 || hours === 24) {
    hours = 12; // Midnight should be 12 AM
    ampm = 'AM'; // Force 'AM' for midnight
  } else if (hours > 12) {
    hours -= 12; // After noon, subtract 12 for PM time
  }

  // Format hours and minutes with leading zeros
  var formattedHours = hours < 10 ? '0' + hours : hours;
  var formattedMins = mins < 10 ? '0' + mins : mins;

  // Construct the time string
  var time = formattedHours + ':' + formattedMins + ' ' + ampm;

  return time;


}
export const alertTimeOnly = (timestamp) => {
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
  const localTime = date.toLocaleTimeString();
  return localTime
}
export const historyTimeOnly = (timestamp) => {
  const date = new Date(timestamp ); // Multiply by 1000 to convert seconds to milliseconds
  const localTime = date.toLocaleTimeString();
  return localTime
}
export const timeStampToTime = (value) => {
  const contactstarttime = value; // Assuming this is the Unix timestamp

  // Convert Unix timestamp to milliseconds
  const milliseconds = contactstarttime * 1000;

  // Create a new Date object
  const dateObject = new Date(milliseconds);

  // Extract hours, minutes, and seconds
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  // Determine AM/PM
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format the time as desired (e.g., hh:mm:ss AM/PM)
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${amOrPm}`;

  return formattedTime; // Output the formatted time

}
export const dateSearchConverter = (dateString) => {
  // Provided date string
  // var dateString = "Fri Feb 16 2024 00:00:00 GMT+0530 (India Standard Time)";

  // Create a new Date object
  var date = new Date(dateString);

  // Get the year, month, and day
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  var day = date.getDate().toString().padStart(2, '0');

  // Construct the formatted date string
  var formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export const hoursConverter = (value) => {
  const hours = Math.floor(value / 60);
  const remainingMinutes = value % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes - minutes) * 60);

  // Format the time duration
  const formattedDuration = `${hours} hrs ${minutes} min ${seconds} sec`;

  return formattedDuration;
}

export const importDateTimeConverter = (dateTimeString) => {
  const [datePart, timePart] = dateTimeString.split(' '); // Split date and time parts
  const [day, month, year] = datePart.split('/').map(Number); // Split date into day, month, and year and convert to numbers
  const [hourMinute, meridian] = timePart.split(' '); // Split time into hour:minute and meridian (AM/PM)
  const [hour, minute] = hourMinute.split(':').map(Number); // Split hour:minute and convert to numbers

  const containsAM = /\b(?:am|AM)\b/.test(dateTimeString);
  // Adjust hour for PM times
  let adjustedHour = hour;
  if (!containsAM && hour !== 12) {
    adjustedHour += 12;
  }

  // Convert 12:00 AM to 00:00
  if (containsAM && hour === 12) {
    adjustedHour = 0;
  }

  // Format the date time string
  const formattedDateTime = new Date(Date.UTC(year, month - 1, day, adjustedHour, minute)).toISOString();
  return formattedDateTime;
}
function convertToCustomFormat(dateTimeString) {
  // Parse the input string to extract date and time components
  const [datePart, timePart] = dateTimeString.split(' ');
  const [day, month, year] = datePart.split('/');
  const [hour, minute] = timePart.split(':');
  const amOrPM = timePart.split(' ')[1]; // Extract AM/PM

  // Convert hour to 24-hour format if PM
  let adjustedHour = parseInt(hour);
  if (amOrPM === 'PM' && adjustedHour !== 12) {
    adjustedHour += 12;
  }

  // Create a Date object with adjusted date and time components
  const customDate = new Date(year, month - 1, day, adjustedHour, minute);

  // Format the custom date to the desired format
  const formattedDate = customDate.toString();

  return formattedDate;
}
export const importDateConverter = (dateString) => {
  const parts = dateString.split('/');
  const days = parts[0].length === 1 ? '0' + parts[0] : parts[0];
  const months = parts[1].length === 1 ? '0' + parts[1] : parts[1];
  const formattedDate = `${parts[2]}-${months}-${days}T00:00:00.000Z`;
  return formattedDate;
}

