import {
  Alert,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomizedTableCell,
  CustomizedTableRow,
} from "../../styles/TablebButtonStyles";
import { MenuProps } from "../../Styles";
import { Paginations } from "../../sharable/pagination/Pagination";
import {
  LightBorderButton,
  StyledCreateButton,
  YellowBorderButton,
} from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { RiBuildingLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteSites } from "../../../redux/actions/OrganisationActions";
export const OrgSites = ({
  isDarkMode,
  orgData,
  orgBlocks,
  sites,
  selectedSiteId,
  handleSites,
  pageCount,
  is_wise,
  currentPage,
  handlePageChange,
}) => {
  const dispatch = useDispatch();
  const deletedSiteData = useSelector(
    (state) => state.organisation.deleteSites
  );
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleAddLevel = () => {
    const org_id = orgData.id;
    window.location.href = `/organisation/level/create/${org_id}`;
  };
  const handleBuildSite = () => {
    const org_id = orgData.id;
    window.location.href = `/organisation/site/create/${org_id}`;
    //window.location.href='/organisation/site/create/${org_id}'
  };
  const handleEdit = (id) => {
    const org_id = orgData.id;
    sessionStorage.setItem("org__level_edit_siteId", selectedSiteId);

    window.location.href = `/organisation/level/edit/${id}/${org_id}`;
  };
  const viewMap = (id) => {
    window.location.href = `/organisation/site/view/map/${id}`;
  };
  const handleDelete = (id) => {
    const org_id = orgData.id;
    const data = {
      org_id: orgData.id,
      created_user: parseInt(sessionStorage.getItem("id")),
      id: id,
    };
    dispatch(deleteSites(data));
  };
  useEffect(() => {
    if (
      !deletedSiteData.isFetching &&
      deletedSiteData.payload &&
      deletedSiteData.payload.success === true
    ) {
      window.location.href = "/organisation/view";
    }
    if (!deletedSiteData.isFetching && deletedSiteData.error) {
      const alert = deletedSiteData.errorMessage.message;
      // alert = "Level Deletion Failed";
      setAlertMessage(alert);
      setAlertSuccess(false);
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [deletedSiteData]);
  const handlealertClose = () => {
    setShowAlert(false);
  }
  return (
    <Grid container>
      <Grid item lg={1.5} md={1.5} sm={6} xs={6} className="mt2" sx={{ mt: 2 }}>
        <Typography className="alert_text_light">List Of Blocks</Typography>
      </Grid>
      <Grid item lg={7} md={7} xs={12} sm={12}>
        <Grid container className="mt2">
          <Grid item sx={{ mt: 2 }}>
            <Typography
              sx={{ textAlign: "center" }}
              className={isDarkMode ? "alert_text_light " : "alert_text_light "}
            >
              Sites :
            </Typography>
          </Grid>
          <Grid item lg={3.5} sm={8} xs={8} md={3.5}>
            <Select
              //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
              sx={{
                color: isDarkMode ? "white" : "#161616",
                ml: 1,
                minWidth: 180,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                  border: "",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(233, 233, 233, 1)",
                },
                ".MuiSvgIcon-root ": {
                  fill: isDarkMode ? "white !important" : "#161616",
                },
              }}
              displayEmpty
              MenuProps={MenuProps}
              className={
                isDarkMode
                  ? "headerSelect select_dark headerhead_dark"
                  : "headerSelect select_light"
              }
              value={selectedSiteId}
              onChange={(e) => handleSites(e.target.value)}
            >
              {/* <MenuItem value={''}>All</MenuItem> */}
              {sites !== 'undefined' && sites.length ?
                sites.map((site, index) => (



                  <MenuItem key={index} value={site.organizationId}>
                    {site.organizationName}
                  </MenuItem>



                ))
                :
                <MenuItem>No Sites Found </MenuItem>
              }
            </Select>
          </Grid>
        </Grid>
      </Grid>
      {!is_wise && (
        <>
          <Grid
            item
            lg={1.5}
            md={1.5}
            sm={6}
            xs={6}
            className="mt2"
            sx={{ mt: 2 }}
          >
            <YellowBorderButton
              variant="contained"
              disableRipple
              startIcon={<MdOutlineAddToPhotos color="#FFAC0B" />}
              onClick={handleAddLevel}
            >
              Add Level
            </YellowBorderButton>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={6}
            xs={6}
            className="mt2"
            sx={{ mt: 2 }}
          >
            <StyledCreateButton
              sx={{ pt: "6% !important", pb: "6% !important" }}
              variant="contained"
              disableRipple
              startIcon={<RiBuildingLine color="white" />}
              onClick={handleBuildSite}
            >
              Build Sites
            </StyledCreateButton>
          </Grid>
        </>
      )}

      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handlealertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handlealertClose}
          severity="error"
          variant="outlined"
          sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="mt2"
        sx={{ mb: 2, mt: 4 }}
      >
        <div className="table_Scroll ml2back">
          {orgBlocks != "undefined" && orgBlocks.length ? (
            <>
              <Table className="table_mob" sx={{ m: 2, width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <CustomizedTableCell sx={{ textAlign: "left", pl: 10 }}>
                      Block
                    </CustomizedTableCell>
                    <CustomizedTableCell sx={{ textAlign: "left" }}>
                      Level
                    </CustomizedTableCell>
                    {/* {!is_wise && ( */}
                    <>
                      <CustomizedTableCell sx={{ textAlign: "left" }}>
                        Map
                      </CustomizedTableCell>

                      {!is_wise &&
                        <CustomizedTableCell sx={{ textAlign: "left" }}>
                          Action
                        </CustomizedTableCell>
                      }
                    </>
                    {/* ) } */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orgBlocks.map((item, index) => (
                    <CustomizedTableRow key={index}>
                      <CustomizedTableCell
                        className="block"
                        sx={{ textAlign: "left", pl: 10 }}
                      >
                        {item.blockdata.map((obj, i) => (
                          <>
                            {obj.block_name}{" "}
                            {i !== item.blockdata.length - 1 && ","}
                          </>
                        ))}
                      </CustomizedTableCell>
                      <CustomizedTableCell sx={{ textAlign: "left" }}>
                        {item.level_name}
                      </CustomizedTableCell>
                      {/* {!is_wise && (
                    <> */}
                      <CustomizedTableCell sx={{ textAlign: "left" }}>
                        <LightBorderButton onClick={() => viewMap(item.id)}>
                          View Map
                        </LightBorderButton>
                      </CustomizedTableCell>
                      {!is_wise &&
                        <CustomizedTableCell sx={{ textAlign: "left" }}>
                          <IconButton onClick={() => handleEdit(item.id)}>
                            <MdOutlineEdit color="black" />
                          </IconButton>
                          <IconButton onClick={() => handleDelete(item.id)}>
                            <RiDeleteBin6Line color="#C61A1A" />
                          </IconButton>
                        </CustomizedTableCell>}
                      {/* </>)} */}
                    </CustomizedTableRow>
                  ))}
                </TableBody>
              </Table>
              <div>
                <Paginations
                  isDarkMode={isDarkMode}
                  pageCount={pageCount}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            <Grid
              item
              lg={12}
              md={12}
              sm={6}
              xs={6}
              className="mt2"
              sx={{ ml: 3, mt: 6 }}
            >
              <Typography>No Block to Show</Typography>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
