export const DashboardTableHead = [{ id: "date", label: "Date" },
{ id: "duration", label: "Start Time & End Time" },
{ id: "org_user_id", label: "Organisation User Id" },
{ id: "type_of_alert", label: "Type Of Alert" },
{ id: "location", label: "Location" },
{ id: "violation", label: "Violation" }]
export const DashboardTableData = [{ date: "02/11/2023", duration: "02 : 00 PM To 02 : 05 PM", org_user_id: "Abc12", type_of_alert: "Critical", location: "Block A", violation: "Did not wear PPE" },
{ date: "03/11/2023", duration: "09 : 00 PM To 09 : 05 PM", org_user_id: "EN100", type_of_alert: "Medium", location: "Block C", violation: "Did not wear Helmet" },
{ date: "03/11/2023", duration: "12 : 00 PM To 12 : 05 PM", org_user_id: "XYZ23", type_of_alert: "Critical", location: "Block D", violation: "Did not wear PPE" },
{ date: "04/11/2023", duration: "11 : 00 PM To 11 : 05 PM", org_user_id: "Useradmi87", type_of_alert: "Medium", location: "Block A", violation: "Did not wear Helmet" },
    // {date:"",duration:"",org_user_id:"",type_of_alert:"",location:"",view:""},
    // {date:"",duration:"",org_user_id:"",type_of_alert:"",location:"",view:""},
    // {date:"",duration:"",org_user_id:"",type_of_alert:"",location:"",view:""},
    // {date:"",duration:"",org_user_id:"",type_of_alert:"",location:"",view:""},
    // {date:"",duration:"",org_user_id:"",type_of_alert:"",location:"",view:""},
    // {date:"",duration:"",org_user_id:"",type_of_alert:"",location:"",view:""},
]
export const organisationTableHead = [
    { id: "created_at", label: "Date" },
    { id: "org_name", label: "Organisation Name" },

    { id: "organization_name", label: "Sub Organisations" },
   
    { id: "email", label: "Mail Id" },
    { id: "phone", label: "Phone No" }]
export const organisationTableData = [{ organization_name: "Engineering College", id: "1002", org_name: "Abc College", email: "abcengineering@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "Hospital", id: "3042", org_name: "MBH ", email: "MBH@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "Medical College", id: "6543", org_name: "Th Medcal College", email: "thmedical@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "Engineering College", id: "1006", org_name: "xyz college", email: "xyzengineering@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "IT Park", id: "2883", org_name: "INT", email: "int@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "Engineering College", id: "2043", org_name: "mno College", email: "mnoengineering@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "Hospital", id: "2754", org_name: "PHC", email: "PHC@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "Medical College", id: "2143", org_name: "KL Medcal College", email: "klmedical@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "Engineering College", id: "9867", org_name: "pqr College", email: "pqrengineering@gmail.com", phone: "+91 73458 09087" },
{ organization_name: "IT Park", id: "3005", org_name: "INT", email: "int@gmail.com", phone: "+91 73458 09087" }]
export const subUserListTableHead = [{ id: "name", label: "Name" },
{ id: "tag_id", label: "Tah Id" },
{ id: "role", label: "Role" },
{ id: "email", label: "Mail Id" },
{ id: "phone", label: "Phone No" },]
export const subUserListTableData = [{ name: "Arvind", tag_id: "EN100", role: "Super Admin", email: "arvind12@gmail.com", phone: "+91 73458 09087" },
{ name: "Subash", tag_id: "Hos101", role: "Admin", email: "subash45@gmail.com", phone: "+91 73458 09087" },]
export const UserSubordTableData = [{ name: "Arvind", tag_id: "EN100", role: "site Admin", email: "arvind12@gmail.com", phone: "+91 73458 09087" },
{ name: "Subash", tag_id: "XY203", role: "Site Admin", email: "subash45@gmail.com", phone: "+91 73458 09087" },
{ name: "Arjun", tag_id: "ED432", role: "Supervisor", email: "arjun@gmail.com", phone: "+91 73458 09087" },
{ name: "Anad", tag_id: "EN231", role: "Site Admin", email: "anand@gmail.com", phone: "+91 73458 09087" },]
export const roleListTableHead = [{ id: "role", label: "Role" },
// { id: "sites", label: "Sites" },
{ id: "features", label: "Feature" },]
export const roleListTableData = [{ role: "Super Admin", sites: "Block A", feature: "Tracking, Videos & Surveillance, User Management " },
{ role: "Police", sites: "Block B", feature: "Tracking, Videos & Surveillance" },
{ role: "Admin", sites: "Block C", feature: "Tracking, Videos & Surveillance, User Management " },
{ role: "Security", sites: "Block D", feature: "Tracking, Videos & Surveillance" },]
export const userListTableHead = [{ id: "name", label: "Name" },
{ id: "role", label: "Role" },
{ id: "location", label: "Location" },
{ id: "mailId", label: "Mail Id" },
{ id: "phnno", label: "Phone No" },
    // {id:"",label:""},
    // {id:"",label:""},
    // {id:"",label:""},
    // {id:"",label:""},
]
export const userListTableData = [{ name: "Aravind", role: "Admin", location: "Block D", mailId: "aravind@gmail.com", phnno: "+91 73458 09087" },
{ name: "Kumar", role: "Police", location: "Block B", mailId: "kumar123@gmail.com", phnno: "+91 73458 09087" },
{ name: "Ajas", role: "Security", location: "Block A", mailId: "ajasjas@gmail.com", phnno: "+91 73458 09087" },
{ name: "Habeeba", role: "Police", location: "Block A", mailId: "habeeba7@gmail.com", phnno: "+91 73458 09087" },
{ name: "Anu", role: "Security", location: "Block D", mailId: "anu@gmail.com", phnno: "+91 73458 09087" },
{ name: "Binu", role: "Admin", location: "Block B", mailId: "binu34@gmail.com", phnno: "+91 73458 09087" },
{ name: "Subash", role: "Security", location: "Block C", mailId: "subash@gmail.com", phnno: "+91 73458 09087" },
{ name: "Adarsh", role: "Security", location: "Block A", mailId: "adarshadi@gmail.com", phnno: "+91 73458 09087" },
{ name: "Arun", role: "Admin", location: "Block D", mailId: "arun321@gmail.com", phnno: "+91 73458 09087" },
{ name: "Sujesh", role: "Police", location: "Block A", mailId: "sujesh@gmail.com", phnno: "+91 73458 09087" },]
export const TrackingListHead = [{ id: "block", label: "Block" }, { id: "level", label: "Level" }];
export const TrackingListData = [{ block: "Block A", level: "Level 1" }
    , { block: "Block D", level: "Level 3" }
    , { block: "Block F", level: "Level 2" }
    , { block: "Block C", level: "Level 6" }
    , { block: "Block B", level: "Level 5" }
]
export const CheckPointTableDat = [
    {
        checkpoint: "CP02", chechpointtype: ["Patrol", "Sentry"],
        sites: "Vidconnect-A", block: "block-A", level: "Level-1", listner: "LST_CP02"
    },
    {
        checkpoint: "CP02", chechpointtype: ["Patrol", "Sentry", "Duty"],
        sites: "Vidconnect-A", block: "block-A", level: "Level-1", listner: "LST_CP02"
    },
    {
        checkpoint: "CP02", chechpointtype: ["Patrol", "Sentry"],
        sites: "Vidconnect-A", block: "block-A", level: "Level-1", listner: "LST_CP02"
    },
    {
        checkpoint: "CP02", chechpointtype: ["Patrol", "Sentry"],
        sites: "Vidconnect-A", block: "block-A", level: "Level-1", listner: "LST_CP02"
    },]


export const AlertData = [
    { id:1,date: "01/01/24",violationtype:1, tag_id: "023", stage: "Critical", site: "KLEC", violation: "Checkpoint Sequence (order) Violation" },
    { id:2,date: "23/01/24",violationtype:2, tag_id: "027", stage: "Medium", site: "Brunoi", violation: "Checkpoint Missed" },
    {id:3, date: "26/01/24",violationtype:3, tag_id: "029", stage: "low", site: "Brunoi", violation: "Checkpoint not present" },

]
export const SingleAlert= {
    "id": 44,
    "user_id": 4,
    "is_order": 1,
    "user_tag_id": 493,
    "username": "Security 0031",
    "site_id": 520,
    "organizationid": 0,
    "date": "2024-02-01T00:00:00.000Z",
    "routeid": 3,
    "isviolation": true,
    "iscompleted": false,
    "violationtype": 1,
    "checkpointrecodes": 17,
    "routename": "route",
    "routestarttime": "2024-02-01T00:04:00.000Z",
    "routeendtime": "2024-01-31T23:55:00.000Z",
    "actualstarttime": "2024-01-31T19:13:59.000Z",
    "actualendtime": "2024-01-31T19:37:22.000Z",
    "checkpoints": [
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "id": 4,
            "ismissed": false,
            "isinroute": false,
            "lastseenat": 1706728439,
            "totalvisit": 1,
            "firstseenat": 1706728439,
            "checkpointname": "11",
            "checkpointidentfier": "19ef37571c94caea2b44"
        },
        {
            "0": "5",
            "id": 5,
            "ismissed": false,
            "isinroute": true,
            "lastseenat": "",
            "totalvisit": 0,
            "firstseenat": "",
            "iswrongorder": false
        },
        {
            "0": "6",
            "id": 6,
            "ismissed": false,
            "isinroute": true,
            "lastseenat": "",
            "totalvisit": 0,
            "firstseenat": "",
            "iswrongorder": false
        }
    ],
    "violations": [
        
    ],
    "plannedcheckpoints": [
        "5",
        "6"
    ],
    "status": 1,
    "startat": 1706728439,
    "endat": 1706729842,
    "isroutetimeexceed": false,
    "created_at": "2024-01-31T19:16:07.500Z",
    "updated_at": "2024-01-31T19:46:03.469Z",
    "empid": "l78651",
    "phone": "7865",
    "email": "test1@gmail.com",
    "infant_name": "Security 005",
    "tag_name": "3071c4ce",
    "block_name": "Room1",
    "level_name": "Layer1",
    "site_name": "KLEC"
}
export const cameraList=[
    {id:1,title  :"	APX-BRN-01",machine_id:"APX-BRN-01",status :2,type  :1,live_stream :true,created_at: "02/11/2023"} ,
    {id:2,title  :"Apparatus-01	",machine_id:"Apparatus-01",status :1,type  :1,live_stream :false,created_at: "02/11/2023"} ,
    {id:3,title  :"Battery-01",machine_id:"Battery-01",status :1,type  :1,live_stream :true,created_at: "02/11/2023"} ,
    {id:4,title  :"Electrical-01",machine_id:"Electrical-01",status :2,type  :1,live_stream :false,created_at: "02/11/2023"} ,
    {id:5,title  :"Electrical-GD-01",machine_id:"Electrical-GD-01",status :3,type  :1,live_stream :true,created_at: "02/11/2023"} 

]