import React, { useEffect } from "react";
import { StyledGreyButton } from "../Styles";
import './Athentication.css';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Alert, Grid, IconButton, InputAdornment, InputLabel, Snackbar, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearResetPassword, resetPassword } from "../../redux/actions/AuthenticationActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { validatePassword } from "../helpers/ValidationHelper";

export const PasswordConfirmation = () => {
    const [showAlert, setAlert] = useState(false);
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [isResetSuccess, setResetSuccess] = useState(false);
    const [alertText, setAlertText] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [showSecondPassword, setShowSecondPassword] = useState(false);
    const [newPasswords, setNewPasswords] = useState({
        firstPassword: "",
        secondPassword: ""
    });
    const [validationErrors, setValidationErrors] = useState({
        firstPassword: "",
        secondPassword: ""
    });
    const dispatch = useDispatch();
    const resetPasswordStatus = useSelector(state => state.login.resetData);
    useEffect(() => {
        if (!resetPasswordStatus.isFetching && resetPasswordStatus.payload && resetPasswordStatus.payload.success === true) {
            setResetSuccess(true);
            // const message = resetPasswordStatus.payload.message;
            // setAlertText(message);
            // setSuccessAlert(true);
            const timeoutId = setTimeout(() => {
                dispatch(clearResetPassword());
                window.location.href = '/';
            }, 1000);
          
            return () => clearTimeout(timeoutId);

        }
        if (!resetPasswordStatus.isFetching && resetPasswordStatus.error === true) {
            const message = resetPasswordStatus.errorMessage.message;
            setAlertText(message);
            setSuccessAlert(true);
            const timeoutId = setTimeout(() => {
                setSuccessAlert(false);
                dispatch(clearResetPassword());
            }, 5000);
        //    window.location.href = '/';
            return () => clearTimeout(timeoutId);
        }
    }, [resetPasswordStatus]);
    const handleChange = (value, key) => {
        let temp = { ...newPasswords };
        temp[key] = value;
        setNewPasswords(temp);
        let error = '';
        switch (key) {
          case 'firstPassword':
        
            let valid = validatePassword(value);
            if (valid.code === false) {
              error = valid.msg;
            } else {
              error = '';
            }
            break;
            case 'secondPassword':
                // Validate second password and check if it matches the first one
                let validSecond = validatePassword(value);
                if (validSecond.code === false) {
                  error = validSecond.msg;
                } else if (value !== newPasswords.firstPassword) {
                  error = 'The passwords do not match';
                }
                break;
          default:
            break;
        }
    
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          [key]: error
        }));
    }
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleSecondPasswordVisibility = () => {
        setShowSecondPassword(!showSecondPassword);
    };
    const handleNewPassword = (e) => {

        if (newPasswords.firstPassword === newPasswords.secondPassword) {
            const email = sessionStorage.getItem("forgotEmail")
            let body = {
                email: email,
                password: newPasswords.firstPassword
            }
            e.preventDefault();
            const isEmptyField = Object.values(newPasswords).some((value) => {
                return (typeof value === 'string' && value.trim() === '') || value === undefined || value === null;
            });
    
            if (isEmptyField) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    firstPassword: newPasswords.firstPassword.trim() === '' ? 'This field is mandatory' : '',
                    secondPassword: newPasswords.secondPassword.trim() === '' ? 'This field is mandatory' : ''
                }));
    
            }
            const allClear = Object.values(validationErrors).every((value) => {
                return (typeof value === 'string' && value.trim() == '') || value === undefined || value === null;
            });
            if (allClear && !isEmptyField) {
                dispatch(resetPassword(body));

                }
            dispatch(resetPassword(body));
        }
        else {
            setAlert(true);
            const timeoutId = setTimeout(() => {
                setAlert(false);
            }, 5000);
            return () => clearTimeout(timeoutId);
        }
    }
    const handleBack = () => {
        window.location.href='/forgot-password';
     }
     const resetSuccessClose=()=>{
        setResetSuccess(false);
     }
     const resetErrorClose=()=>{
        setSuccessAlert(false);
     }
    return (
        <div style={{ height: '100%' }}>
             <Snackbar
                open={isResetSuccess}
                autoHideDuration={5000}
                onClose={resetSuccessClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={resetSuccessClose}
                    severity="success"
                    variant="outlined"
                    sx={{ width: 'auto',  backgroundColor: '#5bdb5b73',color:"#11b311" }}

                >
                   Your Password has been changed Successfully.
                </Alert>
            </Snackbar>
            <Snackbar
                open={showSuccessAlert}
                autoHideDuration={5000}
                onClose={resetErrorClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={resetErrorClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
             {alertText}
                </Alert>
            </Snackbar>
            <Grid container style={{ height: '100%' }} className="light_grey_bg pd5">
            <Grid item container lg={12} md={12} sm={12} style={{ justifyContent: 'left' }}>
                                <IconButton
                                    sx={{ backgroundColor: '#E7E7E7', opacity: '70%', borderRadius: 0, mt: 1 }}
                                    onClick={handleBack}
                                >
                                    <ArrowBackIcon sx={{ color: "#161616", opacity: '100%' }} fontSize="mediuam" />
                                </IconButton>
                            </Grid>
                <Grid item container lg={12} md={12} sm={12} style={{ justifyContent: 'center' }}>
                    <Grid item container lg={6} md={6} sm={12} className="light_grey_bg login_div_container"   >
                        <div className="login_div1 loginpt1">
                       
                            <Grid item container style={{ justifyContent: 'center' }}>
                                <img className="login_logo1" src={require("../../assets/img/logo.png")} alt="logo" />

                            </Grid>
                            <Typography
                            className="otphead"
                                sx={{
                                   mb: 2,
                                    
                                }}>
                                Enter Your New Password</Typography>
                       
                        <InputLabel className="form_text_light"
                            sx={{ color: "black", fontSize: 14, mb: 2, mt: 2, fontWeight: 600 }}>
                            Password</InputLabel>
                        <TextField
                           error={!!validationErrors.firstPassword}
                           helperText={validationErrors.firstPassword}
                            value={newPasswords.firstPassword}
                            onChange={(e) => handleChange(e.target.value, 'firstPassword')}
                            inputProps={{ autoComplete: 'off' }}
                            id="firstpass" 
                            name={`firtpassword_${Math.random()}`}
                            className={"text"}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                        <InputLabel className="form_text_light"
                            sx={{ color: "black", fontSize: 14, mb: 2, mt: 5, fontWeight: 600 }}>
                            Confirm Password</InputLabel>
                        <TextField
                           error={!!validationErrors.secondPassword}
                           helperText={validationErrors.secondPassword}
                            value={newPasswords.secondPassword}
                            onChange={(e) => handleChange(e.target.value, 'secondPassword')}
                            type={showSecondPassword ? "text" : "password"}
                            inputProps={{ autoComplete: 'off' }}
                            id="secpass" 
                            name={`secpassword_${Math.random()}`}
                            className={"text"}
                            sx={{ mb: 5 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleSecondPasswordVisibility} edge="end">
                                            {showSecondPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                        <StyledGreyButton
                            className="full_width"
                            variant="contained"
                            disableRipple
                            onClick={handleNewPassword}
                        >
                            SUBMIT
                        </StyledGreyButton>
                    </div>
                </Grid>
                </Grid>
            </Grid>
        </div>
    )
}