import React from "react";
import { StyledCreateButton } from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { Grid, Typography } from "@mui/material";
import { UserListTable } from "../UserListTable";
import PropTypes from 'prop-types';

export const SubUserList = ({ isDarkMode,pageCount,currentPage,handlePageChange, handleCreate, TableData,handleView }) => {

    return (
        <Grid container sx={{ mt: 5 }}>
            <Grid item lg={8.5} sm={6} xs={6} md={8.5}>
                <Typography sx={{ textAlign: 'left', mt: 2 }}
                    className={"headersubhead  light_text"}>
                    List Of Users

                </Typography>
            </Grid>

            <Grid item lg={3.5} sm={6} xs={6} md={3.5} container className="alignR" 
             //  sx={{ '@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important',mt:2 } }}
               >
                <StyledCreateButton
                    variant="contained"
                    disableRipple
                    startIcon={<MdOutlineAddToPhotos color="white" />}
                    onClick={handleCreate}
                >

                    Create User
                </StyledCreateButton>
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12} sx={{ml:-3}}>
                <UserListTable
                    TableData={TableData}
                    handleView={handleView}
                   // TableData={userList}
                    //handleView={handleView}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageCount={pageCount}
                    isDarkMode={isDarkMode}

                />
            </Grid>

        </Grid>
    )
}
SubUserList.propTypes = {
    isDarkMode: PropTypes.bool.isRequired, // Expect a boolean and it's required
    pageCount: PropTypes.number.isRequired, // Expect a number and it's required
    currentPage: PropTypes.number.isRequired, // Expect a number and it's required
    handlePageChange: PropTypes.func.isRequired, // Expect a function and it's required
    handleCreate: PropTypes.func.isRequired, // Expect a function and it's required
    TableData: PropTypes.arrayOf(PropTypes.object).isRequired, // Expect an array of objects and it's required
    handleView: PropTypes.func.isRequired // Expect a function and it's required
  };