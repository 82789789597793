import React from 'react';
import { RiRoadMapLine } from "react-icons/ri";
import { FaRegPlusSquare } from "react-icons/fa";
import { Grid, IconButton, Typography } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

export const DraggableItem = ({checkPoints,index,handleAddCheckpoints,id}) => {
    return (
        <>
        <Draggable draggableId={id.toString()} index={index} key={id.toString()}>
        {(provided) => (
            <Grid item sx={{ mt: 2 }} 
            
            ref={provided.innerRef} 
            {...provided.draggableProps} 
          
              {...provided.dragHandleProps}>
            
         
                <div className="drag_list_box" >
                    <Grid container>
                        <Grid item xs={10} sm={10} lg={10} md={10}
                            sx={{
                                pt: 2,
                                textAlign: 'center'
                            }}>
                            <Grid container sx={{
                                justifyContent: 'center',
                                textAlign: 'center'
                            }}>
                                <Grid item  >
                                    <RiRoadMapLine size="20px" color={'rgba(255, 172, 11, 1)'} style={{ mt: 1, textAlign: 'center' }} />
                                </Grid>
                                <Grid item  > <Typography className="dragitem">{checkPoints}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} sm={2} lg={2} md={2}
                            sx={{
                                pt: 2,
                                textAlign: 'center'
                            }}>
                                <IconButton onClick={()=>handleAddCheckpoints(id)}>
                            <FaRegPlusSquare size="20px" color={'#28A745'} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            )}
            </Draggable>
        </>
    )
}
DraggableItem.propTypes = {
    checkPoints: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    handleAddCheckpoints: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};