export const roleFeatureActionType={
    FETCH_ROLE_FEATURE: "FETCH_ROLE_FEATURE",
    FETCH_ROLE_FEATURE_SUCCESS: "FETCH_ROLE_FEATURE_SUCCESS",
    FETCH_ROLE_FEATURE_ERROR: "FETCH_ROLE_FEATURE_ERROR",

   

}
export const fetchRoleFeature = (data)=>{
    return{
        type :roleFeatureActionType.FETCH_ROLE_FEATURE,
        data:data
    }
}
export const fetchRoleFeatureSuccess =(data)=>{
    return{
        type :roleFeatureActionType.FETCH_ROLE_FEATURE_SUCCESS,
        data:data
    }
}
export const fetchRoleFeatureError =(error)=>{
    return{
        type :roleFeatureActionType.FETCH_ROLE_FEATURE_ERROR,
        error
    }
}