import { Grow, IconButton, Menu, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import './Tables.css';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CustomizedTableCell, CustomizedTableRow } from "../../styles/TablebButtonStyles";

import { MdArrowDropDown } from "react-icons/md";

export const Tables = ({ isDarkMode, column, row, handleView, actionName, view, handleDelete, handleEdit,
    closeSortMenu, handleSortMenu, sortMenuOpen,handleSortValue,emptyString }) => {
    // const anchorRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <div className='table_Scroll'>
            {row.length && row !== undefined ?
                <Table className="table_mob" sx={{ m: 0, width: "100%" }}>
                    <TableHead>
                        <TableRow>
                            {column.map((col, index) => (
                                <CustomizedTableCell isDarkMode={isDarkMode} key={index}
                                // style={{ position: 'relative' }}
                                >
                                    {col.label}
                                    {col.label === 'Last Seen' && (
                                        <>
                                            <IconButton onClick={handleSortMenu}
                                                id="demo-positioned-button"
                                                aria-controls={handleSortMenu ? 'demo-positioned-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={handleSortMenu ? 'true' : undefined} >
                                                <MdArrowDropDown style={{ verticalAlign: 'middle', marginLeft: '5px' }} />

                                            </IconButton>
                                            {sortMenuOpen &&
                                                <Paper className="sort_options">
                                                    {/* <p>Sort</p> */}
                                                    <MenuList>
                                                        <MenuItem onClick={()=>handleSortValue(0)}>Ascending</MenuItem>
                                                        <MenuItem onClick={()=>handleSortValue(1)}>Descending</MenuItem>
                                                    </MenuList>
                                                </Paper>

                                            }
                                            

                                        </>
                                    )}
                                </CustomizedTableCell>
                            ))}
                            {view ? <CustomizedTableCell isDarkMode={isDarkMode}>{actionName}</CustomizedTableCell> : <></>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {row.map((rows, index) => (
                            <CustomizedTableRow key={index} >
                                {
                                    column.map((col, index) => (
                                        <CustomizedTableCell isDarkMode={isDarkMode} key={index}>{rows[col.id]}</CustomizedTableCell>
                                    ))
                                }

                                {view ?
                                    <CustomizedTableCell isDarkMode={isDarkMode}>
                                        <IconButton onClick={() => handleView(rows)}>
                                            <VisibilityOutlinedIcon sx={{ color: isDarkMode ? "white" : "#000000" }} />
                                        </IconButton>
                                        <IconButton onClick={() => handleEdit(rows)}>
                                            <MdOutlineEdit color="black" />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(rows)}>
                                            <RiDeleteBin6Line color="#C61A1A" />
                                        </IconButton>
                                    </CustomizedTableCell> : <></>
                                }
                            </CustomizedTableRow>
                        ))}
                    </TableBody>
                </Table>
                : <>{emptyString == "" ? "No Organisations found" : emptyString }</>
            }
        </div>
    )
}