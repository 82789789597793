import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import { ExportModal } from '../../Modals/ExportModal';
highcharts3d(Highcharts);

export const TagBatteryChart =({trackingDatas,patrolData,trackingnames,sentryData,exportMenu,handleExportMenu,size,
    confirmExport,handleExporttype,exportModalAlert})=>{

    const barChartOptions = {
        chart: {
            type: 'column',
            width: size,height:600,
            //  height:650,
              marginLeft:0,
             marginTop:10,
            // scrollablePlotArea: {
            //     minWidth: 1300,
            //     scrollPositionX: 1
            //   },
            options3d: {
                enabled: true,
                alpha: 2,
                beta: 20,
                viewDistance: 0,
                depth: 10
            }
        },
    
        title: {
            text: ' ',
          
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        xAxis: {
            categories: trackingnames,
            labels: {
                skew3d: true,
                style: {
                    fontSize: '16px'
                },
             //   overflow: 'justify'

            },
            // min: 10,
            // max: 20,
            scrollbar: {
                enabled: true,
                barBackgroundColor: '#5f5959 !important',
                    trackBorderColor: '#5f5959 !important'
            },
            tickLength: 50
        },
    
        yAxis: {
            allowDecimals: false,
            min: 0, 
            max:9,
            // scrollbar: {
            //     enabled: true
            // },
            title: {
                 text: 'Count',
                skew3d: true,
                style: {
                    fontSize: '16px'
                }
            }
        },
    
        tooltip: {
            headerFormat: '<b>{point.key}</b><br>',
            pointFormat: '<span style="color:{series.color}">\u25CF</span> ' +
                '{series.name}: {point.y} / {point.stackTotal}'
        },
    
        plotOptions: {
            series: {
                // pointStart: '0%'
            },
            column: {
                stacking: 'normal',
                depth: 40
            }
            , column: {
                pointPadding: 2, // Adjust this value to increase or decrease the space between bars
                groupPadding: 2 ,
                pointWidth:30
                // Adjust this value to increase or decrease the space between groups of bars
            }
        },
    
        // series: [ {
        //     name: 'petrol',
        //     data: [650, 654, 643, 612, 572],
        //     stack: 'Europe',
        //     color:'#FF903E'

        // }, {
        //     name: 'Sentry',
        //     data: [564, 562, 582, 571, 533],
        //     stack: 'Europe',
        //     color:'#C52525'

        // }]
     
        // scrollbar:{
        //     enabled:true,
        //     barBackgroundColor: '#5f5959 !important',
        //     trackBorderColor: '#5f5959 !important'
        // },
        series: [{
            name: 'Alert Count',
            data: patrolData,
            stack: 'Activity',
            color: '#C52525',

        },
        //  {
        //     name: 'Sentry',
        //     data: sentryData,
        //     stack: 'Activity',
        //     color: '#FF903E'
        // }
    ]
    };
    return(
        <div className="chart-div" id="barchart_container" >
             <ExportModal
                openModal={exportMenu}
                handleModalClose={handleExportMenu}
                modalHead="Export Alert Reports"
                handleExporttype={handleExporttype}
                confirmExport={confirmExport}
                exportModalAlert={exportModalAlert}
            />
        <HighchartsReact
        style={{overflowX:'scroll !important'}}
         highcharts={Highcharts} 
        options={barChartOptions}
         />
      </div>
    )
}
