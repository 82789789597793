export const personnelActionType = {
    FETCH_PERSONNEL_LIST: "FETCH_PERSONNEL_LIST",
    FETCH_PERSONNEL_LIST_SUCCESS : "FETCH_PERSONNEL_LIST_SUCCESS",
    FETCH_PERSONNEL_LIST_ERROR : "FETCH_PERSONNEL_LIST_ERROR",

    ADD_PERSONNEL: "ADD_PERSONNEL",
    ADD_PERSONNEL_SUCCESS : "ADD_PERSONNEL_SUCCESS",
    ADD_PERSONNEL_ERROR : "ADD_PERSONNEL_ERROR",
    CLEAR_ADD_PERSONNEL_STATUS : "CLEAR_ADD_PERSONNEL_STATUS",

    DELETE_PERSONEL:"DELETE_PERSONEL",
    DELETE_PERSONEL_SUCCESS:"DELETE_PERSONEL_SUCCESS",
    DELETE_PERSONEL_ERROR:"DELETE_PERSONEL_ERROR",
    CLEAR_DELETE_PERSONNEL_STATUS : "CLEAR_DELETE_PERSONNEL_STATUS",

    DELETE_BULK_PERSONEL:"DELETE_BULK_PERSONEL",
    DELETE_BULK_PERSONEL_SUCCESS:"DELETE_BULK_PERSONEL_SUCCESS",
    DELETE_BULK_PERSONEL_ERROR:"DELETE_BULK_PERSONEL_ERROR",
    CLEAR_DELETE_BULK_PERSONEL_STATUS : "CLEAR_DELETE_BULK_PERSONEL_STATUS",

    VIEW_PERSONEL:"VIEW_PERSONEL",
    VIEW_PERSONEL_SUCCESS:"VIEW_PERSONEL_SUCCESS",
    VIEW_PERSONEL_ERROR:"VIEW_PERSONEL_ERROR",

    UPDATE_PERSONEL:"UPDATE_PERSONEL",
    UPDATE_PERSONEL_SUCCESS:"UPDATE_PERSONEL_SUCCESS",
    UPDATE_PERSONEL_ERROR:"UPDATE_PERSONEL_ERROR",
    CLEAR_UPDATE_PERSONNEL_STATUS : "CLEAR_UPDATE_PERSONNEL_STATUS",


    ASSIGN_PERSONEL:"ASSIGN_PERSONEL",
    ASSIGN_PERSONEL_SUCCESS:"ASSIGN_PERSONEL_SUCCESS",
    ASSIGN_PERSONEL_ERROR:"ASSIGN_PERSONEL_ERROR",
    CLEAR_ASSIGN_PERSONEL_STATUS:"CLEAR_ASSIGN_PERSONEL_STATUS",

    FETCH_PERSONEL_TAG :"FETCH_PERSONEL_TAG",
    FETCH_PERSONEL_TAG_SUCCESS :"FETCH_PERSONEL_TAG_SUCCESS",
    FETCH_PERSONEL_TAG_ERROR :"FETCH_PERSONEL_TAG_ERROR",

    IMPORT_PERSONEL : "IMPORT_PERSONEL",
    IMPORT_PERSONEL_SUCCESS : "IMPORT_PERSONEL_SUCCESS",
    IMPORT_PERSONEL_ERROR : "IMPORT_PERSONEL_ERROR",
    CLEAR_IMPORT_PERSONEL_STATUS : "CLEAR_IMPORT_PERSONEL_STATUS",

    UNASSIGN_PERSONEL:"UNASSIGN_PERSONEL",
    UNASSIGN_PERSONEL_SUCCESS:"UNASSIGN_PERSONEL_SUCCESS",
    UNASSIGN_PERSONEL_ERROR:"UNASSIGN_PERSONEL_ERROR",
    CLEAR_UNASSIGN_PERSONNEL_STATUS : "CLEAR_UNASSIGN_PERSONNEL_STATUS",

}

//fetch personnel list
export const fetchPersonnelList = (data) => {


    return {
        type: personnelActionType.FETCH_PERSONNEL_LIST,
        data:data
      
    }
}
export const fetchPersonnelListSuccess = (data) => {
    return {
        type: personnelActionType.FETCH_PERSONNEL_LIST_SUCCESS,
        payload: data
    }
}
export const fetchPersonnelListError = (error) => {
    return {
        type: personnelActionType.FETCH_PERSONNEL_LIST_ERROR,
        error
    }
}

//add personnel 
export const addPersonnel = (data) => {


    return {
        type: personnelActionType.ADD_PERSONNEL,
        data:data
      
    }
}
export const addPersonnelSuccess = (data) => {
    return {
        type: personnelActionType.ADD_PERSONNEL_SUCCESS,
        payload: data
    }
}
export const addPersonnelError = (error) => {
    return {
        type: personnelActionType.ADD_PERSONNEL_ERROR,
        error
    }
}
export const clearAddPersonnelStatus = () => {
    return {
        type: personnelActionType.CLEAR_ADD_PERSONNEL_STATUS,
        
    }
}
//delete personnel 
export const deletePersonnel = (data) => {


    return {
        type: personnelActionType.DELETE_PERSONEL,
        data:data
      
    }
}
export const deletePersonnelSuccess = (data) => {
    return {
        type: personnelActionType.DELETE_PERSONEL_SUCCESS,
        payload: data
    }
}
export const deletePersonnelError = (error) => {
    return {
        type: personnelActionType.DELETE_PERSONEL_ERROR,
        error
    }
}
export const clearDeletePersonnelStatus = () => {
    return {
        type: personnelActionType.CLEAR_DELETE_PERSONNEL_STATUS,
        
    }
}
//delete bulk personnel 
export const deletebulkPersonnel = (data) => {


    return {
        type: personnelActionType.DELETE_BULK_PERSONEL,
        data:data
      
    }
}
export const deletebulkPersonnelSuccess = (data) => {
    return {
        type: personnelActionType.DELETE_BULK_PERSONEL_SUCCESS,
        payload: data
    }
}
export const deletebulkPersonnelError = (error) => {
    return {
        type: personnelActionType.DELETE_BULK_PERSONEL_ERROR,
        error
    }
}
export const clearbulkDeletePersonnelStatus = () => {
    return {
        type: personnelActionType.CLEAR_DELETE_BULK_PERSONEL_STATUS,
        
    }
}
//view personnel 
export const viewPersonnel = (id) => {


    return {
        type: personnelActionType.VIEW_PERSONEL,
        id:id
      
    }
}
export const viewPersonnelSuccess = (data) => {
    return {
        type: personnelActionType.VIEW_PERSONEL_SUCCESS,
        payload: data
    }
}
export const viewPersonnelError = (error) => {
    return {
        type: personnelActionType.VIEW_PERSONEL_ERROR,
        error
    }
}
//update personnel 
export const updatePersonnel = (id,data) => {


    return {
        type: personnelActionType.UPDATE_PERSONEL,
        id:id,
        data:data
      
    }
}
export const updatePersonnelSuccess = (data) => {
    return {
        type: personnelActionType.UPDATE_PERSONEL_SUCCESS,
        payload: data
    }
}
export const updatePersonnelError = (error) => {
    return {
        type: personnelActionType.UPDATE_PERSONEL_ERROR,
        error
    }
}
export const clearUpdatePersonnelStatus = () => {
    return {
        type: personnelActionType.CLEAR_UPDATE_PERSONNEL_STATUS,
        
    }
}
//assign personnel 
export const assignPersonnel = (data) => {


    return {
        type: personnelActionType.ASSIGN_PERSONEL,
        data:data
      
    }
}
export const assignPersonnelSuccess = (data) => {
    return {
        type: personnelActionType.ASSIGN_PERSONEL_SUCCESS,
        payload: data
    }
}
export const assignPersonnelError = (error) => {
    return {
        type: personnelActionType.ASSIGN_PERSONEL_ERROR,
        error
    }
}
export const clearAssignPersonnelStatus = () => {
    return {
        type: personnelActionType.CLEAR_ASSIGN_PERSONEL_STATUS,
        
    }
}

//fetch personnel tag

export const fetchPersonnelTag = (id) => {


    return {
        type: personnelActionType.FETCH_PERSONEL_TAG,
        id:id
      
    }
}
export const fetchPersonnelTagSuccess = (data) => {
    return {
        type: personnelActionType.FETCH_PERSONEL_TAG_SUCCESS,
        payload: data
    }
}
export const fetchPersonnelTagError = (error) => {
    return {
        type: personnelActionType.FETCH_PERSONEL_TAG_ERROR,
        error
    }
}

//import personnel 

export const importPersonnels = (data) => {


    return {
        type: personnelActionType.IMPORT_PERSONEL,
       data:data
      
    }
}
export const importPersonnelsSuccess = (data) => {
    return {
        type: personnelActionType.IMPORT_PERSONEL_SUCCESS,
        payload: data
    }
}
export const importPersonnelsError = (error) => {
    return {
        type: personnelActionType.IMPORT_PERSONEL_ERROR,
        error
    }
}
export const clearImportPersonnelsStatus = () => {


    return {
        type: personnelActionType.CLEAR_IMPORT_PERSONEL_STATUS,
       
      
    }
}
export const unassignPersonnel = (data) => {


    return {
        type: personnelActionType.UNASSIGN_PERSONEL,
        data:data
      
    }
}
export const unassignPersonnelSuccess = (data) => {
    return {
        type: personnelActionType.UNASSIGN_PERSONEL_SUCCESS,
        payload: data
    }
}
export const unassignPersonnelError = (error) => {
    return {
        type: personnelActionType.UNASSIGN_PERSONEL_ERROR,
        error
    }
}
export const clearUnassignPersonnelStatus = () => {
    return {
        type: personnelActionType.CLEAR_UNASSIGN_PERSONNEL_STATUS,
        
    }
}
