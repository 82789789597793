import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { BorderButton, ConfirmButton, StyledCreateSmallButton } from "../styles/ButtonStyles";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { GoTag } from "react-icons/go";
import { TagModalstyle } from "../styles/ModalStyle";

export const PersonnelUnAssignModal = ({ onClose, openModal, modalbody, onUnAssignConfirm }) => {
    return (
        <>
            <Modal
                open={openModal}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={TagModalstyle}>
                    <IconButton><GoTag color="#FFAC0B" /></IconButton>
                    <Typography className="modalhead">
                        UnAssign Personnel
                    </Typography>
                    <Typography className="modalBody modallight" sx={{ mt: 2 }}>

                        {`Are you sure you want to unassign ${modalbody} ?`}

                    </Typography>
                    <div style={{ display: 'flex' }} >
                        <BorderButton
                            sx={{ mr: 2, mt: 3 }} variant="outlined"
                            onClick={onClose}
                        >
                            <HighlightOffRoundedIcon
                                sx={{ fontSize: '20px', mr: '5px', color: "#161616" }} />
                            Cancel
                        </BorderButton>
                        <StyledCreateSmallButton
                            startIcon={<GoTag color="white" />}
                            sx={{ mt: 3 }}
                            variant="contained"
                            onClick={onUnAssignConfirm}
                        >
                            {/* <ThumbUpOffAltIcon sx={{ fontSize: '20px', mr: '5px', color: "#ffffff" }} /> */}
                            UnAssign
                        </StyledCreateSmallButton>

                    </div>

                </Box>
            </Modal >
        </>
    )
}