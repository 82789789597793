import { CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
export const VaViolationProof = ({ AlertDetails,vaViolationProof }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isVideoLoading, setIsViceoLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };
    const handleVideoLoad = () => {
        setIsViceoLoading(false);
    };
    return (
        <Grid container>
            {AlertDetails?.image_link != ""  && (
                <Grid item lg={10.7} md={10.7} sm={10.7} xs={10.9} sx={{mt:5}}>
                     {isLoading && <div className="loader" style={{display:'flex',justifyContent: 'center' }}>
                     <CircularProgress sx={{color:"#FFAC0B"}} size={20} />
                     </div>}
                    <img srcSet={`https://apx-bucket.s3.us-east-2.amazonaws.com/${AlertDetails?.image_link}`} 
                    className="va_img va_ml" alt="logo" 
                    crossorigin="anonymous"
                    onLoad={handleImageLoad}
                    style={{ display: isLoading ? 'none' : 'block' }} // Hide image until it’s loaded
                />

                </Grid>)
            }
             {AlertDetails?.video_link != "" && (
                <Grid item lg={10.7} md={10.7} sm={10.7} xs={10.9} sx={{mt:5}}>
                    {/* <img src={AlertDetails?.image_link} className="va_img va_ml" alt="va violation" crossorigin="anonymous" /> */}
                    {/* <img src={`https://apx-bucket.s3.us-east-2.amazonaws.com/${AlertDetails?.video_link}`} className="va_img va_ml" alt="va violation " crossorigin="anonymous" />
                    <img src={`https://apx-bucket.s3.us-east-2.amazonaws.com/${AlertDetails?.video_link}`} 
      //alt="http://3.135.129.87:3001/video.mjpg?machineid=APX-BRN-01&amp;streamid=1&amp;auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh"
      alt={`https://apx-bucket.s3.us-east-2.amazonaws.com/${AlertDetails?.video_link}`}
    // alt="Live Stream Not Available"
     width="100%" height="100%"  className="va_img va_ml" class="shrinkToFit"></img> */}
                         {isVideoLoading && <div className="loader" style={{display:'flex',justifyContent: 'center' }}>
                         <CircularProgress sx={{color:"#FFAC0B"}} size={20} />
                         </div>}

          <video
            src={`https://apx-bucket.s3.us-east-2.amazonaws.com/${AlertDetails?.video_link}`}
            className="va_img va_ml"
            controls
            width="100%"
            height="100%"
            crossorigin="anonymous"
            alt={`Video from ${AlertDetails?.video_link}`}
            onCanPlay={handleVideoLoad}
            style={{ display: isVideoLoading ? 'none' : 'block' }} // Hide image until it’s loaded
        />
                </Grid>)
            }
        </Grid>
    )
}