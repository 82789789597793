import { useTheme } from '@emotion/react';
import { Button, InputBase, Switch, Tab, TableCell, TableRow, Tabs, TextField, tableCellClasses,
SwitchProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createTheme, styled } from '@mui/material/styles';
 //const theme = useTheme();
export const Search = styled('div')(({ theme, isDarkMode }) => ({
  position: 'relative',
  borderRadius: ' 10px',
  borderWidth: '5px',
  border: '1px solid',
  paddingTop: 7,
  paddingBottom: 9,
  alignContent: 'center',
  borderColor: isDarkMode ? 'transparent' : '#E9E9E9',
  backgroundColor: isDarkMode ? '#1e1d2c' : 'transparent',
  '&:hover': {
    backgroundColor: isDarkMode ? 'transparent' : 'transparent',
  },
  //marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme, isDarkMode }) => ({
  color: isDarkMode ? ' #ffffff' : "#8F8F91",
  fontFamily: 'Nunito, sans-serif !important',
fontSize:'16px',
width: '100% !important',
    fontWeight: 300,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    fontFamily: 'Nunito, sans-serif !important',
    width: '100%  !important',
    minWidth:'50% !important',
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};
export const GreySwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: grey[900]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: grey[800],
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme, isDarkMode }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: isDarkMode ? "transparent !important" : "#ffffff",
    color: isDarkMode ? "#ffffff !important" : "#161616 !important",
    borderColor: isDarkMode ? '#161616' : "#E9E9E9",
    fontFamily: 'Poppins , sans-serif !important',

    fontWeight: 600, // Add the fontWeight here
    '&:not($head)': {
      fontWeight: 'inherit !important', // Reset fontWeight for body cells
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: isDarkMode ? "transparent !important" : "#ffffff",
    color: isDarkMode ? "#ffffff !important" : "#161616 !important",
    borderColor: isDarkMode ? '#161616' : "#E9E9E9",
    fontFamily: 'Poppins , sans-serif  !important'
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(even)': {
  //     backgroundColor: "white",
  // },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const ResponsiveLogo = styled('div')`
  display: none;

  @media (min-width: 900px) and (max-width: 1199px) {
    display: flex; // or 'block' or any other value based on your layout
`;
export const lightTheme = createTheme();
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
export const StyledGreyButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  paddingTop: '2% !important',
  paddingBottom: '2% !important',
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#FFAC0B',
  borderColor: '#FFAC0B',
  fontWeight: 600,
  fontFamily: [
    'Poppins , sans-serif !important',

  ].join(','),
  '&:hover': {
    backgroundColor: '#FFAC0B',
    borderColor: '#FFAC0B',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#FFAC0B',
    borderColor: '#7D8BAA',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});
export const StyledGreySmallButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  paddingTop: '1% !important',
  paddingBottom: '1% !important',
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#7D8BAA',
  borderColor: '#7D8BAA',
  fontWeight: 600,
  fontFamily: [
    'Poppins , sans-serif !important',

  ].join(','),
  '&:hover': {
    backgroundColor: '#7D8BAA',
    borderColor: '#7D8BAA',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#7D8BAA',
    borderColor: '#7D8BAA',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});
export const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
});
export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth:' 80%',
    width: '80%',
   
 
    backgroundColor: '#E28909',
  },
 
});
export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme ,isSelected}) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontFamily:'Poppins , sans-serif !important',
    fontSize: '20px',
    marginRight: theme.spacing(1),
    marginBottom: -14,
    color: '#666666',
    '&.Mui-selected': {
      fontWeight: 600,
      fontFamily:'Poppins , sans-serif !important',
      fontSize: '20px',
      marginRight: theme.spacing(1),
      color: '#000000',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '& .MuiTab-wrapper > *:first-child': {
      marginRight: theme.spacing(1),
      color: isSelected ? 'red' : 'inherit', // Change the color when the tab is selected
    },
  }),
)

export const RouteSwitch = styled((props:SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 55,
  height: 35,
  overflow:'visible !important',
  padding:1,
  // marginTop:'-3% !important',
 // border:'1px soild red !important',
  '& .MuiSwitch-switchBase': {
    padding: 6,
    paddingLeft:'5px',
    margin: 2,
  
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#f6c76cde' : '#f6c76cde',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#FFAC0B',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    border:'1.5px solid #E28909',
    backgroundColor:'#FFAC0B !important'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border:'1.5px solid #FFAC0B !important',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
export const RefreshSwitch = styled((props:SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 55,
  height: 35,
  overflow:'visible !important',
  padding:1,
  marginTop:'-1% !important',
 // border:'1px soild red !important',
  '& .MuiSwitch-switchBase': {
    padding: 6,
    paddingLeft:'5px',
    margin: 2,
  
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#f6c76cde' : '#f6c76cde',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#FFAC0B',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    border:'1.5px solid #E28909',
    backgroundColor:'#FFAC0B !important'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border:'1.5px solid #FFAC0B !important',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));