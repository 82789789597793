import React, { useEffect, useState } from 'react';
import { Search, SearchIconWrapper, StyledInputBase } from '../../Styles';
import SearchIcon from '@mui/icons-material/Search';
import { Box, CircularProgress, Grid } from '@mui/material';
import { DeviceFilter } from './DeviceFilter';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchSites } from '../../../redux/actions/UserRoleActions';
import { fetchDevice, fetchTag } from '../../../redux/actions/DeviceActions';
import { ListenerList } from './listener/ListenerList';
import { BridgeList } from './bridge/BridgeList';
import { TagList } from './tag/TagList';

export const DeviceHome = ({ isDarkMode, deviceTabListValue, handleTabListValues }) => {
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const [deviceListData, setDeviceListData] = useState([]);
    const [tagListData, setTagListData] = useState([])
    const [tagStatus, setTagStatus] = useState(0);
    const [isAssigned, setIsAssigned] = useState(0);
    const [search, setSearch] = useState('');
    const [searchTag, setSearchTag] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [tagPageCount, setTagPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTagPage, setCurrentTagPage] = useState(1);

    const itemsPerPage = 12;
    const dispatch = useDispatch();
    const siteData = useSelector(state => state.userRole.siteData);
    const deviceList = useSelector(state => state.device.deviceList);
    const tagList = useSelector(state => state.device.tagList);
    useEffect(() => {
        dispatch(fetchSites());
    }, []);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp);
            const siteId = sessionStorage.getItem('user_site_id');
            // setSelectedSites(siteId)
            if (siteId != 0) {
                setSelectedSites(siteId)
            }
        }
    }, [siteData]);
    useEffect(() => {
        if (!deviceList.isFetching && deviceList.payload && deviceList.payload.success === true) {
            const tempDeviceList = deviceList.payload.data;
            const tempPaginationData = deviceList.payload.pagination;
            const temp = tempDeviceList.map((devices) => {
                const timestamp = devices.last_reported_time * 1000;
                const lastSeen = new Date(timestamp).toLocaleString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                });
                return {
                    id: devices.id,
                    name: devices.tag_name,
                    deviceuuid: devices.deviceuuid,
                    lastSeen: devices.last_reported_time == 0 || devices.last_reported_time == null ? "NA" : lastSeen,
                    ip_address: devices.ip_address,
                    // timestamp: devices.timestamp
                }
            });
            setDeviceListData(temp)
            setPageCount(tempPaginationData.total_pages)
        }
        if (!tagList.isFetching && tagList.payload && tagList.payload.success === true) {
            const tempDeviceList = tagList.payload.data;
            const tempPaginationData = tagList.payload.pagination;
            const temp = tempDeviceList.map((devices) => {
                const timestamp = devices.last_reported_time * 1000;
                const lastSeen = new Date(timestamp).toLocaleString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                });
                return {
                    infant_id: devices.infant_id,
                    infant_name: devices.infant_name,
                    tag_name: devices.tag_name,
                    asset_type: devices.asset_type === 0 ? 'Normal' : 'Invalid',
                    checkoutstatus: devices.checkoutstatus === 0 ? 'No' : 'Yes',
                    transfer_requested: devices.transfer_requested === 0 ? 'No' : 'Yes',
                    ip_address: devices.ip_address,
                    block_name: devices.block_name,
                    battery: devices.battery,
                    lastSeen: devices.last_reported_time == 0 || devices.last_reported_time == null ? "NA" : lastSeen,

                    // timestamp: devices.timestamp
                }
            });
            setTagListData(temp)
            setTagPageCount(tempPaginationData.total_pages)
        }
    }, [deviceList, tagList]);
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        if (deviceTabListValue === 'listener') {
            let data = {
                start: start,
                limit: limit,
                type: 11001,
                site_id: selectedSites,
                search: search
            }
            dispatch(fetchDevice(data))
        }
        if (deviceTabListValue === 'bridge') {
            let data = {
                start: start,
                limit: limit,
                type: 13012,
                site_id: selectedSites,
                search: search
            }
            dispatch(fetchDevice(data))
        }
        if (deviceTabListValue === 'tag') {
            const start = (currentTagPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let checkoutValue = tagStatus === 0 ? 0 : tagStatus === 1 ? 1 : 0;
            let transferValue = tagStatus === 2 ? 1 : 0;
            let data = {
                start: start,
                limit: limit,
                site_id: selectedSites,
                checkout: checkoutValue,
                transfer: transferValue,
                search: searchTag,
                is_assign: isAssigned
            }
            dispatch(fetchTag(data));
        }
    }, [deviceTabListValue, selectedSites, tagStatus, searchTag, search, isAssigned])
    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        if (deviceTabListValue === 'listener') {
            const data = {
                start: start,
                limit: limit,
                type: 11001,
                site_id: selectedSites,
                search: search
            }
            dispatch(fetchDevice(data))
        }
        if (deviceTabListValue === 'bridge') {
            let data = {
                start: start,
                limit: limit,
                type: 13012,
                site_id: selectedSites,
                search: search
            }
            dispatch(fetchDevice(data))

        }
    }, [currentPage]);
    useEffect(() => {
        const start = (currentTagPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        if (deviceTabListValue === 'tag') {
            let checkoutValue = tagStatus === 0 ? 0 : tagStatus === 1 ? 1 : 0;
            let transferValue = tagStatus === 2 ? 1 : 0;
            let data = {
                start: start,
                limit: limit,
                site_id: selectedSites,
                checkout: checkoutValue,
                transfer: transferValue,
                search: searchTag
            }
            dispatch(fetchTag(data));
        }
    }, [currentTagPage])
    const handleSites = (value) => {
        setSelectedSites(value);
    }
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleTagPageChange = (event, newPage) => {
        setCurrentTagPage(newPage);
    };
    const handleSearchWord = (word) => {
        setSearch(word);
    }
    const handleAssetStuatus = (value) => {
        setTagStatus(value)
    }
    const handleAssignAssets = (value) => {
        setIsAssigned(value);
    }
    return (
        <>
            <Grid container>
                {deviceTabListValue === 'tag' ?
                    <Grid item lg={6} md={6} sx={{ ml: -3 }}>
                        <Search isDarkMode={isDarkMode}>
                            <SearchIconWrapper>
                                <SearchIcon sx={{ color: isDarkMode ? "white" : "#8F8F91" }} fontSize="medium" className="mb" />
                            </SearchIconWrapper>
                            <StyledInputBase
                                isDarkMode={isDarkMode}
                                placeholder="Search Tag Id"
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchTag}
                                onChange={(e) => setSearchTag(e.target.value)}
                            />
                        </Search>
                    </Grid> : null
                }
                <DeviceFilter
                    deviceTabListValue={deviceTabListValue}
                    handleTabListValues={handleTabListValues}
                    sites={sites}
                    handleSites={handleSites}
                    selectedSites={selectedSites}
                    search={search}
                    handleSearchWord={handleSearchWord}
                    handleAssetStuatus={handleAssetStuatus}
                    tagStatus={tagStatus}
                    isAssigned={isAssigned}
                    handleAssignAssets={handleAssignAssets}
                />
                {
                    deviceTabListValue === "listener" ?
                        deviceList.isFetching ?
                            <Grid item lg={12} md={12} sm={12} xs={12} container
                                sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                                <CircularProgress sx={{ color: '#FFAC0B' }} />
                            </Grid>
                            :
                            <ListenerList deviceListData={deviceListData}
                                isDarkMode={isDarkMode}
                                deviceTabListValue={deviceTabListValue}
                                handlePageChange={handlePageChange}
                                pageCount={pageCount}
                                page={currentPage}


                            />
                        : null
                }
                {
                    deviceTabListValue === "bridge" ?
                        deviceList.isFetching ?
                            <Grid item lg={12} md={12} sm={12} xs={12} container
                                sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                                <CircularProgress sx={{ color: '#FFAC0B' }} />
                            </Grid>
                            :
                            <BridgeList
                                isDarkMode={isDarkMode}
                                deviceListData={deviceListData}
                                deviceTabListValue={deviceTabListValue}
                                handlePageChange={handlePageChange}
                                pageCount={pageCount}
                                page={currentPage}
                                search={search}

                            /> : null
                }
                {

                    deviceTabListValue === "tag" ?
                        tagList.isFetching ?
                            <Grid item lg={12} md={12} sm={12} xs={12} container
                                sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                                <CircularProgress sx={{ color: '#FFAC0B' }} />
                            </Grid>
                            :
                            <TagList
                                isDarkMode={isDarkMode}
                                tagListData={tagListData}
                                deviceTabListValue={deviceTabListValue}
                                handlePageChange={handleTagPageChange}
                                pageCount={tagPageCount}
                                page={currentTagPage}
                                isAssigned={isAssigned}
                            // search={search}

                            /> : null
                }

            </Grid>
        </>
    )
}








