import React, { useState, useEffect } from 'react';
import { Layout } from '../../layout/Layout';
import { CircularProgress, Grid } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { OrgViewTabs } from './OrgViewTabs';
import { OrgDetails } from './OrgDetails';
import { useDispatch } from 'react-redux';
import { fetchOrganisationBlocks, fetchOrgTelegramList, fetchSingleOrganisation } from '../../../redux/actions/OrganisationActions';
import { useSelector } from 'react-redux';
import { OrgSites } from './OrgSites';
import { OrgUsers } from './OrgUsers';
import { fetchUserList } from '../../../redux/actions/UserActions';
import { fetchSites } from '../../../redux/actions/UserRoleActions';
import { OrgTelegramList } from '../OrgTelegram/OrgTelegramList';
export const OrganisationView = () => {
    const dispatch = useDispatch();
    const singleOrg = useSelector(state => state.organisation.singleOrganisation);
    const singleOrgBlocks = useSelector(state => state.organisation.orgBlocks);
    const orgUserListData = useSelector(state => state.user.userList);
    const siteData = useSelector(state => state.userRole.siteData);

    const [isDarkMode, setDarkMode] = useState(false);
    const orgTeliList = useSelector(state => state.organisation.telilist);
    const [pageCountTele, setPageCountTele] = useState(0);
    const [currentPageTele, setCurrentPageTele] = useState(1);
    const [orgTelegramList, setOrgTelegramList] = useState([]);
    const [activeMenuItem, setActiveMenuItem] = useState("organisation");
    const [tabValue, setTabValue] = useState('org_details');
    const [orgData, setOrgData] = useState({});
    const [orgBlocks, setOrgBlocks] = useState([]);
    const [orgUserList, setOrgUserList] = useState([]);
    const [userPageCount, setUserPageCount] = useState(0);
    const [sites, setSites] = useState([]);
    const [selectedSiteId, setSelectedSiteId] = useState("")
    const [selectedUserSiteId, setSelectedUserSiteId] = useState('')
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [currentUserPage, setCurrentUserPage] = useState(1);
    const [orgId, setOrgId] = useState(null);
    const [userOrgId, setUserOrgId] = useState(null);
    const [orgUserId, setOrgUserId] = useState(null);
    const [orgTabId, setOrgTabId] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [is_wisilica, setIs_wisilica] = useState(false)
    useEffect(() => {
        const tabId = sessionStorage.getItem('organisationTab');
        if (tabId != '' && tabId !== null) {
            setTabValue(tabId);
        }

        const id = sessionStorage.getItem("organasition_view_id");

        // alert(id);
        dispatch(fetchSingleOrganisation(id));
    }, []);

    useEffect(() => {
        if (!singleOrg.isFetching && singleOrg.payload && singleOrg.payload.success === true) {
            const tempOrg = singleOrg.payload.organization_details;
            const tempOrgId = singleOrg.payload.organization_details.org_type_id;
            const tempUserOrgId = singleOrg.payload.organization_details.id;
            const tempUser = singleOrg.payload.organization_details.user_id;
            const tempWise = singleOrg.payload.organization_details.is_wisilica;
            if (tempOrg.logo !== "") {
                const tempUrl = process.env.REACT_APP_API_BASE_URL + '/media' + tempOrg.logo;
                setPreviewUrl(tempUrl);
            }
            sessionStorage.setItem("orgsanisation_org_id", tempUserOrgId)
            sessionStorage.setItem("orgsanisation_orguser_id", tempUser)
            sessionStorage.setItem("orgsanisation_is_wisilica", tempWise)

            setOrgUserId(tempUser)
            setUserOrgId(tempUserOrgId)
            setOrgData(tempOrg);
            setOrgId(tempOrgId);
            setIs_wisilica(tempWise);
        }

        if (userOrgId !== null) {
            // alert(orgId);
            const start = (currentUserPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            const startTele = (currentPageTele - 1) * itemsPerPage;
            const limitTele = itemsPerPage;
            let data = {

                org_id: userOrgId,
                start: start,
                limit: limit,
                is_wisilica: is_wisilica
            }
            let teleData = {
                org_id: userOrgId,
                start: startTele,
                limit: limitTele,
                is_wisilica: is_wisilica
            }
            // dispatch(fetchUserList(data))
            dispatch(fetchSites(data))
            dispatch(fetchOrgTelegramList(teleData))

        }

    }, [singleOrg, orgId, userOrgId])
    useEffect(() => {
        if (selectedSiteId != 0) {
            const start = (currentPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let data = {
                id: selectedSiteId,
                start: start,
                limit: limit,

            }
            dispatch(fetchOrganisationBlocks(data))

        }

    }, [selectedSiteId])
    useEffect(() => {
        // if (selectedUserSiteId) {
        const start = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
        let data = {
            org_id: userOrgId,
            site_id: parseInt(selectedUserSiteId),
            start: start,
            limit: limit,
            parent_id: parseInt(orgUserId)
        }
        dispatch(fetchUserList(data))

        // }
    }, [selectedUserSiteId])
    useEffect(() => {
        if (!orgTeliList.isFetching && orgTeliList.payload && orgTeliList.payload.success === true) {
            const tempList = orgTeliList.payload.list;
            setOrgTelegramList(tempList);
            const temppagination = orgTeliList.payload.pagination;

            //  const tempOrgId = singleOrg.payload.organization_details.org_type_id;
            setPageCountTele(temppagination.total_pages)
        }
    }, [orgTeliList])
    useEffect(() => {
        if (currentUserPage && userOrgId !== null) {
            const start = (currentUserPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let data = {

                org_id: userOrgId,
                start: start,
                limit: limit,
                parent_id: parseInt(orgUserId)

            }
            dispatch(fetchUserList(data));
        }
        if (currentPage) {
            if (selectedSiteId != 0) {
                const start = (currentPage - 1) * itemsPerPage;
                const limit = itemsPerPage;
                let data = {
                    id: selectedSiteId,
                    start: start,
                    limit: limit,

                }
                dispatch(fetchOrganisationBlocks(data))

            }
        }
        const startTele = (currentPageTele - 1) * itemsPerPage;
        const limitTele = itemsPerPage;

        let teleData = {
            org_id: userOrgId,
            start: startTele,
            limit: limitTele,
            is_wisilica: is_wisilica
        }

        dispatch(fetchOrgTelegramList(teleData))
    }, [currentPage, currentUserPage, currentPageTele]);
    useEffect(() => {
        if (!singleOrgBlocks.isFetching && singleOrgBlocks.payload && singleOrgBlocks.payload.success === true) {
            const tempOrgBlock = singleOrgBlocks.payload.data;
            const tempOrgBlockpagination = singleOrgBlocks.payload.pagination;

            //  const tempOrgId = singleOrg.payload.organization_details.org_type_id;
            setOrgBlocks(tempOrgBlock);
            setPageCount(tempOrgBlockpagination.total_pages)
        }
        if (!orgUserListData.isFetching && orgUserListData.payload && orgUserListData.payload.success === true) {
            const tempOrgusers = orgUserListData.payload.user_list;
            //  const tempOrgId = singleOrg.payload.organization_details.org_type_id;
            const tempPaginationData = orgUserListData.payload.pagination;

            setOrgUserList(tempOrgusers);
            setUserPageCount(tempPaginationData.total_pages)
        }

    }, [singleOrgBlocks, orgUserListData]);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            });
            const start = (currentPage - 1) * itemsPerPage;
            const limit = itemsPerPage;
            let data = {
                id: temp[0]?.organizationId,
                start: start,
                limit: limit
            }
            dispatch(fetchOrganisationBlocks(data))
            setSelectedSiteId(temp[0]?.organizationId)
            setSelectedUserSiteId(temp[0]?.organizationId)

            setSites(temp)
        }
    }, [siteData])
    const handlePageChange = (event, newPage) => {
        setCurrentUserPage(newPage);
    };
    const handlePageChangeTele = (event, newPage) => {
        setCurrentPageTele(newPage);
    };
    const handleBlockPageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
        sessionStorage.setItem('organisationTab', newValue);

        // setAnchorEl(null);
    }
    const handleActiveMenuItems = (menuItems) => {
        setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }

    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);

    }
    const handleSites = (data) => {
        setSelectedSiteId(data);
    }
    const handleUserSites = (id) => {
        setSelectedUserSiteId(id);
    }
    return (
        <Layout
            isDarkMode={isDarkMode}
            handleDarkmode={handleDarkmode}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"organisation"}
            activeMenuKey={"Organisation"}
            isCreate={true}
        >
            <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
                <Grid container spacing={1} sx={{ pt: 3, mb: 5, ml: 2, '@media (max-width: 600px)': { ml: 0 } }}


                //className={isDarkMode ? "chartbg_dark " : "chartbg_light "} 
                >
                    <TabContext value={tabValue}>
                        <OrgViewTabs
                            handleTabChange={handleTabChange}
                            tabValue={tabValue}

                        />
                        <TabPanel value="org_details" index="org_details" sx={{
                            width: "100% !important",
                            '@media (max-width: 600px)': { p: 0 }
                        }}>
                            {singleOrg.isFetching ?
                                <Grid item lg={12} md={12} sm={12} xs={12} container
                                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                                </Grid>
                                :
                                <OrgDetails
                                    isDarkMode={isDarkMode}
                                    orgData={orgData}
                                    previewUrl={previewUrl}
                                />
                            }
                        </TabPanel>
                        <TabPanel value="org_sites" index="org_sites" sx={{
                            width: "100% !important",
                            '@media (max-width: 600px)': { p: 0 }
                        }}>
                            {singleOrgBlocks.isFetching ?
                                <Grid item lg={12} md={12} sm={12} xs={12} container
                                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                                </Grid>
                                :
                                <OrgSites
                                    isDarkMode={isDarkMode}
                                    orgBlocks={orgBlocks}
                                    sites={sites}
                                    selectedSiteId={selectedSiteId}
                                    handleSites={handleSites}
                                    handlePageChange={handleBlockPageChange}
                                    currentPage={currentPage}
                                    pageCount={pageCount}
                                    orgData={orgData}
                                    is_wise={is_wisilica}
                                />
                            }
                        </TabPanel>
                        <TabPanel value="org_user" index="org_user" sx={{
                            width: "100% !important",
                            '@media (max-width: 600px)': { p: 0 }
                        }}>
                            {orgUserListData.isFetching ?
                                <Grid item lg={12} md={12} sm={12} xs={12} container
                                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                                </Grid>
                                :
                                <OrgUsers
                                    isDarkMode={isDarkMode}
                                    orgUserList={orgUserList}
                                    currentUserPage={currentUserPage}
                                    pageCount={userPageCount}
                                    handlePageChange={handlePageChange}
                                    handleUserSites={handleUserSites}
                                    selectedUserSiteId={selectedUserSiteId}
                                    sites={sites}
                                />
                            }
                        </TabPanel>
                        <TabPanel value="org_telegram" index="org_telegram" sx={{
                            width: "100% !important",
                            '@media (max-width: 600px)': { p: 0 }
                        }}>
                            {/* {orgUserListData.isFetching ?
                                <Grid item lg={12} md={12} sm={12} xs={12} container
                                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                                </Grid>
                                : */}
                            {orgTeliList.isFetching ?
                                <Grid item lg={12} md={12} sm={12} xs={12} container
                                    sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                                    <CircularProgress sx={{ color: '#FFAC0B' }} />
                                </Grid>
                                :
                                <OrgTelegramList
                                    isDarkMode={isDarkMode}
                                    // is_wisilica={is_wisilica}
                                    currentPage={currentPageTele}
                                    pageCount={pageCountTele}
                                    handlePageChange={handlePageChangeTele}
                                    orgTelegramList={orgTelegramList}
                                // handleUserSites={handleUserSites}
                                // selectedUserSiteId={selectedUserSiteId}
                                // sites={sites}
                                />
                            }
                            {/* } */}
                        </TabPanel>

                    </TabContext>

                </Grid>
            </div>

        </Layout>
    )
}