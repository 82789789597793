import React, { useEffect, useState } from 'react';

export const Stream = ({camera}) => {
  const [streamData,setStreamData] = useState({});
  
let videoUrl = process.env.REACT_APP_VIDEO_SOFT
  useEffect(() => {
    if(camera != null){
      setStreamData(camera)
    }
  }, [camera]);

  return (
    <div>
      { streamData  && 
      <img src={`${videoUrl}machineid=${streamData?.machine_id}&streamid=${streamData?.stream_id}&auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh`} 
      //alt="http://3.135.129.87:3001/video.mjpg?machineid=APX-BRN-01&amp;streamid=1&amp;auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh"
      alt={`${videoUrl}machineid=${streamData.machine_id}&ampstreamid=${streamData.stream_id}&auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh`}
    // alt="Live Stream Not Available"
     width="100%" height="100%" class="shrinkToFit"></img>
    }
    {/* <img src="http://3.135.129.87:3001/video.mjpg?machineid=APX-BRN-01&amp;streamid=1&amp;auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh"
      alt="http://3.135.129.87:3001/video.mjpg?machineid=APX-BRN-01&amp;streamid=1&amp;auth=VU5OX0Rhc2hib2FyZDpNdXJpdDRubmljMjQh"
       width="100%" height="100%" class="shrinkToFit"></img> */}
    </div>
  );
};