import { Grid, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { MenuProps } from "../Styles";
import { Linechart } from "../sharable/charts/Linechart";
import IconButton from '@mui/material/IconButton';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

export const DashboardLinechart = ({ isDarkMode, removeWidget, id }) => {
  return (
    <div>
      <Grid container spacing={3} sx={{ pt: 1 }}>
        <Grid item sm={4} xs={4} lg={4} md={4}>
          <Typography className={isDarkMode ? "chartheading chartheading_color" : "chartheading_color_light chartheading"}>Device</Typography>
        </Grid>
        {/* <div className="hidden-mobile chartmr15 "></div> */}
        <Grid item sm={3} xs={3} lg={3} md={3}>
          <Select
            className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
            sx={{
              color: isDarkMode ? "white" : "#161616", minWidth: 100,
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '.MuiSvgIcon-root ': {
                fill: isDarkMode ? "white !important" : "#161616",
              }
            }}
            displayEmpty
            MenuProps={MenuProps}
          >
            <MenuItem value="" >EN100</MenuItem>
          </Select>
        </Grid>
        <Grid item sm={3} xs={3} lg={3} md={3}>
          <Select
            className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
            sx={{
              color: isDarkMode ? "white" : "#161616", minWidth: 100,
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '.MuiSvgIcon-root ': {
                fill: isDarkMode ? "white !important" : "#161616",
              }
            }}
            displayEmpty
            MenuProps={MenuProps}
          >
            <MenuItem value="January" >January</MenuItem>
            <MenuItem value="January" >February</MenuItem>
            <MenuItem value="January" >March</MenuItem>
            <MenuItem value="January" >April</MenuItem>
            <MenuItem value="May" >May</MenuItem>
            <MenuItem value="June" >June</MenuItem>
            <MenuItem value="July" >July</MenuItem>
            <MenuItem value="August" >August</MenuItem>
            <MenuItem value="September" >September</MenuItem>
            <MenuItem value="October" >October</MenuItem>
            <MenuItem value="November" >November</MenuItem>
            <MenuItem value="December" >December</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2} sm={2} lg={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
            <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
          </IconButton>
        </Grid>
      </Grid>
      <Linechart />
    </div>
  )
}