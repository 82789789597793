import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { LightBorderButton, StyledCreateSmallButton, YellowBorderButton } from '../../styles/ButtonStyles';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

export const AssignAlert =({isDarkMode,onAssignChange,onAssignSubmit,alertAssignDetails,handleDiscard})=>{
    
    return(
        <>
         <Grid item xs={10} sm={10} lg={6} md={6} sx={{ mt: 3 }}>
                        <InputLabel
                            className={isDarkMode ? "form_text" : "form_text_light"}
                            sx={{ color: isDarkMode ? "white" : "black", ml: 0, mb: 2, }}>
                            Stage Of Alert</InputLabel>
                            <Select
                            className={isDarkMode ? "form_field form_div noml" : "form_field form_field_light noml"}
                           value={alertAssignDetails.violation_type}
                            onChange={(e)=>onAssignChange(e.target.value,'violation_type')}
                            displayEmpty
                            renderValue={(selected) => {
                                const valueMap = {
                                    1: 'Critical',
                                    2: 'Medium',
                                    3: 'Low'
                                };
                        
                                if (selected === 0) {
                                    return <span style={{ color: '#a2a2a2' }}>Stage Of Alert</span>;
                                }
                        
                                return valueMap[selected];
                            }}
                        >
                            <MenuItem value={1}>Critical</MenuItem>
                            <MenuItem value={2}>Medium</MenuItem>
                            <MenuItem value={3}>Low</MenuItem>
                           
                        </Select>
                    </Grid>
                    <Grid item xs={10}  sm={10} lg={6} md={6} sx={{ mt: 3 }}>

                        <InputLabel
                            className={isDarkMode ? "form_text form_text_mob" : "form_text_light form_text_mob"}
                            sx={{ color: isDarkMode ? "white" : "black", mb: 2, }}>
                            Describe</InputLabel>
                        <TextField
                            className={isDarkMode ? "form_field_right form_div" : "form_field_right form_field_light"}
                            placeholder='Describe the alert'
                            value={alertAssignDetails.description}
                            onChange={(e)=>onAssignChange(e.target.value,'description')}
                          
                        />
                    </Grid>
                    <Grid item lg={6} md={6} container columnSpacing={3} sx={{mt:5,ml:'0px !important'}}>
                        <Grid item sx={{mr:1}}>
                        <StyledCreateSmallButton 
                        onClick={onAssignSubmit} >
                        <img src={require("../../../assets/icons/assign_white.png")} />
                            Assign
                        </StyledCreateSmallButton>
                        </Grid>
                       <Grid item>
                       <YellowBorderButton
                        onClick={handleDiscard}
                       >
                             <HighlightOffRoundedIcon
                                sx={{ fontSize: '20px', mr: '5px', color: "#D0871D" }} />
                            Discard
                        </YellowBorderButton>
                       </Grid>
                  
                    </Grid>
        </>
    )
}