import { API } from './Api';
const checkpoints ='/checkpoints/'
//get checkpoint list
export const fetchCheckPointListData = async (data) => {
     const start = data && data.start !== undefined ? data.start : 0;
     const limit = data && data.limit !== undefined ? data.limit : 15;
    // const start=data.start;
    // const limit=data.limit;
    const site_id = data && data.site_id !== undefined ? data.site_id :'';
     const type = data && data.type !== undefined ? data.type : '';
     const searchword= data && data.searchword !== undefined ? data.searchword : '';
     const org_id= parseInt(sessionStorage.getItem('org_Id'));
    const response = await API.get(`${checkpoints}?start=${start}&limit=${limit}&site_id=${site_id}&type=${type}&search=${searchword}&org_id=${org_id}`);
    return response.data;


}
export const fetchCheckPointTypeData = async () => {

    const response = await API.get(`${checkpoints}checkpoint_type`);
    return response.data;


}
export const addNewCheckPointData = async (data) => {

    const response = await API.post(`${checkpoints}`,data);
    return response.data;


}
export const getSingleCheckPointData = async (id) => {

    const response = await API.get(`${checkpoints}${id}`);
    return response.data;


}
export const editCheckPointData = async (id,data) => {

    const response = await API.put(`${checkpoints}${id}`,data);
    return response.data;


}
export const deleteCheckPointData = async (body) => {

    const response = await API.put(`${checkpoints}`,body);
    return response.data;

    // try {
    //     const response = await API.delete(`${checkpoints}${id}`);
    //     if (response.status === 422) {
    //         // Parse the JSON response to extract the error message
    //         const errorData = await response.json();
    //         const errorMessage = errorData.message;
    //         console.error("Error deleting checkpoint:", errorMessage);
    //         // Returning the error message
    //         return { success: false, message: errorMessage };
    //     }

    //     console.log("del", response);
    //     return response.data;
    // } catch (error) {
    //     console.log("Error deleting checkpoint:", error);
    //     throw error; // Rethrow the error to be caught by the calling code
    // }
}