import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { MenuProps, Search, SearchIconWrapper, StyledInputBase } from "../../Styles";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

export const UserHeader = ({ isDarkMode, isCreate,activeSubtab,searchWord ,activeMenuItem,onSearchWordChange,
  placeholder}) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  }
  return (
    <>  {isCreate === true ?
      <>
        <Grid item lg={8.5} sm={12} xs={12} md={8.5} className="ml" sx={{ textAlign: 'center' }}>
          <Grid container>
            <Grid item>
              <IconButton sx={{ mt: 0, }}
                onClick={handleBack}
              >
                <ArrowBackIosNewOutlinedIcon sx={{ color: isDarkMode ? "white" : "#000000" }} fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className={isDarkMode ? "headerhead headerhead_dark" : "headerhead headerhead_light"}> 
              {activeMenuItem === 'rolemanagement' ?"Roles & Features" : "User Management"}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </> :
      <>
        <Grid item
          lg={2.3} sm={12} xs={12} md={2.3}
          className="ml" sx={{ textAlign: 'center' }}>
          <Typography sx={{ textAlign: 'center', mt: 1 }} className={isDarkMode ? "headerhead headerhead_dark" : "headerhead headerhead_light"}>
          {activeMenuItem === 'rolemanagement' ? "Roles & Features" : "User Management"}
          </Typography>
        </Grid>
        <Grid item lg={6.9} sm={12} xs={12} md={6.9}>
            <Grid item lg={9} sm={11} xs={11} md={9}>
            <Search isDarkMode={isDarkMode}>
                            <SearchIconWrapper>
                                <SearchIcon sx={{ color: isDarkMode ? "white" : "#8F8F91" }} fontSize="medium" className="mb" />
                            </SearchIconWrapper>
                            <StyledInputBase
                                isDarkMode={isDarkMode}
                                placeholder={placeholder}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchWord}
                                onChange={(e)=>onSearchWordChange(e.target.value)}
                            />
                        </Search>
            </Grid>
                        
            <Grid item lg={2} sm={1} xs={1} md={2}> 
            </Grid>  
                    </Grid>
        {/* <Grid item
          lg={6} sm={12} xs={12} md={4}
          sx={{ textAlign: 'center' }}>
          <Grid container className="mt2">
            <Grid item sx={{ mt: 2 }}>
              <Typography sx={{ textAlign: 'center' }}
                className={isDarkMode ? "headersubhead headerhead_dark" : "headersubhead headerhead_light"}>
                Sites :
              </Typography>

            </Grid>
            <Grid item lg={4} sm={5} xs={5} md={4}>
              <Select
                //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                sx={{
                  color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(233, 233, 233, 1)',
                    border: '',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(233, 233, 233, 1)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(233, 233, 233, 1)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: isDarkMode ? "white !important" : "#161616",
                  }
                }}

                displayEmpty
                MenuProps={MenuProps}
                className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
              // value={org_type.organizationName}
              // onChange={(e)=>handleChange(e.target.value,'org_type')}
              >

                <MenuItem value={1}>Vidconnect-A </MenuItem>
                <MenuItem value={2}>Vidconnect-B</MenuItem>
                <MenuItem value={3}>Vidconnect-C </MenuItem>

              </Select>
            </Grid>
         
          </Grid>
        </Grid> */}

      </>
    }
    </>
  )
}