import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Alert, CircularProgress, Grid, Snackbar, Typography } from "@mui/material";
import { UserDetails } from "./UserDetails";
import { useDispatch } from "react-redux";
import { clearDeleteUserStatus, deleteUser, fetchUser, fetchUserList } from "../../../redux/actions/UserActions";
import { useSelector } from "react-redux";
import { SubUserList } from "./SubUserList";
import { DeleteModal } from "../../Modals/DeleteModal";
import { CreatorDetails } from "./CreatorDeatils";
import dayjs from "dayjs";
import { dateOnly, utcDateTimeToLocalTime } from "../../convertors/TimeConvertor";

export const UserView = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user.userData);
    const userListData = useSelector(state => state.user.userList)
    const userDeleteStatus = useSelector(state => state.user.deleteUser);
    const [userDetails, setUserDetails] = useState({});
    const [isDarkMode, setDarkMode] = useState(false);
    const [subUserList, setSubUserList] = useState([]);
    const [deleteId, setDeleteId] = useState(0)
    const [deleteUserName, setDeleteUserName] = useState('')
    const [deleteUserOrgId, setDeleteUserOrgId] = useState('')
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [preview, setPreview] = useState('');
    const [createdDate, setCreatedDate] = useState({
        date: '',
        time: ''
    })
    useEffect(() => {
        sessionStorage.setItem("subUser", false)
        const id = sessionStorage.getItem("viewUserId");
        dispatch(fetchUser(id));
        let data = {
            parent_id: id
        }
        dispatch(fetchUserList(data))

    }, []);

    useEffect(() => {
        if (!userData.isFetching && userData.payload && userData.payload.success === true) {
            const tempUser = userData.payload.data;
            if (tempUser.profilepic !== "") {
                const tempUrl = process.env.REACT_APP_API_BASE_URL + '/media' + tempUser.profilepic;
                setPreview(tempUrl);
            }
            sessionStorage.setItem("user_role_id", tempUser.role_id);
            const tempDate = dateOnly(tempUser.created_at);
            const temptime = utcDateTimeToLocalTime(tempUser.created_at)
            const createdtempDate = dateFormat(tempDate);
            let tempcreated = { ...createdDate };
            tempcreated['date'] = createdtempDate;
            tempcreated['time'] = temptime;
            setCreatedDate(tempcreated)
            setUserDetails(tempUser);
        }
        if (!userListData.isFetching && userListData.payload && userListData.payload.success === true) {
            const tempUser = userListData.payload.user_list;

            // const tempPagination = userListData.payload.pagination;
            setSubUserList(tempUser);
        }
        if (!userDeleteStatus.isFetching && userDeleteStatus.payload && userDeleteStatus.payload.success === true) {
            window.location.href = '/usermanagement'

        }
        if (!userDeleteStatus.isFetching && userDeleteStatus.error) {
            setShowAlert(true);
            const timeoutId = setTimeout(() => {
                setShowAlert(false);
                dispatch(clearDeleteUserStatus());
            }, 2000);

            return () => clearTimeout(timeoutId);

        }
    }, [userData, userListData, userDeleteStatus]);
    const dateFormat = (date) => {
        let formattedDate = dayjs(date);

        return formattedDate.format("MM/DD/YYYY")
    }
    const handleActiveMenuItems = () => {
        // setActiveMenuItem(menuItems);
        // window.location.href='/dashboard';
    }

    const handleDarkmode = () => {
        setDarkMode(!isDarkMode);

    }
    const handleCreate = () => {
        const id = sessionStorage.getItem("viewUserId");
        sessionStorage.setItem("subUser", true)
        sessionStorage.setItem("parent_id", id)
        window.location.href = '/usermanagement/user/subuser/create';

    }
    const handleView = (id) => {

        sessionStorage.setItem("viewSubUserId", id);
        window.location.href = '/usermanagement/subuser/view'
    }
    const handleDelete = (id, name, orgId) => {
        setDeleteId(id);
        setDeleteUserName(name);
        setOpenDeleteModal(true);
        setDeleteUserOrgId(orgId);
    }
    const handleDeleteModal = () => {
        setOpenDeleteModal(false);
    }
    const onDeleteConfirm = () => {
        let data = {
            created_user: parseInt(sessionStorage.getItem('id')),
            org_id: deleteUserOrgId,
            id: deleteId,
        }
        //  const id = deleteId;
        dispatch(deleteUser(data));

    }
    const handleEdit = (id) => {
        sessionStorage.setItem("edituserId", id);
        window.location.href = '/usermanagement/user/edit';
    }
    const handlealertClose = () => {
        setShowAlert(false)
    }
    return (
        <Layout
            isDarkMode={isDarkMode}
            handleDarkmode={handleDarkmode}
            handleActiveMenuItems={handleActiveMenuItems}
            activeMenuItem={"usermanagement"}
            activeMenuKey={"usermanagement"}
            isCreate={true}
        //placeHolder={"Search Organization name.."}
        >
            <DeleteModal
                handleDeleteModal={handleDeleteModal}
                openDeleteModal={openDeleteModal}
                ModalHead={'Delete User'}
                ModalBody={deleteUserName}
                onDeleteConfirm={onDeleteConfirm}
            />
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handlealertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handlealertClose}
                    severity="error"
                    variant="outlined"
                    sx={{ width: 'auto', color: '#C61A1A', backgroundColor: '#FCDFDF' }}

                >
                    User Deletion Failed
                </Alert>
            </Snackbar>
            <div style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", }} className="">
                <Grid container sx={{ mt: 5, ml: 9, '@media (max-width: 600px)': { ml: 0 } }}>
                    <Grid item lg={2} sm={12} xs={12} md={2}>
                        <Typography sx={{ textAlign: 'left', mt: 2 }}
                            className={isDarkMode ? "headersubhead  chartheading_color" : "headersubhead  light_text"}>
                            User Details
                            {/* Coming  Soon */}
                        </Typography>
                    </Grid>
                    {userData.isFetching ?
                        <Grid item lg={12} md={12} sm={12} xs={12} container
                            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                            <CircularProgress sx={{ color: '#FFAC0B' }} />
                        </Grid>
                        :
                        <>
                            <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                                <UserDetails
                                    userInfo={userDetails}
                                    handleDelete={handleDelete}
                                    handleEdit={handleEdit}
                                    preview={preview}
                                />
                            </Grid>
                            <Grid item lg={2} sm={12} xs={12} md={2}>
                                <Typography sx={{ textAlign: 'left', mt: 2 }}
                                    className={isDarkMode ? "headersubhead  chartheading_color" : "headersubhead  light_text"}>
                                    Creator Details
                                    {/* Coming  Soon */}
                                </Typography>
                            </Grid>
                            <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                                <CreatorDetails
                                    userInfo={userDetails}
                                    createdDate={createdDate}

                                />
                            </Grid>
                            <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                                <SubUserList
                                    isDarkMode={isDarkMode}
                                    handleCreate={handleCreate}
                                    TableData={subUserList}
                                    handleView={handleView}
                                    subUser={true}
                                />
                            </Grid>
                        </>
                    }

                </Grid>
            </div>
        </Layout>
    )
}