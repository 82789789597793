import React from 'react';
import { RiRoadMapLine } from "react-icons/ri";
import { Grid, IconButton, Typography } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';

export const DroppableItem = ({ checkPoints, index, id,lastIndex ,handleDeleteCheckPoint,isOrder}) => {
    return (
        <>
            <Draggable draggableId={id.toString()} index={index} key={id.toString()}>
                {(provided) => (
                    <Grid item sx={{ mt: 2 }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}   
                        {...provided.dragHandleProps}>
                        <div style={{ display: 'flex' }}>
                            <div className="drop_list_box" >
                                <Grid container>
                                    <Grid item xs={10.5} sm={10.5} lg={10.5} md={10.5}
                                        sx={{
                                            pt: 2,
                                            textAlign: 'center'
                                        }}>
                                        <Grid container sx={{
                                            justifyContent: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Grid item  >
                                                <RiRoadMapLine size="20px" color={'rgba(255, 172, 11, 1)'} style={{ mt: 1, textAlign: 'center' }} />
                                            </Grid>
                                            <Grid item  > <Typography className="dragitem">{checkPoints}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} lg={1} md={1}
                                      sx={{
                                        pt: 2,
                                        textAlign: 'center',
                                        position: 'relative',
                                    }}> 
                                        <IconButton
                                            style={{ backgroundColor:'#28A745', width: '5px', height: '5px',position: 'relative', }}
                                            aria-label="arrow-down"
                                        >{!isOrder? 
                                        <CheckIcon style={{ color: 'white',fontSize:'13px' }}/>
                                        :!lastIndex  ?
                                            <ArrowDownwardIcon style={{ color: 'white',fontSize:'13px' }} />
                                            :
                                            <CheckIcon style={{ color: 'white',fontSize:'13px' }}/>
                                        }
                                        </IconButton>
                                      { index!==0  && isOrder ? <div
                                            style={{
                                                borderLeft: '1px dashed #28A745',
                                                height: '160%',
                                                position: 'absolute',
                                                top: '-105%',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                            }}
                                        />:null}
                                    </Grid>
                                </Grid>
                            </div>
                            <IconButton onClick={()=>{handleDeleteCheckPoint(id)}}>
                                <CancelOutlinedIcon sx={{ color: '#C61A1A' }} />
                            </IconButton>
                        </div>
                    </Grid>
                )}
            </Draggable>
        </>
    )
}
DroppableItem.propTypes = {
    checkPoints: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    lastIndex: PropTypes.bool.isRequired,
    handleDeleteCheckPoint: PropTypes.func.isRequired,
    isOrder: PropTypes.bool.isRequired,
};